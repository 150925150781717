export class QuickFilterConstant {
  public static QuickFilter = [
    {
      label: "Email Address",
      value: "email",
      operator: [
        {
          label: "Is",
          value: "is",
        },
      ],
      selectedOperator: "is",
      optionsType: "SELECT",
      options: [
        {
          label: "Available",
          value: "yes",
        },
        {
          label: "Not Available",
          value: "no",
        },
      ],
      selectedOption: "yes",
    },
    {
      label: "Title",
      value: "title",
      operator: [
        {
          label: "Is",
          value: "is",
        },
      ],
      selectedOperator: "is",
      optionsType: "SELECT",
      options: [
        {
          label: "Available",
          value: "yes",
        },
        {
          label: "Not Available",
          value: "no",
        },
      ],
      selectedOption: "yes",
    },
    {
      label: "Phone Number",
      value: "phoneNumber",
      operator: [
        {
          label: "Is",
          value: "is",
        },
      ],
      selectedOperator: "is",
      optionsType: "SELECT",
      options: [
        { label: "Available", value: "yes" },
        { label: "Not Available", value: "no" },
      ],
    },
    {
      label: "Phone Number Type",
      value: "numberType",
      operator: [
        {
          label: "Is",
          value: "is",
        },
        {
          label: "Is Not",
          value: "is-not",
        },
      ],
      selectedOperator: "is",
      optionsType: "MULTI-SELECT",
      options: [
        { label: "Voip", value: "voip" },
        { label: "Landline", value: "landline" },
        { label: "Mobile", value: "mobile" },
        { label: "Toll Free", value: "toll-free" },
        { label: "N/A", value: "invalid" },
      ],
      selectedOption: ["invalid"],
    },
    {
      label: "Tags",
      value: "tags",
      operator: [
        {
          label: "Is",
          value: "is",
        },
        {
          label: "Is Not",
          value: "is-not",
        },
      ],
      selectedOperator: "is",
      selectedOption: [],
      optionsType: "MULTI-SELECT",
    },
    {
      label: "Teams",
      value: "teams",
      operator: [
        {
          label: "Is",
          value: "is",
        },
        {
          label: "Is Not",
          value: "is-not",
        },
      ],
      selectedOperator: "is",
      selectedOption: [],
      optionsType: "MULTI-SELECT",
    },
    {
      label: "Roles",
      value: "roles",
      operator: [
        {
          label: "Is",
          value: "is",
        },
        {
          label: "Is Not",
          value: "is-not",
        },
      ],
      selectedOperator: "is",
      selectedOption: [],
      optionsType: "MULTI-SELECT",
    },
    {
      label: "Users",
      value: "users",
      operator: [
        {
          label: "Is",
          value: "is",
        },
        {
          label: "Is Not",
          value: "is-not",
        },
      ],
      selectedOperator: "is",
      selectedOption: [],
      optionsType: "MULTI-SELECT",
    },
    {
      label: "Assignment",
      value: "assignment",
      operator: [
        {
          label: "Is",
          value: "is",
        },
        {
          label: "Is Not",
          value: "is-not",
        },
      ],
      selectedOperator: "is",
      selectedOption: [],
      optionsType: "MULTI-SELECT",
    },
    {
      label: "Drips",
      value: "drips",
      operator: [
        {
          label: "Is Active",
          value: "is",
        },
        {
          label: "Is Not Active",
          value: "is-not",
        },
      ],
      selectedOperator: "is",
      selectedOption: [],
      optionsType: "MULTI-SELECT",
    },
    {
      label: "Hot Lead",
      value: "hotLead",
      operator: [
        {
          label: "Is",
          value: "is",
        },
      ],
      selectedOperator: "is",
      optionsType: "SELECT",
      options: [
        { label: "True", value: true },
        { label: "False", value: false },
      ],
    },
    {
      label: "Date Added",
      value: "dateAdded",
      operator: [
        {
          label: "Is",
          value: "is",
        },
        {
          label: "Before",
          value: "before",
        },
        {
          label: "After",
          value: "after",
        },
        {
          label: "From",
          value: "between",
        },
      ],
      selectedOperator: "is",
      options: [
        { label: "Lead Create", value: "leadCreated" },
        { label: "Open Lead", value: "openLeadDate" },
      ],
      selectedOption: "leadCreated",
      optionsType: "DATE",
    },
    {
      label: "Lead Source",
      value: "leadSource",
      operator: [
        {
          label: "Is",
          value: "is",
        },
        {
          label: "Is Not",
          value: "not",
        },
      ],
      selectedOperator: "is",
      selectedOption: [],
      optionsType: "MULTI-SELECT",
    },
    {
      label: "Campaign",
      value: "campaign",
      operator: [
        {
          label: "Is",
          value: "is",
        },
        {
          label: "Is Not",
          value: "not",
        },
      ],
      selectedOperator: "is",
      selectedOption: [],
      optionsType: "MULTI-SELECT",
    },
    {
      label: "Lead Created Date",
      value: "dateTime",
      operator: [
        {
          label: "Is",
          value: "is",
        },
      ],
      selectedOperator: "is",
      optionsType: "SELECT",
      options: [
        { label: "Today's Leads", value: "today" },
        { label: "Biweekly Leads", value: "weekly" },
        { label: "Nurtured Leads", value: "nurtured" },
        { label: "Quarterly Leads", value: "quarter" },
      ],
    },
  ];
}
