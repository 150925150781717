export class StatusConstant {
  /********** Add Buyer-Lead, Seller-Lead, Vendor Tage Type ************/
  public static TagsType = {
    BUYERS: "1",
    SELLERS: "2",
    VENDORS: "3",
    CONTACTS: "4",
  };

  /********** Lead, Buyer, Profile, Rvm moduleId List ************/
  public static ModuleId = {
    LEAD: "647af7ecc36d133152ad379c",
    BUYER: "647af7e8c36d133152ad3799",
    PROFILE: "601cf3c070419f19b74a6882",
    RVM: "64e86dda915ed89554dfa064",
    CONTACT: "651132a2efaf5979e6356dc5",
  };

  /********** Buyer Lead MainStatus List ************/
  public static BuyerLeadStatus = {
    NEW_LEAD: "6493e115ec905bf94d5882a9",
    NO_CONTACT_MADE: "6493e188ec905bf94d5882aa",
    CONTACT_MADE: "6493e1a0ec905bf94d5882ab",
    APPOINTMENT_SET: "6493e1b6ec905bf94d5882ac",
    DUE_DILIGENCE: "6493e1d0ec905bf94d5882ad",
    OFFERS_MADE: "6493e1e6ec905bf94d5882ae",
    ACCEPTED_OFFER: "6493e202ec905bf94d5882af",
    FOLLOW_UP: "65a774e5bec0a46671857b67",
    DEAD: "65a773f7bec0a46671857b65",
  };

  /********** Seller Lead MainStatus List ************/
  public static SellerLeadStatus = {
    NEW_LEAD: "64b117c10f2bd68559a220d2",
    NO_CONTACT_MADE: "64b1183e0f2bd68559a220d3",
    CONTACT_MADE: "64b118ae0f2bd68559a220d4",
    APPOINTMENT_SET: "64b118f00f2bd68559a220d5",
    DUE_DILIGENCE: "64b11b7b0f2bd68559a220d6",
    OFFERS_MADE: "64b11bab0f2bd68559a220d7",
    LISTING_SECURED: "64c1fa9f074188bbd4f50d76",
    FOLLOW_UP: "65a774ebbec0a46671857b68",
    DEAD: "65a774babec0a46671857b66",
  };

  /********** Buyer Transaction MainStatus List ************/
  public static BuyerTransactionStatus = {
    BUYER_ACCEPTED_OFFER: "64c8eaae324c2382e1eb0ad5",
    EMD_SUBMITTED: "64c8eb04324c2382e1eb0ad6",
    INSPECTION: "64c8eb11324c2382e1eb0ad7",
    APPRAISAL: "64c8eb18324c2382e1eb0ad8",
    FINANCING: "651b9f8457054230ab825911",
    CLEAR_TO_CLOSE: "64c8eb1f324c2382e1eb0ad9",
    CLOSED: "656d7da55f6f7b55b40df22b",
  };

  /********** Seller Transaction MainStatus List ************/
  public static SellerTransactionStatus = {
    NEW_LISTING: "64c8ec08324c2382e1eb0ada",
    LIVE_ON_MLS: "64c8ec5e324c2382e1eb0adb",
    OPEN_HOUSE: "651d1c8592f238c2a8032fef",
    SHOWINGS_SCHEDULED: "64c8ecb6324c2382e1eb0adc",
    EVALUATING_OFFERS: "651d1d6792f238c2a8032ff0",
    UNDER_CONTRACT: "64c8ecf6324c2382e1eb0ade",
    PENDING: "651d1f2292f238c2a8032ff1",
    CLEAR_TO_CLOSE: "651b9fa357054230ab825912",
    CLOSED: "656d7b4f5f6f7b55b40df0be",
  };

  /********** General Module Id List ************/
  public static GeneralModule = {
    GENERAL_MODULE_ID: "647af7cbc36d133152ad3793",
    GENERAL_MAIN_STATUS_ID: "652e81c32f97492eb064e43f",
    GENERAL_SUB_MODULE_ID: "658ada6ebe9f2e1acdaaf4ba",
  };

  /********** All Plan Id List ************/
  public static PlanIdList = {
    BASIC: "64f02857475c35b016f5cbb0",
    PRO: "64f0291d475c35b016f5cbb1",
    ENTERPRISE: "64f029a4475c35b016f5cbb2",
  };

  public static RolesList = {
    "64a002220edad2d4b59eb4db": "Account Owner",
    "64e2d956e4e726ec35b3618a": "Team Leader",
    "64e2d946e4e726ec35b36189": "Showing Assistant",
    "64e2d90ce4e726ec35b36187": "Operations Manager",
    "64e2d8fbe4e726ec35b36186": "Office Manager",
    "64e2d8e2e4e726ec35b36185": "Marketing Manager",
    "64e2d8cbe4e726ec35b36184": "Marketing Assistant",
    "64e2d8afe4e726ec35b36183": "Managing Broker",
    "64e2d89ae4e726ec35b36182": "Listing Manager",
    "64e2d86fe4e726ec35b36181": "Listing Coordinator",
    "64e2d859e4e726ec35b36180": "ISA",
    "64e2d845e4e726ec35b3617f": "Executive Assistant",
    "64e2d82ae4e726ec35b3617e": "Cold Caller",
    "64a002b70edad2d4b59eb4de": "Buyer's Assistant",
    "64a002330edad2d4b59eb4dc": "Agent",
  };

  public static RoleDataObject = {
    teamLeader: "64e2d956e4e726ec35b3618a",
    showingAssistant: "64e2d946e4e726ec35b36189",
    operationsManager: "64e2d90ce4e726ec35b36187",
    officeManager: "64e2d8fbe4e726ec35b36186",
    marketingManager: "64e2d8e2e4e726ec35b36185",
    marketingAssistant: "64e2d8cbe4e726ec35b36184",
    managingBroker: "64e2d8afe4e726ec35b36183",
    listingManager: "64e2d89ae4e726ec35b36182",
    listingCoordinator: "64e2d86fe4e726ec35b36181",
    isa: "64e2d859e4e726ec35b36180",
    executiveAssistant: "64e2d845e4e726ec35b3617f",
    coldCaller: "64e2d82ae4e726ec35b3617e",
    buyersAssistant: "64a002b70edad2d4b59eb4de",
    agent: "64a002330edad2d4b59eb4dc",
    accountOwner: "64a002220edad2d4b59eb4db",
  };

  public static DirectMailPostCards = {
    postCardOne: "6576f38f7420c71e9766d811",
    postCardTwo: "6576f4326c3adf1f6e41ed3e",
    postCardThree: "6576f5026c3adf1f6e41efe6",
    postCardFour: "6576f50c6c3adf1f6e41efe9",
    postCardFive: "6577f7a05d261c33c71c7e72",
    postCardSix: "6577f7a85d261c33c71c7e75",
  };

  public static GtagSendTo = {
    id: "AW-773287020/ZLVTCMeU8OEDEOzY3fAC",
    script: `
    console.log("sfvbgyuef hevf sdv ")
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "AW-773287020");
    gtag('event', 'conversion', {
      send_to: 'AW-773287020/ZLVTCMeU8OEDEOzY3fAC',
    });
    console.log("sfvbgyuef hevf sdv ")
        `,
  };

  public static TagModuleId = "auiueiw9230";

  public static sideBarGoogleForm =
    "https://docs.google.com/forms/d/e/1FAIpQLScq7XJDL6NjUj_2Y05810VWSCXS4oEsySAJq_U_6Y4BtZsFRg/viewform";
    
  public static agentUrl = "https://agentprocrm.com/";
}
