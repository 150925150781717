<div>
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{ data.header }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content>
  <div class="container">
    <div class="row">
      <div
        class="col-12"
        [class.re-information]="data.type != 'contingency'"
        [class.re-info]="data.type == 'contingency'"
        *ngIf="data.text"
      >
        <p [innerHTML]="data.text"></p>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  class="{{ data.actionButtonAlignment || 'text-center' }}"
  *ngIf="!data.hideNoButton || !data.hideYesButton || data.buttons?.length"
>
  <ng-container *ngIf="data.buttons; else else_block">
    <button
      mat-raised-button
      mat-dialog-close
      color="primary"
      *ngFor="let button of data?.buttons"
    >
      {{ button.title }}
    </button>
  </ng-container>

  <ng-template #else_block>
    <button
      mat-raised-button
      [mat-dialog-close]="false"
      class="re-cancelbtn"
      *ngIf="noButtonTitle && !data.hideNoButton"
      (click)="onDismiss()"
    >
      {{ noButtonTitle }}
    </button>
    <button
      (click)="submit()"
      mat-raised-button
      color="primary"
      *ngIf="yesButtonTitle && !data.hideYesButton"
      class="{{ data.confirmButtonClass || '' }}"
      cdkFocusInitial
    >
      {{ yesButtonTitle }}
    </button>
  </ng-template>
</mat-dialog-actions>
