import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmationModel } from "../../../utils/models/confirm-dialog";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  yesButtonTitle: string = "Yes";
  noButtonTitle: string = "No";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModel,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    if (data?.yesTitle) {
      this.yesButtonTitle = data?.yesTitle;
    }

    if (data?.noTitle) {
      this.noButtonTitle = data?.noTitle;
    }
  }

  ngOnInit(): void {}

  submit() {
    this.dialogRef.close(this.data);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close();
  }
}
