<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Task Template - Status Automation
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<form
  [formGroup]="addEditTaskTemplateForm"
  (ngSubmit)="onTaskTemplateSubmit()"
  autocomplete="off"
  novalidate
>
  <mat-dialog-content>
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="form-group">
            <label class="re-labels">
              Template Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              name="templateName"
              formControlName="templateName"
              placeholder="A self-explanatory template name here..."
              class="form-control"
            />
            <div class="text-danger re-error-txt">
              <span *ngIf="submitted && hasError('templateName', 'required')">
                {{
                  messageConstant.requiredField.replace(
                    "Field",
                    "Template Name"
                  )
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="form-group">
            <label class="re-labels">
              Task Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              name="title"
              formControlName="title"
              placeholder="Descriptive task title"
              class="form-control"
            />
            <div class="text-danger re-error-txt">
              <span *ngIf="submitted && hasError('title', 'required')">
                {{
                  messageConstant.requiredField.replace("Field", "Task Name")
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="form-group">
            <label class="re-labels">
              Task Type <span class="text-danger">*</span></label
            >
            <select
              name="taskTypeOption"
              formControlName="taskTypeOption"
              class="form-control"
            >
              <option value="">Select task type</option>
              <option *ngFor="let task of taskType" value="{{ task.id }}">
                {{ task.name }}
              </option>
            </select>
            <div class="text-danger">
              <span *ngIf="submitted && hasError('taskTypeOption', 'required')">
                {{
                  messageConstant.requiredField.replace("Field", "Task Type")
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="form-group">
            <label class="re-labels"> Detail</label>
            <textarea
              name="taskDetail"
              formControlName="taskDetail"
              placeholder="Details text here..."
              class="form-control"
              rows="2"
            ></textarea>
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="row">
            <div class="col-12 col-md-2">
              <div class="form-group">
                <label class="re-labels"> Assigned to</label>
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn" [class.active]="!selectUser">
                    <input
                      type="radio"
                      name="assignedTo"
                      (click)="assignedTask('Assigned Role')"
                      autocomplete="off"
                      [checked]="!selectUser"
                    />
                    Role
                  </label>
                  <label class="btn" [class.active]="selectUser">
                    <input
                      type="radio"
                      name="assignedTo"
                      (click)="assignedTask('Assigned User')"
                      autocomplete="off"
                      [checked]="true"
                    />
                    User
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group" *ngIf="!selectUser">
                <label class="re-labels">
                  Select Role <span class="text-danger">*</span></label
                >
                <select
                  name="assignRoleId"
                  formControlName="assignRoleId"
                  class="form-control"
                >
                  <option value="">Select role</option>
                  <option
                    *ngFor="let role of assignRoleList"
                    value="{{ role._id }}"
                  >
                    {{ role?.name }}
                  </option>
                </select>
                <div class="text-danger re-error-txt">
                  <span *ngIf="submitted && validationShow">
                    {{ validationMess }}
                  </span>
                </div>
              </div>
              <div class="form-group" *ngIf="selectUser">
                <label class="re-labels">
                  Select User <span class="text-danger">*</span></label
                >
                <select
                  name="assignUserId"
                  formControlName="assignUserId"
                  class="form-control"
                >
                  <option value="">Select user</option>
                  <option
                    *ngFor="let user of assignUserList"
                    [value]="user.value"
                  >
                    {{ user?.label || user?.name }}
                  </option>
                </select>
                <div class="text-danger re-error-txt">
                  <span *ngIf="submitted && validationShow">
                    {{ validationMess }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="re-labels"> Priority</label>
                <div class="re-priority-select">
                  <select
                    name="taskPriority"
                    formControlName="taskPriority"
                    class="form-control re-urgent"
                    [class.re-urgent]="priorityClass == 1"
                    [class.re-urgent-2]="priorityClass == 2"
                    [class.re-urgent-3]="priorityClass == 3"
                    [class.re-urgent-4]="priorityClass == 4"
                    [class.re-urgent-5]="priorityClass == 5"
                    (change)="
                      onChangePriority(
                        addEditTaskTemplateForm.value.taskPriority
                      )
                    "
                  >
                    <option
                      style="color: black; background-color: #fff"
                      value="1"
                    >
                      Urgent Priority
                    </option>
                    <option
                      style="color: black; background-color: #fff"
                      value="2"
                    >
                      High Priority
                    </option>
                    <option
                      style="color: black; background-color: #fff"
                      value="3"
                    >
                      Medium Priority
                    </option>
                    <option
                      style="color: black; background-color: #fff"
                      value="4"
                    >
                      Low Priority
                    </option>
                    <option
                      style="color: black; background-color: #fff"
                      value="5"
                    >
                      None Priority
                    </option>
                  </select>
                  <i class="fa fa-flag-o" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="row">
            <div class="col-12 col-md-12">
              <label class="re-labels">
                Task Due <span class="text-danger">*</span></label
              >
              <div class="row mb-2">
                <div class="col-12 col-md-3">
                  <label class="radiobtns">
                    <input
                      type="radio"
                      name="taskdue"
                      (click)="taskDueDetails('One Hour')"
                      [checked]="
                        addEditTaskTemplateForm?.value?.taskDueType == 0
                      "
                    />
                    <span class="checkmark">One Hour</span>
                  </label>
                </div>
                <div class="col-12 col-md-3">
                  <label class="radiobtns">
                    <input
                      type="radio"
                      name="taskdue"
                      (click)="taskDueDetails('One Day')"
                      [checked]="
                        addEditTaskTemplateForm?.value?.taskDueType == 1
                      "
                    />
                    <span class="checkmark">One Day</span>
                  </label>
                </div>
                <div class="col-12 col-md-3">
                  <label class="radiobtns">
                    <input
                      type="radio"
                      name="taskdue"
                      (click)="taskDueDetails('One Week')"
                      [checked]="
                        addEditTaskTemplateForm?.value?.taskDueType == 2
                      "
                    />
                    <span class="checkmark">One Week</span>
                  </label>
                </div>
                <div class="col-12 col-md-3">
                  <label class="radiobtns">
                    <input
                      type="radio"
                      name="taskdue"
                      (click)="taskDueDetails('Custom')"
                      [checked]="
                        addEditTaskTemplateForm?.value?.taskDueType == 3
                      "
                    />
                    <span class="checkmark">Custom</span>
                  </label>
                </div>
              </div>
              <div class="row" *ngIf="customTask">
                <div class="col-12 col-md-12">
                  <label class="re-labels">
                    Due In <span class="text-danger">*</span></label
                  >
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <select
                      name="taskDueTime"
                      formControlName="taskDueTime"
                      class="form-control"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let number of minutesList"
                        value="{{ number }}"
                      >
                        {{ number }}
                      </option>
                    </select>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="submitted && hasError('taskDueTime', 'required')"
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "custom Due"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <select
                      name="taskDueTimeType"
                      formControlName="taskDueTimeType"
                      class="form-control"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let timeType of taskTimeTypeList"
                        value="{{ timeType }}"
                      >
                        {{ timeType }}
                      </option>
                    </select>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="
                          submitted && hasError('taskDueTimeType', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "custom Due"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="re-notify">
            <div class="radiosec mb-2">
              <p class="radotext">
                Would you like to notify the assignee on task creation?
              </p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox1"
                  (click)="onInstantNotification()"
                  [checked]="instantNotification"
                />
                <span class="slider"></span>
                <span value="false" class="text1">No</span>
                <span value="true" class="text2">Yes</span>
              </label>
            </div>
            <div class="re-radiolist" *ngIf="instantNotification === true">
              <label class="re-radiobtn">
                <input
                  type="radio"
                  name="smsAndEmail"
                  formControlName="smsAndEmail"
                  value="both"
                />
                <span class="re-checkmark"></span>Both SMS and Email
              </label>
              <label class="re-radiobtn">
                <input
                  type="radio"
                  name="smsAndEmail"
                  formControlName="smsAndEmail"
                  value="sms"
                />
                <span class="re-checkmark"></span>SMS
              </label>
              <label class="re-radiobtn">
                <input
                  type="radio"
                  name="smsAndEmail"
                  formControlName="smsAndEmail"
                  value="email"
                />
                <span class="re-checkmark"></span>Email
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div
            class="row"
            *ngIf="
              addEditTaskTemplateForm.get('reminders')['controls'].length > 0
            "
          >
            <div class="col-12 col-md-4 col-sm-12">
              <label class="re-labels">
                Remind Via <span class="text-danger">*</span></label
              >
            </div>
            <div class="col-12 col-md-4 col-sm-5">
              <label class="re-labels">
                Remind Before <span class="text-danger">*</span></label
              >
            </div>
          </div>

          <div formArrayName="reminders">
            <div
              *ngFor="
                let control of addEditTaskTemplateForm.get('reminders')[
                  'controls'
                ];
                let i = index
              "
            >
              <div [formGroupName]="i" class="row">
                <div class="col-12 col-md-4 col-sm-12">
                  <div class="form-group">
                    <select formControlName="via" class="form-control">
                      <option value="">--Select--</option>
                      <option value="sms">SMS</option>
                      <option value="email">Email</option>
                    </select>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="
                          submitted &&
                          (control.get('via').invalid ||
                            (control.get('via').invalid &&
                              control.get('via').touched))
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Remind Via"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 col-sm-5">
                  <div class="form-group">
                    <select formControlName="before" class="form-control">
                      <option value="">--Select--</option>
                      <option
                        *ngFor="let number of minutesList"
                        value="{{ number }}"
                      >
                        {{ number }}
                      </option>
                    </select>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="
                          submitted &&
                          (control.get('before').invalid ||
                            (control.get('before').invalid &&
                              control.get('before').touched))
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Remind Before"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3 col-sm-5">
                  <div class="form-group">
                    <select formControlName="time" class="form-control">
                      <option value="">--Select--</option>
                      <option
                        *ngFor="let timeType of taskTimeTypeList"
                        value="{{ timeType }}"
                      >
                        {{ timeType }}
                      </option>
                    </select>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="
                          submitted &&
                          (control.get('time').invalid ||
                            (control.get('time').invalid &&
                              control.get('time').touched))
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Remind Time"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-1 col-sm-2">
                  <div class="form-group text-center re-trash-icon">
                    <a class="pointer" (click)="removeReminder(i)">
                      <img src="assets/images/delete-red.svg" alt="delete" />
                    </a>
                  </div>
                </div>

                <div class="col-12 re-invalid-reminder" #errorReminder>
                  <span
                    class="text-danger"
                    *ngIf="
                      submitted &&
                      !control.get('via').invalid &&
                      !control.get('before').invalid &&
                      !control.get('time').invalid &&
                      reminderErrors.length &&
                      reminderErrors[i]
                    "
                  >
                    {{ reminderErrors[i] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12 mb-3">
              <a (click)="addReminder()" class="btn btn-block re-addreminder">
                Add Reminder
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" style="padding-top: 14px !important">
    <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
    <button cdkFocusInitial class="btn btn-teal" (click)="save()">
      {{ button }} Template
    </button>
  </mat-dialog-actions>
</form>
