import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseUrl } from "src/app/utils/base-url-constants";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CallFlowService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem("token");

  constructor(private _commonFunction: CommonFunctionsService) {}

  saveFlow = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + "save";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editFlow = (data, flowId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.callFlowUrl + "edit";
    if (flowId) {
      endpoint += `/${flowId}`;
    }
    return this._commonFunction.globalPostService(endpoint, data);
  };

  flowInfo = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + "details";
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  deleteFlow = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + "delete";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  makeInAppPayment = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "inAppAnswerCharge";
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  getInAppAmt = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "getInAppAnswerChargeAmount";
    return this._commonFunction.globalPostService(endpoint, flow);
  };
}
