export class FilterListConstant {
  public static filterList = [
    {
      label: "Team",
      value: "team",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },

    {
      label: "Roles",
      value: "roles",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Users",
      value: "users",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Assignment",
      value: "assignment",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Tags",
      value: "tags",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Drips",
      value: "drips",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Task Assigned",
      value: "taskAssigned",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Task Type",
      value: "taskType",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Task Due",
      value: "taskDue",
      operator: [
        { label: "Is", value: "is" },
        { label: "Before", value: "before" },
        { label: "After", value: "after" },
        { label: "From", value: "between" },
      ],
      optionsType: "DATE",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Pending Task",
      value: "pendingTask",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Date",
      value: "date",
      operator: [
        { label: "Is", value: "is" },
        { label: "Before", value: "before" },
        { label: "After", value: "after" },
        { label: "From", value: "between" },
      ],
      optionsType: "DATE",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Lead Age",
      value: "leadAge",
      operator: [
        { label: "Is", value: "is" },
        { label: "Before", value: "before" },
        { label: "After", value: "after" },
        { label: "From", value: "between" },
      ],
      optionsType: "DATE",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Hot Lead",
      value: "hotLead",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Lead Types",
      value: "leadTypes",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Source",
      value: "source",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Campaign",
      value: "campaign",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },
    {
      label: "Missing Info",
      value: "missingInfo",
      operator: [
        { label: "Is", value: "is" },
        { label: "Is Not", value: "not" },
        { label: "Includes", value: "in" },
      ],
      optionsType: "MULTI-SELECT",
      show: 1,
      selectedOperator: "is",
    },
  ];
}
