import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { BaseUrl } from "../../utils/base-url-constants";
import { environment } from "src/environments/environment";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
@Injectable({
  providedIn: "root",
})
export class UserService {
  baseUrl = environment.baseUrl;
  redirectUrl = environment.appUrl + BaseUrl.confirmEmailRedirectLink;
  public token = localStorage.getItem("token");
  constructor(private _commonFunction: CommonFunctionsService) {}

  /**
   * FUNCTION: GET USER DETAILS
   * @param {Object} data
   * @returns OBJECT OF USER DETAILS
   */
  getUserDetails = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "get_user_detail";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCoursePercentage = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "getCoursePercentage";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUsers = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "getUsers";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUserRoleList = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "sub_user_list/main_user";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateNotificationPreferences = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "updateNotificationPreferences";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editUserDetails = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "edit_user_details";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateUserProfileImage = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "updateUserProfileImage";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateCompanyLogoImage = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "updateCompanyLogoImage";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  changePassword = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "changePassword";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSubUserDetail = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "getSubUserDetail";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSubUserList = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "getSubUserList";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getRoles = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.roleUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUserAccessData = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "getUserAccessData";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addRoleAccess = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "add-role-access";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addEditSubUser = (data, isEdit): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.user + (isEdit ? "editSubUser" : "addSubUser");
    return this._commonFunction.globalPostService(
      endpoint,
      data,
      this.getRequestHeaders()
    );
  };

  removeUserRoleAccess = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "remove-role-access";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCampaigns = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.teamUrl + "listWithNumber";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: ADD A SUB USER
   * @param {Object} data
   * @returns IF SUCCESS ADDED USER OBJECT ELSE ERROR
   */
  addUser = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "addSubUser";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: EDIT A USER
   * @param {Object} data
   * @returns IF SUCCESS EDITED USER OBJECT ELSE ERROR
   */
  editUser = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "editSubUser";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  protected getRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    let headers;
    headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Redirect-Url": this.redirectUrl,
    });
    return { headers: headers };
  }

  deleteSubUser = (data: any): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.user + "deleteSubUser";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  fileUploadForprofileandOther = (
    data: any,
    isProfile,
    moduleId
  ): Observable<any> => {
    let endpoint = "";
    if (isProfile) {
      endpoint = environment.baseUrl + BaseUrl.sharedUrl + "file-upload";
      endpoint += `?moduleId=${moduleId}&isPublic=1`;
    } else {
      endpoint = environment.baseUrl + BaseUrl.sharedUrl + "file-upload";
      endpoint += `?moduleId=${moduleId}&isPublic=0`;
    }
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addUserExperience = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "addUserExperience";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUserExperience = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "getUserExperience";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  toggleVacationMode = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "vacation-mode-on-off";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  dialerCharge = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "dialerCharge";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getDialerChargeAmount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "getDialerChargeAmount";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSubUserChargeAmount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "getSubUserChargeAmount";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  setPassword = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "setPassword";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSpecificRoleUser = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "getSpecificRoleUser";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  userDetailData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "userDetailData";
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
