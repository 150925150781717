// MODULES
import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChildren,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { saveAs } from "file-saver";

// SERVICS
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SharedService } from "../../shared.service";

// UTILS
import { MessageConstant } from "src/app/utils/message-constant";
import { ErrorModel } from "src/app/utils/models/error";
import { ResponseModel } from "src/app/utils/models/response";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-media-preview",
  templateUrl: "./media-preview.component.html",
  styleUrls: ["./media-preview.component.scss"],
})
export class MediaPreviewComponent implements OnInit, OnDestroy {
  @ViewChildren("viewPlayer") viewPlayer;
  @ViewChildren("audioPlayer") audioPlayer;

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (this._sharedService.checkLoader("master")) {
      return;
    }

    if (event.keyCode === 37) {
      this.changeSlide();
    }

    if (event.keyCode === 39) {
      this.changeSlide(true);
    }
  }

  type: string = "video";
  src: any = this._sanitizer.bypassSecurityTrustUrl("");
  currentIndex: number = 0;
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _loaderService: NgxUiLoaderService,
    private _sharedService: SharedService,
    private _toastrService: ToastrService,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const { file, index } = this.data;
    this.currentIndex = index || 0;

    if (file?.fileType === "multiple") {
      return;
    }

    this.getFile(file);
  }

  isImage(type) {
    return !!(
      type === "jpg" ||
      type === "jpeg" ||
      type === "png" ||
      type === "svg" ||
      type === "gif" ||
      type === "webp" ||
      type === "heic" ||
      type === "ico"
    );
  }

  isVideo(type) {
    return !!(
      type === "mp4" ||
      type === "avi" ||
      type === "mov" ||
      type === "wmv" ||
      type === "3gpp" ||
      type === "flv"
    );
  }

  isDoc(type) {
    return !!(
      type === "docx" ||
      type === "doc" ||
      type === "xls" ||
      type === "xlsx" ||
      type === "csv" ||
      type === "pdf" ||
      type === "txt"
    );
  }

  isAudio(type) {
    return !!(
      type === "mp3" ||
      type === "m4a" ||
      type === "ema" ||
      type === "wav" ||
      type === "webm"
    );
  }

  onSwipe(evt) {
    const x =
      Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? "right" : "left") : "";

    if (x === "right") {
      this.changeSlide();
    } else if (x === "left") {
      this.changeSlide(true);
    }
  }

  changeSlide(bool?) {
    if (this.viewPlayer) {
      const list = this.viewPlayer?.toArray();
      if (list) {
        list.filter((input) => {
          input?.nativeElement?.pause();
        });
      }
    }

    if (this.audioPlayer) {
      const list = this.audioPlayer?.toArray();
      if (list) {
        list.filter((input) => {
          input?.pause();
        });
      }
    }

    const newIndex = bool ? this.currentIndex + 1 : this.currentIndex - 1;

    if (newIndex < 0 || newIndex > this.data?.items.length - 1) {
      return;
    }

    this.currentIndex = newIndex;

    if (this.data?.items[this.currentIndex]?.src) {
      return;
    }

    this.getFile(this.data?.items[this.currentIndex]);
  }

  getFile(data) {
    if (data?.thumbnailUrl) {
      return;
    }

    if (!data?.thumbnailUrl && data?.fileType?.toLowerCase() === "heic") {
      return;
    }

    if (!this.checkFileType(data.fileType)) {
      this.data.items[this.currentIndex].src = `/assets/images/${this.getIcon(
        data?.fileType
      )}`;
      return;
    }

    let obj = {
      src: data?.photo,
    };

    if (!this.data.isPublic) {
      obj["moduleId"] = data?.moduleId;
    }

    this._loaderService.start();
    this._sharedService.getFile(obj, this.data.isPublic).subscribe(
      (response) => {
        this._loaderService.stop();
        if (response) {
          const fileType =
            this.data.items[this.currentIndex]?.fileType?.toLowerCase();

          if (this.isAudio(fileType)) {
            var blob = new Blob([response], {
              type: "audio/mp3",
            });
            const url = URL.createObjectURL(blob);
            this.data.items[this.currentIndex].src = url;

            this.initializeAudio();
          } else if (this.isDoc(fileType)) {
            var blob = new Blob([response], {
              type: "application/pdf",
            });
            const url = URL.createObjectURL(blob);
            this.data.items[this.currentIndex].src = url;
            this.data.items[this.currentIndex].src = this.sanitizeUrl(
              this.data.items[this.currentIndex].src
            );
          } else {
            let objectURL = URL.createObjectURL(response);
            this.data.items[this.currentIndex].src =
              this._sanitizer.bypassSecurityTrustUrl(objectURL);
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(
            error.message ? error.message : this.messageConstant.unknownError,
            ""
          );
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  checkFileType(fileType) {
    const type = fileType.toLowerCase();

    return !!(
      type === "pdf" ||
      type === "png" ||
      type === "jpeg" ||
      type === "heic" ||
      type === "jpg" ||
      type === "svg" ||
      type === "gif" ||
      type === "webp" ||
      type === "ico" ||
      type === "mp4" ||
      type === "mp3" ||
      type === "avi" ||
      type === "mov"
    );
  }

  _base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  sanitizeUrl(src) {
    if (src?.changingThisBreaksApplicationSecurity) {
      return this._sanitizer.bypassSecurityTrustResourceUrl(
        src?.changingThisBreaksApplicationSecurity
      );
    } else {
      return this._sanitizer.bypassSecurityTrustResourceUrl(src);
    }
  }

  ngOnDestroy() {
    if (this.viewPlayer) {
      const list = this.viewPlayer?.toArray();
      if (list) {
        list.filter((input) => {
          input?.nativeElement?.pause();
        });
      }
    }
  }

  getImageSrc(image) {
    if (image?.thumbnailUrl) {
      return environment.newS3Url + image?.thumbnailUrl;
    }

    return image?.src;
  }

  getIcon(fileType) {
    const name = fileType;

    if (!name) {
      return "other_icon.png";
    }

    if (name === "doc" || name === "docx") {
      return "doc_icon.png";
    }

    if (name === "pdf") {
      return "pdf_icon.png";
    }

    if (name === "mp4") {
      return "mp4_icon.png";
    }

    if (name === "mp3") {
      return "mp3_icon.png";
    }

    if (name === "gif") {
      return "gif_icon.png";
    }

    if (name === "png") {
      return "png_icon.png";
    }

    if (name === "xls" || name === "xlsx") {
      return "xls_icon.png";
    }

    if (name === "jpeg" || name === "jpg") {
      return "jpeg_icon.png";
    }

    if (name === "ico") {
      return "ico_icon.png";
    }

    if (name === "svg") {
      return "svg_icon.png";
    }

    if (name === "zip") {
      return "zip_icon.png";
    }

    if (name === "txt") {
      return "txt_icon.png";
    }

    if (name === "rar") {
      return "rar_icon.png";
    }

    if (name === "json") {
      return "json_icon.png";
    }

    if (name === "html") {
      return "html_icon.png";
    }

    if (name === "dwg") {
      return "dwg_icon.png";
    }

    if (name === "ai") {
      return "ai_icon.png";
    }

    if (name === "csv") {
      return "csv_icon.png";
    }

    if (name === "webp") {
      return "webp_icon.png";
    }

    return name;
  }

  downloadFile(file) {
    const obj = {
      moduleId: file?.moduleId,
      src: file?.photo,
    };
    this._loaderService.start();
    this._sharedService.getFile(obj).subscribe(
      (response) => {
        if (response) {
          var blob = new Blob([response], {
            type: `image/${file?.fileType}`,
          });
          saveAs(blob, file?.title);
          this._loaderService.stop();
          this._toastrService.success(this.messageConstant.fileDownloadSuccess);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(
            error.message ? error.message : this.messageConstant.unknownError,
            ""
          );
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  initializeAudio() {
    if (this.audioPlayer) {
      const list = this.audioPlayer?.toArray();
      if (list) {
        list[this.currentIndex].initialize(
          this.data.items[this.currentIndex].src
        );
      }
    }
  }
}
