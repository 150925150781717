<form [formGroup]="generalForm" autocomplete="off">
  <!-- PHONE NUMBER -->
  <div class="form-group">
    <label class="labels" for="inputCampaign4">Phone Number</label>
    <input
      type="text"
      class="form-control"
      id="inputphone"
      placeholder="Phone"
      formControlName="number"
      readonly
      (change)="onChangeEvent($event)"
    />
  </div>

  <!-- TEAM NAME -->
  <div class="form-group">
    <label class="labels" for="inputflowName2">
      Which Team is this Campaign for? <span class="text-danger">*</span>
    </label>

    <select
      class="form-control"
      formControlName="teamId"
      name="teamId"
      (change)="onChangeEvent($event)"
      [attr.disabled]="isTeamReadonly ? 'disabled' : null"
    >
      <option *ngFor="let team of teams" [value]="team._id">
        {{ team.title }}
      </option>
    </select>
  </div>

  <!-- LEAD SOURCE -->
  <div class="form-group">
    <label class="labels" for="inputleadSource">
      Lead Source <span class="text-danger">*</span>
    </label>
    <select
      id="inputleadSource"
      class="form-control"
      formControlName="leadSourceId"
      (change)="onChangeEvent($event)"
    >
      <option value="">Select Lead Source</option>
      <option *ngFor="let leadSource of leadSources" [value]="leadSource._id">
        {{ leadSource.answer }}
      </option>
    </select>
    <div
      *ngIf="
        (submitted && f['leadSourceId'].errors) ||
        (f['leadSourceId'].errors &&
          (f['leadSourceId'].dirty || f['leadSourceId'].touched))
      "
      class="text-danger"
    >
      <span *ngIf="f['leadSourceId'].errors['required']">
        Lead Source is required
      </span>
    </div>
  </div>

  <!-- CAMPAIGN NAME -->
  <div class="form-group">
    <label class="labels" for="inputCampaign4">
      Campaign Name <span class="text-danger">*</span>
    </label>
    <input
      type="text"
      class="form-control"
      id="inputCampaign4"
      formControlName="marketingTitle"
      placeholder="Campaign Name"
      (change)="onChangeEvent($event)"
    />
    <div
      *ngIf="
        (submitted && f['marketingTitle'].errors) ||
        (f['marketingTitle'].errors &&
          (f['marketingTitle'].dirty || f['marketingTitle'].touched))
      "
      class="text-danger"
    >
      <span *ngIf="f['marketingTitle'].errors['required']">
        Campaign Name is required
      </span>
    </div>
  </div>

  <!-- CALL FLOW NAME -->
  <div class="form-group">
    <label class="labels" for="inputCalln4">
      Call Flow Name <span class="text-danger">*</span>
    </label>
    <select
      class="form-control"
      formControlName="flowId"
      name="flowId"
      (change)="onChangeEvent($event)"
    >
      <option *ngFor="let eachFlow of flowList" [value]="eachFlow._id">
        {{ eachFlow.name }}
      </option>
    </select>
  </div>
</form>
