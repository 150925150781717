import { Component, Inject, OnInit, EventEmitter } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { UploadOutput, UploadInput } from "ngx-uploader";
import { MatDialog } from "@angular/material/dialog";
//service
import { SharedService } from "../../shared.service";
import { RvmService } from "./../../../providers/rvm/rvm.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AddRecordingComponent } from "../../../shared/dialog/add-recording/add-recording.component";

//utils
import { ErrorModel } from "src/app/utils/models/error";
import { MessageConstant } from "src/app/utils/message-constant";
import { environment } from "src/environments/environment";
import { BaseUrl } from "src/app/utils/base-url-constants";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";

@Component({
  selector: "app-add-edit-rvm-template",
  templateUrl: "./add-edit-rvm-template.component.html",
  styleUrls: ["./add-edit-rvm-template.component.scss"],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class AddEditRvmTemplateComponent implements OnInit {
  messageConstant = MessageConstant;
  uploadInput: EventEmitter<UploadInput>;
  public moduleData: any;
  uploadFiles: any[] = [];
  recordFile: any[] = [];
  selectedModuleId: any[] = [];
  rvmTitle: string = "";
  rvmTitleRequied: boolean = false;
  audioRequied: boolean = false;
  rvmTitleLenght: boolean = false;
  moduleId: string;
  imageArr: any[] = [];
  originName: string = "";
  fileText: string = MessageConstant?.fileAudioFormatErr;
  audioStart = false;
  audio: any;
  rvmFile: any = "";
  urlData: any = [];
  newOrOld = "select-existing";
  audioList: any;
  dialogRef: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<AddEditRvmTemplateComponent>,
    private router: Router,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _sharedService: SharedService,
    private _rvmService: RvmService,
    private fb: FormBuilder,
    private _dialog: MatDialog,
    private _commonFunction: CommonFunctionsService
  ) {
    this.getGreetingList();
    this.audio = new Audio();
    this.audio.addEventListener("ended", () => {
      this.audioStart = false;
    });
  }

  ngOnInit(): void {
    this.moduleId = this.data.moduleId;
    this.uploadInput = new EventEmitter<UploadInput>();
    if (typeof this.data.dataVal !== "undefined") {
      this.rvmTitle = this.data.dataVal.name;
      this.fileText = this.data.dataVal.originName;
      this.originName = this.data.dataVal.originName;
      for (let i = 0; i < this.data.dataVal.moduleId.length; i++) {
        for (let j = 0; j < this.data.items.length; j++) {
          if (this.data.items[j]._id == this.data.dataVal.moduleId[i]) {
            this.selectedModuleId.push(this.data.items[j]);
            break;
          }
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.stopAudio();
  }
  onUploadLogo(event: any): void {
    if (!event || !event.target?.files || !event.target?.files[0]) {
      this.fileText = this.messageConstant?.fileAudioFormatErr;
      this.urlData["url"] = "";
      return;
    }

    this.audioRequied = false;
    this.rvmFile = "";
    this._loaderService.start();

    let endpoint = environment.baseUrl + BaseUrl.sharedUrl + "file-upload";
    if (this.moduleId) {
      endpoint += `?moduleId=${this.moduleId}&isPublic=1&type=1`;
    }

    let data = event.target?.files[0];
    const formData = new FormData();
    formData.append("file", data);

    this._commonFunction.globalPostService(endpoint, formData).subscribe(
      (response) => {
        this._loaderService.stop();
        if (response["statusCode"] == 200) {
          this.urlData["url"] = response["data"][0]?.url;

          response["data"]?.filter((x) => {
            this.imageArr.push(x);
            this.originName = x.name;
            this.fileText = this.originName;
          });
        }
      },
      (err: ErrorModel) => {
        this._toastrService.error(err.error.message);
        this._loaderService.stop();
      }
    );
  }
  search(moduleName) {
    let tempArr = [];
    for (let i = 0; i < this.data.items.length; i++) {
      if (this.data.items[i].title.indexOf(moduleName) >= 0) {
        tempArr.push(this.data.items[i]);
      }
    }
    let finalArr = [],
      flag;
    for (let i = 0; i < tempArr.length; i++) {
      flag = 1;
      for (let j = 0; j < this.selectedModuleId.length; j++) {
        if (tempArr[i]._id == this.selectedModuleId[j]._id) {
          flag = 0;
          break;
        }
      }
      if (flag == 1) finalArr.push(tempArr[i]);
    }

    this.moduleData = finalArr;
  }
  submitForm() {
    let moduleIds = [];
    for (let i = 0; i < this.selectedModuleId.length; i++) {
      moduleIds.push(this.selectedModuleId[i]._id);
    }
    if (this.rvmTitle.length == 0) {
      this.rvmTitleRequied = true;
      this.audioRequied = false;
      return false;
    } else if (this.rvmTitle.length == 0) {
      this.rvmTitleRequied = true;
      return false;
    } else if (this.rvmTitle.length > 50) {
      this.rvmTitleLenght = true;
      return false;
    }

    this._loaderService.start();
    if (typeof this.data.dataVal === "undefined") {
      let rvm = this.audioList.filter((d) => d._id === this.rvmFile)[0];
      let fileUrl;
      if (rvm && this.newOrOld == "select-existing") {
        this.originName = rvm.originName;
        fileUrl = rvm.url;
      } else if (this.recordFile && this.newOrOld == "new-record") {
        this.originName = this.recordFile["originName"];
        fileUrl = this.recordFile["url"];
      } else if (this.imageArr.length != 0) {
        fileUrl = this.imageArr[0].url;
      }
      if (!fileUrl) {
        this._loaderService.stop();
        this.audioRequied = true;
        return false;
      }
      let obj = {
        name: this.rvmTitle,
        originName: this.originName,
        url: fileUrl,
        urlType:
          this.data?.urlType == "dialerVoiceMail"
            ? this.data?.urlType
            : "ringlessvoicemail",
      };
      this._rvmService.saveRVM(obj).subscribe(
        (response) => {
          this._loaderService.stop();
          if (response && response.statusCode == 200) {
            this.dialog.close();
            if (this.data?.urlType == "dialerVoiceMail")
              this._toastrService.success(this.messageConstant.dvmAddedSuccess);
            else
              this._toastrService.success(this.messageConstant.rvmAddedSuccess);
          } else {
            this._toastrService.error(response.message);
          }
        },
        (err: ErrorModel) => {
          this._toastrService.error(err.error.message);
          this._loaderService.stop();
        }
      );
    } else {
      let fileUrl;
      let rvm = this.audioList.filter((d) => d._id === this.rvmFile)[0];
      if (rvm && this.newOrOld == "select-existing") {
        this.originName = rvm.originName;
        fileUrl = rvm.url;
      } else if (this.recordFile && this.newOrOld == "new-record") {
        this.originName = this.recordFile["originName"];
        fileUrl = this.recordFile["url"];
      } else {
        fileUrl =
          this.imageArr.length > 0
            ? this.imageArr[0].url
            : this.data.dataVal.url;
      }
      let obj = {
        audioTrackId: this.data.dataVal._id,
        moduleId: moduleIds,
        name: this.rvmTitle,
        originName: this.originName,
        url: fileUrl,
      };
      this._rvmService.editRVM(obj).subscribe(
        (response) => {
          this._loaderService.stop();
          if (response && response.statusCode == 200) {
            let result = this.data.dataVal;
            result.name = this.rvmTitle;
            result.moduleId = moduleIds;
            result.originName = this.originName;
            result.url =
              this.imageArr.length > 0
                ? this.imageArr[0].url
                : this.data.dataVal.url;
            this.dialog.close(result);
            if (this.data?.urlType == "dialerVoiceMail")
              this._toastrService.success(
                this.messageConstant.dvmUpdatedSuccess
              );
            else
              this._toastrService.success(
                this.messageConstant.rvmUpdatedSuccess
              );
          } else {
            this._toastrService.error(response.message);
          }
        },
        (err: ErrorModel) => {
          this._toastrService.error(err.error.message);
          this._loaderService.stop();
        }
      );
    }
  }
  checkValue(event) {
    this.rvmTitleRequied = event?.target?.value ? false : true;
    this.rvmTitleLenght = event?.target?.value?.length > 50 ? true : false;
  }
  changeVoice(id = null) {
    this.stopAudio();
    if (this.rvmFile) {
      this.audioRequied = false;
    }
    if (id === null) {
      this.urlData = this.audioList.filter((d) => d._id === this.rvmFile)[0];
    }
    if (id === null && this.newOrOld === "new-upload") {
      this.urlData = [];
    }
    if (this.newOrOld === "new-record") {
      this.onAddRecordingDialog();
    }
  }
  onAddRecordingDialog() {
    this.dialogRef = this._dialog.open(AddRecordingComponent, {
      width: "500px",
      panelClass: "editRvm",
      data: {
        type: "RVM",
        title: "Record RVM",
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this.newOrOld = "select-existing";
      if (result) {
        result.urlType = "ringlessvoicemail";
        this._loaderService.start();
        this.uploadFile(result);
      }
    });
  }
  getGreetingList() {
    const data = { page: 1, limit: 100, urlType: this.data?.urlType };
    this._rvmService.getRVM(data).subscribe(
      (response) => {
        if (response && response?.statusCode === 200) {
          this.audioList = response?.data?.items;
          if (this.data?.dataVal?._id) {
            this.urlData = response?.data?.items.filter(
              (d) => d._id === this.data.dataVal._id
            )[0];
            this.rvmFile = this.urlData?._id;
          }
        }
      },
      (err: ErrorModel) => {}
    );
  }
  startAudio() {
    this.audio.src = "";
    this.audioStart = true;
    this.audio.src = this.urlData.url ? this.urlData.url : "";
    if (this.audio.src !== "" && this.urlData?.url !== undefined) {
      this.audio.load();
      this.audio.play();
    } else {
      this.audioStart = false;
      this._toastrService.error(this.messageConstant?.fileAudioEmptyErr);
    }
  }
  stopAudio() {
    this.audioStart = false;
    this.audio.pause();
    this.audio.currentTime = 0;
  }
  uploadFile(e) {
    let moduleIds = [];
    for (let i = 0; i < this.selectedModuleId.length; i++) {
      moduleIds.push(this.selectedModuleId[i]._id);
    }
    if (typeof this.data.dataVal === "undefined") {
      const obj = {
        name: this.rvmTitle ? this.rvmTitle : e.name,
        originName: e.originName,
        url: e.url,
        urlType:
          this.data?.urlType == "dialerVoiceMail"
            ? this.data?.urlType
            : e.urlType,
      };

      this._rvmService.saveRVM(obj).subscribe(
        (response) => {
          this._loaderService.stop();
          if (response && response?.statusCode == 200) {
            this.urlData = [];
            if (this.data?.urlType == "dialerVoiceMail")
              this._toastrService.success(this.messageConstant.dvmAddedSuccess);
            else
              this._toastrService.success(this.messageConstant.rvmAddedSuccess);
            this.dialog.close();
          } else {
            this._toastrService.error(response?.message);
          }
        },
        (err: ErrorModel) => {
          this._toastrService.error(err.error.message);
          this._loaderService.stop();
        }
      );
    } else {
      let obj = {
        audioTrackId: this.data.dataVal._id,
        moduleId: moduleIds,
        name: this.rvmTitle ? this.rvmTitle : e.name,
        originName: e.originName,
        url: e.url,
      };
      this._rvmService.editRVM(obj).subscribe(
        (response) => {
          this._loaderService.stop();
          if (response && response.statusCode == 200) {
            let result = this.data.dataVal;
            result.name = this.rvmTitle;
            result.moduleId = moduleIds;
            result.originName = this.originName;
            result.url =
              this.imageArr.length > 0
                ? this.imageArr[0].url
                : this.data.dataVal.url;
            this.dialog.close(result);
            if (this.data?.urlType == "dialerVoiceMail")
              this._toastrService.success(
                this.messageConstant.dvmUpdatedSuccess
              );
            else
              this._toastrService.success(
                this.messageConstant.rvmUpdatedSuccess
              );
          } else {
            this._toastrService.error(response.message);
          }
        },
        (err: ErrorModel) => {
          this._toastrService.error(err.error.message);
          this._loaderService.stop();
        }
      );
    }
  }
}
