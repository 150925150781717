<div class="re-agent-add-newcard">
  <div class="dialog-header">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        {{ isEdit ? "Update Card" : "Add New Card" }}
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form
    autocomplete="off"
    #saveCreditCardForm="ngForm"
    (submit)="submitAction(saveCreditCardForm)"
  >
    <mat-dialog-content class="re-agent-typography">
      <div class="container">
        <div class="re-agent-box">
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12" *ngIf="!isEdit">
              <div class="form-group">
                <label class="labels">Card Info</label>
                <ngx-stripe-card
                  [options]="cardOptions"
                  [elementsOptions]="elementsOptions"
                  (change)="onChange($event)"
                ></ngx-stripe-card>
                <span class="text-danger" *ngIf="stripeError">
                  {{ stripeError }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-12 col-sm-12" *ngIf="isEdit">
              <div class="form-group">
                <label class="labels">Card Number</label>
                <input
                  [disabled]="true"
                  type="tel"
                  name="cardData.cardNumber"
                  #cardnumber="ngModel"
                  [(ngModel)]="cardData.cardNumber"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-6 col-md-6 col-sm-6" *ngIf="isEdit">
              <div class="form-group">
                <label class="labels">Expire Date</label>
                <select
                  name="cardData.expMonth"
                  #cardexpiremonth="ngModel"
                  [(ngModel)]="cardData.expMonth"
                  class="form-control"
                  required
                >
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <span
                  *ngIf="
                    cardexpiremonth.errors &&
                    (cardexpiremonth.dirty ||
                      cardexpiremonth.touched ||
                      saveCreditCardForm.submitted)
                  "
                  class="text-danger"
                >
                  <span [hidden]="!cardexpiremonth.errors['required']">
                    ( Please Select Expiration Month)
                  </span>
                </span>
              </div>
            </div>
            <div class="col-6 col-md-6 col-sm-6" *ngIf="isEdit">
              <div class="form-group">
                <label class="labels">&nbsp; </label>
                <select
                  name="cardData.expYear"
                  #cardexpireyear="ngModel"
                  [(ngModel)]="cardData.expYear"
                  class="form-control"
                  required
                >
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2031">2031</option>
                  <option value="2032">2032</option>
                  <option value="2033">2033</option>
                  <option value="2034">2034</option>
                  <option value="2035">2035</option>
                </select>
                <span
                  *ngIf="
                    cardexpireyear.errors &&
                    (cardexpireyear.dirty ||
                      cardexpireyear.touched ||
                      saveCreditCardForm.submitted)
                  "
                  class="text-danger"
                >
                  <span [hidden]="!cardexpireyear.errors['required']">
                    ( Please Select Expiration Year)
                  </span>
                </span>
              </div>
            </div>
            <div class="col-12 col-md-12 col-sm-12" *ngIf="isEdit">
              <div class="form-group">
                <label class="labels">Zip Code</label>
                <input
                  type="tel"
                  name="cardData.addressZip"
                  #cardbilingzip="ngModel"
                  [(ngModel)]="cardData.addressZip"
                  maxlength="5"
                  class="form-control"
                  title="Enter minimum and maximum of 5 numeric values"
                  pattern="[0-9]{5}"
                  required
                />
                <span
                  *ngIf="
                    cardbilingzip.errors &&
                    (cardbilingzip.dirty ||
                      cardbilingzip.touched ||
                      saveCreditCardForm.submitted)
                  "
                  class="text-danger"
                >
                  <span [hidden]="!cardbilingzip.errors['required']">
                    ( Please Enter ZIP Code)
                  </span>
                </span>
                <div
                  class="arrorvalue topd"
                  *ngIf="
                    cardbilingzip.errors &&
                    (cardbilingzip.dirty || cardbilingzip.touched)
                  "
                >
                  <div
                    [hidden]="!cardbilingzip.errors['pattern']"
                    style="color: #ff0000"
                  >
                    Enter minimum and maximum of 5 numeric values
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">Mark As Primary</label>
                <div class="re-radiolist">
                  <label class="re-radiobtn"
                    >Yes
                    <input
                      (change)="cardRadioChange($event)"
                      type="radio"
                      name="primaryRadio"
                      value="true"
                      [checked]="isDefaultCard" /><span
                      class="re-checkmark"
                    ></span
                  ></label>
                  <label class="re-radiobtn"
                    >No
                    <input
                      (change)="cardRadioChange($event)"
                      type="radio"
                      name="radioc1"
                      name="primaryRadio"
                      value="false"
                      [checked]="!isDefaultCard" /><span
                      class="re-checkmark"
                    ></span
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr class="m-0" />
    <mat-dialog-actions align="center" class="mat-dialog-actions">
      <button class="btn" type="button" mat-dialog-close>Cancel</button>
      <button class="btn btn-save" type="submit">
        {{ isEdit ? "Update Card" : isPay ? "Pay" : "Add Card" }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
