<div class="re-agent-send-message">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between"
      >
        Send Message
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form
    autocomplete="off"
    [formGroup]="smsDetailForm"
    (ngSubmit)="onSubmit()"
    novalidate
  >
    <mat-dialog-content>
      <!-- SMS -->
      <div class="re-agent-box mt-3">
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <p class="re-agent-credit-info">
                Remaining credits: <span>{{ data?.SMSBalance }}</span> SMS
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="re-agent-label">Send To</label>
              <select name="" formControlName="toNumber" class="form-control">
                <option
                  *ngFor="let contact of data?.contacts"
                  [value]="contact?.phoneNumber"
                >
                  {{ _utilities.capitalizeName(contact?.title) || "N/A" }}
                  &bull;
                  {{ _utilities.capitalizeName(contact?.contactType) }}
                  {{ _utilities.maskNumber(contact?.phoneNumber) }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="re-agent-label"
                >Sending From <span class="text-danger">*</span></label
              >
              <select
                name="contactType"
                class="form-control"
                formControlName="fromNumber"
              >
                <option value="">Select number</option>
                <option
                  *ngFor="let item of data?.leadSource"
                  [value]="item?.number"
                >
                  {{ _utilities.maskNumber(item?.number) }}
                </option>
              </select>
              <div class="text-danger mt-1">
                <span *ngIf="submitted && hasError('fromNumber', 'required')">
                  *Please select Number
                </span>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="re-agent-label"
                >Sending From <span class="text-danger">*</span></label
              >
              <input
                type="text"
                formControlName="fromNumber"
                class="form-control"
                readonly
                mask="(000) 000-0000"
              />

              <div class="text-danger mt-1">
                <span *ngIf="submitted && hasError('fromNumber', 'required')">
                  *Please select Number
                </span>
              </div>
            </div>
          </div> -->
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="re-agent-label">
                Choose the message from templates ({{ smsTemplates?.length }})
                <span class="re-agent-changebtn" (click)="onAddTemplate()"
                  >Create New Template</span
                >
              </label>
              <select
                class="form-control"
                name="smsTemplateId"
                formControlName="smsTemplateId"
                (change)="setSmsTemplate($any($event.target).value, true)"
              >
                <option value="">Select SMS Template</option>
                <option *ngFor="let sms of smsTemplates" [value]="sms._id">
                  {{ sms?.templateName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="re-agent-label">
                Message Text
                <span class="text-danger">*</span> &nbsp;
                <span class="pull-right re-grey-text" *ngIf="smsCount != 0">
                  ({{ smsCharCount + "/" + smsCount }})
                </span>
              </label>
              <quill-editor
                #editor
                name="messageText"
                formControlName="smsBody"
                [styles]="editorStyle"
                [modules]="{
                  toolbar: false,
                  mention: editorOptions.mention
                }"
                [placeholder]="editorOptions.placeholder"
                (ngModelChange)="getSMSCount($event)"
              ></quill-editor>
              <div class="text-danger mt-1">
                <span *ngIf="submitted && hasError('smsBody', 'required')">
                  *Please enter Message
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="re-agent-box re-agent-switch">
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <div class="radiosec">
                  <p class="radotext">
                    Do you want to schedule this SMS for later date and time?
                  </p>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      (click)="onToggle()"
                      name="activeTimeChange"
                      formControlName="activeTimeChange"
                    />
                    <span class="slider"></span>
                    <span class="text1" value="false">No</span>
                    <span class="text2" value="true">Yes</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-sm-12" *ngIf="toggle === true">
              <div class="row">
                <div class="col-12 col-md-7 col-sm-12">
                  <div class="form-group">
                    <label class="re-agent-label">Date & Time</label>
                    <div class="row">
                      <div class="col-12 col-md-7 col-sm-12 mb-2">
                        <p-calendar
                          formControlName="scheduleDate"
                          [minDate]="minimumTime"
                          styleClass="ngCalendarClass"
                          [showIcon]="true"
                          [icon]="'fa fa-calendar'"
                          appendTo="body"
                          (onSelect)="onChangeTime()"
                        >
                        </p-calendar>
                        <div class="text-danger re-error-txt">
                          <span
                            class="re-error"
                            *ngIf="hasError('scheduleDate', 'required')"
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Schedule Date"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="col-12 col-md-5 col-sm-12">
                        <p-calendar
                          formControlName="scheduleTime"
                          [minDate]="minimumTime"
                          styleClass="ngCalendarClass"
                          [showIcon]="true"
                          [icon]="'fa fa-clock-o'"
                          appendTo="body"
                          hourFormat="12"
                          [timeOnly]="true"
                          (onSelect)="onChangeTime()"
                        ></p-calendar>
                        <div class="text-danger re-error-txt">
                          <span
                            class="re-error"
                            *ngIf="hasError('scheduleTime', 'required')"
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Schedule Time"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-5 col-sm-12">
                  <div class="form-group">
                    <label class="re-agent-label">Time Zone</label>
                    <select
                      name="timezone"
                      formControlName="timezone"
                      class="form-control"
                    >
                      <option
                        *ngFor="let timezone of timezoneList"
                        value="{{ timezone.timezonename }}"
                      >
                        {{ timezone.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ATTACHMENTS -->
      <div>
        <div class="re-agent-box re-agent-attachments">
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <span class="re-agent-attach">ATTACHMENTS</span>
                <span class="re-agent-file-attach">
                  <input
                    type="file"
                    multiple
                    name="attachmentArray"
                    formControlName="mediaUrls"
                    (change)="onUploadLogo($event)"
                  />
                  <!-- (uploadOutput)="onUploadLogo($any($event))"
                    [uploadInput]="uploadInput" -->
                  <img
                    src="assets/images/attachment-symbol.png"
                    alt="attachment"
                  />
                  Add attachments
                </span>
              </div>
            </div>

            <!-- FILES -->
            <ng-container
              *ngFor="
                let attachment of showAttachments;
                let attachmentIndex = index
              "
            >
              <div class="col-12 col-md-12 col-sm-12 re-agent-attachment-files">
                <span>
                  {{ attachment?.name }}
                </span>
                <img
                  src="/assets/images/close-black.svg"
                  (click)="removeExistingAttachment(attachmentIndex)"
                />
              </div>
            </ng-container>

            <!-- FILES -->
            <ng-container *ngFor="let file of attachments; let i = index">
              <div class="col-12 col-md-12 col-sm-12 re-agent-attachment-files">
                <span> {{ file.name }} </span>
                <img
                  src="/assets/images/close-black.svg"
                  (click)="removeImageArr(file)"
                />
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="re-agent-box">
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <p class="re-agent-credit-info">
                Your SMS messages might not get delivered by cell phone carriers
                because your 10DLC registration is not complete.<a
                  mat-dialog-close
                  >Learn more</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
      <button mat-flat-button color="primary" type="submit" cdkFocusInitial>
        {{ toggle ? "Schedule Message" : "Send Right Away" }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
