// MODULES
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";

// SERVICES
import { ToastrService } from "ngx-toastr";
import { DripService } from "src/app/providers/drip/drip.service";

// UTILS
import { ResponseModel } from "src/app/utils/models/response";
import { MessageConstant } from "src/app/utils/message-constant";

@Component({
  selector: "app-copy-drip",
  templateUrl: "./copy-drip.component.html",
  styleUrls: ["./copy-drip.component.scss"],
})
export class CopyDripComponent implements OnInit {
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<CopyDripComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _dripService: DripService
  ) {}

  ngOnInit(): void {}

  submit() {
    this._loaderService.start();
    const { _id } = this.data.details;

    switch (this.data.subModuleId) {
      case "copy":
        this.copyDrip(_id);
        break;

      case "stop":
        this.stopDrip(_id);
        break;

      default:
        this._loaderService.stop();
        break;
    }
  }

  copyDrip(id) {
    let obj = {
      dripCampaignId: id,
    };
    this._loaderService.start();

    this._dripService.copyDrip(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.dialog.close(this.data.details);
          this._toastrService.success(this.messageConstant.dripCopySuccess);
        }
        this._loaderService.stop();
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          this.dialog.close(this.data.details);
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  stopDrip(id) {
    let obj = {
      dripCampaignId: id,
    };
    this._loaderService.start();

    this._dripService.stopDrip(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.dialog.close(this.data.details);
          this._toastrService.success(this.messageConstant.dripStopSuccess);
        }
        this._loaderService.stop();
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          this.dialog.close(this.data.details);
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }
}
