import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseUrl } from "../../utils/base-url-constants";
import { environment } from "src/environments/environment";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";

@Injectable({
  providedIn: "root",
})
export class TeamsService {
  public baseUrl = environment.baseUrl;
  public token = localStorage.getItem("token");

  constructor(
    private http: HttpClient,
    private _commonFunction: CommonFunctionsService
  ) {}

  getTeams = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.teamUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  saveTeam = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.teamUrl + "save";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteTeams = (data: any): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.teamUrl + "delete";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editTeams = (data: any): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.teamUrl + "edit";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTeamRoleAssignment = (data: any): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.teamUrl + "getTeamRoleAssignment";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  checkTeamTitle = (data: any): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.teamUrl + "checkTeam";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTeamRoleAssignmentAgent = (data: any): Observable<any> => {
    let endpoint =
      this.baseUrl + BaseUrl.teamUrl + "getTeamRoleAssignmentAgent";
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
