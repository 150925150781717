export class MessageConstant {
  //General
  public static requiredField = "Field is required";
  public static validSelectValue = "Please Select";
  public static requiredName = "Name is required";
  public static namePattern = "Name must contain only characters";
  public static requiredReferral =
    "Please enter coupon code or give brief description of where you heard about AgentPro CRM.";
  public static unAuthorizedError = "You are not authorized. Please login.";
  public static unknownError = "Something went wrong. Please try again later.";
  public static unRequiredFieldError = "Fill all the required fields.";
  public static successRegister =
    "You are registered Successfully. Please login with your registered credentials";
  public static noNumber =
    'Please add a phone number for Buyers before you can call or text any of your buyers. <br><a href="https://www.loom.com/share/16c8ec39f2404afd943d729f991bd764" target="_blank" class="toastr-link"><u>Click here for a quick demo video on how to add a phone number for Buyers.</u></a>';
  public static phoneOrEmailErr =
    "Either a Phone number or an Email is required";
  public static mandatoryFieldsErr = "Please fill all mandatory fields";
  public static dontUseTempErr =
    "If you don't want to use any of the available templates.";
  public static templateCopySuccess = "Template copied successfully";
  public static selectKeyErr = "Select available key";

  //Email
  public static invalidEmail = "Please Enter a valid Email";
  public static requiredEmail = "Email is required";
  public static emailInUse = "Email is already in use.";
  public static emailSendSuccess = "Email Sent Successfully";
  public static noEmail =
    "You do not have any email associated. Please assign an email before continuing with this action.";
  public static noCampaignNumber =
    'You do not have any campaign assigned to this lead. <br><a href="https://resimpli.com/assigning-a-marketing-campaign-before-calling" target="_blank" class="toastr-link"><u>Click here to watch a tutorial video on how to do that.</u></a>';
  public static exceedSizeLimit =
    "Your selected attachment size must be less than 10mb";
  public static exceedTotalSizeLimit =
    "Your total attachments size must be less than 10mb";
  public static EmailconfirmedMsg = "Email address confirmed";

  //Password
  public static notMatchedPassword = "Password does not match";
  public static passwordChangeSuccess = "Password Updated Successfully";
  public static passwordSetSuccess = "Password Set Successfully";
  public static requiredPassword = "Password is required";
  public static validFieldValue = "Please enter valid";
  public static passwordPattern =
    "Password must contain Capital Letter, Small Letter, A Special Character, A Number and a minimum of 8 character length";
  public static forgotPwdSuccessMsg =
    "Please check your mail to get the reset password link.";
  public static resetPwdSuccessMsg = "Your Password Was Reset Successfully";

  //List
  public static blankList = "New List Name is required";
  public static tagLengthError = "Tag Name cannot be more than 50 characters";
  public static sameRenameList =
    "New List Name and Old List Name can't be same";
  public static nonUniqueTag = "Tag Name Already Exists!";
  public static listRenamedSuccess = "List Renamed Successfully";
  public static nonUniqueList = "List Name Already Exists!";
  public static listRemoveBlank = "Please select lists to Remove";

  //Tags
  public static blankTag = "New Tag Name is required";
  public static sameRenameTag = "New Tag Name and Old Tag Name can't be same";
  public static tagsAddedSuccess = "Tags Added Successfully";
  public static tagsDeletedSuccess = "Tags Deleted Successfully";
  public static tagsRemoveBlank = "Please Select Tags To Remove";
  public static tagsFieldErr = "Tags can't be blank";
  public static tagsEditPermissionErr = "You can not edit this tag";

  // Email Template
  public static checkYourEmail =
    "Please check your email to verify your email address";
  public static emailAddedSuccess = "Email Template Added Successfully";
  public static emailUpdatedSuccess = "Email Template Updated Successfully";
  public static emailDeletedSuccess = "Email Template Deleted Successfully";
  public static mailTemplateCopySuccess = "Email Template Copied Successfully";

  //Plan
  public static planActivated = "Your Selected Plan";
  public static planEnterpriseErr =
    "This feature is available in Enterprise Plans only. Please Upgrade Your Plan";

  //Lead
  public static leadAddedSuccess = "Lead Added Successfully";
  public static leadUpdatedSuccess = "Lead Updated Successfully";
  public static leadDeletedSuccess = "Lead Deleted Successfully";
  public static customSectionUpdateSuccess = "[[type]] [[status]] Successfully";
  public static checkValuePreference =
    "Same options values are not allowed in Data Source";
  public static markedAsHot = "Marked as Hot Lead Successfully.";
  public static removedAsHot = "Unmarked as Hot Lead Successfully.";
  public static leadTeamErr = "Please assign a team member";
  public static leadAddressErr =
    "Sorry, but this address is already associated with another record in your account.";
  public static leadPrimaryContactOptErr =
    "Primary contact cannot be unselected";
  public static noUserAssignmentForCampErr = `There is no user assigned to this role for this campaign. Please
  add another user in this campaign for this role and try again.`;
  public static noLeadDetailsErr = "No Lead Details Available.";

  //User Profile
  public static userProfileUpdatedSuccess = "User Profile Updated Successfully";
  public static userProfileImageUpdatedSuccess =
    "User Profile Image Updated Successfully";
  public static userCompanyLogoImageUpdatedSuccess =
    "Company Logo Updated Successfully";
  public static propertyAddressMissing =
    "Please fill in property address before proceeding";
  public static subUserCampaignDeleteSuccess =
    "User has been successfully removed from the campaign";
  public static userRoleUpdated = "User Role Updated Successfully";
  public static subUserDeleteSuccess = "User Deleted Successfully";
  public static primaryUser = "You can not delete primary User";
  public static marketingCampErr = "Please select marketing campaign";

  //Team
  public static teamDeleteSuccess = "Team Deleted Successfully";
  public static teamUpdatedSuccess = "Team Updated Successfully";
  public static teamAssignUserErr = "Choose at least one user";
  public static teamAddSuccess = "Team Added Successfully";

  //Role
  public static roleSelectOptErr = "Please select at least one role";
  public static roleReAssignOptErr =
    "Please select a user to Re-aasign the leads";
  public static roleReAssignSelectErr = "Please Select Assign Role";
  public static dueSelectErr = "Pleace Select Due";
  public static subUserRoleDeleteSuccess =
    "User has been Successfully removed from the role.";
  public static subUserTeamDeleteSuccess =
    "User has been Successfully removed from the team";
  public static subUserLeadAccessDeleteSuccess =
    "Successfully removed lead access from user";

  //Contact
  public static contactUpdatedSuccess = "Contact Updated Successfully";
  public static contactAddedSuccess = "Contact Added Successfully";
  public static contactDeletedSuccess = "Contact Deleted Successfully";
  public static relatedContactDeletedSuccess =
    "Related Contact Deleted Successfully";
  public static selectContactWithNumber =
    "Please select at least one contact with contact number";
  public static selectBulkContactWithLimit =
    "You can only send [[TYPE]] to [[LIMIT]] contacts at a time";
  public static noDataAvailable = "No Data Available";
  public static selectContactWithEmail =
    "Please select at least one contact with email id";
  public static quickFilterUpdatedSuccessfully =
    "Quick Filter Updated Successfully";
  public static quickFilterSaveSuccessfully = "Quick Filter Save Successfully";
  public static quickFilterListSuccessfully =
    "Quick Filter List Updated Successfully";
  public static quickFilterDeletedSuccessfully =
    "Quick Filter Deleted Successfully";
  public static enterPhoneAndEmailErr = "Please enter phone number or email.";
  public static filterOptErr = "Please select at least one Filter";
  public static contactinvalidErr = "Please select valid contact";

  //Activity
  public static activityLogsAddedSuccess = "Logs Added Successfully";
  public static activityLogsUpdatedSuccess = "Logs Updated Successfully";
  public static activityLogsDeletedSuccess = "Logs Deleted Successfully";
  public static noteAddedSuccess = "Note Added Successfully";

  //Files And Folder
  public static fileMoveRequired = "Please select at least one file.";
  public static folderAddSuccess = "Folder Added Successfully";
  public static folderUpdateSuccess = "Folder Updated Successfully";
  public static folderSharedSuccess = "Folder Shared Successfully";
  public static folderDeletedSuccess = "Folder Deleted Successfully";
  public static folderUnsharedSuccess =
    "The owner of this folder has disabled file sharing.";
  public static fileAddSuccess = "File Added Successfully";
  public static fileUpdateSuccess = "File Updated Successfully";
  public static fileSharedSuccess = "File Shared Successfully";
  public static fileDeletedSuccess = "File Deleted Successfully";
  public static fileUnsharedSuccess =
    "The owner of this file has disabled file sharing.";
  public static fileDownloadSuccess = "File Downloaded Successfully";
  public static fileUpdatedSuccess = "File Updated Successfully";
  public static fileExportSuccess = "File Export Successfully";
  public static linkCopiedSuccess = "Link Copied To Clipboard";
  public static fileFormateErr = "File format not supported";
  public static fileAudioFormatErr =
    "Select an audio file (mp3, mp4, wav, aac audio files supported)";
  public static fileAudioEmptyErr = "Audio file is empty";
  public static playAudioErr = "Can not play audio in your browser";

  // Card
  public static cardAddedSuccess = "Card Added Successfully";
  public static cardUpdatedSuccess = "Card Updated Successfully";
  public static cardDeletedSuccess = "Card Deleted Successfully";

  // Number
  public static blockNumber = "Number Blocked Successfully";
  public static unBlockNumber = "Number Unblocked Successfully";
  public static invalidPhoneNumber = "Please Enter a valid Phone Number";
  public static userExperienceSuccess =
    "Your Agent Questions Added Successfully";
  public static numberDeletedSuccess = "Number Deleted Successfully";

  //Task
  public static taskAdd = "Task Added Successfully";
  public static taskUpdate = "Task Updated Successfully";
  public static taskMarkCompMsg = "Task marked as Completed";
  public static taskMarkPendingMsg = "Task marked as Pending";
  public static taskDueTimeMsg = "Task Due Time cannot be in Past\n";
  public static taskReminderMsg = `Task Due Time cannot be in Past. Reminder is invalid.`;

  // Buy Number
  public static newBuyNumberAdd = "New Phone Number Purchased Successfully";
  public static numberUpdatedSuccess = "Number Updated Successfully";

  //flow
  public static flowNotFound =
    'You do not have any Call Flow in your account. Call Flow is required to be attached to a phone number so please create a Call Flow before buying a new phone number. <br><a href="https://resimpli.com/tutorial/#video-gallery-6229bd5-24" target="_blank" class="toastr-link"><u> Click for a demo video on how to set up a Call Flow. </u></a>';
  public static flowAddedSuccess = "Flow Added Successfully";
  public static flowEditSuccess = "Flow Edited Successfully";
  public static greetingAddedSuccess = "Greeting Added Successfully";
  public static flowDeletedSuccess = "Flow Deleted Successfully";
  public static flowCopySuccess = "Flow Copied Successfully";
  public static flowNameErr = "Please enter Flow Name";
  public static timeScheduleErr = "Please select Timezone in your Schedule";
  public static endTimeErr = "End Time cannot be less than Start Time";
  public static businessScheduleErr =
    "Please fill at least one Forward Type Option in Business Hours Schedule";
  public static nonBusinessScheduleErr =
    "Please fill at least one Forward Type Option in Non Business Hours Schedule";
  public static businessRepeatCallErr =
    "Please enter valid Phone Number in Repeat Caller in Business Hours";
  public static nonBusinessRepeatCallErr =
    "Please enter valid Phone Number in Repeat Caller in Non Business Hours";
  public static businessGreetingRecordErr =
    "Please select a Greeting Recording in Business Hours";
  public static businessRoundRobinErr =
    "You must enter at least 2 Round Robin numbers in Business Hours";
  public static businessRoundRobinValidNumberErr =
    "Please enter valid Phone Number in Round Robin in Business Hours";
  public static businessSimultaneousErr =
    "You must enter at least 2 Simultaneous calling numbers in Business Hours";
  public static businessSimultaneousValidNumberErr =
    "Please enter valid Phone Number in Simultaneous calling in Business Hours";
  public static businessSingleForwardErr =
    "Phone number missing in Single Forward in Business Hours";
  public static businessSingleForwardValidNumberErr =
    "Please enter valid Phone Number in Single Forward in Business Hours";
  public static businessVoiceMailRecordErr =
    "Please select a Voicemail Recording in Business Hours";
  public static businessMissCallSmsErr =
    "Missed Call SMS Message is blank in Business Hours";
  public static businessRvmRecordErr =
    "Please select a RVM recording in Business Hours";
  public static businessRepeatCallerErr =
    "Phone Number missing in Repeat Caller in Business Hours";
  public static businessUserRoleRepeatCallerErr =
    "User Role missing in Repeat Caller in Business Hours";
  public static businessRepeatCallerVoicemailRecorErr =
    "Please select a Repeat Caller Voicemail Recording in Business Hours";
  public static businessRoleAssignRepeatCallerErr =
    "There are some roles or voicemail that you have not assigned for repeat caller based on property status in Business Hours";
  public static nonBusinessGreetingRecordErr =
    "Please select a Greeting Recording in Non Business Hours";
  public static nonBusinessRoundRobinErr =
    "You must enter at least 2 Round Robin numbers in Non Business Hours";
  public static nonBusinessRoundRobinValidPhoneErr =
    "Please enter valid Phone Number in Round Robin in Non Business Hours";
  public static nonBusinessSimultaneousCallErr =
    "You must enter at least 2 Simultaneous calling numbers in Non Business Hours";
  public static nonBusinessSimultaneousValidNumberErr =
    "Please enter valid Phone Number in Simultaneous calling numbers in Non Business Hours";
  public static nonBusinessSingleForwardNumberErr =
    "Phone Number missing in Single Forward in Non Business Hours";
  public static nonBusinessSingleForwardValidNumberErr =
    "Please enter valid Phone Number in Single Forward in Non Business Hours";
  public static nonBusinessVoiceMailErr =
    "Please select a Voicemail recording in Non Business Hours";
  public static nonBusinessMissedCallSMSErr =
    "Missed Call SMS Message is blank in Non Business Hours";
  public static nonBusinessRvmRecordErr =
    "Please select a RVM Recording in Non Business Hours";
  public static nonBusinessRepeatCallMissErr =
    "Phone Number missing in Repeat Caller in Non Business Hours";
  public static nonBusinessRepeatRoleMissErr =
    "User Role missing in Repeat Caller in Non Business Hours";
  public static nonBusinessRepeatVoiceCallErr =
    "Please select a Repeat Caller Voicemail Recording in Non Business Hours";
  public static nonBusinessRepeatVoiceAssignErr =
    "There are some roles or voicemail that you have not assigned for repeat caller based on property status in Non Business Hours";
  public static businessIvrRecordErr =
    "Please select a IVR Recording in IVR Business Hours";
  public static businessForwardOptErr =
    "Please fill at least one Forward Type Option in Business Hours Schedule in ";
  public static businessRoundRobinOptErr =
    "Please enter valid Phone Number in Round Robin in Business Hours in ";
  public static businessSimultaneousOptErr =
    "Please enter valid Phone Number in Simultaneous calling in Business Hours in ";
  public static businessIvrGreetingOptErr =
    "Please select a Greeting Recording in IVR Business Hours in ";
  public static businessRoundRobinIvrOptErr =
    "You must enter at least 2 Round Robin numbers in IVR Business Hours in ";
  public static businessSimultaneousIvrOptErr =
    "You must enter at least 2 Simultaneous calling numbers in IVR Business Hours in ";
  public static businessSingleIvrOptErr =
    "Phone Number missing in Single Forward in IVR Business Hours in ";
  public static businessSingleIvrOptValidNumberErr =
    "Please enter valid Phone Number in Single Forward in IVR Business Hours in ";
  public static businessVoiceMailOptErr =
    "Please select a Voicemail recording in IVR Business Hours in ";
  public static businessMissCallSmsOptErr =
    "Missed Call SMS Message is blank in IVR Business Hours in ";
  public static businessRvmRecordOptErr =
    "Please select a RVM Recording in IVR Business Hours in ";
  public static NonBusinessIvrRecordOptErr =
    "Please select a IVR recording in IVR Non Business Hours";
  public static NonBusinessForwardTypeOptErr =
    "Please fill at least one Forward Type Option in Non Business Hours Schedule in ";
  public static NonBusinessRoundRobinPhoneErr =
    "Please enter valid Phone Number in Round Robin in Non Business Hours in ";
  public static NonBusinessSimpultaneiusValidPhoneErr =
    "Please enter valid Phone Number in Simultaneous calling in Non Business Hours in ";
  public static NonBusinessGreetingRecordingErr =
    "Please select a Greeting Recording in IVR Non Business Hours in ";
  public static NonBusinessRoundRobinIvrErr =
    "You must enter at least 2 Round Robin numbers in IVR Non Business Hours in ";
  public static NonBusinessSimultaneousIvrErr =
    "You must enter at least 2 Simultaneous calling numbers in IVR Non Business Hours in ";
  public static NonBusinessSingleForwardIvrErr =
    "Phone Number missing in Single Forward in IVR Non Business Hours in ";
  public static NonBusinessValidSingleForwardIvrErr =
    "Please enter valid Phone Number in Single Forward in Non Business Hours in ";
  public static NonBusinessVoiceMailIvrErr =
    "Please select a Voicemail recording in IVR Non Business Hours in ";
  public static NonBusinessMissCallIvrErr =
    "Missed Call SMS Message is blank in IVR Non Business Hours in ";
  public static NonBusinessRvmRecordIvrErr =
    "Please select a RVM Recording in IVR Non Business Hours in ";
  public static whisperMessagerErr = `Please note the whisper message feature does not work with iOS and Android in-app calling so we recommend leaving it unchecked`;
  public static numberAttchWithFlowMsg =
    "You have some numbers attached with this Call Flow. Please reassign the numbers to a different Call Flow before deleting this Call Flow";

  //rvm
  public static rvmAddedSuccess = "RVM Template Added Successfully";
  public static voiceMailAddedSuccess = "Voice Mail Added Successfully";
  public static rvmUpdatedSuccess = "RVM Template Updated Successfully";
  public static rvmDeletedSuccess = "RVM Template Deleted Successfully";
  public static invalidRVMUrl = "Please select RVM recording.";
  public static invalidRVMContact = "Send to contact can't be empty";
  public static invalidFROMRVMContact = "Send from contact can't be empty";
  public static sendRVMSuccess = "RVM Sent Successfully";

  //dialer voice mail
  public static dvmDeletedSuccess = "Dialer Voicemail Deleted Successfully";
  public static dvmAddedSuccess = "Dialer Voicemail Added Successfully";
  public static dvmUpdatedSuccess = "Dialer Voicemail Updated Successfully";

  // SMS Template
  public static smsTemplateAddSuccess = "SMS Template Added Successfully";
  public static smsTemplateEditSuccess = "SMS Template Updated Successfully";
  public static smsTemplateDeleteSuccess = "SMS Template Deleted Successfully";

  // Sms
  public static smsSendSuccess = "SMS Sent Successfully";
  public static smsScheduledSuccess = "SMS Scheduled Successfully";
  public static deleteSmsScheduledSuccess =
    "Scheduled SMS Deleted Successfully";
  public static smsScheduledUpdateSuccess =
    "SMS Scheduled Updated Successfully";
  public static phoneNumberRequired = "Phone Number is required";
  public static callNumberRequired =
    "You do not have any phone-number associated. Please assign an phone-number before continuing with this action.";
  public static smsCreditLimit = "You do not have enough credits to send SMS.";

  // TaskTemplate
  public static taskTemplateAddedSuccess = "Task Template Added Successfully";
  public static taskTemplateUpdatedSuccess =
    "Task Template Updated Successfully";
  public static taskTemplateDeletedSuccess =
    "Task Template Deleted Successfully";
  public static taskAddedSuccess = "Task Added Successfully";

  // Claim
  public static successleadClaim = "Lead Claim Successfully";

  // Calendar
  public static calendarSync = "Google Calendar Added Successfully";
  public static calendarUnSync = "Google Calendar Deleted Successfully";

  // Availability
  public static setAvailabilitySuccess = "Availability Updated Successfully";
  public static featureMessage =
    "This feature is only available in Pro and Enterprise plans. Please send an email to <a href='mailto:support@agentprocrm.com' class='toastr-link'><u>support@agentprocrm.com</u></a> to upgrade your plan.";

  //Favorite
  public static markedAsFavorite = "Marked as Favorite Successfully.";
  public static removedAsFavorite = "Unmarked as Favorite Successfully.";

  // Mark VIP
  public static markAsVIP = "Mark as VIP Successfully.";
  public static removeMarkAsVIP = "UnMark as VIP Successfully.";

  //Call Forward
  public static callforward = "Different Forwarding Number Added Successfully";
  public static removedcallforward =
    "Different Forwarding Number Deleted Successfully";

  // Drip Campaign
  public static leadDripAssignSuccess = "Drip Campaign Activated Successfully";
  public static leadDripUnassignSuccess = "Drip Campaign Stopped Successfully";
  public static dripAddedSuccess = "Drip Campaign Added Successfully";
  public static dripUpdatedSuccess = "Drip Campaign Updated Successfully";
  public static dripDeletedSuccess = "Drip Campaign Deleted Successfully";
  public static dripCopySuccess = "Drip Campaign Copied Successfully";
  public static dripStopSuccess = "Drip Campaign Stopped Successfully";
  public static dripStepMsg =
    "At least one step is required so you cannot delete it";
  public static dripRvmRedirectMsg =
    "This Drip Campaign will be saved and you will be redirected to Add RVM page.";
  public static dripAddEmailTempMsg =
    "This Drip Campaign will be saved and you will be redirected to Add Email Template page.";
  public static saveDripMsg =
    "It may take few seconds to load Tasks. Please refresh page after 5-10 seconds";
  public static selectDripMsg = "Please select drip";

  //API Token
  public static tokenGenerated = "Token Generated Successfully";

  // Vacation Mode
  public static removeUserRestrict = `You can only delete a user that's assigned to one role. Please remove this user from all but one role before deleting. <a href="https://www.loom.com/share/69f790cadcb14050970bf5d494d819cf" target="_blank" class="toastr-link"><u>Click here</u></a> to find how to remove user from a role.`;
  public static campaignRequired =
    "Please select at least any 1 campaign to proceed further";
  public static vacationModeOn = "Vacation Mode turned ON";
  public static vacationModeOff = "Vacation Mode turned OFF";
  public static vacationModeUpdateSuccess =
    "Vacation Mode Updated Successfully.";

  //Campaign
  public static campaignExists = "Campaign Name Already Exists.";
  public static campaignUpdated = "Campaign Updated Successfully";
  public static assigned = "Assigned Successfully";
  public static unAssigned = "Unassigned Successfully";
  public static noSmsCampErr =
    "This filter option will not be applied if you have more than one or no SMS Campaign selected.";
  public static noEmailCampErr =
    "This filter option will not be applied if you have more than one or no Email Campaign selected.";

  //call
  public static callUnderProgress =
    "You can not make another call while there is a call in progress";
  public static noBuyerNumber =
    "You do not have any phone number associated. Please assign a phone before continuing with this action.";
  public static callCreditLimit =
    "You do not have enough credits to make call.";

  // URL Pattern message
  public static urlPattern = "Please enter valid url";

  // Filter Template message
  public static filterDeleteSuccess = "Filter Deleted Successfully";
  public static numbersOnly = "[[FIELD]] can only contain numbers.";
  public static filterSaveSuccess = "Filter Saved Successfully";
  public static filterEditSuccess = "Filter Updated Successfully";
  public static minMaxValue =
    "Value should be [[type]] than or equal to [[value]]";

  //Agent
  public static agentAddedSuccess = "Agent Added Successfully";
  public static agentUpdatedSuccess = "Agent Updated Successfully";

  //Offer
  public static offerAddedSuccess = "Offer Added Successfully";
  public static offerUpdatedSuccess = "Offer Updated Successfully";
  public static offerDeletedSuccess = "Offer Deleted Successfully";

  //openHouse
  public static openHouseAddedSuccess = "Open House Added Successfully";
  public static openHouseUpdatedSuccess = "Open House Updated Successfully";
  public static openHouseDeletedSuccess = "Open House Deleted Successfully";

  // Showing
  public static showingAddSuccess = "Showing Added Successfully";
  public static showingEditSuccess = "Showing Updated Successfully";
  public static showingDeleteSuccess = "Showing Deleted Successfully";
  public static columnDeletedSuccess = "Column Deleted Successfully";

  //Appointment
  public static appointmentDeletedSuccess = "Appointment Deleted Successfully";
  public static appointmentTimeMsg = "Please Select Start And End Time";
  public static appointmentStartTimeMsg = "Please Select Start Time.";
  public static appointmentEndTimeMsg = "Please Select End Time.";
  public static startEndDateError =
    "Please select both the start & the end Date";

  //stripe
  public static stripeCardErr = "Please enter card details";
  public static paymentSucessMsg = "Payment done successfully";

  //Reminder
  public static reminderLmtErr = "Only 2 reminder allowed";
  public static reminder5LmtErr = "Only 5 reminder allowed";
  public static reminderFillLmtErr = "Fill all reminders";

  //Dlc
  public static websiteAssociateWithEmailErr =
    "website address and an email must be associated with it.";
  public static assignmentTypeErr = "Please select Assignment Type";
  public static statusOptErr = "Please select at least one status";
}
