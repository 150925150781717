// MODULES
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router } from "@angular/router";

// SERVICES
import { ToastrService } from "ngx-toastr";
import { CommonFunctionsService } from "../../../utils/common-functions/common-functions.service";
import { CallFlowService } from "../../../providers/call-flow/call-flow.service";

// UTILS
import { ErrorModel } from "src/app/utils/models/error";
import { MessageConstant } from "src/app/utils/message-constant";

@Component({
  selector: "app-copy-call-flow",
  templateUrl: "./copy-call-flow.component.html",
  styleUrls: ["./copy-call-flow.component.scss"],
})
export class CopyCallFlowComponent implements OnInit {
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<CopyCallFlowComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    public utilities: CommonFunctionsService,
    public router: Router,
    private _flowService: CallFlowService
  ) {}

  ngOnInit(): void {}
  submit() {
    this._loaderService.start();
    switch (this.data.subModuleId) {
      case "copy":
        this.copyFlow(this.data.details);
        break;

      default:
        this._loaderService.stop();
        break;
    }
  }
  copyFlow(data) {
    let obj = { ...data };
    obj["name"] = `${obj.name} - Copy`;
    delete obj["_id"];
    delete obj["numberData"];
    this._flowService.saveFlow(obj).subscribe(
      (response) => {
        this._loaderService.stop();
        if (response && response?.statusCode == 200) {
          this.utilities.currentNumberTab = 2;
          this.router.navigate(["/manage-numbers"]);
          this.dialog.close(this.data.details);
          this._toastrService.success(this.messageConstant.flowCopySuccess);
        } else {
          this.dialog.close(this.data.details);
          this._toastrService.error(response?.message);
        }
      },
      (err: ErrorModel) => {
        this._toastrService.error(err.error.message);
        this._loaderService.stop();
      }
    );
  }
}
