export const environment = {
  production: false,
  hmr: false,
  releaseStage: "development",

  // URLS
  baseUrl: "https://dev-api.agentprocrm.com/",
  appUrl: `${window.location.origin}/`,
  s3Url: "https://resimpli.s3.us-west-2.amazonaws.com/",
  newS3Url: "https://resimpli--v2-public.s3.us-east-2.amazonaws.com/",

  // KEYS
  authorizationKey: "cmVzaW1wbGk6YW5ndWxhcmV4cGVydA==",
  // stripePublickKey:
  //   "pk_test_51IGkXSGnh37yO8eUSLwXqWU2cZVGYJhdSyxV9To1QBOkV17Gz6fj6O3xBNrp5VaXdk5QumHtr5hpRPnhU55IhtUZ008m7fnq4x",
  stripePublickKey:
    "pk_test_51NY4cZITA7V9eYRPsgWM0XvIDW6XdQk0vT13srDElPcjCI3OPbAFUP9AZ6XxLPax0oZ58ME05Q9hSkkN9DjVWDrC00mVwqtqrz",

  // PAGINATION
  pagination: {
    defaultSort: "DESC", // ASC, DESC
    defaultView: "GRID", // GRID, LIST
    pageLimit: 12,
    type: "STANDARD",
  },

  //FOR BULK
  contact: {
    contactImportLimit: 5001,
    contactSMSLimit: 2500,
    contactEmailLimit: 10000,
  },

  // OTHERS
  defaultPath: "dashboard",
  defaultPlan: 99,
  countryCode: "+1",
};
