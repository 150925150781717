import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseUrl } from "../../utils/base-url-constants";
import { environment } from "src/environments/environment";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";

@Injectable({
  providedIn: "root",
})
export class QuickFilterService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem("token");

  constructor(private _commonFunction: CommonFunctionsService) {}

  getQuickFilter = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.quickFilterUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  save = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.quickFilterUrl + "save";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  update = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.quickFilterUrl + "edit";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteOne = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.quickFilterUrl + "delete";
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
