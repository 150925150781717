// MODULES
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// UTILS
import { ResponseModel } from "src/app/utils/models/response";
import { ErrorModel } from "src/app/utils/models/error";
import { MessageConstant } from "src/app/utils/message-constant";

// SERVICES
import { UserService } from "src/app/providers/user/user.service";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CreditService } from "src/app/providers/credit/credit.service";
import { ToastrService } from "ngx-toastr";
import { LeadsService } from "src/app/providers/leads/leads.service";

declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  messageConstant = MessageConstant;
  modules: any[] = [];
  filteredBrands: any[] = [];
  moduleList: any[] = [];
  userData: any;
  searchedItem: any;
  userImage: any = "";
  userList: any[] = [];
  userListing: any[] = [];
  subscriptionValidDate: any;
  subscriptionValid: any;
  coursePercent: any;
  actions: any[] = [];
  isDirectMailFreeUser: boolean = false;

  constructor(
    public _utilities: CommonFunctionsService,
    private router: Router,
    private _userService: UserService,
    private _creditService: CreditService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _leadService: LeadsService
  ) {}

  ngOnInit(): void {
    this.actions = [
      {
        label: "",
        alt: "Hot Leads",
        icon: "assets/images/hot-lead-new.svg",
        link: "hotLeads",
      },
      {
        label: "",
        alt: "Calendar",
        icon: "assets/images/navbar/calendar-icon-1.svg",
        link: "/calendar",
      },
      {
        label: "Balance",
        alt: "Balance",
        icon: "assets/images/navbar/sold-icon.svg",
        link: "/credit/buy-credit",
      },
    ];
    let token = localStorage.getItem("token");
    if (token) {
      this.getUserDetails();
      this.getUserPlan();
      this.getUserAccessData();
    }
  }

  getUserDetails() {
    this._userService.getUserDetails({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.userData = response?.data;
          this.getCoursePercentage(this.userData?.teachableUserId);

          if (this.userData?.isSubUser) {
            this.modules = this.moduleList.filter(
              (x) => x.id !== 2 && x.id !== 3 && x.id !== 4
            );
          } else {
            this.modules = this.moduleList;
          }
          if (this.userData.isDirectMailFreeUser == true) {
            this.isDirectMailFreeUser = true;
            localStorage.setItem(
              "isDirectMailFreeUser",
              this.userData.isDirectMailFreeUser
            );
          }
          if (this.userData?.loginData) {
            localStorage.setItem(
              "currentUser",
              JSON.stringify({ ...this.userData?.loginData })
            );
          }
          this.setMenu();
          if (this.userData?.profileImage) {
            this.userImage = this.userData?.profileImage;
          }
          this._utilities.userData = this.userData;
          this.getUsers();
        }
      },
      (err: ErrorModel) => {
        this.getUsers();
      }
    );
  }

  getUserPlan() {
    this._creditService.getUserPlan({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._utilities.currentPlanData = response.data;
        }
      },
      (err: ErrorModel) => {}
    );
  }

  setMenu() {
    let profileSubMenu = [];

    if (this.userData?.isSubUser && this.userData?.isManageUser) {
      profileSubMenu = [
        {
          id: 1,
          label: "Profile",
          link: "/users/profile",
          icon: "/assets/images/navbar/profile-icons.svg",
        },
        // {
        //   id: 4,
        //   label: "Manage User",
        //   link: "/users/user-list",
        //   icon: "/assets/images/navbar/manage-users-icons.svg",
        // },
      ];
    } else if (this.userData?.isSubUser) {
      profileSubMenu = [
        {
          id: 1,
          label: "Profile",
          link: "/users/profile",
          icon: "/assets/images/navbar/profile-icons.svg",
        },
      ];
    } else {
      profileSubMenu = [
        {
          id: 1,
          label: "Profile",
          link: "/users/profile",
          icon: "/assets/images/navbar/profile-icons.svg",
        },
        {
          id: 2,
          label: "Billing Details",
          link: "/billing/list",
          icon: "/assets/images/navbar/billing-icons.svg",
        },
        {
          id: 3,
          label: "Upgrade Plan",
          link: "/upgrade-plans",
          icon: "/assets/images/navbar/rightbar-upgrade-plan.svg",
        },
        // {
        //   id: 4,
        //   label: "Subscribe D4$",
        //   link: "/upgrade-plans/driving-plans",
        //   icon: "/assets/images/navbar/d4dollar.svg",
        // },
        // {
        //   id: 5,
        //   label: "Global Settings",
        //   link: "/users/user-list",
        //   icon: "/assets/images/navbar/global-settings.svg",
        // },
        // {
        //   id: 14,
        //   label: "Manage Users",
        //   link: "/users/user-list",
        //   icon: "/assets/images/navbar/manage-users-icons.svg",
        // },
      ];
    }

    this.moduleList = [
      {
        // label: "Profile",
        submenu: profileSubMenu,
      },
      // {
      //   label: "MARKETING",
      //   submenu: [
      // {
      //   id: 11,
      //   label: "Manage Teams",
      //   link: "/teams",
      //   icon: "/assets/images/navbar/manage-teams.svg",
      // },
      // {
      //   id: 6,
      //   label: "Manage Numbers",
      //   link: "/manage-numbers",
      //   icon: "/assets/images/navbar/manage-numbers-icons.svg",
      // },
      // {
      //   id: 7,
      //   label: "Manage Templates",
      //   link: "/manage-templates",
      //   icon: "/assets/images/navbar/manage-templates.svg",
      // },
      // {
      //   id: 8,
      //   label: "SMS Templates",
      //   link: "/sms-template",
      //   icon: "/assets/images/navbar/sms-templates-icons.svg",
      // },
      // {
      //   id: 9,
      //   label: "RVM Templates",
      //   link: "/rvm-template",
      //   icon: "/assets/images/navbar/rvm-icons.svg",
      // },
      //   ],
      // },
      // {
      //   label: "CUSTOMIZATION",
      //   submenu: [
      //     {
      //       id: 5,
      //       label: "Custom Questions",
      //       link: "/preference",
      //       icon: "/assets/images/navbar/custom-questions-icons.svg",
      //     },
      //     {
      //       id: 10,
      //       label: "Manage Tags",
      //       link: "/tags",
      //       icon: "/assets/images/navbar/tags-icons.svg",
      //     },
      //     {
      //       id: 12,
      //       label: "API Token",
      //       link: "/api-token",
      //       icon: "/assets/images/navbar/api-token.svg",
      //     },
      //   ],
      // },
    ];
    this.modules = this.moduleList;
  }

  logout() {
    let currentUser: any = localStorage.getItem("currentUser");
    const user = JSON.parse(currentUser);
    this._utilities.reset();
    localStorage.clear();
    this.router.navigate(["/auth/login"]);
  }

  getCoursePercentage(teachableUserId: any) {
    this._userService.getCoursePercentage({}).subscribe(
      (response: ResponseModel) => {
        let subscriptionData = response.data;
        this.subscriptionValidDate = subscriptionData?.subscriptionValidDate;
        this.subscriptionValid = subscriptionData?.subscriptionValid;
        this.coursePercent = subscriptionData?.courseDetail?.percent_complete;
      },
      (error: any) => {}
    );
  }

  closePopOver(menu?: any) {
    $(".re-popover_close").parents(".popover").popover("hide");
    $("body .infinite-scroll").removeClass("re-scroll-hide");
    $("body .re-card").removeClass("re-open-card");

    if (menu) {
      if (menu?.link) {
        this.router.navigate([menu?.link], {
          queryParams: menu?.queryParam,
        });
      } else {
        return;
      }
    }
  }

  reload() {
    this._utilities.updateAvailable = false;
    document.location.reload();
  }

  getUsers() {
    this._userService.getUsers({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.userList = [];
          this.userListing = response?.data;
          for (let i = 0; i < response?.data.length; i++) {
            const user = response?.data[i];
            const fullName = user.firstName + " " + user.lastName;
            const matches = fullName.match(/\b(\w)/g);
            const acronym = matches?.join("");
            this.userList.push({
              label: user.firstName + " " + user.lastName,
              value: user._id,
              profileImage: user?.profileImage ? user?.profileImage : "",
              name: acronym || "N/A",
            });
          }
        } else {
          this._toastrService.error(response?.message, "");
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  filterBrands(event) {
    this.filteredBrands = [""];
    const obj = {
      searchString: this.searchedItem
        ?.replaceAll("(", "")
        ?.replaceAll(")", "")
        ?.trim(),
    };
    this._leadService.searchleads(obj).subscribe(
      (response: ResponseModel) => {
        this.filteredBrands = response.data.items;
      },
      (error: any) => {
        console.error("filterBrandsError -------------->", error);
      }
    );
  }

  goTo(item) {
    if (typeof item === "object") {
      this.goToDetails(item);
    } else {
      if (!item?.trim()) {
        return;
      }

      this.router.navigate([
        "/leads/search-leads/",
        item.replaceAll("(", "").replaceAll(")", ""),
      ]);
      this.searchedItem = "";
      this.filteredBrands = [];
    }
  }

  goToDetails({ _id }) {
    if (!_id) {
      return;
    }

    let detail = this.filteredBrands.filter((x) => x?._id == _id)[0];

    const key = detail?.contactType == 2 ? "sellerId" : "buyerId";
    this.router.navigate([`/leads/details`], {
      queryParams: { [key]: _id },
    });
  }

  getUserAccessData() {
    this._userService.getUserAccessData({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._utilities.userAccessData = response?.data;
        }
      },
      (err: ErrorModel) => {}
    );
  }

  moveToHotLead() {
    this._utilities.isFilterLeads = true;
    let obj = {
      hotLead: { value: "true" },
    };
    this._utilities.leadFilter = obj;
    this.router.navigate(["/leads"], {
      queryParams: {
        ["hotlead"]: "true",
      },
    });
  }
}
