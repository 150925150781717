//MODULE
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment-timezone";
import * as _ from "lodash";

// UTILS
import { MessageConstant } from "src/app/utils/message-constant";
import { ErrorModel } from "src/app/utils/models/error";
import { ResponseModel } from "src/app/utils/models/response";
import { StatusConstant } from "src/app/utils/status-constant";
import { MiscellaneousConstant } from "src/app/utils/miscellaneous-constant";
import { timezoneConstant } from "src/app/utils/timezone-list-constant";

// SERVICES
import { ToastrService } from "ngx-toastr";
import { LeadsService } from "src/app/providers/leads/leads.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { TaskService } from "src/app/providers/task/task.service";
import { DashboardService } from "src/app/providers/dashboard/dashboard.service";
import { UserService } from "src/app/providers/user/user.service";
import { TeamsService } from "src/app/providers/teams/teams.service";
@Component({
  selector: "app-add-edit-task",
  templateUrl: "./add-edit-task.component.html",
  styleUrls: ["./add-edit-task.component.scss"],
})
export class AddEditTaskComponent implements OnInit, AfterViewInit {
  @ViewChild("errorReminder") errorReminder: ElementRef;

  addEditTaskForm: FormGroup;
  type;
  // detail;
  button;
  minutesList;
  reminders;
  timezoneList: any = [];
  instantNotification: boolean = false;
  isCompleted: boolean = false;
  taskBeforeAfterChange: string = "";
  submitted: boolean = false;
  taskTimeTypeList: any[] = [];
  minimumDate = new Date();
  minimumTime = new Date();
  public messageConstant = MessageConstant;
  teams: any[] = [];
  assignUser: any[] = [];
  roles: any[] = [];
  // assignUserList: any[] = [];
  // showAssignToUser: boolean = false;
  taskType: any[] = [];
  isGeneralTask: boolean = false;
  mainStatusId: string = "";
  // moduleId: string = "";
  label: string = "";
  value: string = "";
  subModuleId: string = "";
  propertyLoader: boolean = false;
  propertyList: any = [];
  noDataAvailable: string = "No Data Available";
  timeOption: any = [
    "12:00 AM",
    "12:15 AM",
    "12:30 AM",
    "12:45 AM",
    "01:00 AM",
    "01:15 AM",
    "01:30 AM",
    "01:45 AM",
    "02:00 AM",
    "02:15 AM",
    "02:30 AM",
    "02:45 AM",
    "03:00 AM",
    "03:15 AM",
    "03:30 AM",
    "03:45 AM",
    "04:00 AM",
    "04:15 AM",
    "04:30 AM",
    "04:45 AM",
    "05:00 AM",
    "05:15 AM",
    "05:30 AM",
    "05:45 AM",
    "06:00 AM",
    "06:15 AM",
    "06:30 AM",
    "06:45 AM",
    "07:00 AM",
    "07:15 AM",
    "07:30 AM",
    "07:45 AM",
    "08:00 AM",
    "08:15 AM",
    "08:30 AM",
    "08:45 AM",
    "09:00 AM",
    "09:15 AM",
    "09:30 AM",
    "09:45 AM",
    "10:00 AM",
    "10:15 AM",
    "10:30 AM",
    "10:45 AM",
    "11:00 AM",
    "11:15 AM",
    "11:30 AM",
    "11:45 AM",
    "12:00 PM",
    "12:15 PM",
    "12:30 PM",
    "12:45 PM",
    "01:00 PM",
    "01:15 PM",
    "01:30 PM",
    "01:45 PM",
    "02:00 PM",
    "02:15 PM",
    "02:30 PM",
    "02:45 PM",
    "03:00 PM",
    "03:15 PM",
    "03:30 PM",
    "03:45 PM",
    "04:00 PM",
    "04:15 PM",
    "04:30 PM",
    "04:45 PM",
    "05:00 PM",
    "05:15 PM",
    "05:30 PM",
    "05:45 PM",
    "06:00 PM",
    "06:15 PM",
    "06:30 PM",
    "06:45 PM",
    "07:00 PM",
    "07:15 PM",
    "07:30 PM",
    "07:45 PM",
    "08:00 PM",
    "08:15 PM",
    "08:30 PM",
    "08:45 PM",
    "09:00 PM",
    "09:15 PM",
    "09:30 PM",
    "09:45 PM",
    "10:00 PM",
    "10:15 PM",
    "10:30 PM",
    "10:45 PM",
    "11:00 PM",
    "11:15 PM",
    "11:30 PM",
    "11:45 PM",
  ];
  priorityClass: number = 5;
  reminderErrors: any[] = [];
  customTime: boolean = true;
  taskDueNumber: number = 3;
  taskTemplate: any = [];
  currentPage: number = 1;
  // currentLimit: number = MiscellaneousConstant.paginationLimit.STANDARD;
  submitButton: string = "Add Task";
  activeTab: string = this.data?.activeTask;
  // isSubmit: boolean = false;
  isMarketReadonly: boolean = false;
  leadAssignmentRoleList: any[] = [];
  userListing: any[] = [];
  assignLeadRoleId: any;
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddEditTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _utilities: CommonFunctionsService,
    private toastr: ToastrService,
    private _leadService: LeadsService,
    private _loaderService: NgxUiLoaderService,
    private taskService: TaskService,
    private _dashboardService: DashboardService,
    private _teamService: TeamsService,
    private _userService: UserService
  ) {
    this.isGeneralTask = this.data.isGeneralTask;
  }

  // taskTab(tabValue) {
  //   this.isGeneralTask = tabValue;
  // }

  ngOnInit(): void {
    this.timezoneList = timezoneConstant.timezoneArray;
    this.taskTimeTypeList = this._utilities.taskTimeTypeList();
    this.taskType = this._utilities.taskType();
    this.type = this.data.category;

    this.getTaskTemplateDetails();

    if (this.data.isLocalTask && this.type == "Add") {
      // this.getTeams();
      this.getLeadAssignmentRoleList();
      this.getUserRoleList();
    }
    if (this.data.isGeneralTask && this.type == "Add") {
      this.getUserRoleList();
    }

    if (this.type == "Add") {
      this.button = "Create";
    } else {
      this.button = "Update";
    }

    this.minutesList = this._utilities.minutesList(59, 1);
    this.addEditTaskForm = this.formBuilder.group({
      taskTemplateOption: [""],
      subModuleId: [""],
      title: ["", Validators.required],
      taskTypeOption: [8, Validators.required],
      taskPriority: ["5", Validators.required],
      taskDetail: [""],
      roleId: [""],
      assignUserId: ["", Validators.required],
      dueInIso: [new Date(), Validators.required],
      time: [new Date(), Validators.required],
      isInstantNotification: ["0", Validators.required],
      smsAndEmail: ["email"],
      reminders: this.formBuilder.array([]),
      isEnabled: ["true", Validators.required],
      taskTimezone: ["", Validators.required],
    });

    if (this.data.isLocalTask) {
      this.addEditTaskForm.patchValue({
        subModuleId: this.data.leadInfo._id,
      });
    }

    let currentTime = new Date();
    let convertTime = moment(currentTime)
      .tz(localStorage.getItem("timezoneOffset"))
      .format("YYYY-MM-DD HH:mm:ss");
    let dueIso = this._utilities.dueDateFormat(new Date(convertTime)).parseDate;
    this.minimumDate = new Date(
      moment
        .tz(localStorage.getItem("timezoneOffset"))
        .format("YYYY/MM/DD h:mm:ss A")
    );
    this.minimumTime = new Date(
      moment
        .tz(localStorage.getItem("timezoneOffset"))
        .subtract(1, "days")
        .format("YYYY/MM/DD h:mm:ss A")
    );
    this.addEditTaskForm.patchValue({
      dueInIso: dueIso,
      time: moment(dueIso, "h:mm:ss A").set({ second: 0 }).format("LT"),
      taskTimezone: localStorage.getItem("timezoneOffset"),
    });

    if (this.type == "Update") {
      if (!this.data.task.isGeneralTask && this.data.leadInfo) {
        // this.getTeams(this.data.task.subModuleId, this.data.task.isGeneralTask);
        this.getLeadAssignmentRoleList(this.data.task.isGeneralTask);
      }
      this.getUserRoleList();

      if (this.data.task.isGeneralTask) {
        this.tabChange("General");
      }

      let smsEmail = "";
      let {
        isInstantNotification,
        isInstantNotificationSms,
        isInstantNotificationEmail,
        taskBeforeAfter,
        taskBeforeAfterStatus,
      } = this.data.task;

      let dateInso = new Date(
        moment(this.data.task.dueInIso)
          .utcOffset("0")
          .format("YYYY-MM-DD HH:mm")
      );
      let dueIso = this._utilities.dueDateFormat(dateInso).parseDate;
      if (isInstantNotificationEmail && isInstantNotificationSms) {
        smsEmail = "both";
      } else if (isInstantNotificationSms) {
        smsEmail = "sms";
      } else if (isInstantNotificationEmail) {
        smsEmail = "email";
      }

      // this.timeOption.push(
      //   moment(dueIso, "h:mm:ss A").set({ second: 0 }).format("LT")
      // );
      this.instantNotification = isInstantNotification;
      isInstantNotification = isInstantNotification ? "1" : "0";
      this.taskBeforeAfterChange = this.data.task.taskBeforeAfter;
      if (this.data.leadInfo) {
        this.isGeneralTask =
          this.data.task.isGeneralTask != undefined
            ? this.data.task.isGeneralTask
            : false;
      }
      this.addEditTaskForm.patchValue({
        subModuleId: this.data.task.subModuleId,
        title: this.data.task.title,
        taskTypeOption: this.data.task.taskTypeOption
          ? this.data.task.taskTypeOption
          : 8,
        taskPriority: this.data.task.taskPriority
          ? String(this.data.task.taskPriority)
          : "5",
        taskDetail: this.data.task.taskDetail,
        // roleId: this.data.task.roleId,
        // assignUserId: this.data.task.assignUserId,
        dueInIso: dueIso,
        time: moment(dueIso, "h:mm:ss A").set({ second: 0 }).format("LT"),
        isInstantNotification,
        smsAndEmail: smsEmail,
        taskBeforeAfter: taskBeforeAfter,
        taskBeforeAfterStatus: taskBeforeAfterStatus,
        isEnabled: true,
        taskTimezone:
          this.data.task.taskTimezone || this.data.task.user.timezoneOffset,
      });
      this.onChangePriority(
        this.data.task.taskPriority ? String(this.data.task.taskPriority) : "5"
      );
      this.addEditTaskForm.setControl(
        "reminders",
        this.setExistingReminder(this.data.task.reminders)
      );

      if (this.data.task?.assignRoleId) {
        this.onChangeRole(this.data.task.assignRoleId);
      }
      if (this.data.task?.assignUserId) {
        this.onChangeRole(this.data.task.assignUserId);
      }
    }
    // else {
    //   this.timeOption.push(
    //     moment(dueIso, "h:mm:ss A").set({ second: 0 }).format("LT")
    //   );
    // }
  }

  ngAfterViewInit() {
    this.isMarketReadonly = true;
  }

  getTaskTemplateDetails() {
    let payload = {
      page: this.currentPage,
      limit: 2000,
    };
    this._loaderService.start();
    this.taskService.listTaskTemplate(payload).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.taskTemplate = response?.data?.items;
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this.toastr.error(error.message, "");
        } else {
          this.toastr.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  onChangeTemplate(templateId) {
    if (!templateId) {
      this.addEditTaskForm = this.formBuilder.group({
        taskTemplateOption: [""],
        subModuleId: [""],
        title: ["", Validators.required],
        taskTypeOption: [8, Validators.required],
        taskPriority: ["5", Validators.required],
        taskDetail: [""],
        roleId: [""],
        assignUserId: ["", Validators.required],
        dueInIso: [new Date(), Validators.required],
        time: [new Date(), Validators.required],
        isInstantNotification: ["0", Validators.required],
        smsAndEmail: ["email"],
        reminders: this.formBuilder.array([]),
        isEnabled: ["true", Validators.required],
        taskTimezone: ["", Validators.required],
      });
      if (this.data.isLocalTask) {
        this.addEditTaskForm.patchValue({
          subModuleId: this.data.leadInfo._id,
        });
      }
      this.priorityClass = 5;
      this.taskDueNumber = 3;
      this.taskDue("Custom");
    }
    let templateDetails;
    if (templateId) {
      templateDetails = this.taskTemplate.find(
        (item) => item._id == templateId
      );
    }
    if (templateDetails) {
      let smsEmail = "";
      let {
        isInstantNotification,
        isInstantNotificationSms,
        isInstantNotificationEmail,
        taskDueType,
      } = templateDetails;
      let taskOptiontype = "";
      if (taskDueType == 0) {
        taskOptiontype = "One Hour";
      } else if (taskDueType == 1) {
        taskOptiontype = "One Day";
      } else if (taskDueType == 2) {
        taskOptiontype = "One Week";
      } else if (taskDueType == 3) {
        taskOptiontype = "Custom";
      }

      if (isInstantNotificationEmail && isInstantNotificationSms) {
        smsEmail = "both";
      } else if (isInstantNotificationSms) {
        smsEmail = "sms";
      } else if (isInstantNotificationEmail) {
        smsEmail = "email";
      }
      this.instantNotification = isInstantNotification;
      isInstantNotification = isInstantNotification ? "1" : "0";

      this.taskDue(taskOptiontype, templateDetails);
      this.addEditTaskForm.patchValue({
        taskTemplateOption: templateDetails._id,
        title: templateDetails.title,
        taskTypeOption: templateDetails.taskTypeOption
          ? templateDetails.taskTypeOption
          : 8,
        taskPriority: templateDetails.taskPriority
          ? String(templateDetails.taskPriority)
          : "5",
        taskDetail: templateDetails.taskDetail,
        // roleId: templateDetails.assignRoleId,
        // assignUserId: templateDetails.assignUserId,
        isInstantNotification,
        smsAndEmail: smsEmail,
        isEnabled: true,
      });

      this.onChangePriority(
        templateDetails.taskPriority
          ? String(templateDetails.taskPriority)
          : "5"
      );
      this.addEditTaskForm.setControl(
        "reminders",
        this.setExistingReminder(templateDetails.reminders)
      );
      if (templateDetails?.assignRoleId) {
        this.onChangeRole(templateDetails.assignRoleId);
      }
      if (templateDetails?.assignUserId) {
        this.onChangeRole(templateDetails.assignUserId);
      }
    }
  }

  getTeamBySubModuleId(subModuleId) {
    const obj = {
      leadId: subModuleId,
    };
    this.addEditTaskForm.patchValue({
      roleId: "",
    });
    this._loaderService.start();
    this._leadService.leadInfo(obj).subscribe(
      (response: ResponseModel) => {
        this.addEditTaskForm.value.mainStatusId =
          response.data?.leadData?.mainStatusId;
        this.mainStatusId = response.data?.leadData?.mainStatusId;
        // this.getTeams(response.data?.leadData?._id, true);
        this.getLeadAssignmentRoleList(response.data?.leadData?._id, true);
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this.toastr.error(error.message, "");
        } else {
          this.toastr.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  createReminder(): FormGroup {
    return this.formBuilder.group({
      via: ["", Validators.required],
      before: ["", Validators.required],
      time: ["", Validators.required],
    });
  }

  setExistingReminder(reminders): FormArray {
    const formArray = new FormArray([]);
    reminders.forEach((element) => {
      formArray.push(
        this.formBuilder.group({
          via: element.via,
          before: element.before.toString(),
          time: element.time,
        })
      );
    });
    return formArray;
  }

  addReminder(): void {
    this.reminders = this.addEditTaskForm.get("reminders") as FormArray;
    if (this.reminders.length >= 5) {
      this.toastr.error(this.messageConstant?.reminder5LmtErr, "");
    } else {
      let add = true;
      if (this.reminders.length > 0) {
        for (let i = 0; i < this.reminders.length; i++) {
          let element = this.reminders.at(i).value;
          if (!element.via || !element.before || !element.time) {
            add = false;
          }
        }
      }
      if (add) {
        this.reminders.push(this.createReminder());
      } else {
        this.toastr.error(this.messageConstant?.reminderFillLmtErr, "");
      }
    }
  }

  removeReminder(i: number) {
    (<FormArray>this.addEditTaskForm.get("reminders")).removeAt(i);
  }

  onInstantNotification() {
    this.instantNotification = !this.instantNotification;
    this.addEditTaskForm.patchValue({
      isInstantNotification: this.instantNotification,
    });
  }

  tabChange(activeTabE: string) {
    this.activeTab = activeTabE;
    this.submitButton = "Add Task";
    if (activeTabE == "General") {
      this.isGeneralTask = true;
    } else {
      this.isGeneralTask = false;
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addEditTaskForm.controls[controlName].hasError(errorName);
  };

  save() {
    this.submitted = true;
    let formObject = this.addEditTaskForm.value;
    let { isInstantNotification, reminders, dueInIso, time } = formObject;

    if (this.assignLeadRoleId) {
      let assignedUserId = this.leadAssignmentRoleList.find(
        (item) => item._id == this.assignLeadRoleId
      );

      if (assignedUserId) {
        this.addEditTaskForm.patchValue({
          roleId: assignedUserId?.roleId,
          assignUserId: assignedUserId?.userId,
        });
      }
    }

    if (this.type == "Update" && this.data.leadInfo) {
      if (
        !this.data.task.hasOwnProperty("isGeneralTask") ||
        !this.data.task.isGeneralTask
      ) {
        this.addEditTaskForm.get("roleId").setValidators([Validators.required]);

        this.addEditTaskForm.get("roleId").updateValueAndValidity();
      }
    }
    if (this.type == "Add" && !this.isGeneralTask) {
      this.addEditTaskForm.get("roleId").setValidators([Validators.required]);
      this.addEditTaskForm.get("roleId").updateValueAndValidity();

      if (!this.addEditTaskForm.value.subModuleId) {
        if (!this.addEditTaskForm.value.subModuleId) {
          this.addEditTaskForm.patchValue({
            subModuleId: "",
          });
        }

        this.addEditTaskForm
          .get("subModuleId")
          .setValidators([Validators.required]);

        this.addEditTaskForm.get("subModuleId").updateValueAndValidity();
      }
    }

    if (this.type == "Add" && this.isGeneralTask) {
      this.addEditTaskForm.get("roleId").clearValidators();
      this.addEditTaskForm.get("roleId").updateValueAndValidity();

      this.addEditTaskForm.get("subModuleId").clearValidators();
      this.addEditTaskForm.get("subModuleId").updateValueAndValidity();
    }

    if (this.addEditTaskForm.invalid) {
      return;
    }

    const taskDueValidation = this.taskDueValidation(reminders, dueInIso, time);
    if (taskDueValidation.toString().length > 33) {
      this.errorReminder.nativeElement?.scrollIntoView({ behavior: "smooth" });
      return false;
    }

    this.addEditTaskForm.value.taskType = "LEAD";
    this.addEditTaskForm.value.isInstantNotification =
      isInstantNotification == 0 ? false : true;
    this.addEditTaskForm.value.dueInIso =
      moment(dueInIso).format("YYYY-MM-DD") +
      "T" +
      moment(time, "h:mm:ss A").set({ second: 0 }).format("HH:mm:ss");

    if (isInstantNotification) {
      if (this.addEditTaskForm.value.smsAndEmail == "email") {
        this.addEditTaskForm.value.isInstantNotificationEmail = true;
        this.addEditTaskForm.value.isInstantNotificationSms = false;
      } else if (this.addEditTaskForm.value.smsAndEmail == "sms") {
        this.addEditTaskForm.value.isInstantNotificationSms = true;
        this.addEditTaskForm.value.isInstantNotificationEmail = false;
      } else if (this.addEditTaskForm.value.smsAndEmail == "both") {
        this.addEditTaskForm.value.isInstantNotificationEmail = true;
        this.addEditTaskForm.value.isInstantNotificationSms = true;
      }
    }

    delete this.addEditTaskForm.value.smsAndEmail;
    delete this.addEditTaskForm.value.time;
    delete this.addEditTaskForm.value.taskTemplateOption;
    this.addEditTaskForm.value.title = this.addEditTaskForm.value.title
      ? this.addEditTaskForm.value.title.trim()
      : "";
    this.addEditTaskForm.value.taskDetail = this.addEditTaskForm.value
      .taskDetail
      ? this.addEditTaskForm.value.taskDetail.trim()
      : "";
    this.addEditTaskForm.value.isGeneralTask = this.isGeneralTask;

    if (typeof this.addEditTaskForm.value.subModuleId === "object") {
      this.addEditTaskForm.value.subModuleId =
        this.addEditTaskForm.value.subModuleId._id;
    }

    if (this.isGeneralTask) {
      let data = Object.assign(this.addEditTaskForm.value, {
        mainStatusId: StatusConstant.GeneralModule.GENERAL_MAIN_STATUS_ID,
      });
      if (data.taskPriority) data.taskPriority = parseInt(data.taskPriority);
      let timeStamp =
        this.addEditTaskForm.value.dueInIso +
        moment(this.addEditTaskForm.value.dueInIso)
          .tz(this.addEditTaskForm.value.taskTimezone)
          .format("Z");
      if (this.addEditTaskForm.value.reminders.length) {
        let remindersDates = _.map(
          this.addEditTaskForm.value.reminders,
          (item) => {
            item.remInIsoTimeStamp = moment(timeStamp)
              .subtract(item.before, item.time)
              .valueOf();
            return item;
          }
        );
        data.reminders = remindersDates;
      }
      data.dueInIsoTimeStamp = moment(timeStamp).valueOf();
      if (this.type == "Update") {
        data.isNewTask = true;
      }
      this.dialogRef.close(data);
    } else {
      let data = Object.assign(this.addEditTaskForm.value, {
        mainStatusId: this.mainStatusId,
      });
      if (!data.mainStatusId) {
        delete data.mainStatusId;
      }
      let timeStamp =
        this.addEditTaskForm.value.dueInIso +
        moment(this.addEditTaskForm.value.dueInIso)
          .tz(this.addEditTaskForm.value.taskTimezone)
          .format("Z");
      if (this.addEditTaskForm.value.reminders.length) {
        let remindersDates = this.addEditTaskForm.value.reminders.map(
          (item) => {
            item.remInIsoTimeStamp = moment(timeStamp)
              .subtract(item.before, item.time)
              .valueOf();
            return item;
          }
        );
        data.reminders = remindersDates;
      }
      data.dueInIsoTimeStamp = moment(timeStamp).valueOf();

      if (this.type == "Update") {
        data.isNewTask = true;
      }
      data.taskPriority = parseInt(data.taskPriority);
      this.dialogRef.close(data);
    }
  }

  // taskCompleted(e) {
  //   if (e.target.checked) {
  //     this.isCompleted = true;
  //     this.toastr.success(this.messageConstant?.taskMarkCompMsg, "");
  //     this.dialogRef.close({ isCompleted: this.isCompleted });
  //   } else {
  //     this.isCompleted = false;
  //     this.toastr.success(this.messageConstant?.taskMarkPendingMsg, "");
  //     this.dialogRef.close({ isCompleted: this.isCompleted });
  //   }
  // }

  taskDueValidationChange() {
    let reminders = this.addEditTaskForm.get("reminders").value as FormArray;
    let date = this.addEditTaskForm.get("dueInIso").value;
    let time = this.addEditTaskForm.get("time").value;
    this.taskDueValidation(reminders, date, time);
  }

  taskDueValidation(reminders, date, time) {
    this.reminderErrors = [];
    let str = this.messageConstant?.taskDueTimeMsg;
    const concatDate =
      moment(date).format("YYYY-MM-DD") + "T" + moment(time).format("HH:mm:ss");

    reminders.forEach((element, i) => {
      const timeZone = timezoneConstant.timezoneArray.find((element) => {
        return element.timezonename == this.addEditTaskForm.value.taskTimezone;
      });
      const reminder = moment
        .tz(timeZone.timezonename)
        .add(element.before, element.time)
        .format("YYYY-MM-DDTHH:mm:ss");

      if (moment(reminder).isAfter(concatDate)) {
        str += `Reminder ${i + 1} is invalid\n`;
        this.reminderErrors[i] = this.messageConstant?.taskReminderMsg;
      } else {
        this.reminderErrors.splice(i, 1);
      }
    });

    return str.length > 0 ? str : true;
  }

  onChangeRole(id, update?) {
    if (update) {
      let assignedId = this.leadAssignmentRoleList.find(
        (item) => item._id == id
      );

      if (assignedId && this.activeTab == "Lead") {
        this.assignLeadRoleId = id;
      }
    }

    let assignedUserId = this.leadAssignmentRoleList.find(
      (item) => item.userId.toLowerCase() == id.toLowerCase()
    );

    let assignedRoleId = this.leadAssignmentRoleList.find(
      (item) => item.roleId.toLowerCase() == id.toLowerCase()
    );

    if (assignedUserId && this.activeTab == "Lead") {
      this.assignLeadRoleId = assignedUserId?._id;
    } else if (assignedRoleId && this.activeTab == "Lead") {
      this.assignLeadRoleId = assignedRoleId?._id;
    } else {
      let listUserId = this.userListing.find((item) => item.value == id);
      if (listUserId) {
        this.addEditTaskForm.patchValue({
          assignUserId: id,
        });
      }
      return;
    }
    this.addEditTaskForm.patchValue({
      roleId: this.assignLeadRoleId,
    });
  }

  getLeadAssignmentRoleList(subModuleId?, isGeneral?) {
    let obj;
    if (!isGeneral) {
      obj = {
        leadId: this.data?.leadInfo?.leadId || this.data?.leadInfo?._id,
      };
    } else {
      if (subModuleId) {
        obj = {
          leadId: subModuleId,
        };
      } else {
        obj = {
          leadId: this.data?.leadInfo?.leadId || this.data?.leadInfo?._id,
        };
      }
    }
    // if (!isGeneral) {
    //   let obj = {
    //     leadId: this.data?.leadInfo._id,
    //   }
    this._loaderService.start();
    this._leadService.getLeadAssignmentRoleList(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode && response?.statusCode == 200) {
          this.leadAssignmentRoleList = response.data?.items[0].userAssignment;
        }

        if (this.type == "Update") {
          if (this.data.task?.assignRoleId) {
            this.onChangeRole(this.data.task.assignRoleId);
          }
          if (this.data.task?.assignUserId) {
            this.onChangeRole(this.data.task.assignUserId);
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
        } else {
          this.toastr.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  getTeams(subModuleId?, isGeneral?) {
    if (!isGeneral) {
      let obj = {
        teamId: this.data?.leadInfo.teamId,
      };

      this._teamService.getTeamRoleAssignment(obj).subscribe((response) => {
        let teamData = response.data?.items;
        var finalArray = [];
        if (teamData) {
          teamData.map((tData) => {
            let objData = {
              _id: tData.roleId,
              name: tData.roleName + " | " + tData.name,
              assignUserId: tData._id,
            };
            finalArray.push(objData);
          });
        }
        if (finalArray.length > 0) {
          this.roles = finalArray;
        }
      });
    } else {
      let obj;

      if (subModuleId) {
        obj = {
          leadId: subModuleId,
        };
      } else {
        obj = {
          leadId: this.data?.leadInfo?.leadId || this.data?.leadInfo?._id,
        };
      }

      this._loaderService.start();
      this._leadService.getTeams(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode && response.statusCode == 200) {
            this.teams = response.data?.items;
            this.assignUser = response.data?.items?.assignUser;
            let object = this.assignUser;
            let arr = [];
            for (const property in object) {
              const role = this.teams["roles"].find(
                (item) => item._id == property
              );
              if (object[property] && role?.members?.length) {
                const assignedUser = role.members.find(
                  (item) => item._id == object[property]
                );
                if (assignedUser)
                  arr.push({
                    _id: property,
                    name:
                      role.name +
                      " | " +
                      assignedUser?.firstName +
                      " " +
                      assignedUser?.lastName,
                    assignUserId: object[property],
                  });
              }
            }
            const userIndex = arr.findIndex(
              (x) => x.assignUserId === this.addEditTaskForm.value.assignUserId
            );
            if (userIndex === -1) {
              const user = this.data?.userList.find(
                (x) => x._id === this.addEditTaskForm.value.assignUserId
              );
              if (user) {
                user.roleData.filter((role) => {
                  if (role._id === this.addEditTaskForm.value.roleId) {
                    const name =
                      role.name + " | " + user.firstName + " " + user.lastName;
                    arr.push({
                      _id: role._id,
                      name,
                      assignUserId: this.addEditTaskForm.value.assignUserId,
                    });
                  }
                });
              }
            }
            this.roles = arr;
            this.roles = _.sortBy(this.roles, "name");
            if (this.type == "Update") {
              this.onChangeRole(this.data?.task?.roleId);
            }
            if (!this.propertyLoader) {
              this._loaderService.stop();
            }
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
          } else {
            this.toastr.error(this.messageConstant.unknownError, "");
          }
        }
      );
    }
  }

  getPropertyAddress(searchText) {
    if (!searchText || searchText == "") {
      return;
    }
    this._dashboardService.getAllLeadAddress({ searchText }).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode && response?.statusCode == 200) {
          this.propertyList = response?.data;
        } else {
          this.toastr.error(response?.message, "");
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this.toastr.error(error.message, "");
        } else {
          this.toastr.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  taskDue(type, data?) {
    this.customTime = false;
    let currentTime = new Date();
    let convertTimeNew;
    let convertTime;
    let dueIso;
    switch (type) {
      case "One Hour":
        this.taskDueNumber = 0;
        convertTimeNew = new Date(
          new Date(currentTime).setHours(currentTime.getHours() + 1)
        );
        convertTime = moment(convertTimeNew)
          .tz(localStorage.getItem("timezoneOffset"))
          .format("YYYY-MM-DD HH:mm:ss");
        dueIso = this._utilities.dueDateFormat(new Date(convertTime)).parseDate;
        this.minimumDate = new Date(
          moment
            .tz(localStorage.getItem("timezoneOffset"))
            .format("YYYY/MM/DD h:mm:ss A")
        );
        this.minimumTime = new Date(
          moment
            .tz(localStorage.getItem("timezoneOffset"))
            .subtract(1, "days")
            .format("YYYY/MM/DD h:mm:ss A")
        );
        this.addEditTaskForm.patchValue({
          dueInIso: dueIso,
          time: dueIso,
          taskTimezone: localStorage.getItem("timezoneOffset"),
        });
        break;
      case "One Day":
        this.taskDueNumber = 1;
        convertTimeNew = new Date(
          new Date(currentTime).setDate(currentTime.getDate() + 1)
        );
        convertTime = moment(convertTimeNew)
          .tz(localStorage.getItem("timezoneOffset"))
          .format("YYYY-MM-DD HH:mm:ss");
        dueIso = this._utilities.dueDateFormat(new Date(convertTime)).parseDate;
        this.minimumDate = new Date(
          moment
            .tz(localStorage.getItem("timezoneOffset"))
            .format("YYYY/MM/DD h:mm:ss A")
        );
        this.minimumTime = new Date(
          moment
            .tz(localStorage.getItem("timezoneOffset"))
            .subtract(1, "days")
            .format("YYYY/MM/DD h:mm:ss A")
        );
        this.addEditTaskForm.patchValue({
          dueInIso: dueIso,
          time: dueIso,
          taskTimezone: localStorage.getItem("timezoneOffset"),
        });
        break;
      case "One Week":
        this.taskDueNumber = 2;
        convertTimeNew = new Date(
          new Date(currentTime).setDate(currentTime.getDate() + 7)
        );
        convertTime = moment(convertTimeNew)
          .tz(localStorage.getItem("timezoneOffset"))
          .format("YYYY-MM-DD HH:mm:ss");
        dueIso = this._utilities.dueDateFormat(new Date(convertTime)).parseDate;
        this.minimumDate = new Date(
          moment
            .tz(localStorage.getItem("timezoneOffset"))
            .format("YYYY/MM/DD h:mm:ss A")
        );
        this.minimumTime = new Date(
          moment
            .tz(localStorage.getItem("timezoneOffset"))
            .subtract(1, "days")
            .format("YYYY/MM/DD h:mm:ss A")
        );
        this.addEditTaskForm.patchValue({
          dueInIso: dueIso,
          time: dueIso,
          taskTimezone: localStorage.getItem("timezoneOffset"),
        });
        break;
      case "Custom":
        this.taskDueNumber = 3;
        if (data && data.taskDueTime && data.taskDueTimeType) {
          let timeDue = parseInt(data.taskDueTime);
          if (data.taskDueTimeType == "Minutes") {
            convertTimeNew = new Date(
              new Date(currentTime).setMinutes(
                currentTime.getMinutes() + timeDue
              )
            );
          } else if (data.taskDueTimeType == "Hours") {
            convertTimeNew = new Date(
              new Date(currentTime).setHours(currentTime.getHours() + timeDue)
            );
          } else if (data.taskDueTimeType == "Days") {
            convertTimeNew = new Date(
              new Date(currentTime).setDate(currentTime.getDate() + timeDue)
            );
          } else if (data.taskDueTimeType == "Weeks") {
            convertTimeNew = new Date(
              new Date(currentTime).setDate(currentTime.getDate() + 7 * timeDue)
            );
          } else if (data.taskDueTimeType == "Months") {
            convertTimeNew = new Date(
              new Date(currentTime).setMonth(currentTime.getMonth() + timeDue)
            );
          }
          convertTime = moment(convertTimeNew)
            .tz(localStorage.getItem("timezoneOffset"))
            .format("YYYY-MM-DD HH:mm:ss");
          dueIso = this._utilities.dueDateFormat(
            new Date(convertTime)
          ).parseDate;
          let timeZoneData = moment(dueIso, "h:mm:ss A")
            .set({ second: 0 })
            .format("LT");
          this.minimumDate = new Date(
            moment
              .tz(localStorage.getItem("timezoneOffset"))
              .format("YYYY/MM/DD h:mm:ss A")
          );
          this.minimumTime = new Date(
            moment
              .tz(localStorage.getItem("timezoneOffset"))
              .subtract(1, "days")
              .format("YYYY/MM/DD h:mm:ss A")
          );
          this.addEditTaskForm.patchValue({
            dueInIso: dueIso,
            time: dueIso,
            taskTimezone: localStorage.getItem("timezoneOffset"),
          });
        } else {
          this.customTime = true;
          convertTime = moment(currentTime)
            .tz(localStorage.getItem("timezoneOffset"))
            .format("YYYY-MM-DD HH:mm:ss");

          dueIso = this._utilities.dueDateFormat(
            new Date(convertTime)
          ).parseDate;
          this.minimumDate = new Date(
            moment
              .tz(localStorage.getItem("timezoneOffset"))
              .format("YYYY/MM/DD h:mm:ss A")
          );
          this.minimumTime = new Date(
            moment
              .tz(localStorage.getItem("timezoneOffset"))
              .subtract(1, "days")
              .format("YYYY/MM/DD h:mm:ss A")
          );
          this.addEditTaskForm.patchValue({
            dueInIso: dueIso,
            time: dueIso,
            taskTimezone: localStorage.getItem("timezoneOffset"),
          });
        }
        break;
      default:
        break;
    }
  }

  onChangePriority(priorityVal) {
    this.priorityClass = priorityVal ? priorityVal : 5;
  }

  // timezoneSelected(e) {
  //   this.minimumDate = new Date(
  //     moment.tz(e.target.value).format("YYYY/MM/DD h:mm:ss A")
  //   );
  //   this.minimumTime = new Date(
  //     moment.tz(e.target.value).format("YYYY/MM/DD h:mm:ss A")
  //   );
  // }

  getUserRoleList() {
    this._userService
      .getUserRoleList({ page: this.currentPage, limit: 100 })
      .subscribe(
        (response: ResponseModel) => {
          let userData = response.data?.items;
          if (userData) {
            userData.map((item) => {
              let objData = {
                label: item.firstName + " " + item.lastName,
                value: item._id,
              };
              this.userListing.push(objData);
            });
          }
          if (this.type == "Update" && this.data.isGeneralTask) {
            if (this.data.task?.assignRoleId) {
              this.onChangeRole(this.data.task.assignRoleId);
            }
            if (this.data.task?.assignUserId) {
              this.onChangeRole(this.data.task.assignUserId);
            }
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this.toastr.error(error.message, "");
          } else {
            this.toastr.error(this.messageConstant.unknownError, "");
          }
        }
      );
  }
}
