import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, delay, retryWhen, tap } from "rxjs/operators";
import { Router } from "@angular/router";

// SERVICES
import { AuthService } from "./providers/auth/auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private retryCount = 2;
  private retryWaitMilliSeconds = 1000;
  private retryStatus = 504;

  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      !request.url.includes("index.php?Api") &&
      !request.url.includes("account/show?email=")
    ) {
      if (this.authService.getToken()) {
        request = request.clone({
          setHeaders: {
            Authorization: `${this.authService.getToken()}`,
          },
        });
      }
    }

    return next.handle(request).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          const timezoneOffset = evt.headers.get("x-main-user-timezone");
          if (timezoneOffset) {
            localStorage.setItem("timezoneOffset", timezoneOffset);
          }
        }
      }),

      retryWhen((error) =>
        error.pipe(
          concatMap((error, count) => {
            if (count < this.retryCount && error.status === this.retryStatus) {
              return of(error);
            }
            return throwError(error);
          }),
          delay(this.retryWaitMilliSeconds),
          tap((err) => console.error("Retrying..."))
        )
      ),

      catchError((error, caught) => {
        if (error.error?.message !== "You were logged out due to inactivity") {
          const obj = {
            body: request.body,
            error: error?.error,
            message: error.error?.message,
          };
        }
        if (error instanceof HttpErrorResponse) {
          if (
            error.error?.message === "You were logged out due to inactivity" ||
            error?.error?.message === "Authorization not found!"
          ) {
            localStorage.clear();
            this.router.navigate(["/auth/login"]);
          } else if (error.status == 406) {
            this.router.navigate(["/permission-denied"]);
          }
        }
        return throwError(error);
      })
    );
  }
}
