<mat-dialog-content>
  <span class="re-close" mat-dialog-close>
    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
  </span>
  <div class="container">
    <div class="re-errortext">
      <img class="img" src="assets/images/warning-24x.svg" alt="warning" />
      <ng-container *ngIf="!data?.customMessage">
        <p>
          Are you sure you want to delete this {{ data?.subModule
          }}{{ data?.text }}?
        </p>
        <p class="text-danger">This action can not be undone.</p>
      </ng-container>
      <ng-container *ngIf="data?.customMessage">
        <p>
          {{ data?.customMessage }}
        </p>
      </ng-container>
      <ng-container *ngIf="data?.customSubMessage">
        <p>
          {{ data?.customSubMessage }}
        </p>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button (click)="submit()" mat-raised-button color="warn" cdkFocusInitial>
    <ng-container *ngIf="!data?.customButtonTitle">
      Delete {{ data?.subModule }}
    </ng-container>
    <ng-container *ngIf="data?.customButtonTitle">
      {{ data?.customButtonTitle }}
    </ng-container>
  </button>
</mat-dialog-actions>
