export class TwilioErrorMessageConstant {
  public static 30001 = `Twilio queues messages based on the sending rate of a sender or an account`;
  public static 30002 = `Your account was suspended between the time of message send and delivery`;
  public static 30003 = `The destination handset you are trying to reach is switched off or otherwise unavailable`;
  public static 30004 = `The destination number you are trying to reach is blocked from receiving this message`;
  public static 30005 = `The destination number you are trying to reach is unknown and may no longer exist`;
  public static 30006 = `The destination number is unable to receive this message. Potential reasons could include trying to reach a landline or, in the case of short codes, an unreachable carrier`;
  public static 30007 = `Your message content was flagged as going against carrier guidelines`;
  public static 30008 = `Unknown error`;
  public static 30009 = `One or more segments associated with your multi-part inbound message was not received`;
  public static 30010 = `Message price exceeds max price`;
  public static 30011 = `MMS not supported by the receiving phone number in this region`;
  public static 30012 = `Message Time To Live is too small`;
  public static 30013 = `Message Time To Live is too big`;
  public static 30014 = `The To attribute list is invalid or empty`;
  public static 30015 = `The To or From attributes have an unsupported prefix for channel`;
  public static 30016 = `The To or From attributes have channel prefixes what are incompatible`;
  public static 30017 = `Message request was rejected because the carrier has high traffic`;
  public static 30018 = `You sent a message to a mobile number in a country that requires Alphanumeric Sender ID pre-registration`;
  public static 30019 = `Message failed because the size of the content associated with the message exceeded carrier limit`;
  public static 30020 = `An internal error has occurred with Message Scheduling that prevented Twilio from processing your request`;
  public static 30021 = `An internal error has occurred with the messaging service orchestrator that prevented Twilio from processing your response`;
  public static 30022 = `Your messages to this particular carrier has exceeded the maximum allowable limits for your phone number and/or campaign`;
  public static 30023 = `Your messages to this particular carrier has exceeded the maximum allowable messages for the day`;
  public static 30024 = `Provisioning Issue with Carrier You sent a message to a mobile number in a country that requires Numeric Sender ID pre-registration and provisioning`;
  public static 30025 = `As part of the US A2P 10DLC initiative, T-Mobile has instituted a daily message limit`;
  public static 30026 = `US A2P 10DLC - 70% T-Mobile Daily Message Limit Consumed`;
  public static 30027 = `US A2P 10DLC - T-Mobile Daily Message Limit Reached`;
  public static 30028 = `API version used in the request is invalid`;
  public static 30029 = `ContentRetention value used in the request is invalid`;
  public static 30030 = `AddressRetention is not either 'obfuscate' or 'retain' or Message Privacy features are not enabled for this account`;
  public static 30031 = `MaxRate value is not a float or is equal to or less than zero`;
  public static 30032 = `Toll-Free Number Has Not Been Verified`;
  public static 30033 = `Your message could not be sent because you are sending this message from a suspended US A2P 10DLC Campaign.`;
  public static 30034 = `US A2P 10DLC - Message from an Unregistered Number`;
}
