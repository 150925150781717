import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  public token: any;
  router: any;
  constructor() {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    this.token = localStorage.getItem("token");
    if (this.token != undefined && this.token != "") {
      return true;
    } else {
      return false;
    }
  }

  redirectRoute() {
    this.router.navigate([environment.defaultPath]);
  } //Redirect Route ends here
} //Main Class ends here
