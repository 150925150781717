import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { BaseUrl } from "src/app/utils/base-url-constants";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ContactService {
  public baseUrl = environment.baseUrl;

  constructor(
    private _commonFunction: CommonFunctionsService,
    private http: HttpClient
  ) {}

  checkContact = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "checkContact";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  saveContact = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "save";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getContact = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTotalCountList = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "getCount";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  contactInfo = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "getInfo";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editContact = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "edit";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteContact = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "delete";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getInfoCount = (lead: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "getDetailCount";
    return this._commonFunction.globalPostService(endpoint, lead);
  };

  contactLead = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "getLeadContactList";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  contactTransactionLead = (data: any): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.contactUrl + "getTransactionLeadContactList";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  interestedContactListInterested = (data: any): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.contactUrl + "getInterestedContactList";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  saveRelatedContact = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "saveRelated";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getRelatedContact = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "listRelated";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  contactAnalytics = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "analytics";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  analytics = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "analytics";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  contactMakePrimary = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "makePrimaryContact";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  specificContactType = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "specificContact";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getPreviousNextContact = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "previous-next";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateVipContacts = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "update-multiple-vip";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  markAsVip = (moduleId, data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.contactUrl + "markVip";
    if (moduleId) {
      endpoint += `/${moduleId}`;
    }
    return this._commonFunction.globalPutService(endpoint, data);
  };

  updatePrimaryAssignee = (data: any): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.contactUrl + "updatePrimaryAssignee";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  exportContact = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "export";
    return this._commonFunction.globalPostService(endpoint, data, {
      responseType: "text",
    });
  };

  deleteRelatedContact = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "deleteRelated";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  searchContacts = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contactUrl + "searchContacts";
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
