import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "titleCase",
})
export class TitleCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return "";

    // Split the input value into words
    const words = value.split(" ");

    // Convert each word to title case
    const titleCaseWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the title case words back into a sentence
    return titleCaseWords.join(" ");
  }
}
