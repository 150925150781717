import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { saveAs } from "file-saver";

// UTILS
import { StreamState } from "src/app/utils/models/stream-state";

// SERVICES
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { MessageConstant } from "src/app/utils/message-constant";

@Component({
  selector: "app-audio-player",
  templateUrl: "./audio-player.component.html",
  styleUrls: ["./audio-player.component.scss"],
})
export class AudioPlayerComponent implements OnInit, OnDestroy {
  @Input() url: any;
  @Input() customCss: string;
  @Input() moduleName: string

  playbackRate: any[] = [1, 1.5, 2];
  state: StreamState = {
    playing: false,
    readableCurrentTime: "",
    readableDuration: "",
    duration: 0,
    currentTime: 0,
    volume: 0.5,
    canplay: false,
    playbackRate: 1,
    error: false,
  };
  stop$ = new Subject();
  audioObj = new Audio();
  audioEvents = [
    "ended",
    "error",
    "play",
    "playing",
    "pause",
    "timeupdate",
    "canplay",
    "loadedmetadata",
    "loadstart",
  ];
  stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(this.state);

  constructor(
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.initialize(this.url);
  }

  initialize(url) {
    const _player = new Audio(url);
    const that = this;
    _player.addEventListener(
      "durationchange",
      function (e) {
        if (this.duration != Infinity) {
          const duration = this.duration;
          _player.remove();
          _player.currentTime = 24 * 60 * 60; //fake big time
          _player.volume = 0;
          _player.play();
          _player.pause();

          that.state.duration = duration;
          that.state.readableDuration = that.formatTime(that.state.duration);
        }
      },
      false
    );
    _player.load();
  }

  onSliderChangeEnd(change) {
    this.seekTo(change.target.value);
  }

  playStream(url) {
    return this.streamObservable(url).pipe(takeUntil(this.stop$));
  }

  private streamObservable(url) {
    return new Observable((observer) => {
      // Play audio
      this.audioObj.src = url;
      this.audioObj.load();
      this.audioObj.play();

      const handler = (event: Event) => {
        this.updateStateEvents(event);
        observer.next(event);
      };

      this.addEvents(this.audioObj, this.audioEvents, handler);
      return () => {
        // Stop Playing
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        // remove event listeners
        this.removeEvents(this.audioObj, this.audioEvents, handler);
        // reset state
        this.resetState();
      };
    });
  }

  private addEvents(obj, events, handler) {
    events.forEach((event) => {
      obj?.addEventListener(event, handler);
    });
  }

  private removeEvents(obj, events, handler) {
    events.forEach((event) => {
      obj?.removeEventListener(event, handler);
    });
  }

  private updateStateEvents(event: Event): void {
    switch (event.type) {
      case "canplay":
        this.state.duration = this.audioObj.duration;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.canplay = true;
        break;
      case "playing":
        this.state.playing = true;
        break;
      case "pause":
        this.state.playing = false;
        break;
      case "timeupdate":
        this.state.currentTime = this.audioObj.currentTime;
        this.state.readableCurrentTime = this.formatTime(
          this.state.currentTime
        );
        break;
      case "error":
        this.resetState();
        this.state.error = true;
        break;
    }
    this.stateChange.next(this.state);
  }

  private resetState() {
    this.state = {
      playing: false,
      readableCurrentTime: "",
      readableDuration: "",
      duration: undefined,
      currentTime: undefined,
      volume: 0.5,
      canplay: false,
      playbackRate: 1,
      error: false,
    };
  }

  play() {
    if (this.audioObj.paused && this.audioObj.readyState !== 0) {
      this.audioObj.play();
      return;
    }

    this.stop();
    this.playStream(this.url).subscribe((events) => {
      // listening for fun here
    });
  }

  pause() {
    this.audioObj.pause();
  }

  stop() {
    this.stop$.next(null);
  }

  seekTo(seconds) {
    this.audioObj.currentTime = seconds;
  }

  setVolume(volume) {
    this.audioObj.volume = volume;
  }

  formatTime(time: number, format: string = "HH:mm:ss") {
    const momentTime = time * 1000;
    return moment.utc(momentTime).format(format);
  }

  changePlayBackRate() {
    const index = this.playbackRate.indexOf(this.state.playbackRate);

    if (index === this.playbackRate.length - 1) {
      this.state.playbackRate = this.playbackRate[0];
    } else {
      this.state.playbackRate = this.playbackRate[index + 1];
    }

    this.audioObj.playbackRate = this.state.playbackRate;
  }

  downloadAudio() {
    this._loaderService.start();
    
    const fileExt = this.url.split('.').pop();
    saveAs(this.url, "audio."+fileExt);
    
    this._loaderService.stop();

    // let filename = this.url?.split("/")?.pop();
    // let fileURL =
    //   "https://media.plivo.com/v1/Account/MAYZI3YJE2NTM2N2EXMT/Recording/" +
    //   filename;

    // fetch(fileURL, {
    //   method: "GET",
    // })
    //   .then((res) => {
    //     if (res?.status == 200) {
    //       return res.blob();
    //     } else {
    //       saveAs(this.url, "audio.mp3");
    //     }
    //   })
    //   .then((blob) => {
    //     var url = window.URL.createObjectURL(blob);
    //     var a = document.createElement("a");
    //     a.href = url;
    //     a.download = "audio.mp3";
    //     document.body.appendChild(a);
    //     this._loaderService.stop();
    //     a.click();
    
    //       window.URL.revokeObjectURL(url);
    //     }, 1000);
    //     a.remove();
    
    //   .catch((err) => {
    //     this._loaderService.stop();
    //     // this._toastrService.error(MessageConstant.unknownError);
    //   });
  }

  ngOnDestroy(): void {
    this.audioObj.pause();
  }
}
