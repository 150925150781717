import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { BaseUrl } from "../../utils/base-url-constants";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TaggingService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem("token");

  constructor(private http: HttpClient) {}

  getTags = (type): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.globalTagsUrl + "list";
    if (type) {
      endpoint += `?tagType=${type}`;
    }
    return this.http.get(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getUsedTagsColor = (type): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.globalTagsUrl + "getUsedColorIndex";
    if (type) {
      endpoint += `?tagType=${type}`;
    }
    return this.http.get(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addTags = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.globalTagsUrl + "save";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  renameTags = (id, data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.globalTagsUrl + "update/" + id;
    return this.http.put(endpoint, { ...data }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteGlobalTag = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.globalTagsUrl + "delete/" + data.id;
    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  //listStacking tags
  renameTag = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.globalTagsUrl + "renameTag";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  renameList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.globalTagsUrl + "renameList";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };
}
