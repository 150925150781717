<div class="re-agent-quick-filter" *ngIf="quickFilterView">
  <div class="re-agent-left">
    <span class="re-filter-title">
      <img src="assets/images/filter-icon-1.svg" alt="filter" />
      Quick Filters
    </span>
    <div class="re-filter-list" *ngIf="quickFilterList.length > 0">
      <a
        *ngFor="let filter of mainQuickFilterList; let i = index"
        class="re-links"
        [class.re-active]="i === activeFilterIndex"
        (click)="setActiveFilter(i, true)"
      >
        {{ filter?.title }}
      </a>
    </div>
    <div class="re-filter-list" *ngIf="quickFilterList.length == 0">
      <a class="re-links"> No Quick Filter </a>
    </div>
    <!-- <a class="re-filter-more">More <i class="fa fa-angle-down"></i></a> -->
    <select
      class="re-filter-more"
      (change)="setActiveFilter($event?.target['value'])"
      [(ngModel)]="subFilter"
    >
      <option value="clear">More</option>
      <option
        *ngFor="let item of subQuickFilterList; let i = index"
        [value]="5 + i + 1"
      >
        {{ item?.title }}
      </option>
    </select>
  </div>
  <div class="re-agent-right">
    <a class="re-clear" (click)="clearList()">Clear</a> |
    <a class="re-managelist" (click)="manageList()">Manage List</a>
  </div>
</div>

<div
  class="re-module-table"
  [class.infinite-scroll]="
    paginationType === miscellaneousConstant.paginationType.INFINITE
  "
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  (scrolled)="onScroll()"
  [ngStyle]="style"
  style="max-height: calc(100vh - 180px)"
>
  <form
    class="re-inline-form"
    [formGroup]="inlineAddForm"
    (ngSubmit)="onSubmit()"
  >
    <table class="table table-hover re-res-table" [ngClass]="listClass">
      <!-- TABLE COLGROUP -->
      <colgroup *ngIf="header?.length">
        <ng-container *ngFor="let item of header">
          <col width="{{ item?.colsWidth }}" />
        </ng-container>
      </colgroup>
      <!-- TABLE HEAD -->
      <thead>
        <!-- HEADER -->
        <tr *ngIf="header?.length">
          <th
            *ngFor="let item of header; let itemIndex = index"
            [style]="item?.headerStyle"
          >
            <span
              *ngIf="item?.isMultiSelect"
              [class.selectAllCheckBoxButton]="item?.isMultiSelect"
            >
              <label class="re-checkbox-btn">
                <input
                  type="checkbox"
                  [name]="moduleName"
                  [value]="item?._id"
                  (click)="selectAll($event)"
                  style="vertical-align: middle"
                  [checked]="isChecked()"
                  [disabled]="selectActionType"
                />
                <span class="re-checkmark"></span>
              </label>
            </span>
            <span
              [class.re-agent-column-title]="item?.isMultiSelect"
              [class.re-agent-column-center-title]="item?.isCenterTitle"
              (click)="item?.sort ? onChangeSort(item) : null"
            >
              {{ item?.label }}
              <span
                [class]="sortVal == item?.key ? sortClass : 're-agent-sort'"
                *ngIf="item?.sort"
              >
              </span>
            </span>
            <span
              [pTooltip]="item?.tooltip"
              [escape]="false"
              tooltipPosition="bottom"
              *ngIf="item?.isHeaderTooltipExists || item?.isHeaderTooltipIcon"
              (mouseenter)="
                activeIconIndex = itemIndex; activeIcon = item?.iconImage
              "
              (mouseleave)="activeIconIndex = -1; activeIcon = ''"
            >
              <i
                class="fa fa-question-circle"
                *ngIf="item?.isHeaderTooltipExists"
              ></i>
              <img
                *ngIf="item?.isHeaderTooltipIcon"
                [src]="getTooltipIcon(item, itemIndex)"
                class="re-agent-padd"
                [alt]="item?.iconImage"
              />
            </span>
          </th>
        </tr>
      </thead>

      <!-- TABLE BODY -->
      <tbody *ngIf="data">
        <!-- ROWS -->
        <ng-container *ngIf="checkLoader()">
          <ng-container
            *ngFor="let item of data['items']; let itemIndex = index"
          >
            <tr>
              <ng-container *ngFor="let head of header; let headIndex = index">
                <td
                  *ngIf="head?.key !== 'action'"
                  [class.re-pointer]="!head?.isClickExists"
                  [ngStyle]="head?.style"
                  class="re-table-td"
                >
                  <!-- MULTI SELECT -->
                  <span [class.pull-left]="head?.isCheckBoxLeft">
                    <ng-container
                      *ngIf="isMultiSelectEnabled && headIndex === 0"
                    >
                      <label
                        class="re-checkbox-btn"
                        [ngStyle]="head?.styleCheckbox"
                      >
                        <input
                          type="checkbox"
                          [name]="moduleName"
                          [value]="item?._id"
                          (click)="selectItem(item, $event)"
                          style="vertical-align: middle"
                          [checked]="item?.isCompleted"
                          [disabled]="selectActionType"
                        />
                        <span class="re-checkmark"></span>
                      </label>
                    </ng-container>
                  </span>

                  <!----------------- Manage Lead Record ---------------------->
                  <ng-container *ngIf="head?.manageLeadRecord">
                    <button
                      class="re-agent-edit-btn"
                      *ngIf="item?.isInstantLeadConnect"
                      (click)="checkInstantLead(item, true, itemIndex)"
                    >
                      Re-Configure
                    </button>
                    <button
                      class="re-agent-edit-btn"
                      *ngIf="!item?.isInstantLeadConnect"
                      (click)="checkInstantLead(item, false, itemIndex)"
                    >
                      Configure
                    </button>
                  </ng-container>

                  <!-- DATA -->
                  <div
                    [class.re-agent-manageUserInfo]="head?.metaData?.image"
                    [class.re-user-info]="head?.manageUserInfo"
                    (click)="
                      head?.isClickExists
                        ? callAction(head, item, itemIndex, moduleId)
                        : ''
                    "
                  >
                    <!-- ICON -->
                    <img
                      *ngIf="head?.isIconExists"
                      class="re-user-img"
                      [src]="getImageIcon(item, head)"
                      [ngStyle]="head?.iconStyle?.style"
                      onerror="this.src='/assets/images/demo-profile.png'"
                    />

                    <!-- META DATA IMAGE -->
                    <span
                      *ngIf="
                        getValue(item, head?.metaData?.image) != 'N/A' &&
                        head?.metaData?.image
                      "
                    >
                      <div class="re-imgdiv">
                        <img
                          *ngIf="head?.metaData?.image"
                          class="re-user-image"
                          [ngStyle]="head?.metaData?.image?.style"
                          [src]="getValue(item, head?.metaData?.image)"
                        />
                        <div
                          *ngIf="head?.metaData?.image?.isDotsExists"
                          class="re-dots"
                        ></div>
                      </div>
                    </span>
                    <!-- INITIALS -->
                    <span
                      *ngIf="getValue(item, head?.metaData?.image) == 'N/A'"
                      class="re-agent-initials"
                    >
                      {{ utilities.getInitials(getValue(item, head)) }}
                    </span>

                    <ng-container *ngIf="head?.isDynamicTooltip">
                      <div class="re-tooltip">
                        <!-- <span
                          class="re-file-title"
                          [innerHTML]="getValueTooltip(item, head)"
                          tooltipPosition="top"
                        >
                          {{ getValue(item, head) }}
                        </span> -->

                        <!-- dynamic tooltip for contingencies -->
                        <!-- <span
                          *ngIf="item['contingencies'].length >= 0"
                          [pTooltip]="
                            item['contingencies'].length > 0
                              ? tooltipContent
                              : nullTooltipContent
                          "
                          class="re-file-title"
                          tooltipPosition="bottom"
                        >
                          {{ getValue(item, head) }}
                        </span> -->

                        <!-- <span
                          *ngIf="item['contingencies'].length > 0"
                          class="re-file-title"
                          tooltipPosition="bottom"
                          [pTooltip]="tooltipContent"
                        >
                          {{ getValue(item, head) }}
                        </span> -->

                        <span
                          *ngIf="item['contingencies'].length >= 0"
                          [pTooltip]="getTooltipContent(item)"
                          class="re-file-title"
                          tooltipPosition="bottom"
                        >
                          {{ getValue(item, head) }}
                        </span>

                        <!-- <ng-template #tooltipContent>
                          <span
                            class="re-tooltiptext"
                            *ngIf="
                              item['contingencies'] &&
                              item['contingencies'].length > 0
                            "
                          >
                            <ul *ngFor="let cont of item['contingencies']">
                              <li class="re-capitalize re-tooltip-text">
                                {{ cont }}
                              </li>
                            </ul>
                          </span>
                        </ng-template> -->
                      </div>
                    </ng-container>

                    <!-- VALUE WITHOUT PIPES -->
                    <ng-container *ngIf="!head?.pipe && !head?.file">
                      <div [class.re-agent-inline]="!head?.manageLeadRecord">
                        <span
                          [class]="
                            head?.isContactTypeExists ||
                            head?.contactNumberOption
                              ? 're-agent-contact-type'
                              : 're-agent-file-title'
                          "
                          [ngClass]="
                            getValue(item, head) != 'General Business'
                              ? head.class
                              : ''
                          "
                        >
                          <span
                            class="re-name"
                            [ngClass]="head?.customClass"
                            [pTooltip]="
                              head?.isTooltipExists &&
                              getValue(item, head, head?.isTooltipExists)
                            "
                            tooltipPosition="bottom"
                            [tooltipStyleClass]="
                              moduleName === 'Email Templates' &&
                              head?.key === 'subject'
                                ? 're-email-template'
                                : ''
                            "
                          >
                            <ng-container
                              *ngIf="head?.key && !head?.manageLeadRecord"
                            >
                              <ng-container *ngIf="!head?.isNoViewKey">
                                <ng-container *ngIf="head?.isNoTitleCase">
                                  {{
                                    head?.key == "taskTypeOption"
                                      ? customFunction(item, head, "value")
                                      : getValue(item, head) !== "" &&
                                        getValue(item, head) !== null &&
                                        getValue(item, head) !== " " &&
                                        getValue(item, head) !== "null"
                                      ? getValue(item, head)
                                      : "N/A"
                                  }}
                                </ng-container>
                                <ng-container
                                  *ngIf="
                                    !head?.isNoTitleCase &&
                                    !head?.isDynamicTooltip &&
                                    !head?.isCommunicationList &&
                                    !head?.isLastTouchList
                                  "
                                >
                                  <ng-container
                                    *ngIf="head?.key; else elseTitleCase"
                                  >
                                    {{
                                      getValue(item, head) !== "" &&
                                      getValue(item, head) !== null &&
                                      getValue(item, head) !== " " &&
                                      getValue(item, head) !== "null"
                                        ? getValue(item, head)
                                        : "N/A"
                                    }}
                                  </ng-container>
                                  <ng-template #elseTitleCase>
                                    {{
                                      utilities.titleCaseToWord(
                                        head?.key == "taskTypeOption"
                                          ? customFunction(item, head, "value")
                                          : getValue(item, head) !== "" &&
                                            getValue(item, head) !== null &&
                                            getValue(item, head) !== " " &&
                                            getValue(item, head) !== "null"
                                          ? getValue(item, head)
                                          : "N/A"
                                      )
                                    }}
                                  </ng-template>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngIf="head?.isSpamCheck">
                                <span
                                  class="re-agent-green-label"
                                  *ngIf="
                                    item?.carrierAttFlagged == false &&
                                    item?.carrierSprintFlagged == false &&
                                    item?.carrierTmobileFlagged == false &&
                                    item?.carrierVerizonFlagged == false
                                  "
                                >
                                  Good
                                </span>
                                <span
                                  *ngIf="
                                    checkSpam(item) != 0 &&
                                    (item?.carrierAttFlagged ||
                                      item?.carrierSprintFlagged ||
                                      item?.carrierTmobileFlagged ||
                                      item?.carrierVerizonFlagged)
                                  "
                                  class="re-agent-red-label"
                                  [pTooltip]="checkSpamMsg(item)"
                                  tooltipPosition="bottom"
                                  [escape]="false"
                                >
                                  Spam {{ checkSpam(item) }}
                                </span>
                                <span
                                  *ngIf="
                                    checkSpam(item) == 0 &&
                                    (item?.carrierAttFlagged != false ||
                                      item?.carrierSprintFlagged != false ||
                                      item?.carrierTmobileFlagged != false ||
                                      item?.carrierVerizonFlagged != false)
                                  "
                                  class="re-agent-red-label"
                                  pTooltip="This number hasn’t been checked for Spam yet. Please check back in few days."
                                  tooltipPosition="bottom"
                                >
                                  N/A
                                </span>
                              </ng-container>
                              <ng-container *ngIf="head?.isCommunicationList">
                                <div class="re-agent-calls-infos">
                                  <div class="re-agent-infos">
                                    <img
                                      src="assets/images/calls_iconss.svg"
                                      alt="calls_iconss"
                                    />
                                    x{{
                                      item?.outgoingCallCount
                                        ? item?.outgoingCallCount
                                        : 0
                                    }}
                                  </div>
                                  <div class="re-agent-infos">
                                    <img
                                      src="assets/images/sms_iconss.svg"
                                      alt="sms_iconss"
                                    />x{{
                                      item?.outgoingSmsCount
                                        ? item?.outgoingSmsCount
                                        : 0
                                    }}
                                  </div>
                                  <div class="re-agent-infos">
                                    <img
                                      src="assets/images/rvm_iconss.svg"
                                      alt="rvm_iconss"
                                    />x{{
                                      item?.outgoingRvmCount
                                        ? item?.outgoingRvmCount
                                        : 0
                                    }}
                                  </div>
                                  <div class="re-agent-infos">
                                    <img
                                      src="assets/images/email_iconss.svg"
                                      alt="email_iconss"
                                    />x{{
                                      item?.outgoingEmailCount
                                        ? item?.outgoingEmailCount
                                        : 0
                                    }}
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="head?.isLastTouchList">
                                <div
                                  class="re-taskss"
                                  *ngIf="
                                    item?.lastActivityStatus != 7 &&
                                    item?.lastOutgoingDate
                                  "
                                >
                                  <img
                                    [src]="
                                      item?.lastActivityStatus == 1 ||
                                      item?.lastActivityStatus == 2
                                        ? 'assets/images/calls_iconss-1.svg'
                                        : item?.lastActivityStatus == 3 ||
                                          item?.lastActivityStatus == 4
                                        ? 'assets/images/sms_iconss-1.svg'
                                        : item?.lastActivityStatus == 5
                                        ? 'assets/images/email_iconss-1.svg'
                                        : item?.lastActivityStatus == 6
                                        ? 'assets/images/rvm_iconss-1.svg'
                                        : 'assets/images/calls_iconss-1.svg'
                                    "
                                    alt="calls"
                                  />
                                  &nbsp;
                                  {{
                                    item?.lastOutgoingDate | date : "MMM d, y"
                                  }}
                                </div>
                                <div
                                  class="re-taskss"
                                  *ngIf="
                                    item?.lastActivityStatus == 7 ||
                                    !item?.lastOutgoingDate
                                  "
                                >
                                  No Activity
                                </div>
                              </ng-container>
                              <!------------- Outbound Call Record---------------->
                              <ng-container *ngIf="head?.manageCallRecord">
                                <div class="re-agent-notification-box">
                                  <div class="re-agent-radiosec">
                                    <label class="re-agent-switch">
                                      <input
                                        type="checkbox"
                                        id="checkbox1"
                                        name="activeTimeChange"
                                        [checked]="item?.isOutboundCallRecord"
                                        (change)="
                                          checkCallRecord(
                                            item?._id,
                                            item?.isOutboundCallRecord,
                                            itemIndex
                                          )
                                        "
                                      />
                                      <span class="re-agent-slider"></span>
                                      <span value="true" class="re-agent-text1">
                                        No
                                      </span>
                                      <span value="true" class="re-agent-text2">
                                        Yes
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </span>
                          <span
                            *ngIf="head?.isLeadTypeExists"
                            class="{{
                              item?.contactType == 2 || item?.contactType == 4
                                ? 're-seller-tag'
                                : 're-buyer-tag'
                            }} "
                            >{{
                              item?.contactType == 2 || item?.contactType == 4
                                ? "S"
                                : "B"
                            }}</span
                          >
                          <span
                            *ngIf="head?.isLeadTypeTitleExists"
                            class="{{
                              item?.contactType == 2 || item?.contactType == 4
                                ? 're-seller-tag'
                                : 're-buyer-tag'
                            }} "
                            >{{
                              item?.contactType == 2 || item?.contactType == 4
                                ? "Seller"
                                : "Buyer"
                            }}</span
                          >
                          <span
                            *ngIf="head?.isContactTypeExists"
                            [innerHtml]="contactTypeClass(item?.contactType)"
                          >
                            <!-- <span *ngFor="let contactType of item?.contactType">
                              <span
                                class="{{
                                  contactType == 1
                                    ? 're-buyer-tag'
                                    : contactType == 2
                                    ? 're-seller-tag'
                                    : contactType == 3
                                    ? 're-vendor-tag'
                                    : contactType == 4
                                    ? 're-agent-tag'
                                    : contactType == 5
                                    ? 're-ff-tag'
                                    : ''
                                }}"
                              >
                                {{
                                  contactType == 1
                                    ? "B"
                                    : contactType == 2
                                    ? "S"
                                    : contactType == 3
                                    ? "V"
                                    : contactType == 4
                                    ? "A"
                                    : contactType == 5
                                    ? "F-F"
                                    : "None"
                                }}</span
                              >
                            </span> -->
                          </span>
                          <span *ngIf="head?.isPipeIconExists"> | </span>
                          <span
                            class="re-vactime-icon"
                            [ngStyle]="head?.IconStyle"
                            *ngIf="head?.isImageIconExists"
                          >
                            <img
                              src="../../../assets/images/{{
                                getApptType(item, 'img')
                              }}"
                              alt=""
                            />
                          </span>
                          <span *ngIf="head?.isIconType">
                            {{ getApptType(item, "name") }}
                          </span>

                          <!-- <span
                            class="re-vactime"
                            *ngIf="head?.isVacationExists"
                          >
                            On Vacation: Nov 28 - Dec 5, 2021
                          </span> -->
                          <span
                            class="re-appoint-time"
                            *ngIf="head?.isTextView && todayTimeStamp(item)"
                          >
                            {{ head?.isTextView }}
                          </span>

                          <!-- INNER VALUE -->
                          <p [ngStyle]="head?.innerValueStyle">
                            <ng-container *ngIf="head?.innerValue">
                              <ng-container
                                *ngFor="
                                  let value of head?.innerValue;
                                  let innerIndex = index
                                "
                              >
                                <span *ngIf="value" [ngStyle]="value?.style">
                                  <ng-container [ngSwitch]="value?.pipe">
                                    <!-- DATE -->
                                    <ng-container *ngSwitchCase="'DATE'">
                                      {{
                                        getValue(item, value)
                                          | date : "MMM dd, yyyy"
                                      }}
                                    </ng-container>

                                    <!-- DATE TIME -->
                                    <ng-container *ngSwitchCase="'DATE_TIME'">
                                      <ng-container
                                        *ngIf="
                                          getValue(item, value) &&
                                            getValue(item, value) !== 'N/A';
                                          else else_date
                                        "
                                      >
                                        {{
                                          getValue(item, value)
                                            | date : "MMM dd, yyyy hh:mm a"
                                        }}
                                      </ng-container>

                                      <ng-template #else_date> - </ng-template>
                                    </ng-container>

                                    <!-- TIME -->
                                    <ng-container *ngSwitchCase="'TIME'">
                                      {{
                                        getValue(item, value)
                                          | date : "shortTime"
                                      }}
                                    </ng-container>

                                    <!-- PHONE -->
                                    <ng-container *ngSwitchCase="'PHONE'">
                                      <span *ngIf="!head?.contactNumberOption">
                                        {{
                                          utilities.maskNumber(
                                            getValue(item, value)
                                          )
                                        }}
                                      </span>
                                      <span *ngIf="head?.contactNumberOption">
                                        <a
                                          class="re-pointer re-open-account-dropdown"
                                          *ngIf="
                                            getValue(item, value) != 'N/A' &&
                                            getValue(item, value) != '' &&
                                            getValue(item, value) != ' '
                                          "
                                        >
                                          <div class="dropdown mr-0">
                                            <button
                                              class="btn dropdown-toggle re-add-list-btn"
                                              type="button"
                                              id="dropdownMenuButton"
                                              data-toggle="dropdown"
                                              aria-expanded="true"
                                              (click)="onToggle()"
                                            >
                                              {{
                                                utilities.maskNumber(
                                                  getValue(item, value)
                                                )
                                              }}
                                            </button>
                                            <ul
                                              class="dropdown-menu"
                                              aria-labelledby="dropdownMenuButton"
                                            >
                                              <li>
                                                <a
                                                  (click)="
                                                    getBuyerNumbers('sms', item)
                                                  "
                                                >
                                                  Send SMS
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  (click)="
                                                    getBuyerNumbers(
                                                      'call',
                                                      item
                                                    )
                                                  "
                                                >
                                                  Call
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </a>
                                        <ng-container
                                          *ngIf="
                                            getValue(item, value) == 'N/A' ||
                                            getValue(item, value) == '' ||
                                            getValue(item, value) == ' '
                                          "
                                        >
                                          N/A
                                        </ng-container>
                                      </span>
                                    </ng-container>

                                    <!-- CAMEL CASE -->
                                    <ng-container *ngSwitchCase="'CAMEL_CASE'">
                                      {{
                                        moduleName != "Call Logs"
                                          ? utilities.camelCaseToWord(
                                              getValue(item, value),
                                              value?.style
                                            )
                                          : item?.type === "incomingCall" &&
                                            head?.key == "source" &&
                                            (item?.status == "no-answer" ||
                                              item?.status == "cancel" ||
                                              item?.status == "no-confirm" ||
                                              item?.status == "busy")
                                          ? "Missed Call"
                                          : item?.type == "incomingCall" &&
                                            head?.key == "source" &&
                                            item?.communicationType == 3
                                          ? "Voice Mail"
                                          : item?.type == "incomingCall" &&
                                            item?.communicationType == 4
                                          ? "Incoming RVM"
                                          : utilities.camelCaseToWord(
                                              getValue(item, value),
                                              value?.style
                                            )
                                      }}
                                      <img
                                        *ngIf="
                                          head?.isStatusIconExists &&
                                          moduleName == 'Call Logs' &&
                                          (item?.type == 'outgoingSms' ||
                                            item?.type == 'outgoingMms')
                                        "
                                        [pTooltip]="smsTooltipMsg(item)"
                                        tooltipPosition="bottom"
                                        [escape]="false"
                                        class="re-agent-sms-status"
                                        [src]="
                                          'assets/images/' + getStatusIcon(item)
                                        "
                                        alt=""
                                      />
                                      <img
                                        *ngIf="
                                          head?.isStatusIconExists &&
                                          moduleName == 'Call Logs' &&
                                          item?.type == 'incomingCall' &&
                                          item?.isNewCall == true
                                        "
                                        pTooltip="New Caller"
                                        tooltipPosition="bottom"
                                        class="re-agent-sms-status"
                                        src="assets/images/new.svg"
                                        alt=""
                                      />
                                      <img
                                        *ngIf="
                                          head?.isStatusIconExists &&
                                          moduleName == 'Call Logs' &&
                                          item?.type == 'incomingCall' &&
                                          item?.isNewCall == false
                                        "
                                        pTooltip="Repeat Caller"
                                        tooltipPosition="bottom"
                                        class="re-agent-sms-status"
                                        src="assets/images/Repeat-Caller.svg"
                                        alt=""
                                      />
                                    </ng-container>

                                    <!-- TITLE CASE -->
                                    <ng-container *ngSwitchCase="'TITLE_CASE'">
                                      {{
                                        utilities.titleCaseToWord(
                                          getValue(item, value)
                                        )
                                      }}
                                    </ng-container>

                                    <ng-container
                                      *ngSwitchCase="'CALL_NUMBER_TYPE'"
                                    >
                                      {{
                                        callNumberType(
                                          item,
                                          getValue(item, value)
                                        )
                                      }}
                                    </ng-container>

                                    <!-- CUSTOM FUNCTION -->
                                    <ng-container
                                      *ngSwitchCase="'CUSTOM_FUNCTION'"
                                    >
                                      <div
                                        class="{{
                                          value.isCustomClass
                                            ? customFunction(
                                                item,
                                                value,
                                                'class'
                                              )
                                            : ''
                                        }}"
                                        [innerHTML]="
                                          customFunction(item, value, 'value')
                                        "
                                      ></div>
                                    </ng-container>

                                    <!-- CUSTOM FUNCTION SPAN -->
                                    <ng-container
                                      *ngSwitchCase="'CUSTOM_FUNCTION_SPAN'"
                                    >
                                      <span
                                        class="{{
                                          value.isCustomClass
                                            ? customFunction(
                                                item,
                                                value,
                                                'class'
                                              )
                                            : ''
                                        }}"
                                        [innerHTML]="
                                          customFunction(item, value, 'value')
                                        "
                                      ></span>
                                    </ng-container>

                                    <!-- DEFAULT -->
                                    <ng-container *ngSwitchDefault>
                                      {{
                                        moduleName != "Call Logs"
                                          ? getValue(item, value)
                                          : (item?.status == "no-answer" ||
                                              item?.status == "cancel") &&
                                            item?.type == "outgoingCall"
                                          ? "Not Answered"
                                          : ""
                                      }}
                                    </ng-container>
                                  </ng-container>
                                </span>
                              </ng-container>
                            </ng-container>

                            <!-- INNER ICON -->
                            <ng-container
                              *ngFor="
                                let icon of head?.innerIcon;
                                let innerIndex = index
                              "
                            >
                              <img
                                style="
                                  padding: 2px 6px 0px 0px;
                                  position: absolute;
                                  margin-left: 0px;
                                "
                                [ngStyle]="icon?.style"
                                *ngIf="
                                  item.reminders.length > 0 &&
                                  icon.type == 'reminder'
                                "
                                [src]="getIcon(icon, head)"
                                alt=""
                              />

                              <img
                                style="
                                  padding: 2px 6px 0px 0px;
                                  position: absolute;
                                  margin-left: 20px;
                                "
                                [ngStyle]="icon?.style"
                                [pTooltip]="
                                  icon?.isTooltipExists && getValue(item, icon)
                                "
                                tooltipPosition="bottom"
                                *ngIf="
                                  item.taskDetail && icon.type == 'description'
                                "
                                [src]="getIcon(icon, head)"
                                alt=""
                              />
                            </ng-container>

                            <ng-container
                              *ngFor="
                                let data of head?.innerData;
                                let innerIndex = index
                              "
                            >
                              <ng-container [ngSwitch]="data.pipe">
                                <ng-container *ngSwitchCase="'CUSTOM_FUNCTION'">
                                  <div
                                    class="{{
                                      data.isCustomClass
                                        ? customFunction(item, data, 'class')
                                        : ''
                                    }}"
                                    [innerHTML]="
                                      customFunction(item, data, 'value')
                                    "
                                  ></div>
                                </ng-container>

                                <ng-container *ngSwitchDefault>
                                  <span
                                    *ngIf="!data.static"
                                    [ngStyle]="data?.style"
                                    [pTooltip]="
                                      data?.isTooltipExists &&
                                      getValue(item, data)
                                    "
                                  >
                                    {{
                                      getValue(item, data) == "general"
                                        ? ""
                                        : utilities.titleCaseToWord(
                                            getValue(item, data)
                                          )
                                    }}
                                  </span>

                                  <span
                                    class="re-agent-sub-category"
                                    *ngIf="
                                      data.static &&
                                      !data.leadStatic &&
                                      data.key != 'leadCount'
                                    "
                                  >
                                    • {{ data.static }}:
                                    {{ getValue(item, data) }}
                                  </span>

                                  <span
                                    class="re-agent-sub-category"
                                    *ngIf="
                                      data.static &&
                                      data.static == 'Lead' &&
                                      data.key == 'leadCount'
                                    "
                                  >
                                    {{ getValue(item, data) }}
                                    {{
                                      getValue(item, data) != "N/A"
                                        ? data.static
                                        : ""
                                    }}
                                  </span>

                                  <span
                                    class="re-agent-sub-category"
                                    *ngIf="data.static && data.leadStatic"
                                  >
                                    {{ getValue(item, data) }}
                                    {{
                                      getValue(item, data) != "N/A"
                                        ? data.static
                                        : ""
                                    }}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </ng-container>

                            <span
                              style="
                                height: 10px;
                                width: 10px;
                                background-color: #0a4e81;
                                border-radius: 50%;
                                display: inline-block;
                              "
                              *ngIf="head?.checkKey && checkKey(head, item)"
                            >
                              &nbsp;
                            </span>
                          </p>
                        </span>
                      </div>
                    </ng-container>

                    <!-- VALUE WITH PIPES -->
                    <ng-container *ngIf="head?.pipe">
                      <ng-container [ngSwitch]="head?.pipe">
                        <!-- NUMBER -->
                        <ng-container *ngSwitchCase="'NUMBER'">
                          {{ getCurrency(item, head) | number : "1.2-2" }}
                        </ng-container>

                        <ng-container *ngSwitchCase="'NUMBER_VALUE'">
                          {{ getCurrency(item, head) | number : "1.0-0" }}
                        </ng-container>

                        <!-- DATE -->
                        <ng-container *ngSwitchCase="'DATE'">
                          {{
                            getValue(item, head) == "N/A"
                              ? "N/A"
                              : (getValue(item, head) | date : "MMM dd, yyyy")
                          }}
                        </ng-container>

                        <!-- DATE TIME -->
                        <ng-container *ngSwitchCase="'DATE_TIME'">
                          <ng-container
                            *ngIf="
                              getValue(item, head) &&
                                getValue(item, head) !== 'N/A';
                              else else_date_pipe
                            "
                          >
                            {{
                              getValue(item, head)
                                | date : "MMM dd, yyyy hh:mm a"
                            }}
                          </ng-container>
                          <ng-template #else_date_pipe> - </ng-template>
                        </ng-container>

                        <!-- DATE TIME TIMEZONE -->
                        <ng-container *ngSwitchCase="'DATE_TIME_TIMEZONE'">
                          <ng-container
                            *ngIf="
                              getValue(item, head) &&
                                getValue(item, head) !== 'N/A';
                              else else_date_pipe_time
                            "
                          >
                            {{ getValue(item, head) | myTimezone }}
                          </ng-container>
                          <ng-template #else_date_pipe_time> - </ng-template>
                        </ng-container>

                        <!-- DATE TIMEZONE -->
                        <ng-container *ngSwitchCase="'DATE_TIMEZONE'">
                          <ng-container
                            *ngIf="
                              getValue(item, head) &&
                                getValue(item, head) !== 'N/A';
                              else else_date_pipe_timezone
                            "
                          >
                            {{
                              getValue(item, head) | myTimezone : "MMM DD, yyyy"
                            }}
                          </ng-container>
                          <ng-template #else_date_pipe_timezone>
                            -
                          </ng-template>
                        </ng-container>

                        <!-- TIME -->
                        <ng-container *ngSwitchCase="'TIME'">
                          {{ getValue(item, head) | date : "shortTime" }}
                        </ng-container>

                        <!-- PHONE -->
                        <ng-container *ngSwitchCase="'PHONE'">
                          {{ utilities.maskNumber(getValue(item, head)) }}
                        </ng-container>

                        <!-- EMAIL -->
                        <ng-container *ngSwitchCase="'EMAIL'">
                          {{ getValue(item, head) }}
                        </ng-container>

                        <!-- CAMEL CASE -->
                        <ng-container *ngSwitchCase="'CAMEL_CASE'">
                          {{ utilities.camelCaseToWord(getValue(item, head)) }}
                        </ng-container>

                        <!-- TITLE CASE -->
                        <ng-container *ngSwitchCase="'TITLE_CASE'">
                          {{
                            utilities.titleCaseToWord(
                              getValue(item, head) !== "" &&
                                getValue(item, head) !== null &&
                                getValue(item, head) !== " " &&
                                getValue(item, head) !== "null"
                                ? getValue(item, head)
                                : "N/A"
                            )
                          }}
                        </ng-container>

                        <!-- DUE DATE -->
                        <ng-container *ngSwitchCase="'DUE_DATE'">
                          <span
                            [style.color]="
                              head?.colorCode ? getColorCode(item, head) : ''
                            "
                          >
                            {{
                              getValue(item, head) !== "N/A"
                                ? utilities.dueDateFormat(
                                    getValue(item, head),
                                    item?.taskTimezone
                                  ).timezoneDate
                                : "N/A"
                            }}
                            <span
                              [style.color]="head.colorCode ? '' : '#9b9ea0'"
                              *ngIf="getValue(item, head) !== 'N/A'"
                            >
                              {{
                                getValue(item, head) !== "N/A"
                                  ? utilities.dueDateFormat(
                                      getValue(item, head),
                                      item?.taskTimezone
                                    ).timezoneTime
                                  : "N/A"
                              }}
                            </span>
                          </span>
                        </ng-container>

                        <!-- SCHEDULED SMS TITLE WITH TIME -->
                        <ng-container
                          *ngSwitchCase="'SCHEDULED_SMS_TITLE_WITH_TIME'"
                        >
                          <span
                            class="re-agent-sms-name"
                            style="color: #0a4e81"
                          >
                            {{
                              utilities.titleCaseToWord(getValue(item, head))
                            }}
                          </span>
                          <span
                            [ngClass]="
                              (item?.leadInfo && item?.leadInfo?.address) ||
                              (item?.contactData && item?.contactData?.address)
                                ? 're-agent-sms-property'
                                : 're-agent-sms-noProperty'
                            "
                          >
                            {{
                              getPropertyAddress(
                                item?.type == "Contact"
                                  ? item?.contactData
                                  : item?.leadInfo
                              )
                            }}
                          </span>
                        </ng-container>

                        <!-- DATE WITH TIMEZONE -->
                        <ng-container *ngSwitchCase="'DUE_DATE_TIMEZONE'">
                          <span class="re-agent-sms-date">
                            {{
                              utilities.dueDateFormat(
                                getValue(item, head),
                                item[head.timezone]
                              ).timezoneDate
                            }}
                          </span>
                          <span class="re-agent-sms-time">
                            {{
                              utilities.dueDateFormat(
                                getValue(item, head),
                                item[head.timezone]
                              ).timezoneTime
                            }}
                          </span>
                          <!-- {{
                              utilities.dueDateFormat(
                                getValue(item, head),
                                item[head.timezone]
                              ).timezoneAbbr
                            }} -->
                        </ng-container>

                        <!-- TIME AGO -->
                        <ng-container *ngSwitchCase="'TIME_AGO'">
                          {{ timeAgo(getValue(item, head)) }}
                        </ng-container>

                        <!-- FORMATTED DATE-->
                        <ng-container *ngSwitchCase="'FORMATTED_DATE'">
                          {{ formatedDate(getValue(item, head)) }}
                        </ng-container>

                        <ng-container *ngSwitchCase="'SELECT'">
                          <select
                            class="form-control re-select"
                            [(ngModel)]="item[head.key]"
                            (ngModelChange)="changeValue(head, item, $event)"
                            [ngModelOptions]="{ standalone: true }"
                          >
                            <ng-container
                              *ngIf="
                                head?.key != 'transactionLeadId' &&
                                head?.key != 'mainStatusId'
                              "
                            >
                              <ng-container
                                *ngFor="let option of head?.options"
                              >
                                <option [value]="option.value">
                                  {{ option?.label }}
                                </option>
                              </ng-container>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                head?.key == 'transactionLeadId' ||
                                head?.key == 'mainStatusId'
                              "
                            >
                              <ng-container
                                *ngFor="
                                  let option of checkMainStatus(
                                    head?.options,
                                    item,
                                    head
                                  )
                                "
                              >
                                <option
                                  [value]="option.value"
                                  [disabled]="butDisabled"
                                >
                                  {{ option?.label }}
                                </option>
                              </ng-container>
                            </ng-container>
                          </select>
                        </ng-container>

                        <!-- CURRENCY -->
                        <ng-container *ngSwitchCase="'CURRENCY'">
                          <span
                            [ngClass]="{
                              're-agent-green-amount':
                                !head?.hideClass &&
                                getCurrency(item, head) >= 0,
                              're-agent-red-amount':
                                !head?.hideClass && getCurrency(item, head) < 0
                            }"
                          >
                            {{
                              getCurrency(item, head) == 0
                                ? "$0"
                                : (getCurrency(item, head)
                                  | currency
                                  | currencyPositive)
                            }}
                          </span>
                        </ng-container>

                        <!-------- CUSTOM_FUNCTION ----------------->
                        <ng-container *ngSwitchCase="'CUSTOM_FUNCTION'">
                          <div
                            pTooltip="{{
                              item?.twilioSubType === 'tollfree'
                                ? '10DLC registration is not required for any Toll Free numbers.'
                                : ''
                            }}"
                            class="{{
                              head.isCustomClass
                                ? customFunction(item, head, 'class')
                                : ''
                            }}"
                            tooltipPosition="bottom"
                            [innerHTML]="
                              head.isCustomClass
                                ? customFunction(item, head, 'value')
                                : customFunction(item, head, secondaryData)
                            "
                          ></div>
                        </ng-container>
                      </ng-container>

                      <p>
                        <!-- INNER VALUE -->
                        <ng-container
                          *ngFor="
                            let value of head?.innerValue;
                            let innerIndex = index
                          "
                        >
                          <span
                            *ngIf="value"
                            [ngStyle]="value?.style"
                            (click)="
                              value?.isClickExists
                                ? callAction(value, item, value)
                                : ''
                            "
                          >
                            <ng-container [ngSwitch]="value?.pipe">
                              <!-- CURRENCY -->
                              <ng-container *ngSwitchCase="'CURRENCY'">
                                <span>
                                  {{ getCurrency(item, value) | currency }}
                                </span>
                              </ng-container>

                              <!-- TITLE CASE -->
                              <ng-container *ngSwitchCase="'TITLE_CASE'">
                                <span class="text-capitalize">
                                  {{
                                    item?.mainStatusTitle ==
                                      "assigned to buyer" &&
                                    value?.key == "buyer" &&
                                    item?.buyers?.length
                                      ? (item?.buyers[0]?.firstName
                                          ? item?.buyers[0]?.firstName
                                          : "") +
                                        " " +
                                        (item?.buyers[0]?.lastName
                                          ? item?.buyers[0]?.lastName
                                          : "")
                                      : ""
                                  }}
                                </span>
                              </ng-container>

                              <ng-container *ngSwitchDefault>
                                <span
                                  *ngIf="
                                    getValue(item, value) &&
                                    getValue(item, value) != 'N/A'
                                  "
                                >
                                  {{ getValue(item, value) }}
                                </span>
                              </ng-container>
                            </ng-container>
                          </span>

                          <span *ngIf="innerIndex > 0 && head?.separatorEnd">
                            {{ head?.separatorEnd }}
                          </span>

                          <!-- PREFERRED -->
                          <span *ngIf="innerIndex > 0 && item?.isPreferred">
                            <i class="fa fa-star"></i>
                          </span>

                          <!-- BLACKLISTED -->
                          <span *ngIf="innerIndex > 0 && item?.isBlackListed">
                            <i class="fa fa-minus-circle"></i>
                          </span>
                        </ng-container>
                      </p>
                    </ng-container>

                    <ng-container *ngIf="head?.file">
                      <ng-container [ngSwitch]="head?.file">
                        <!-- AUDIO -->
                        <ng-container *ngSwitchCase="'AUDIO'">
                          <app-audio-player
                            *ngIf="
                              getValue(item, head) &&
                              getValue(item, head) !== 'N/A' &&
                              item?.duration != '0' &&
                              item?.status != 'no-answer' &&
                              item?.status != 'cancel' &&
                              item?.status != 'no-confirm' &&
                              item?.status != 'busy'
                            "
                            [url]="getValue(item, head)"
                          ></app-audio-player>
                          <span
                            *ngIf="
                              moduleName == 'Call Logs' &&
                              (item?.duration == '0' ||
                                item?.status == 'no-answer' ||
                                item?.status == 'cancel' ||
                                item?.status == 'no-confirm' ||
                                item?.status == 'busy')
                            "
                          >
                            N/A
                          </span>
                        </ng-container>

                        <!-- ATTACHMENT -->
                        <ng-container
                          *ngIf="
                            head?.isAttachmentExists &&
                            item[head?.attachmentKey]?.length
                          "
                        >
                          <span
                            class="re-attachment"
                            (click)="
                              previewAttachment(item[head?.attachmentKey])
                            "
                          >
                            <img
                              src="assets/images/attachment-symbol.svg"
                              alt="attachment"
                            />
                            View Attachment
                          </span>
                        </ng-container>

                        <!-- NO RECORD -->
                        <!-- *ngIf="
                        item['smsBody'] != '' &&
                        !item[head?.attachmentKey]?.length
                      " -->
                        <ng-container *ngIf="item['smsBody'] != ''">
                          {{
                            item["smsBody"]
                              ? item["smsBody"].slice(
                                  0,
                                  item["smsBody"]?.length > 40
                                    ? 40
                                    : item["smsBody"]?.length
                                )
                              : ""
                          }}{{
                            item["smsBody"]
                              ? item["smsBody"]?.length > 40
                                ? "....."
                                : ""
                              : ""
                          }}
                        </ng-container>

                        <!-- NO RECORD -->
                        <ng-container
                          *ngIf="
                            getValue(item, head) === 'N/A' &&
                            item['smsBody'] == '' &&
                            !head?.isAttachmentExists &&
                            !item[head?.attachmentKey]?.length
                          "
                        >
                          N/A
                        </ng-container>
                        <ng-container
                          *ngIf="
                            (item?.status == 'completed' ||
                              item?.status == 'received' ||
                              item?.status == 'delivered' ||
                              item?.status == 'sent') &&
                            !item['smsBody'] &&
                            !item['recording'] &&
                            !head?.isAttachmentExists
                          "
                        >
                          N/A
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <div
                      class="re-agent-hover-text"
                      *ngIf="
                        hoverText &&
                        getValue(item, hoverText) &&
                        head?.key == 'recording'
                      "
                    >
                      {{ getValue(item, hoverText) }}
                    </div>

                    <div
                      class="re-agent-hover-Tooltip-text"
                      *ngIf="
                        hoverTooltipText &&
                        getValue(item, hoverTooltipText) &&
                        head?.key == 'recording'
                      "
                    >
                      {{ getValue(item, hoverTooltipText) }}
                    </div>
                  </div>
                  <div class="re_ongoing_list" *ngIf="head?.isOngoingCall">
                    <a class="re-btnlink">
                      <svg
                        data-name="Group 13125"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path
                          data-name="Path 2957"
                          d="M0 0h18v18H0z"
                          style="fill: none"
                        />
                        <path
                          data-name="Path 2958"
                          d="M6 8.249a5.25 5.25 0 1 1 9.75 2.7 7.5 7.5 0 0 1-1.5 1.5 6 6 0 0 0-1.5 2.25 3.375 3.375 0 0 1-5.1 1.05"
                          transform="translate(-1.5 -.749)"
                          style="
                            stroke: #01010b;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                            fill: none;
                          "
                        />
                        <path
                          data-name="Path 2959"
                          d="M10 9.241a2.25 2.25 0 1 1 3.75 1.65"
                          transform="translate(-2.5 -1.741)"
                          style="
                            stroke: #01010b;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                            fill: none;
                          "
                        />
                      </svg>
                      Start</a
                    >
                    <a class="re-btnlink re-active-btn">
                      <svg
                        data-name="Group 13127"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path
                          data-name="Path 4683"
                          d="M0 0h18v18H0z"
                          style="fill: none"
                        />
                        <rect
                          data-name="Rectangle 23459"
                          width="3"
                          height="4.5"
                          rx="1.5"
                          transform="translate(3 9.75)"
                          style="
                            stroke: #01010b;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                            fill: none;
                          "
                        />
                        <rect
                          data-name="Rectangle 23460"
                          width="3"
                          height="4.5"
                          rx="1.5"
                          transform="translate(12 9.75)"
                          style="
                            stroke: #01010b;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                            fill: none;
                          "
                        />
                        <path
                          data-name="Path 4684"
                          d="M4 12.25V10a6 6 0 1 1 12 0v2.25"
                          transform="translate(-1 -1)"
                          style="
                            stroke: #01010b;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                            fill: none;
                          "
                        />
                        <path
                          data-name="Path 4685"
                          d="M16.5 19c0 1.243-2.015 2.25-4.5 2.25"
                          transform="translate(-3 -4.75)"
                          style="
                            stroke: #01010b;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                            fill: none;
                          "
                        />
                      </svg>
                      Start</a
                    >
                    <a class="re-btnlink re-stop-btn">
                      <svg
                        data-name="Group 13128"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path
                          data-name="Path 4676"
                          d="M0 0h18v18H0z"
                          style="fill: none"
                        />
                        <path
                          data-name="Line 1394"
                          transform="translate(5.25 2.25)"
                          style="
                            stroke: #01010b;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                            fill: none;
                          "
                          d="M0 0v6.53"
                        />
                        <path
                          data-name="Path 4677"
                          d="M9 6 6 3 3 6"
                          transform="translate(-.75 -.75)"
                          style="
                            stroke: #01010b;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                            fill: none;
                          "
                        />
                        <path
                          data-name="Path 4678"
                          d="m17 12 3-3-3-3"
                          transform="translate(-4.25 -1.5)"
                          style="
                            stroke: #01010b;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                            fill: none;
                          "
                        />
                        <path
                          data-name="Path 4679"
                          d="M7 18.25A8.25 8.25 0 0 1 15.25 10h2.25"
                          transform="translate(-1.75 -2.5)"
                          style="
                            stroke: #01010b;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.5px;
                            fill: none;
                          "
                        />
                      </svg>
                      Start</a
                    >
                  </div>
                </td>

                <!-- ACTION -->
                <td
                  *ngIf="head?.key === 'action'"
                  [ngStyle]="head?.actionStyle"
                  class="re-action"
                >
                  <ng-container *ngFor="let action of head?.options">
                    <ng-container *ngIf="action?.route; else else_block">
                      <a
                        class="pointer float-left"
                        (click)="callAction(action, item, itemIndex)"
                      >
                        <img
                          [src]="getActionIcon(action, itemIndex)"
                          class="float-left"
                          [alt]="action?.type"
                        />
                      </a>
                    </ng-container>

                    <ng-template #else_block>
                      <ng-container
                        *ngIf="action?.type == 'DEFAULT_CARD'; else else_block1"
                      >
                        <div class="re-radiolist w-auto">
                          <label class="re-radiobtn"
                            >Mark as primary
                            <input
                              (change)="cardRadioChange($event, item)"
                              type="radio"
                              name="primaryRadio"
                              value="true"
                              [checked]="item?.isDefaultCard" /><span
                              class="re-checkmark"
                            ></span
                          ></label>
                        </div>
                      </ng-container>

                      <ng-template #else_block1>
                        <a
                          class="pointer float-left"
                          (click)="
                            callAction(action, item, itemIndex, moduleId)
                          "
                          (mouseenter)="
                            activeIconIndex = itemIndex;
                            activeIcon = action?.type
                          "
                          (mouseleave)="activeIconIndex = -1; activeIcon = ''"
                          [pTooltip]="renderTooltip(action, item)"
                          tooltipPosition="bottom"
                          *ngIf="!item?.isHideAction || item?.isHideAction == 1"
                        >
                          <ng-container
                            *ngIf="
                              action?.type === 'SHARE_FILE' ||
                                action?.type === 'CHECK_CONTINGENCY';
                              else else_icon
                            "
                          >
                            <ng-container *ngIf="action?.type === 'SHARE_FILE'">
                              <img
                                [src]="
                                  item?.isSharing
                                    ? action?.activeIcon
                                    : getActionIcon(action, itemIndex)
                                "
                                class="float-left"
                                [alt]="action?.type"
                              />
                            </ng-container>

                            <ng-container
                              *ngIf="action?.type === 'CHECK_CONTINGENCY'"
                            >
                              <img
                                [src]="
                                  item?.isCompleted
                                    ? action?.activeIcon
                                    : getActionIcon(action, itemIndex)
                                "
                                class="float-left"
                                [alt]="action?.type"
                              />
                            </ng-container>
                          </ng-container>

                          <ng-template #else_icon>
                            <ng-container *ngIf="action?.type !== 'STOP_DRIP'">
                              <ng-container
                                *ngIf="
                                  action?.isToggle && !item.isEnabled;
                                  else icon_else
                                "
                              >
                                <i class="fa fa-repeat fa-rotate-90"></i>
                              </ng-container>

                              <ng-template #icon_else>
                                <img
                                  [src]="
                                    action?.checkActive &&
                                    item[action?.activeCheckKey]
                                      ? action?.activeIcon
                                      : getActionIcon(action, itemIndex)
                                  "
                                  class="float-left"
                                  [alt]="action?.type"
                                />
                              </ng-template>
                            </ng-container>

                            <img
                              [src]="
                                item?.isSharing
                                  ? action?.activeIcon
                                  : getActionIcon(action, itemIndex)
                              "
                              class="float-left"
                              [alt]="action?.type"
                              *ngIf="
                                action?.type === 'STOP_DRIP' &&
                                getValue(item, action)
                              "
                            />
                            <span
                              *ngIf="action?.isUnblock"
                              class="re-agent-unblocknumber"
                            >
                              {{ action?.isUnblock }}
                            </span>
                          </ng-template>
                        </a>
                      </ng-template>
                    </ng-template>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </ng-container>
          <tr
            *ngIf="
              data['items']?.length &&
              data['count'] === data['items']?.length &&
              paginationType === miscellaneousConstant.paginationType.INFINITE
            "
          >
            <td
              [colSpan]="header?.length"
              class="text-center text-muted font-weight-normal"
            >
              End of list
            </td>
          </tr>
        </ng-container>

        <!-- LOADING -->
        <tr>
          <!-- STANDARD LOADING -->
          <td
            [colSpan]="header?.length"
            *ngIf="_sharedService.checkLoader(moduleName)"
          >
            <!-- LOADER BY ID -->
            <div class="re-loading">
              <img src="/assets/images/loading.gif" alt="" /> Loading...
            </div>
            <p *ngIf="customLoader" class="re-custom-loader-message">
              {{ customLoaderMessage }}
            </p>
          </td>
        </tr>

        <!-- NO DATA -->
        <ng-container *ngIf="!_sharedService.checkLoader(moduleName)">
          <tr
            *ngIf="
              !data['items'] ||
              (data['items'] && (!keys(data).length || !data['items']?.length))
            "
          >
            <td [colSpan]="header?.length" class="text-center">
              No Records Found
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </form>
</div>

<!-- STANDARD PAGINATION -->
<div
  class="custom_pagination"
  *ngIf="paginationType === miscellaneousConstant.paginationType.STANDARD"
>
  <app-pagination
    #pagination
    [initialPage]="currentPage"
    [count]="data?.count"
    [moduleName]="moduleName"
    (changePage)="onChangePage($event)"
  ></app-pagination>
</div>
