// MODULES
import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import * as moment from "moment";
import { Router } from "@angular/router";

// SERVICES
import { ToastrService } from "ngx-toastr";
import { LeadsService } from "../../../providers/leads/leads.service";
import { StatusConstant } from "../../../utils/status-constant";
import { SharedService } from "../../shared.service";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";

// COMPONENTS
import { SmsDialogComponent } from "../sms-dialog/sms-dialog.component";

// UTILS
import { ErrorModel } from "src/app/utils/models/error";
import { ResponseModel } from "src/app/utils/models/response";
import { MessageConstant } from "src/app/utils/message-constant";
import { environment } from "src/environments/environment";
import { Observable, Subject } from "rxjs";
import { BaseUrl } from "../../../utils/base-url-constants";
import { TwilioServiceService } from "../../../utils/twilio-service/twilio-service.service";

declare var Plivo;
declare var Twilio: any;

interface dataModelInterFace {
  isCalling: any;
  parameters: any;
  leadDetails: leadDetailInterFace[];
  buyers: any;
  name: any;
  callerName: any;
  numberType: any;
  listStackingDetails: leadDetailInterFace[];
  isTwilio: any;
  connectionParams: any;
  buyersDetails: leadDetailInterFace[];
  buyerLeadDetails: leadDetailInterFace[];
}

interface leadDetailInterFace {
  leadId: any;
  _id: any;
  address: any;
  TagId: any;
  mainStatusId: any;
  ListId: any;
  id: any;
  Formated_Address: any;
  crmQuestionTitle: any;
  numberTitle: any;
  lastName: any;
  firstName: any;
  name: any;
  Full_Name: any;
}
@Component({
  selector: "app-incoming-call-detail-dailogs",
  templateUrl: "./incoming-call-detail-dailogs.component.html",
  styleUrls: ["./incoming-call-detail-dailogs.component.scss"],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class IncomingCallDetailDailogsComponent {
  callDetailForm: FormGroup;
  plivoAccountIndex: number = 0;
  options = {
    debug: "ALL",
    permOnClick: true,
    enableTracking: true,
    closeProtection: true,
    maxAverageBitrate: 48000,
    allowMultipleIncomingCalls: true,
  };
  callDurationIncoming: string = "00:00:00";
  counterIncoming: any;
  callReady: boolean = false;
  muteCall: boolean = false;
  isCalling: boolean = false;
  submitted: boolean = false;
  messageConstant = MessageConstant;
  userId: string = "";
  leadSourceCountryCode: string = environment.countryCode;
  callBalance: number = 0;
  isMinimized: boolean = false;
  evt: any;
  isDialerOpen: boolean = false;
  isListStackingListOpen: boolean = false;
  isLeadListOpen: boolean = false;
  listopen: string = "inline-block";
  dialedNumber: string = "";
  dialogRef: any;
  holdCall: boolean = false;
  listLimit: any = 3;
  tagLimit: any = 3;
  tagLength: any = [3, 3, 3, 3];
  listLength: any = [3, 3, 3, 3];
  mainStatus: any = [];
  mainStatusDetails: any = [];
  callUUID: any = "";
  currentCall: any = "";
  numberType: any;
  baseUrl = environment.baseUrl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: dataModelInterFace,
    private _toastrService: ToastrService,
    private _sharedService: SharedService,
    public _utilities: CommonFunctionsService,
    private _dialog: MatDialog,
    private _route: Router,
    private _leadService: LeadsService,
    private incomingCallDetailDailog: MatDialogRef<IncomingCallDetailDailogsComponent>,
    private _twilioService: TwilioServiceService
  ) {
    if (data?.isCalling) {
      this.isCalling = data?.isCalling;
      this.numberType = data?.numberType;
    }
    this._twilioService.twilioIncomingCancelEvent = new Subject();
    this._twilioService.twilioIncomingCancelEvent.subscribe((response) => {
      if (response) {
        this.cancel({ direction: "incoming" });
      }
    });
  }

  openCloseLead(bool) {
    this.isLeadListOpen = bool;
    this.isListStackingListOpen = bool ? !bool : this.isListStackingListOpen;
    if (bool) {
      this.listopen = "none";
    } else {
      this.listopen = "inline-block";
    }
  }
  openCloseListStacking(bool) {
    this.isListStackingListOpen = bool;
    this.isLeadListOpen = bool ? !bool : this.isLeadListOpen;
    if (bool) {
      this.listopen = "none";
    } else {
      this.listopen = "inline-block";
    }
  }

  onFullList(bool, i) {
    this.listLimit = bool
      ? this.data?.listStackingDetails[i].ListId?.length
      : 3;
    this.listLength[i] = bool
      ? this.data?.listStackingDetails[i].ListId?.length
      : 3;
  }

  onFullTagList(bool, i) {
    this.tagLimit = bool ? this.data?.listStackingDetails[i].TagId?.length : 3;
    this.tagLength[i] = bool
      ? this.data?.listStackingDetails[i].TagId?.length
      : 3;
  }
  getMainStatus(d, i) {
    if (d.mainStatusId == StatusConstant.SellerLeadStatus.NEW_LEAD) {
      this.mainStatus[i] = "New Leads";
      this.mainStatusDetails[i] = "Go To Lead Detail";
    } else if (
      d.mainStatusId == StatusConstant.SellerLeadStatus.NO_CONTACT_MADE
    ) {
      this.mainStatus[i] = "No Contact Made";
      this.mainStatusDetails[i] = "Go To Lead Detail";
    } else if (d.mainStatusId == StatusConstant.SellerLeadStatus.CONTACT_MADE) {
      this.mainStatus[i] = "Contact Made";
      this.mainStatusDetails[i] = "Go To Lead Detail";
    } else if (
      d.mainStatusId == StatusConstant.SellerLeadStatus.APPOINTMENT_SET
    ) {
      this.mainStatus[i] = "Appointments Set";
      this.mainStatusDetails[i] = "Go To Lead Detail";
    } else if (
      d.mainStatusId == StatusConstant.SellerLeadStatus.DUE_DILIGENCE
    ) {
      this.mainStatus[i] = "Due Diligence";
      this.mainStatusDetails[i] = "Go To Lead Detail";
    } else if (!this.data?.leadDetails && this.data?.listStackingDetails) {
      this.mainStatus[i] = "List Stacking";
      this.mainStatusDetails[i] = "View List Detail";
    } else {
      this.mainStatus[i] = "";
      this.mainStatusDetails[i] = "";
    }
    return this.mainStatus[i];
  }

  get leadSource() {
    return this.callDetailForm.get("leadSource");
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.callDetailForm.controls[controlName].hasError(errorName);
  };

  goToLeadDetails(data, i = 0) {
    if (this.isMinimized) {
      if (document.querySelector(".popup-Backdrop-Class-dialer-incoming"))
        document.querySelector(".popup-Backdrop-Class-dialer-incoming")[
          "style"
        ].display = "block";

      if (document.querySelector(".re-incomingcall-modal")) {
        document.querySelector(".re-incomingcall-modal")["style"].position =
          "relative";
        document.querySelector(".re-incomingcall-modal")["style"].height =
          "auto";
        document.querySelector(".re-incomingcall-modal")["style"].transform =
          "";
        document
          .querySelector(".re-incomingcall-modal")
          .classList.remove("re-lead-dialer-minimize-ss");
      }
    } else {
      if (document.querySelector(".popup-Backdrop-Class-dialer-incoming"))
        document.querySelector(".popup-Backdrop-Class-dialer-incoming")[
          "style"
        ].display = "none";
      if (document.querySelector(".re-incomingcall-modal")) {
        document.querySelector(".re-incomingcall-modal")["style"].position =
          "absolute";
        document
          .querySelector(".re-incomingcall-modal")
          .classList.add("re-lead-dialer-minimize-ss");
      }
      this.reset();
    }
    this.isMinimized = !this.isMinimized;
    if (data) {
      let key = data?.contactType == 1 ? "buyerId" : "sellerId";
      this._route.navigate(["/leads/details"], {
        queryParams: { [key]: data._id },
      });
    } else {
      this.isMinimized = false;
      this._toastrService.warning("No Lead Details Available.");
    }
  }

  goToBuyersLeadDetails(id, i = 0, statusid) {
    if (this.isMinimized) {
      if (document.querySelector(".popup-Backdrop-Class-dialer-incoming"))
        document.querySelector(".popup-Backdrop-Class-dialer-incoming")[
          "style"
        ].display = "block";

      if (document.querySelector(".re-incomingcall-modal")) {
        document.querySelector(".re-incomingcall-modal")["style"].position =
          "relative";
        document.querySelector(".re-incomingcall-modal")["style"].height =
          "auto";
        document.querySelector(".re-incomingcall-modal")["style"].transform =
          "";
        document
          .querySelector(".re-incomingcall-modal")
          .classList.remove("re-lead-dialer-minimize-ss");
      }
    } else {
      if (document.querySelector(".popup-Backdrop-Class-dialer-incoming"))
        document.querySelector(".popup-Backdrop-Class-dialer-incoming")[
          "style"
        ].display = "none";
      if (document.querySelector(".re-incomingcall-modal")) {
        document.querySelector(".re-incomingcall-modal")["style"].position =
          "absolute";
        document
          .querySelector(".re-incomingcall-modal")
          .classList.add("re-lead-dialer-minimize-ss");
      }
      this.reset();
    }
    this.isMinimized = !this.isMinimized;
    if (id) {
      this.goToDetails({
        _id: id,
        mainStatusId: statusid,
      });
    } else {
      this.isMinimized = false;
      this._toastrService.warning(this.messageConstant?.noLeadDetailsErr);
    }
  }
  goToBuyerDetails(_id) {
    if (!_id) {
      return;
    }
    this._route.navigateByUrl(`/buyers/details?buyersId=${_id}&contact=1`);
  }
  goToDetails({ _id, mainStatusId }) {
    if (!_id) {
      return;
    }
    const obj = { leadid: _id };

    localStorage.setItem("toplead", "true");
    localStorage.setItem("topappoint", "true");

    if (
      mainStatusId === "5feb3f94aa810a3849fa5515" ||
      mainStatusId === "5feb4221aa810a3849fa551b" ||
      mainStatusId === "5feb4252aa810a3849fa551c" ||
      mainStatusId === "60221e37d8562ef0219c072a"
    ) {
      // this._leadService.getProjectWithLeadId(obj).subscribe(
      //   (response: ResponseModel) => {
      //     if (!response.data?.items) {
      //       this._route.navigate([`/inventory`]);
      //       return;
      //     }
      //
      //     const { mainStatusId, _id } = response.data?.items;
      //     if (
      //       mainStatusId == '60225879d8562ef021ca2fcd' ||
      //       mainStatusId == '5feb4221aa810a3849fa551b'
      //     ) {
      //       this._route.navigate([`/sold/details`], {
      //         queryParams: { ['soldId']: _id },
      //       });
      //     } else if (
      //       mainStatusId == '60225867d8562ef021ca2180' ||
      //       mainStatusId == '5feb4252aa810a3849fa551c'
      //     ) {
      //       this._route.navigate([`/rental/details`], {
      //         queryParams: { ['rentalId']: _id },
      //       });
      //     } else {
      //       this._route.navigate([`/inventory/details`], {
      //         queryParams: { ['inventoryId']: _id },
      //       });
      //     }
      //   },
      //   (error: any) => {
      //     console.log(error);
      //   }
      // );
    } else {
      const key = "leadsId";
      this._route.navigate([`/leads/details`], {
        queryParams: { [key]: _id },
      });
    }
  }

  showTime(dateA, dateB) {
    dateB.add(1, "seconds");
    this.callDurationIncoming = moment
      .utc(
        moment(dateB, "DD/MM/YYYY HH:mm:ss").diff(
          moment(dateA, "DD/MM/YYYY HH:mm:ss")
        )
      )
      .format("HH:mm:ss");
  }

  mute() {
    this.muteCall = true;
    this._twilioService.twilioCallIncomingConnection.mute(true);
  }

  unmute() {
    this.muteCall = false;
    this._twilioService.twilioCallIncomingConnection.mute(false);
  }

  cancel(callInfo) {
    if (this.holdCall) {
      this.unHold(true);
      return;
    }
    this._utilities.callTypeReject = "true";
    if (callInfo.direction === "incoming") {
      this._twilioService.twilioCallIncomingConnection.disconnect();
      if (this._utilities.numberOfCallsRunning > 0) {
        this._utilities.numberOfCallsRunning--;
      }
      setTimeout(() => {
        this._sharedService.refreshActivityLog.next(true);
      }, 2000);
      this.isCalling = false;
      this.callReady = false;

      if (this.isMinimized) this.toggleDisplay();
      this.incomingCallDetailDailog.close(this.data.parameters.CallSid);
      setTimeout(() => {
        this._utilities.callType = "outgoing";
        this._utilities.callTypeReject = "false";
      }, 1000);
    }
  }
  rejectCall(callInfo) {
    this._utilities.callTypeReject = "true";
    if (callInfo.direction === "incoming") {
      this._twilioService.twilioCallIncomingConnection.reject();
      if (this._utilities.numberOfCallsRunning > 0) {
        this._utilities.numberOfCallsRunning--;
      }
      setTimeout(() => {
        this._sharedService.refreshActivityLog.next(true);
      }, 2000);
      this.isCalling = false;
      this.callReady = false;

      if (this.isMinimized) this.toggleDisplay();
      this.incomingCallDetailDailog.close(this.data.parameters.CallSid);
      setTimeout(() => {
        this._utilities.callType = "outgoing";
        this._utilities.callTypeReject = "false";
      }, 1000);
    }
  }

  smsReply() {
    this.dialogRef = this._dialog.open(SmsDialogComponent, {
      width: "500px",
      data: {
        phoneNumber: "",
        leadSource: "",
      },
    });
  }

  removeDialer() {
    if (this.dialedNumber) {
      this.dialedNumber = this.dialedNumber.slice(0, -1);
    }
  }

  async answerCall() {
    const call = this._twilioService.twilioCallIncomingConnection.accept();
    this._utilities.numberOfCallsRunning++;
    this._utilities.callTypeReject = "false";
    this.callUUID = this.data.parameters.CallSid;
    if (this.currentCall !== "") {
      this.currentCall = this.data.parameters.CallSid;
    }
    this.callReady = true;
    this.isCalling = true;
    let a = moment();
    let b = moment();
    this.counterIncoming = setInterval(() => {
      this.showTime(a, b);
    }, 1000);
  }

  toggleDisplay() {
    if (this.isMinimized) {
      if (document.querySelector(".popup-Backdrop-Class-dialer-incoming"))
        document.querySelector(".popup-Backdrop-Class-dialer-incoming")[
          "style"
        ].display = "block";

      if (document.querySelector(".re-incomingcall-modal")) {
        document.querySelector(".re-incomingcall-modal")["style"].position =
          "relative";
        document.querySelector(".re-incomingcall-modal")["style"].height =
          "auto";
        document.querySelector(".re-incomingcall-modal")["style"].transform =
          "";
        document
          .querySelector(".re-incomingcall-modal")
          .classList.remove("re-lead-dialer-minimize-ss");
      }
    } else {
      if (document.querySelector(".popup-Backdrop-Class-dialer-incoming"))
        document.querySelector(".popup-Backdrop-Class-dialer-incoming")[
          "style"
        ].display = "none";
      if (document.querySelector(".re-incomingcall-modal")) {
        document.querySelector(".re-incomingcall-modal")["style"].position =
          "absolute";
        document
          .querySelector(".re-incomingcall-modal")
          .classList.add("re-lead-dialer-minimize-ss");
      }
      this.reset();
    }

    this.isMinimized = !this.isMinimized;
  }

  drop(ev): void {
    this.evt = ev.source;
  }

  reset() {
    if (this.evt) this.evt._dragRef.reset();
  }

  dialer(key) {
    console.log("key", key);
    if (!key) {
      return;
    }
    this.dialedNumber += key;
    console.log("dialedNumber", this.dialedNumber);
    this._twilioService.twilioCallIncomingConnection.sendDigits(key);
    console.log("dialedNumber done");
  }

  hold() {
    this._twilioService
      ?.holdCallFn({ callSid: this.data.parameters.CallSid })
      .subscribe(
        (response) => {
          this.holdCall = true;
        },
        (err: ErrorModel) => {
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
  }

  unHold(disConnect?) {
    if (this.data.isTwilio) {
      this._twilioService
        ?.unHoldCallFn({ callSid: this.data.parameters.CallSid })
        .subscribe(
          (response) => {
            this.holdCall = false;
            if (disConnect) {
              setTimeout(() => {
                this.cancel({ direction: "incoming" });
              }, 2000);
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, "");
            } else {
              this._toastrService.error(this.messageConstant.unknownError, "");
            }
          }
        );
    }
  }
}
