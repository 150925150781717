import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseUrl } from "../../utils/base-url-constants";
import { environment } from "src/environments/environment";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";

@Injectable({
  providedIn: "root",
})
export class RvmService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem("token");

  constructor(private _commonFunction: CommonFunctionsService) {}

  getRVM = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.rvmUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  saveRVM = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.rvmUrl + "save";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editRVM = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.rvmUrl + "edit";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  sendRVM = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.rvmUrl + "sendRvm";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteRVM = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.rvmUrl + "remove";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // getPresignedUrl = (): Observable<any> => {
  //   const endpoint = this.baseUrl + BaseUrl.rvmUrl + "pre-signed-url";
  //   return this._commonFunction.globalGetService(endpoint);
  // };
}
