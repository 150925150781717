<div>
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      {{ type }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<div class="re-agent-rightdiv">
  <button class="btn" data-toggle="modal" (click)="addFilter()">
    + Add Filter
  </button>
</div>
<div class="re-agent-manageList" *ngIf="data.details.length > 0">
  <ng-container *ngFor="let user of data.details; let index = index">
    <div
      dndDragImageRef
      [dndDraggable]="user"
      [dndEffectAllowed]="draggable.effectAllowed"
      [dndDisableIf]="draggable.disable"
      (dndStart)="onDragStart($event)"
      (dndMoved)="onDragged(user, data.details, 'move')"
      [dndDropzone]="['user']"
      [dndType]="'user'"
      (dndDrop)="onDrop($event, user, 'section')"
      class="col-12"
    >
      <div class="re-agent-member">
        <img
          class="re-agent-profile"
          src="/assets/images/arrow-up-down.svg"
          alt="arrow-up-down"
        />
        <div>
          <div class="re-agent-name">{{ user.title | titleCase }}</div>
          <div class="re-agent-team-name">{{ user.description }}</div>
        </div>
        <div class="re-edit-div">
          <!-- EDIT BUTTON -->
          <a class="re-icon" href="javascript:void(0);" (click)="edit(user)">
            <img src="assets/images/edit-edit-blue.svg" alt="edit" />
          </a>

          <!-- DELETE BUTTON -->
          <a
            class="re-icon"
            href="javascript:void(0);"
            (click)="deleteFilter(user?._id)"
          >
            <img src="assets/images/edit-trash-red.svg" alt="trash" />
          </a>
        </div>
      </div>
    </div>
    <hr />
  </ng-container>
</div>
<div class="re-agent-manageList" *ngIf="data.details.length == 0">
  <div class="re-agent-no-filter">No Quick Filter</div>
</div>
