import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";

// UTILS
import { ErrorModel } from "src/app/utils/models/error";
import { ResponseModel } from "src/app/utils/models/response";
import { MessageConstant } from "src/app/utils/message-constant";
import { MiscellaneousConstant } from "src/app/utils/miscellaneous-constant";

// SERVICES

import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TeamsService } from "src/app/providers/teams/teams.service";

@Component({
  selector: "app-team-delete",
  templateUrl: "./team-delete.component.html",
  styleUrls: ["./team-delete.component.scss"],
})
export class TeamDeleteComponent {
  teamList: any = [];
  selectedTeam: any = "";
  newTeam: any;
  messageConstant = MessageConstant;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TeamDeleteComponent>,
    public _utilities: CommonFunctionsService,

    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _teamService: TeamsService,
    private _router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getTeamsList();
  }

  submit() {
    this.dialogRef.close({
      oldTeamId: this.data?.details?._id,
      newTeamId: this.newTeam?._id,
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  getTeamsList() {
    let param = {
      page: 1,
      limit: 100,
    };

    this._loaderService.start();
    this._teamService.getTeams(param).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.teamList = response?.data?.items.filter(
            (i) => i?._id.toLowerCase() != this.data?.details?._id.toLowerCase()
          );
          this.newTeam = response?.data?.items.filter(
            (i) => i?.title == "Primary Team"
          )[0];

          this.selectedTeam = this.newTeam?._id;
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (error?.platform != "web") {
            this._toastrService.error(error.platform, "");
          } else {
            this._toastrService.error(error.message, "");
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  selectNewTeam() {
    this.newTeam = this.teamList.filter(
      (i) => this.selectedTeam.toString() == i?._id.toString()
    )[0];
  }
}
