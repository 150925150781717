<div class="re-agent-add-appointment-modal">
  <div class="" *ngIf="isDialog">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between text-capitalize"
      >
        {{ appointmentType }} Appointment
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <!-- FORM -->
  <form
    [formGroup]="addEditForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="re-agent-typography vh-100">
      <div class="container-fluid p-0">
        <div class="re-agent-addedit-appotab">
          <ul class="nav nav-tabs" role="tablist">
            <li role="presentation">
              <a
                href="#appointment"
                aria-controls="appointment"
                role="tab"
                data-toggle="tab"
                aria-expanded="false"
                class="active"
              >
                Appointment Detail
              </a>
            </li>
            <li role="presentation">
              <a
                href="#sellerReminder"
                aria-controls="sellerReminder"
                role="tab"
                data-toggle="tab"
                aria-expanded="false"
              >
                {{ leadType }} Reminders
              </a>
            </li>
            <li role="presentation">
              <a
                href="#myReminder"
                aria-controls="myReminder"
                role="tab"
                data-toggle="tab"
                aria-expanded="false"
              >
                Assignee Reminders
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <!-- APPOINTMENT DETAILS -->
            <div role="tabpanel" class="tab-pane active" id="appointment">
              <div class="re-agent-box">
                <div class="row">
                  <!-- APPOINTMENT TITLE -->
                  <div class="col-12">
                    <div class="form-group">
                      <label class="re-agent-label required">
                        Appointment Title
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        name="title"
                        type="text"
                        class="form-control"
                        placeholder="Appointment Title"
                        formControlName="title"
                        maxlength="50"
                      />

                      <div
                        class="text-danger re-agent-error-text"
                        *ngIf="submitted && hasError('title', 'required')"
                      >
                        <span>
                          {{
                            messageConstant?.requiredField.replace(
                              "Field",
                              "Appointment Title"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- DESCRIPTION -->
              <div class="re-agent-box">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="re-agent-label">Description</label>
                      <textarea
                        name="description"
                        class="form-control"
                        rows="2"
                        placeholder="Enter Description (Optional)"
                        formControlName="description"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <!-- APPOINTMENT TYPE -->
              <div class="re-agent-box">
                <div class="row">
                  <div class="col-12 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="re-agent-label">Appointment Type</label>

                      <mat-radio-group
                        aria-label="Select an option"
                        formControlName="appointmentType"
                        (change)="changeAppointmentType()"
                      >
                        <ng-container *ngFor="let type of appointmentTypes">
                          <mat-radio-button class="" [value]="type.value">
                            {{ type?.label || "NA" }}
                          </mat-radio-button>
                        </ng-container>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </div>

              <!-- TYPE OF SELLER APPOINTMENT -->
              <div class="re-agent-box" *ngIf="isSellerAppointmentVisible()">
                <div class="row">
                  <div class="col-12 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="re-agent-label"
                        >Type of {{ leadType }} Appointment</label
                      >

                      <mat-radio-group
                        aria-label="Select an option"
                        formControlName="appointmentSubType"
                      >
                        <ng-container *ngFor="let type of appointmentSubTypes">
                          <mat-radio-button class="" [value]="type.value">
                            {{ type?.label || "NA" }}
                          </mat-radio-button>
                        </ng-container>
                      </mat-radio-group>

                      <div
                        class="text-danger re-agent-error-text"
                        *ngIf="
                          submitted &&
                          hasError('appointmentSubType', 'required')
                        "
                      >
                        <span>
                          {{
                            messageConstant?.requiredField.replace(
                              "Field",
                              "Type of {{leadType}}
                          Appointment" ) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr class="mb-0" />

              <!-- AVAILABLE DATE -->
              <div class="re-agent-box pl-2 pr-2">
                <div class="row">
                  <div class="col-12 col-md-7 col-lg-7 re-agent-border">
                    <div class="pt-3 re-agent-calendar-sec">
                      <p-calendar
                        formControlName="dateTime"
                        styleClass="ngCalendarClass"
                        placeholder="Select From Available Date"
                        [minDate]="currentDate"
                        [inline]="true"
                        appendTo="body"
                        (onSelect)="changeAvailableDate($event)"
                      ></p-calendar>

                      <div
                        class="text-danger re-agent-error-text"
                        *ngIf="submitted && hasError('dateTime', 'required')"
                      >
                        <span>
                          {{
                            messageConstant?.requiredField.replace(
                              "Field",
                              "Available Date"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- AVAILABILITY FOR USER & TIME SLOT -->
                  <div class="col-12 col-md-5 col-lg-5 pt-3">
                    <div class="row">
                      <ng-container>
                        <!-- AVAILABILITY FOR -->
                        <div
                          class="col-12 col-padd"
                          [class.d-none]="isChooseAvailability"
                        >
                          <div class="form-group">
                            <label class="re-agent-label re-agent-font-13">
                              Availability for
                              <span class="text-danger">*</span>
                            </label>
                            <select
                              name="user"
                              class="form-control"
                              [(ngModel)]="user"
                              (change)="
                                getAvailability(
                                  [$any($event.target).value],
                                  true,
                                  '',
                                  $event
                                )
                              "
                              [ngModelOptions]="{ standalone: true }"
                            >
                              <option value="">Select User</option>
                              <option
                                *ngFor="let user of users"
                                [value]="user?._id"
                                [class.text-muted]="user?.isDeleted"
                              >
                                {{ user?.label }}
                                <span *ngIf="user?.isDeleted">(Deleted)</span>
                              </option>
                            </select>

                            <div
                              class="text-danger re-agent-error-text"
                              *ngIf="submitted && !user"
                            >
                              <span>
                                {{
                                  messageConstant?.requiredField.replace(
                                    "Field",
                                    "Availability for"
                                  )
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr
                          class="w-100 d-inline-block"
                          [class.d-none]="isChooseAvailability"
                        />
                        <!-- TIMEZONE -->
                        <div
                          class="col-12 col-padd"
                          [class.d-none]="isChooseAvailability"
                        >
                          <div class="form-group">
                            <label class="re-agent-label re-agent-font-13">
                              Time Zone
                              <span class="text-danger">*</span>
                            </label>
                            <select
                              name="timezoneOffset"
                              formControlName="timezoneOffset"
                              class="form-control re-agent-res-select"
                              (change)="timezoneChange()"
                            >
                              <option value="">Select Timezone</option>
                              <option
                                *ngFor="let timezone of timezoneList"
                                [value]="timezone?.timezonename"
                              >
                                {{ timezone?.name }}
                              </option>
                            </select>

                            <div
                              class="text-danger re-agent-error-text"
                              *ngIf="
                                submitted &&
                                hasError('timezoneOffset', 'required')
                              "
                            >
                              <span>
                                {{
                                  messageConstant?.requiredField.replace(
                                    "Field",
                                    "Timezone"
                                  )
                                }}
                              </span>
                            </div>
                          </div>
                        </div>

                        <ng-container
                          *ngIf="
                            user &&
                            addEditForm.value.timezoneOffset &&
                            !isChooseAvailability
                          "
                        >
                          <hr class="w-100 d-inline-block" />

                          <!-- START TIME -->
                          <div class="col-12 col-padd">
                            <div class="form-group">
                              <label class="re-agent-label re-agent-font-13">
                                Start Time:
                                <a
                                  href="javascript:void(0);"
                                  (click)="chooseTime('startTime')"
                                  *ngIf="!startTime"
                                  class="re-agent-choose"
                                >
                                  Choose Time
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    [style.transform]="'rotate(-90deg)'"
                                  >
                                    <g transform="translate(0 .001)">
                                      <path
                                        d="M7.5 15A7.5 7.5 0 1 1 15 7.5 7.509 7.509 0 0 1 7.5 15zM4.346 5.458a.973.973 0 0 0-.862.605.816.816 0 0 0 .2.92c1.163 1.159 2.28 2.274 3.409 3.409a.887.887 0 0 0 .622.252.9.9 0 0 0 .636-.261L11.436 7.3l.322-.322a.806.806 0 0 0 .2-.908.969.969 0 0 0-.863-.6.839.839 0 0 0-.6.263c-1.12 1.116-2 2-2.778 2.779l-.891-.891c-.629-.629-1.263-1.265-1.892-1.9a.829.829 0 0 0-.588-.263z"
                                        data-name="Subtraction 9"
                                        transform="translate(0 -.001)"
                                      />
                                    </g>
                                  </svg>
                                </a>

                                <a
                                  href="javascript:void(0);"
                                  *ngIf="startTime"
                                  class="re-agent-choose"
                                  (click)="chooseTime('startTime')"
                                >
                                  {{ moment(startTime).format("hh:mm A") }}
                                  <span
                                    class="fa fa-repeat fa-rotate-90"
                                  ></span>
                                </a>
                              </label>
                            </div>
                          </div>

                          <hr class="w-100 d-inline-block" />

                          <!-- END TIME -->
                          <div class="col-12 col-padd">
                            <div class="form-group">
                              <label class="re-agent-label re-agent-font-13">
                                End Time:
                                <a
                                  href="javascript:void(0);"
                                  class="re-agent-choose"
                                  (click)="startTime && chooseTime('endTime')"
                                  *ngIf="!endTime"
                                  [class.re-agent-disabled]="!startTime"
                                >
                                  Choose Time
                                  <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    [style.transform]="'rotate(-90deg)'"
                                  >
                                    <g transform="translate(0 .001)">
                                      <path
                                        d="M7.5 15A7.5 7.5 0 1 1 15 7.5 7.509 7.509 0 0 1 7.5 15zM4.346 5.458a.973.973 0 0 0-.862.605.816.816 0 0 0 .2.92c1.163 1.159 2.28 2.274 3.409 3.409a.887.887 0 0 0 .622.252.9.9 0 0 0 .636-.261L11.436 7.3l.322-.322a.806.806 0 0 0 .2-.908.969.969 0 0 0-.863-.6.839.839 0 0 0-.6.263c-1.12 1.116-2 2-2.778 2.779l-.891-.891c-.629-.629-1.263-1.265-1.892-1.9a.829.829 0 0 0-.588-.263z"
                                        data-name="Subtraction 9"
                                        transform="translate(0 -.001)"
                                      />
                                    </g>
                                  </svg>
                                  <span
                                    class="d-block font-weight-normal text-dark"
                                    *ngIf="!startTime"
                                    >Choose start time first</span
                                  >
                                </a>

                                <a
                                  href="javascript:void(0);"
                                  *ngIf="endTime"
                                  class="re-agent-choose"
                                  (click)="chooseTime('endTime')"
                                >
                                  {{ moment(endTime).format("hh:mm A") }}
                                  <span
                                    class="fa fa-repeat fa-rotate-90"
                                  ></span>
                                </a>
                              </label>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>

                      <!-- REQUIRED START TIME SLOT -->
                      <div class="col-12 col-padd" *ngIf="isChooseAvailability">
                        <label class="re-agent-label re-agent-slot-label">
                          {{
                            slotType === "startTime" ? "Start Time" : "End Time"
                          }}
                          <span
                            class="re-agent-times"
                            (click)="isChooseAvailability = false"
                          >
                            &times;
                          </span>
                        </label>
                        <ul class="re-agent-timescroll">
                          <ng-container
                            *ngIf="!_sharedServices.checkLoader('slot')"
                          >
                            <li
                              class="re-agent-availability"
                              *ngFor="
                                let slot of availability;
                                let slotIndex = index;
                                let lastIndex = last
                              "
                              (click)="setAvailability(slot, lastIndex)"
                              [class.re-agent-disabled]="
                                (lastIndex && slotType === 'startTime') ||
                                checkAvailability(slot)
                              "
                              [class.re-agent-active]="isSlotActive(slot)"
                            >
                              {{ moment(slot).format("hh:mm A") }}
                            </li>

                            <!-- NO AVAILABILITY -->
                            <li
                              *ngIf="!availability?.length"
                              class="re-agent-no-slot"
                            >
                              No time slot available
                            </li>
                          </ng-container>

                          <!-- LOADER -->
                          <li
                            class="re-agent-loading"
                            *ngIf="_sharedServices.checkLoader('slot')"
                          >
                            <ngx-ui-loader
                              [loaderId]="'slot'"
                              [fgsType]="'three-bounce'"
                              [hasProgressBar]="false"
                              [overlayColor]="'#ffffff'"
                            ></ngx-ui-loader>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SELLER REMINDER -->
            <div role="tabpanel" class="tab-pane" id="sellerReminder">
              <!-- NOTIFY SELLER -->
              <div class="re-agent-box">
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-12">
                    <ng-container>
                      <div
                        class="re-agent-otherInfo-box"
                        [class.re-agent-open-div]="
                          isTextVisible('notify-seller')
                        "
                      >
                        <div class="re-agent-info-title">
                          <h4 class="re-agent-group-title">
                            NOTIFY {{ leadType }}
                          </h4>
                          <a
                            class="re-agent-toggle-btn"
                            (click)="toggleSection('notify-seller')"
                            *ngIf="isToggleIconView"
                          >
                            <span *ngIf="!isIconVisible('notify-seller')">
                              <i class="fa fa-angle-down"></i>
                            </span>
                            <span *ngIf="isIconVisible('notify-seller')">
                              <i class="fa fa-angle-up"></i>
                            </span>
                          </a>
                        </div>
                        <div class="re-agent-info-content">
                          <div class="form-group">
                            <label class="re-agent-label re-agent-left">
                              Send a notification to {{ leadType }} as soon as
                              you create this appt?
                            </label>

                            <div class="re-agent-notification-box">
                              <div class="re-agent-radiosec">
                                <label class="re-agent-switch">
                                  <input
                                    type="checkbox"
                                    id="checkbox1"
                                    name="isNotifyNotification"
                                    formControlName="isNotifyNotification"
                                    (change)="notifySeller()"
                                  />
                                  <span class="re-agent-slider"></span>
                                  <span value="false" class="re-agent-text1">
                                    No
                                  </span>
                                  <span value="true" class="re-agent-text2">
                                    Yes
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="addEditForm?.value?.isNotifyNotification">
                <!-- NOTIFICATION TYPE -->
                <div class="re-agent-box">
                  <div class="row">
                    <div class="col-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <div class="radiolist">
                          <label class="radiobtn orm-check-inline">
                            <input
                              type="radio"
                              name="isNotifyNotificationType"
                              formControlName="isNotifyNotificationType"
                              (change)="notifySeller()"
                              value="0"
                            />
                            <span class="checkmark"></span>
                            Both SMS and Email
                          </label>
                          <label class="radiobtn orm-check-inline">
                            <input
                              type="radio"
                              name="isNotifyNotificationType"
                              formControlName="isNotifyNotificationType"
                              (change)="notifySeller()"
                              value="1"
                            />
                            <span class="checkmark"></span>
                            Via SMS
                          </label>
                          <label class="radiobtn orm-check-inline">
                            <input
                              type="radio"
                              name="isNotifyNotificationType"
                              formControlName="isNotifyNotificationType"
                              (change)="notifySeller()"
                              value="2"
                            />
                            <span class="checkmark"></span>
                            Via Email
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="re-agent-box">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="re-agent-label"
                          >Select a contact to notify
                          <span class="text-danger">*</span></label
                        >
                        <p-multiSelect
                          placeholder="Select a contact to notify"
                          [options]="contacts"
                          name="notifySecondaryContact"
                          formControlName="notifySecondaryContact"
                          appendTo="body"
                        ></p-multiSelect>
                        <div
                          class="text-danger re-agent-error-text"
                          *ngIf="
                            submitted &&
                            hasError('notifySecondaryContact', 'required')
                          "
                        >
                          <span>
                            {{
                              messageConstant?.requiredField.replace(
                                "Field",
                                "Contact to notify"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- SMS TEMPLATE -->
                <div
                  *ngIf="
                    addEditForm?.value?.isNotifyNotificationType === '0' ||
                    addEditForm?.value?.isNotifyNotificationType === '1'
                  "
                >
                  <hr />
                  <div class="re-agent-box">
                    <div class="row">
                      <div class="col-12">
                        <h1 class="re-agent-title">
                          SMS TEMPLATE
                          <span class="text-danger">*</span>
                        </h1>
                      </div>

                      <!-- TEMPLATE -->
                      <div class="col-12">
                        <div class="form-group">
                          <label class="re-agent-label">
                            Choose the message from templates ({{
                              smsTemplates?.length
                            }})
                          </label>
                          <select
                            class="form-control"
                            name="notifySmsTemplateId"
                            formControlName="notifySmsTemplateId"
                            (change)="
                              setSmsTemplate(
                                $event['target']['value'],
                                'notify'
                              )
                            "
                          >
                            <option value="">Select SMS Template</option>
                            <option
                              *ngFor="let sms of smsTemplates"
                              [value]="sms._id"
                            >
                              {{ sms?.templateName }}
                            </option>
                          </select>

                          <div
                            class="text-danger re-agent-error-text"
                            *ngIf="
                              submitted &&
                              hasError('notifySmsTemplateId', 'required')
                            "
                          >
                            <span>
                              {{
                                messageConstant?.requiredField.replace(
                                  "Field",
                                  "SMS Template"
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- PREVIEW -->
                      <div class="col-12">
                        <div class="form-group">
                          <label class="re-agent-label"> SMS preview </label>
                          <textarea
                            name="description"
                            class="form-control"
                            rows="5"
                            placeholder="Enter your Message Here"
                            formControlName="notifySmsMessage"
                            [mention]="items"
                            [mentionConfig]="{
                              triggerChar: '@',
                              maxItems: 100,
                              labelKey: 'name',
                              allowSpace: true
                            }"
                            [attr.readonly]="true"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- EMAIL TEMPLATE -->
                <div
                  *ngIf="
                    addEditForm?.value?.isNotifyNotificationType === '0' ||
                    addEditForm?.value?.isNotifyNotificationType === '2'
                  "
                >
                  <hr />
                  <div class="re-agent-box">
                    <div class="row">
                      <div class="col-12">
                        <h1 class="re-agent-title">
                          EMAIL TEMPLATE
                          <span class="text-danger">*</span>
                        </h1>
                      </div>

                      <div class="col-12">
                        <div class="form-group">
                          <label class="re-agent-label">
                            Choose the message from templates ({{
                              emailTemplates?.length
                            }})
                          </label>
                          <select
                            name="notifyEmailTemplateId"
                            formControlName="notifyEmailTemplateId"
                            class="form-control"
                          >
                            <option value="">Select Email Template</option>
                            <option
                              *ngFor="let email of emailTemplates"
                              [value]="email?._id"
                            >
                              {{ email?.templateName }}
                            </option>
                          </select>

                          <div
                            class="text-danger re-agent-error-text"
                            *ngIf="
                              submitted &&
                              hasError('notifyEmailTemplateId', 'required')
                            "
                          >
                            <span>
                              {{
                                messageConstant?.requiredField.replace(
                                  "Field",
                                  "Email Template"
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <hr />

              <!-- REMIND SELLER -->
              <div class="re-agent-box">
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-12">
                    <ng-container>
                      <div
                        class="re-agent-otherInfo-box"
                        [class.re-agent-open-div]="
                          isTextVisible('remind-seller')
                        "
                      >
                        <div class="re-agent-info-title">
                          <h4 class="re-agent-group-title">
                            REMIND {{ leadType }}
                          </h4>
                          <a
                            class="re-agent-toggle-btn"
                            (click)="toggleSection('remind-seller')"
                            *ngIf="isToggleIconView"
                          >
                            <span *ngIf="!isIconVisible('remind-seller')">
                              <i class="fa fa-angle-down"></i>
                            </span>
                            <span *ngIf="isIconVisible('remind-seller')">
                              <i class="fa fa-angle-up"></i>
                            </span>
                          </a>
                        </div>
                        <div class="re-agent-info-content">
                          <div class="form-group">
                            <label class="re-agent-label re-agent-left">
                              Want to notify the {{ leadType }} via Email and/or
                              SMS
                            </label>

                            <div class="re-agent-notification-box">
                              <div class="re-agent-radiosec">
                                <label class="re-agent-switch">
                                  <input
                                    type="checkbox"
                                    id="checkbox1"
                                    name="isReminderNotification"
                                    formControlName="isReminderNotification"
                                    (change)="notifySeller()"
                                  />
                                  <span class="re-agent-slider"></span>
                                  <span value="false" class="re-agent-text1">
                                    No
                                  </span>
                                  <span value="true" class="re-agent-text2">
                                    Yes
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="addEditForm?.value?.isReminderNotification">
                <!-- NOTIFICATION TYPE -->
                <div class="re-agent-box">
                  <div class="row">
                    <div class="col-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <div class="radiolist">
                          <label class="radiobtn orm-check-inline">
                            <input
                              type="radio"
                              name="isReminderNotificationType"
                              formControlName="isReminderNotificationType"
                              (change)="notifySeller()"
                              value="0"
                            />
                            <span class="checkmark"></span>
                            Both SMS and Email
                          </label>
                          <label class="radiobtn orm-check-inline">
                            <input
                              type="radio"
                              name="isReminderNotificationType"
                              formControlName="isReminderNotificationType"
                              (change)="notifySeller()"
                              value="1"
                            />
                            <span class="checkmark"></span>
                            Via SMS
                          </label>
                          <label class="radiobtn orm-check-inline">
                            <input
                              type="radio"
                              name="isReminderNotificationType"
                              formControlName="isReminderNotificationType"
                              (change)="notifySeller()"
                              value="2"
                            />
                            <span class="checkmark"></span>
                            Via Email
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="re-agent-box">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="re-agent-label"
                          >Select a contact to remind
                          <span class="text-danger">*</span></label
                        >
                        <p-multiSelect
                          placeholder="Select a contact to remind"
                          [options]="contacts"
                          name="reminderSecondaryContact"
                          formControlName="reminderSecondaryContact"
                          appendTo="body"
                        ></p-multiSelect>
                        <div
                          class="text-danger re-agent-error-text"
                          *ngIf="
                            submitted &&
                            hasError('reminderSecondaryContact', 'required')
                          "
                        >
                          <span>
                            {{
                              messageConstant?.requiredField.replace(
                                "Field",
                                "Contact to remind"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- SMS BEFORE -->
                <div
                  class="re-agent-box"
                  *ngIf="
                    addEditForm?.value?.isReminderNotificationType === '0' ||
                    addEditForm?.value?.isReminderNotificationType === '1'
                  "
                >
                  <div class="row">
                    <div class="col-12 col-md-7">
                      <label class="re-agent-label re-agent-m-top">
                        Remind {{ leadType }} about the Appointment via SMS
                        before
                      </label>
                    </div>
                    <div class="col-12 col-md-2">
                      <div class="form-group">
                        <input
                          name="smsRemindTime"
                          type="text"
                          class="form-control"
                          formControlName="smsRemindTime"
                        />
                      </div>
                    </div>

                    <div class="col-12 col-md-3">
                      <div class="form-group">
                        <select
                          class="form-control"
                          formControlName="smsRemindBefore"
                        >
                          <option
                            *ngFor="let timeType of taskTimeTypeList"
                            value="{{ timeType }}"
                          >
                            {{ timeType }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- EMAIL BEFORE -->
                <div
                  *ngIf="
                    addEditForm?.value?.isReminderNotificationType === '0' ||
                    addEditForm?.value?.isReminderNotificationType === '2'
                  "
                >
                  <div class="re-agent-box">
                    <div class="row">
                      <div class="col-12 col-md-7">
                        <label class="re-agent-label re-agent-m-top">
                          Remind {{ leadType }} about the Appointment via Email
                          before
                        </label>
                      </div>
                      <div class="col-12 col-md-2">
                        <div class="form-group">
                          <input
                            name="emailRemindTime"
                            type="text"
                            class="form-control"
                            formControlName="emailRemindTime"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group">
                          <select
                            class="form-control"
                            formControlName="emailRemindBefore"
                          >
                            <option
                              *ngFor="let timeType of taskTimeTypeList"
                              value="{{ timeType }}"
                            >
                              {{ timeType }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- SMS TEMPLATE -->
                <div
                  *ngIf="
                    addEditForm?.value?.isReminderNotificationType === '0' ||
                    addEditForm?.value?.isReminderNotificationType === '1'
                  "
                >
                  <hr />
                  <div class="re-agent-box">
                    <div class="row">
                      <div class="col-12">
                        <h1 class="re-agent-title">
                          SMS TEMPLATE
                          <span class="text-danger">*</span>
                        </h1>
                      </div>

                      <!-- TEMPLATE -->
                      <div class="col-12">
                        <div class="form-group">
                          <label class="re-agent-label">
                            Choose the message from templates ({{
                              smsTemplates?.length
                            }})
                          </label>
                          <select
                            class="form-control"
                            name="reminderSmsTemplateId"
                            formControlName="reminderSmsTemplateId"
                            (change)="
                              setSmsTemplate(
                                $event['target']['value'],
                                'reminder'
                              )
                            "
                          >
                            <option value="">Select SMS Template</option>
                            <option
                              *ngFor="let sms of smsTemplates"
                              [value]="sms._id"
                            >
                              {{ sms?.templateName }}
                            </option>
                          </select>

                          <div
                            class="text-danger re-agent-error-text"
                            *ngIf="
                              submitted &&
                              hasError('reminderSmsTemplateId', 'required')
                            "
                          >
                            <span>
                              {{
                                messageConstant?.requiredField.replace(
                                  "Field",
                                  "SMS Template"
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- PREVIEW -->
                      <div class="col-12">
                        <div class="form-group">
                          <label class="re-agent-label"> SMS preview </label>
                          <textarea
                            name="description"
                            class="form-control"
                            rows="5"
                            placeholder="Enter your Message Here"
                            formControlName="remindSmsMessage"
                            [mention]="items"
                            [mentionConfig]="{
                              triggerChar: '@',
                              maxItems: 100,
                              labelKey: 'name',
                              allowSpace: true
                            }"
                            [attr.readonly]="true"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- EMAIL TEMPLATE -->
                <div
                  *ngIf="
                    addEditForm?.value?.isReminderNotificationType === '0' ||
                    addEditForm?.value?.isReminderNotificationType === '2'
                  "
                >
                  <hr />
                  <div class="re-agent-box">
                    <div class="row">
                      <div class="col-12">
                        <h1 class="re-agent-title">
                          EMAIL TEMPLATE
                          <span class="text-danger">*</span>
                        </h1>
                      </div>

                      <div class="col-12">
                        <div class="form-group">
                          <label class="re-agent-label">
                            Choose the message from templates ({{
                              emailTemplates?.length
                            }})
                          </label>
                          <select
                            name="reminderEmailTemplateId"
                            formControlName="reminderEmailTemplateId"
                            class="form-control"
                          >
                            <option value="">Select Email Template</option>
                            <option
                              *ngFor="let email of emailTemplates"
                              [value]="email?._id"
                            >
                              {{ email?.templateName }}
                            </option>
                          </select>

                          <div
                            class="text-danger re-agent-error-text"
                            *ngIf="
                              submitted &&
                              hasError('reminderEmailTemplateId', 'required')
                            "
                          >
                            <span>
                              {{
                                messageConstant?.requiredField.replace(
                                  "Field",
                                  "Email Template"
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>

            <!-- MY REMINDER -->
            <div role="tabpanel" class="tab-pane" id="myReminder">
              <!-- REMIND SELLER -->
              <div class="re-agent-box">
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <label class="re-agent-label re-agent-left">
                        Want to notify yourself via Email and/or SMS
                      </label>

                      <div class="re-agent-notification-box">
                        <div class="re-agent-radiosec">
                          <label class="re-agent-switch">
                            <input
                              type="checkbox"
                              id="checkbox1"
                              name="isMyRemindNotification"
                              formControlName="isMyRemindNotification"
                            />
                            <span class="re-agent-slider"></span>
                            <span value="false" class="re-agent-text1">
                              No
                            </span>
                            <span value="true" class="re-agent-text2">
                              Yes
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="addEditForm?.value?.isMyRemindNotification">
                <!-- NOTIFICATION TYPE -->
                <div class="re-agent-box">
                  <div class="row">
                    <div class="col-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <div class="radiolist">
                          <label class="radiobtn orm-check-inline">
                            <input
                              type="radio"
                              formControlName="isMyRemindNotificationType"
                              name="isMyRemindNotificationType"
                              value="0"
                            />
                            <span class="checkmark"></span>
                            Both SMS and Email
                          </label>
                          <label class="radiobtn orm-check-inline">
                            <input
                              type="radio"
                              formControlName="isMyRemindNotificationType"
                              name="isMyRemindNotificationType"
                              value="1"
                            />
                            <span class="checkmark"></span>
                            SMS
                          </label>
                          <label class="radiobtn orm-check-inline">
                            <input
                              type="radio"
                              formControlName="isMyRemindNotificationType"
                              name="isMyRemindNotificationType"
                              value="2"
                            />
                            <span class="checkmark"></span>
                            Email
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- SMS BEFORE -->
                <div
                  class="re-agent-box"
                  *ngIf="
                    addEditForm?.value?.isMyRemindNotificationType === '0' ||
                    addEditForm?.value?.isMyRemindNotificationType === '1'
                  "
                >
                  <div class="row">
                    <div class="col-12 col-md-7">
                      <label class="re-agent-label re-agent-m-top">
                        Remind me about the Appointment via SMS before
                      </label>
                    </div>
                    <div class="col-12 col-md-2">
                      <div class="form-group">
                        <input
                          name="smsMyRemindTime"
                          type="text"
                          class="form-control"
                          formControlName="smsMyRemindTime"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div class="form-group">
                        <select
                          class="form-control"
                          formControlName="smsMyRemindBefore"
                        >
                          <option
                            *ngFor="let time of taskTimeTypeList"
                            [value]="time"
                          >
                            {{ time }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- EMAIL BEFORE -->
                <div
                  class="re-agent-box"
                  *ngIf="
                    addEditForm?.value?.isMyRemindNotificationType === '0' ||
                    addEditForm?.value?.isMyRemindNotificationType === '2'
                  "
                >
                  <div class="row">
                    <div class="col-12 col-md-7">
                      <label class="re-agent-label re-agent-m-top">
                        Remind me about the Appointment via Email before
                      </label>
                    </div>
                    <div class="col-12 col-md-2">
                      <div class="form-group">
                        <input
                          name="emailMyRemindTime"
                          type="text"
                          class="form-control"
                          formControlName="emailMyRemindTime"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div class="form-group">
                        <select
                          class="form-control"
                          formControlName="emailMyRemindBefore"
                        >
                          <option
                            *ngFor="let time of taskTimeTypeList"
                            [value]="time"
                          >
                            {{ time }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <hr style="margin: 0" />

    <mat-dialog-actions align="center" *ngIf="isDialog">
      <button mat-raised-button mat-dialog-close class="re-agent-cancel-btn">
        Cancel
      </button>
      <button mat-flat-button color="primary" type="submit" cdkFocusInitial>
        Save Appointment
      </button>
    </mat-dialog-actions>
  </form>
</div>
