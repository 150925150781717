<header>
  <!-- NEW UPDATE AVAILABLE -->
  <div *ngIf="_utilities.updateAvailable" class="re-agent-update-available">
    <span> A new version of AgentPro CRM available </span>

    <button
      type="submit"
      mat-flat-button
      class="re-agent-refresh"
      (click)="reload()"
    >
      REFRESH
    </button>
  </div>

  <nav class="re-agent-navbar navbar-default" role="navigation">
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggle"
        data-toggle="collapse"
        data-target="#navbar-collapse"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="fa fa-bars"></span>
      </button>
      <a
        class="navbar-brand"
        routerLink="/dashboard"
        *ngIf="isDirectMailFreeUser == false"
      >
        <img src="assets/images/navbar/logo.svg" alt="logo" />
      </a>
      <a
        class="navbar-brand"
        *ngIf="isDirectMailFreeUser"
        style="cursor: pointer"
      >
        <img src="assets/images/navbar/logo.svg" alt="logo" />
      </a>
    </div>
    <div id="navbar-collapse" class="collapse navbar-collapse re-txt-right">
      <div class="re-onbording-sec" *ngIf="subscriptionValid">
        <div class="re-progressmain">
          <div class="re-progre">
            <span class="re-txt">Onboarding</span>
            <div class="re-indicat">
              <div
                class="re-countt"
                [ngStyle]="{ width: coursePercent + '%' }"
              ></div>
            </div>
            <span class="re-txt">{{ coursePercent }}%</span>
          </div>
          <a
            class="re-btnb"
            target="_blank"
            href="https://course.resimpli.com/courses/enrolled/1935020"
            ><i class="fa fa-play" aria-hidden="true"></i> Getting Started</a
          >
        </div>
        <div class="re-txtinfo">
          Complete by {{ subscriptionValidDate | date : "MMM dd, yyyy" }} and
          get <span>$50 off</span> on next bill.
        </div>
      </div>

      <!-- GLOBAL SEARCH -->
      <form class="navbar-form" role="search">
        <div class="input-group">
          <span class="input-group-addon">
            <span class="fa fa-search"></span>
          </span>
          <p-autoComplete
            [(ngModel)]="searchedItem"
            field="firstname"
            [suggestions]="filteredBrands"
            name="item"
            (completeMethod)="filterBrands($event)"
            [size]="30"
            [minLength]="2"
            placeholder="Search..."
            (keyup.enter)="goTo(searchedItem)"
            (click)="closePopOver()"
          >
            <ng-template let-brand pTemplate="item">
              <div class="ui-helper-clearfix">
                <div class="re-searchname" (click)="goToDetails(brand)">
                  {{ brand?.name ? brand?.name : brand?.title }}
                  {{
                    brand?.number
                      ? _utilities.maskNumber(brand?.number)
                      : _utilities.maskNumber(brand?.phoneNumber)
                  }}
                  <br />
                  <br />
                  {{ _utilities.formatAddress(brand?.address, brand?.unitNo) }}
                </div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </form>

      <!-- ACTIONS -->
      <ul class="nav re-navbar-nav navbar-right">
        <li class="re-addlead-head">
          <div class="dropdown">
            <a class="dropdown-toggle" data-toggle="dropdown"> + Add </a>
            <div class="dropdown-menu dropdown-block" role="menu">
              <a class="re-links" routerLink="/leads/add"> Lead </a>
            </div>
          </div>
        </li>
        <ng-container *ngIf="isDirectMailFreeUser == false">
          <ng-container *ngFor="let action of actions">
            <li class="re-actions" *ngIf="action?.link == 'hotLeads'">
              <a class="btn" (click)="moveToHotLead()">
                <img class="img" [src]="action?.icon" [alt]="action?.alt" />
                <span *ngIf="action?.label" class="btntext">
                  {{ action?.label }}
                </span>
              </a>
            </li>
            <li
              class="re-actions"
              *ngIf="action?.link != 'hotLeads' && action?.link != 'whiteboard'"
            >
              <a class="btn" [routerLink]="[action?.link]">
                <img class="img" [src]="action?.icon" [alt]="action?.alt" />
                <span *ngIf="action?.label" class="btntext">
                  {{ action?.label }}
                </span>
              </a>
            </li>
          </ng-container>
        </ng-container>
        <!-- MODULES -->
        <li class="re-profile-drop">
          <div class="dropdown">
            <a id="user-profile" class="dropdown-toggle" data-toggle="dropdown">
              <!-- USER PROFILE IMAGE -->
              <img
                class="img-responsive img-thumbnail img-circle"
                [src]="userImage"
                alt="profile"
                *ngIf="userImage"
              />

              <!-- DEFAULT IMAGE -->
              <img
                src="/assets/images/demo-profile.png"
                class="img-responsive img-thumbnail img-circle"
                alt="profile"
                *ngIf="!userImage"
              />
              <span class="profileText">Profile</span>
            </a>
            <ul class="dropdown-menu dropdown-block" role="menu">
              <div class="re-userinfo-div">
                <div class="re-userinfo-sub">
                  <img
                    class="img-circle"
                    [src]="userImage"
                    alt="profile"
                    *ngIf="userImage"
                  />
                  <img
                    src="/assets/images/demo-profile.png"
                    class="img-circle"
                    alt="profile"
                    *ngIf="!userImage"
                  />
                  <p class="re-txt1">
                    {{ userData?.firstName }} {{ userData?.lastName }}
                  </p>
                  <p class="re-txt2">{{ userData?.email }}</p>
                </div>
              </div>
              <ng-container *ngFor="let module of modules">
                <div class="re-menu-div">
                  <!-- <li>
                    <span class="re-labels">
                      {{ module?.label }}
                    </span>
                  </li> -->
                  <li *ngFor="let menu of module.submenu">
                    <a (click)="closePopOver(menu)">
                      <img [src]="menu.icon" class="img" alt="icons" />
                      {{ menu?.label }}
                    </a>
                  </li>
                </div>
                <div class="re-plan-div" *ngIf="!userData?.isSubUser">
                  <div class="re-plan-txt1">
                    {{ _utilities.currentPlanData?.planName }} Plan •
                    <span>{{
                      _utilities.currentPlanData?.isYearlyPlan
                        ? "Yearly"
                        : "Monthly"
                    }}</span>
                  </div>
                  <div class="re-plan-txt2">
                    {{ 4 }} Members / {{ 23 }} Days left
                  </div>
                  <button type="button" class="re-upgade-btn">
                    <div class="re-txt1">Save {{ "$3000" }}</div>
                    <div class="re-txt2">Switch to Yearly</div>
                    <img
                      src="/assets/images/navbar/rightbar-up-arrow.svg"
                      class="img"
                      alt="up-arrow"
                    />
                  </button>
                </div>
              </ng-container>

              <!-- LOGOUT -->
              <div class="re-logout-div">
                <a class="re-mlink" (click)="logout()"
                  ><img
                    src="/assets/images/navbar/rightbar-logout.svg"
                    class="img"
                    alt="icons"
                  />Logout</a
                >
              </div>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
