//MODULE
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Clipboard } from "@angular/cdk/clipboard";

// SERVICES
import { SharedService } from "../../../shared.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { ContactService } from "src/app/providers/contact/contact.service";

// UTILS
import { FolderModel } from "src/app/utils/models/folder";
import { ResponseModel } from "src/app/utils/models/response";
import { ErrorModel } from "src/app/utils/models/error";
import { MessageConstant } from "src/app/utils/message-constant";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-add-edit-folder-files-modal",
  templateUrl: "./add-edit-folder-files-modal.component.html",
  styleUrls: ["./add-edit-folder-files-modal.component.scss"],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class AddEditFolderFilesModalComponent implements OnInit {
  @ViewChild("input") input: ElementRef;

  addEditForm: FormGroup;
  header: string = "";
  name: string = "";
  currentValue: string = "";
  messageConstant = MessageConstant;
  submitted: boolean = false;
  // isClipboardActive: boolean = false;
  splitName = [];
  responseData: any = "";
  baseUrl: string = environment.appUrl;
  accessLevel: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddEditFolderFilesModalComponent>,
    private sharedService: SharedService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clipboard: Clipboard,
    public utilities: CommonFunctionsService,
    private _contactService: ContactService
  ) {}

  ngOnInit(): void {
    this.addEditForm = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required])],
    });

    const { action, type, data } = this.data;
    if (action === "add") {
      this.header = "Add Folder";
      this.addEditForm
        .get("name")
        .setValidators([
          Validators.required,
          Validators.pattern("^[-a-zA-Z0-9 ]+$"),
        ]);
    } else if (action === "edit") {
      if (type === "file") {
        const name = data?.title.split(".");
        let originalName = [];

        if (name.length > 1) {
          originalName = name.splice(0, name.length - 1);
          this.splitName = name.splice(0, name.length + 1);
          this.name = originalName.join(".");
        } else {
          this.name = data?.title;
        }
      } else {
        this.name = data?.folderName;
      }

      this.addEditForm.get("name").setValidators([Validators.required]);

      this.currentValue = this.name;
      this.addEditForm.patchValue({
        name: this.name,
      });
      this.header = `Update ${type} Name`;
    } else if (action === "share") {
      this.header = "Sharing Options";
      this.currentValue = data.isSharing;
      this.accessLevel = data.accessType || 0;
    }

    setTimeout(() => {
      if (this.data?.action !== "share") {
        this.input.nativeElement.focus();
      }
    }, 500);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addEditForm.controls[controlName].hasError(errorName);
  };

  onValueChange(event: any) {
    this.currentValue = event.target.value.trim();
  }

  onEditClose() {
    this.currentValue = "";
    this.dialogRef.close(this.responseData);
  }

  openShareLink() {
    const { type, data } = this.data;
    if (!data?.shareLink) {
      return;
    }

    const link =
      this.baseUrl + "public/files?type=" + type + "&itemId=" + data?.shareLink;

    window.open(link, "_blank");
  }

  copyToClipboard() {
    const { type, data } = this.data;
    if (!data?.shareLink) {
      return;
    }

    const link =
      this.baseUrl + "public/files?type=" + type + "&itemId=" + data?.shareLink;

    this.clipboard.copy(link);
    this._toastrService.success(this.messageConstant.linkCopiedSuccess, "");
  }

  shareLink($event) {
    this.data.data.isSharing = $event.target.checked;
  }

  onSubmit(val?) {
    this.submitted = true;
    if (this.data.type == "folder") {
      switch (this.data.action) {
        case "edit":
          this.updateFolder("edit");
          break;
        case "add":
          this.addFolder();
          break;
        case "share":
          this.updateFolder("share", val);
          break;
      }
    } else {
      switch (this.data.action) {
        case "edit":
          this.updateFile("edit");
          break;
        case "add":
          this.addFolder();
          break;
        case "share":
          this.updateFile("share", val);
          break;
      }
    }
  }

  addFolder() {
    const { name } = this.addEditForm.value;

    if (this.addEditForm.invalid || !name?.trim()) {
      if (!name?.trim()) {
        this.addEditForm.patchValue({ name: "" });
      }

      return;
    }

    this._loaderService.start();
    const { moduleId, subModuleId } = this.data;
    const obj: FolderModel = {
      folderName: name.trim(),
      moduleId: moduleId,
      subModuleId: subModuleId,
    };

    this.sharedService.addFolder(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._toastrService.clear();
          this._toastrService.success(this.messageConstant.folderAddSuccess);
          this.sharedService.refreshActivityLog.next(true);
          this.dialogRef.close(response.data);
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  updateFolder(type, bool?) {
    const { name } = this.addEditForm.value;

    if (type == "edit") {
      if (this.addEditForm.invalid || !name?.trim()) {
        if (!name?.trim()) {
          this.addEditForm.patchValue({ name: "" });
        }

        return;
      }
    }

    this._loaderService.start();
    const { _id } = this.data.data;
    const { subModuleId } = this.data;

    let obj;
    if (type == "edit") {
      obj = {
        folderName: name.trim(),
        subModuleId,
      };
    } else if (type == "share") {
      obj = {
        isSharing: this.currentValue,
        accessType: this.accessLevel || 0,
        subModuleId,
      };
    }
    this.sharedService.updateFolder(obj, _id).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          if (type == "edit") {
            this._toastrService.clear();
            this._toastrService.success(
              this.messageConstant.folderUpdateSuccess
            );
            this.dialogRef.close(response.data);
            this.responseData = response.data;
            this.data.data = response.data;
            if (response.data?.isSharing == true) {
              this.data.data.isSharingFolder = true;
            }
          } else if (type == "share") {
            this.responseData = response.data;
            this.data.data = response.data;
            if (response.data?.isSharing == true) {
              this.data.data.isSharingFolder = true;
              this._toastrService.success(
                this.messageConstant.folderSharedSuccess,
                "",
                {
                  timeOut: 4000,
                }
              );

              this.copyToClipboard();
            } else {
              this._toastrService.clear();
              this._toastrService.success(
                this.messageConstant.folderUnsharedSuccess,
                ""
              );
            }
          }
          this.sharedService.refreshActivityLog.next(true);

          if (bool) {
            this.dialogRef.close(this.responseData);
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  updateFile(type, bool?) {
    const { name } = this.addEditForm.value;

    if (type == "edit") {
      if (this.addEditForm.invalid || !name?.trim()) {
        if (!name?.trim()) {
          this.addEditForm.patchValue({ name: "" });
        }

        return;
      }
    }

    this._loaderService.start();

    const { _id } = this.data.data;
    const { subModuleId } = this.data;

    let obj;
    if (type == "edit") {
      obj = {
        title: name.trim().concat(".", this.splitName[0]),
        subModuleId,
      };
    } else {
      obj = {
        isSharing: this.currentValue,
        subModuleId,
      };
    }
    this.sharedService.updateFile(obj, _id).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          if (type == "edit") {
            this._toastrService.clear();
            this._toastrService.success(
              this.messageConstant.fileUpdatedSuccess
            );
            this.dialogRef.close(response.data);
          } else if (type == "share") {
            this.responseData = response.data;
            this.data.data = response.data;
            if (response.data?.isSharing == true) {
              this._toastrService.success(
                this.messageConstant.fileSharedSuccess
              );

              this.copyToClipboard();
            } else {
              this._toastrService.clear();
              this._toastrService.success(
                this.messageConstant.fileUnsharedSuccess
              );
            }
          }

          this.sharedService.refreshActivityLog.next(true);

          if (bool) {
            this.dialogRef.close(this.responseData);
          }
          this.splitName = [];
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }
}
