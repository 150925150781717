import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseUrl } from "../../utils/base-url-constants";
import { environment } from "src/environments/environment";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  baseUrl = environment.baseUrl;

  constructor(private _commonFunction: CommonFunctionsService) {}

  getAllLeadAddress = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + "getAllLeadAddress";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  setCommentPinned = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + "setCommentPinned";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getContingenciesCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + "getContiCount";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getLeadTaskCount = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardUrl + "getTodayTaskDueCount";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getNewLeadStats = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + "newLeadStats";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getLeadMarkNTaskStatus = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardUrl + "leadMarkNTaskStatus";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCallSMSData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + "getCallSMSData";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCallStats = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + "getCallStats";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getHotLeadData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + "getHotLeadCount";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getLeadSourceData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + "getLeadSourceData";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getConversionTrendData = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardUrl + "getConversionTrendData";
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
