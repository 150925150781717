<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      {{ data?.subModule }}
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="re-typography">
  <div class="container">
    <div class="box re-addrvm-modal">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="labels"
              >{{
                data?.urlType == "dialerVoiceMail" ? "Dialer Voicemail" : "RVM"
              }}
              Title<span class="re-reqired">*</span></label
            >
            <input
              type="text"
              class="form-control"
              placeholder="Give {{
                data?.urlType == 'dialerVoiceMail' ? 'Dialer Voicemail' : 'RVM'
              }} a friendly title"
              [(ngModel)]="rvmTitle"
              (keyup)="checkValue($event)"
              maxLength="50"
            />
            <span class="text-danger re-error-text" [hidden]="!rvmTitleRequied">
              *{{
                data?.urlType == "dialerVoiceMail" ? "Dialer Voicemail" : "RVM"
              }}
              title is required
            </span>
            <span class="text-danger re-error-text" [hidden]="!rvmTitleLenght">
              *{{
                data?.urlType == "dialerVoiceMail" ? "Dialer Voicemail" : "RVM"
              }}
              title cannot be more than 50 characters
            </span>
          </div>
        </div>

        <div class="col-12 col-md-12 col-sm-12">
          <div class="re-greet-option">
            <div class="re-radiolist">
              <label class="re-radiobtn form-check-inline" for="selectexisting">
                <input
                  class="re-checkbox-size"
                  id="selectexisting"
                  type="radio"
                  name="newOrOld"
                  value="select-existing"
                  [(ngModel)]="newOrOld"
                  [checked]="newOrOld == 'select-existing'"
                  (change)="changeVoice()"
                /><span class="re-checkmark"></span>Select from an existing
                greetings</label
              >
            </div>
            <div class="re-radiolist">
              <label class="re-radiobtn form-check-inline" for="uploadnew">
                <input
                  class="re-checkbox-size"
                  id="uploadnew"
                  type="radio"
                  name="newOrOld"
                  value="new-upload"
                  [(ngModel)]="newOrOld"
                  [checked]="newOrOld == 'new-upload'"
                  (change)="changeVoice()"
                /><span class="re-checkmark"></span>Upload a new
                recording</label
              >
            </div>
            <div class="re-radiolist">
              <label class="re-radiobtn form-check-inline" for="recordnew">
                <input
                  class="re-checkbox-size"
                  id="recordnew"
                  type="radio"
                  name="newOrOld"
                  value="new-record"
                  [(ngModel)]="newOrOld"
                  [checked]="newOrOld == 'new-record'"
                  (change)="changeVoice()"
                /><span class="re-checkmark"></span>Record new recording</label
              >
            </div>
          </div>

          <div class="re-upload-file" *ngIf="newOrOld == 'new-upload'">
            <span class="re-upload-left">Upload new recording</span>
            <span class="re-upload-right">
              <a
                class="preview"
                [ngClass]="
                  urlData && urlData?.url ? 'preview' : 'disabled-anchor'
                "
                *ngIf="!audioStart"
                (click)="startAudio()"
                ><i class="fa fa-play" aria-hidden="true"></i>Preview
                Recording</a
              >
            </span>
            <span class="re-upload-right">
              <a class="preview" *ngIf="audioStart" (click)="stopAudio()"
                ><i class="fa fa-stop" aria-hidden="true"></i>Preview
                Recording</a
              >
            </span>
            <div class="uploadiv">
              {{ fileText }}
              <input
                type="file"
                accept="audio/*"
                (change)="onUploadLogo($event)"
              />
              <span class="browse">Browse</span>
            </div>
          </div>
          <div class="re-timezone" *ngIf="newOrOld == 'select-existing'">
            <label for="inputState" class="labels">Select Audio File</label>
            <span class="re-upload-right">
              <a
                class="preview"
                [ngClass]="
                  urlData && urlData?.url ? 'preview' : 'disabled-anchor'
                "
                *ngIf="!audioStart"
                (click)="startAudio()"
                ><i class="fa fa-play" aria-hidden="true"></i>Preview
                Recording</a
              >
            </span>
            <span class="re-upload-right">
              <a class="preview" *ngIf="audioStart" (click)="stopAudio()"
                ><i class="fa fa-stop" aria-hidden="true"></i>Preview
                Recording</a
              >
            </span>
            <select
              id="inputState"
              class="form-control"
              [(ngModel)]="rvmFile"
              (change)="changeVoice()"
            >
              <option selected value="">Select audio file</option>
              <option *ngFor="let au of audioList" value="{{ au._id }}">
                {{ au.originName }}
              </option>
            </select>
          </div>
          <span class="text-danger re-error-text" [hidden]="!audioRequied">
            *Please select or upload audio file
          </span>
        </div>
        <div style="height: 20px; display: block">&nbsp;</div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" style="padding-top: 14px !important">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button
    (click)="submitForm()"
    color="primary"
    mat-raised-button
    cdkFocusInitial
  >
    {{ data?.subModule }}
  </button>
</mat-dialog-actions>
