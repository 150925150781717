import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ListViewComponent } from "../../list-view/list-view.component";

// SERVICES
import { UserService } from "src/app/providers/user/user.service";
import { SharedService } from "../../shared.service";

// UTILS
import { MessageConstant } from "src/app/utils/message-constant";
import { ErrorModel } from "src/app/utils/models/error";
import { ResponseModel } from "src/app/utils/models/response";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";

@Component({
  selector: "app-lead-connect-dialog",
  templateUrl: "./lead-connect-dialog.component.html",
  styleUrls: ["./lead-connect-dialog.component.scss"],
})
export class LeadConnectDialogComponent implements OnInit {
  messageConstant = MessageConstant;
  instantLeadAssignment: any = {
    trigger: {},
    roles: [],
  };
  roles: any[] = [];
  userAssignmentArray: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<ListViewComponent>,
    private _userService: UserService,
    private _sharedService: SharedService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    public _utilities: CommonFunctionsService
  ) {
    this.getRoles();
  }

  ngOnInit(): void {}

  getRoles() {
    this._loaderService.start();
    this._userService.getRoles({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._loaderService.stop();
          this.roles = response.data;
          this.initialize();
        }
      },
      (error) => {
        this._loaderService.stop();
        this.initialize();
      }
    );
  }

  initialize() {
    this.userAssignmentArray = Object.keys(this.data.data.userAssignment);
    this.instantLeadAssignment.trigger = {
      integration:
        this.data.data?.instantLeadAssignment?.trigger?.integration || false,
    };
    if (this.data.data?.instantLeadAssignment?.roles?.length > 0) {
      this.instantLeadAssignment.roles =
        this.data.data?.instantLeadAssignment?.roles;
    } else {
      this.userAssignmentArray.map((data) => {
        let pusheditems = {};
        pusheditems[data] = "2";
        this.instantLeadAssignment.roles.push(pusheditems);
      });
    }
  }

  getRoleName(role) {
    const currentRole = this.roles.find((x) => x.key === role);
    if (currentRole) return currentRole?.name;
    return role;
  }

  onToggle(data) {
    this.instantLeadAssignment.trigger[data] =
      !this.instantLeadAssignment.trigger[data];
  }

  changeRoles(instantLeadAssignment, role, value, index) {
    let pusheditems = {};
    pusheditems[role] = value;
    this.instantLeadAssignment.roles[index][role] = value;
  }
  onDone() {
    let obj = {
      instantLeadAssignment: this.instantLeadAssignment,
      isInstantLeadConnect: !this.instantLeadAssignment.trigger["integration"]
        ? false
        : true,
      numberId: this.data.data._id,
    };
    this._loaderService.start();
    this._sharedService.updateLeadConnect(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode === 200) {
          this.instantLeadAssignment = response.data.instantLeadAssignment;
          this.dialog.close({
            data: response.data,
            index: this.data.index,
            type: "success",
          });
          this._toastrService.success(
            this.messageConstant.numberUpdatedSuccess,
            ""
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  onClose() {
    this.dialog.close({
      index: this.data.index,
      type: "cancel",
      _id: this.data.data._id,
    });
  }
}
