import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

// SERVICES
import { LeadsService } from "src/app/providers/leads/leads.service";
import { TeamsService } from "src/app/providers/teams/teams.service";
import { UserService } from "src/app/providers/user/user.service";
import { CommonFunctionsService } from "../../../../utils/common-functions/common-functions.service";
import { SharedService } from "../../../../shared/shared.service";
import { CreditService } from "src/app/providers/credit/credit.service";

// COMPONENTS
import { ReassignLeadsComponent } from "./reassign-leads/reassign-leads.component";
import { DeleteDialogComponent } from "../../../dialog/delete-dialog/delete-dialog.component";
import { ConfirmationDialogComponent } from "../../confirmation-dialog/confirmation-dialog.component";

// UTILS
import { ErrorModel } from "src/app/utils/models/error";
import { MessageConstant } from "../../../../utils/message-constant";
import { ResponseModel } from "../../../../utils/models/response";
import { StatusConstant } from "src/app/utils/status-constant";

@Component({
  selector: "app-teams",
  templateUrl: "./teams.component.html",
  styleUrls: ["./teams.component.scss"],
})
export class TeamsComponent implements OnInit, AfterViewInit {
  @Input() details: any;
  @Input() campaign: any;

  teamsForm: FormGroup;
  messageConstant = MessageConstant;
  moduleId: string = StatusConstant?.ModuleId?.LEAD;

  checkboxBtnArray: any[] = [];
  teams: any[] = [];
  roles: any[] = [];
  allUserRoles: any[] = [];
  users: any[] = [];
  collapsedSections: any[] = [];
  rolesModifiedArray: any[] = [];
  checkedAssigned: any[] = [];
  userList: any[] = [];
  mainStatusList: any[] = [];

  dialogRef: any;
  generalTeam: any;
  leadAccessManagement: any;

  submitted: boolean = false;
  leadMessageShow: boolean = false;

  tagType: string = StatusConstant.TagsType.BUYERS;
  planName: string = "";

  userRoles: any = {};

  currentPage: number = 1;
  currentLimit: number = 100;
  currentRoleCount: number = 0;

  constructor(
    private _leadsService: LeadsService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _teamServices: TeamsService,
    private _userService: UserService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public _utilities: CommonFunctionsService,
    private _sharedService: SharedService,
    private _creditService: CreditService
  ) {
    this.teamsForm = this.fb.group({
      assignmentMethod: ["1"],
    });
  }

  ngOnInit(): void {
    this.getRoles();
    this.getUserPlan();
  }

  ngAfterViewInit(): void {
    this.teamsForm.patchValue({
      assignmentMethod: this.campaign?.assignmentMethod?.toString(),
    });
  }

  getRoles() {
    this._userService.getRoles("").subscribe((response: ResponseModel) => {
      if (response.statusCode === 200) {
        this.roles = this._utilities.sortItems(response.data, "name");
        this.getUserTeamsList();
        this.getUserList();
        this.getTeams();
        this.getMainStatus();
      }
    });
  }

  getUserPlan() {
    this._loaderService.start();
    this._creditService.getUserPlan({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          this.planName = response?.data?.planName?.toLowerCase();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  getUserTeamsList() {
    const obj = {
      page: this.currentPage,
      limit: this.currentLimit,
    };

    this._loaderService.start();
    this._teamServices.getTeams(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.teams = response.data?.items?.filter(
            (x) => x.isEnabled === true
          );
          this.generalTeam = this.teams.find((x) => x.title === "Primary Team");
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  getUserList() {
    let param = {
      page: this.currentPage,
      limit: this.currentLimit,
    };

    this._loaderService.start();
    this._userService.getSubUserList(param).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.userList = response?.data?.items;
        } else {
          this._toastrService.error(response?.message, "Failure");
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "Failure");
        } else {
          this._toastrService.error(
            this.messageConstant.unknownError,
            "Failure"
          );
        }
      }
    );
  }

  getTeams() {
    this._leadsService
      .campaignTeam({
        campaignId: this.campaign._id,
        teamId: this.campaign.teamId,
      })
      .subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            let resultGroupBy = this.groupItemBy(response.data, "roleId");
            let roles = [];
            for (const [key, value] of Object.entries(resultGroupBy)) {
              roles.push({
                roleName: key,
                displayName: this.getRoleName(key),
                row: this.groupItemBy(value, "userId"),
              });
            }
            this.rolesModifiedArray = this._utilities.sortItems(
              roles,
              "displayName"
            );
            this.currentRoleCount = this.rolesModifiedArray.length;
            this._loaderService.stop();
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
  }

  /**
   * FUNCTION TO GROUP BY ARRAY ITEMS
   * @param {Array} array
   * @param {Object} property
   * @returns Object
   */
  groupItemBy(array, property) {
    var hash = {},
      props = property.split(".");
    for (var i = 0; i < array.length; i++) {
      var key = props.reduce(function (acc, prop) {
        return acc && acc[prop];
      }, array[i]);
      if (!hash[key]) hash[key] = [];
      hash[key].push(array[i]);
    }
    return hash;
  }

  getRoleName(roleId) {
    const role = this.roles.find((o) => o._id == roleId);
    if (role) return role.name;
    return "";
  }

  getUserName(userId) {
    if (userId == "null") {
      return "N/A";
    }

    let user = this.userList.find((o) => o._id == userId);
    if (user) {
      return user?.name;
    } else {
      return "N/A";
    }
  }

  countAssignedLeads(data) {
    return data?.reduce((acc, item) => {
      acc += item.count || 0;
      return acc;
    }, 0);
  }

  getMainStatusTitle(mainStatusId) {
    let titleName = "";
    this.mainStatusList.map((item) => {
      if (item?._id == mainStatusId) {
        titleName = item?.title;
      }
    });
    return titleName;
  }

  public get f() {
    return this.teamsForm.controls;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.teamsForm.controls[controlName].hasError(errorName);
  };

  toggleSection(groupIndex) {
    const index = this.collapsedSections.findIndex((x) => x === groupIndex);
    if (index > -1) {
      this.collapsedSections.splice(index, 1);
    } else {
      this.rolesModifiedArray.forEach((ele) => {
        const index = this.collapsedSections.findIndex(
          (x) => x === ele.roleName
        );
        this.collapsedSections.splice(index, 1);
      });
      this.collapsedSections.push(groupIndex);
    }
  }

  isVisible(groupIndex) {
    const index = this.collapsedSections.findIndex((x) => x === groupIndex);
    return index > -1 ? false : true;
  }

  isReassignAvailable(userId, uniqueId) {
    let result = false;
    let details = this.roles.filter(
      (o) => o._id.toString() == uniqueId?.split("_")[0].toString()
    );

    let finalArray = details.map((x) => {
      let userArray = [];
      this.details.map((y) => {
        if (x._id.toString() == y.roleId.toString()) {
          return userArray.push(y?.userId);
        }
      });

      return userArray;
    })[0];

    if (finalArray?.length > 0) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  closeMessage() {
    this.leadMessageShow = false;
  }

  filterCheckedAssigned(uniqueId) {
    this.checkedAssigned = this.checkedAssigned.filter((e) => {
      if (!e.value.includes(uniqueId)) {
        return e;
      }
    });
  }

  openReAssignPopUp(userId, uniqueId) {
    if (this.teamsForm.get("assignmentMethod").value == "0") {
      this._toastrService.error(this.messageConstant?.assignmentTypeErr);
      return false;
    }

    if (!this.isStatusChecked(uniqueId)) {
      this._toastrService.error(this.messageConstant?.statusOptErr);
      return false;
    }

    // OPTIMIZE THE CODE BLOCK
    let assignedArray = [];
    this.checkedAssigned.filter((e) => {
      if (e.value.includes(uniqueId)) {
        assignedArray.push({
          userId: e.userId,
          mainStatusId: e.mainStatusId,
          count: e.count,
          roleId: e.roleId,
        });
      }
    });

    this.dialogRef = this.dialog.open(ReassignLeadsComponent, {
      height: "auto",
      width: "400px",
      disableClose: true,
      data: {
        assignmentMethod: this.teamsForm.get("assignmentMethod").value,
        details:
          this.details[
            this.roles.find((o) => o._id == uniqueId.split("_")[0]).key
          ],
        userId: userId,
        checkedAssignedStatus: assignedArray,
        userList: this.userList,
        roles: this.roles,
        actionType: userId && userId != "null" ? 1 : 2,
        campaign: this.campaign,
      },
    });

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this._toastrService.success(this.messageConstant.assigned);
        this.filterCheckedAssigned(uniqueId);
        this.leadMessageShow = true;
        this.getTeams();
      }
    });
  }

  openAssignTeamMemberPopUp(userId, uniqueId) {
    if (this.teamsForm.get("assignmentMethod").value == "0") {
      this._toastrService.error(this.messageConstant?.assignmentTypeErr);
      return;
    }

    if (!this.isStatusChecked(uniqueId)) {
      this._toastrService.error(this.messageConstant?.statusOptErr);
      return;
    }

    let assignedArray = [];
    this.checkedAssigned.filter((e) => {
      if (e.value.includes(uniqueId)) {
        assignedArray.push({
          userId: e.userId,
          mainStatusId: e.mainStatusId,
          count: e.count,
          roleId: e.roleId,
        });
      }
    });

    this.dialogRef = this.dialog.open(ReassignLeadsComponent, {
      height: "auto",
      width: "400px",
      disableClose: true,
      data: {
        assignmentMethod: this.teamsForm.get("assignmentMethod").value,
        details:
          this.details[
            this.roles.find((o) => o._id == uniqueId.split("_")[0]).key
          ],
        userId: userId,
        checkedAssignedStatus: assignedArray,
        userList: this.userList,
        roles: this.roles,
        actionType: 3,
        campaign: this.campaign,
      },
    });

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this._toastrService.success(this.messageConstant.assigned);
        this.filterCheckedAssigned(uniqueId);
        this.getTeams();
      }
    });
  }

  openUnAssignPopUp(userId, uniqueId) {
    if (this.teamsForm.get("assignmentMethod").value == "0") {
      this._toastrService.error(this.messageConstant?.assignmentTypeErr);
      return false;
    }

    if (!this.isStatusChecked(uniqueId)) {
      this._toastrService.error(this.messageConstant?.statusOptErr);
      return false;
    }

    let assignedArray = [];
    this.checkedAssigned.filter((e) => {
      if (e.value.includes(uniqueId)) {
        assignedArray.push({
          userId: e.userId,
          mainStatusId: e.mainStatusId,
          count: e.count,
          roleId: e.roleId,
        });
      }
    });

    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        subModule: "leadUnAssign",
        assignmentMethod: this.teamsForm.get("assignmentMethod").value,
        details:
          this.details[
            this.roles.find((o) => o._id == uniqueId.split("_")[0]).key
          ],
        userId: userId,
        checkedAssignedStatus: assignedArray,
        userList: this.userList,
        roles: this.roles,
        actionType: userId && userId != "null" ? 1 : 2,
        campaign: this.campaign,
        roleId: uniqueId.split("_")[0],
        mainStatusId: assignedArray.map((item) => item.mainStatusId),
        customMessage: `Are you sure you want to Unassign ${
          assignedArray.map((item) => item.mainStatusId).length
        } Status and ${this.countAssignedLeads(
          assignedArray
        )} Leads from ${this.getUserName(
          userId
        )}? All the leads will be unassigned and tasks will get deleted.`,
      },
      width: "550px",
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.leadUnAssign(uniqueId, assignedArray, userId);
      }
    });
  }

  leadUnAssign(uniqueId, assignedArray, userId) {
    let Obj = {
      marketingTitle: this.campaign.marketingTitle,
      number: this.campaign.number,
      leadSourceId: this.campaign.leadSourceId,
      flowId: this.campaign.flow._id,
      assignmentMethod: this.teamsForm.get("assignmentMethod").value,
      leadAccessManagement: [
        {
          roleId: uniqueId.split("_")[0],
          mainStatusId: assignedArray.map((item) => item.mainStatusId),
          currentUserId: userId,
          newUserId: userId,
          actionType: 2,
        },
      ],
    };

    this._loaderService.start();
    this._sharedService.updateAssignment(Obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.filterCheckedAssigned(uniqueId);
          this.leadMessageShow = true;
          this.getTeams();
          this._toastrService.success(this.messageConstant.unAssigned);
        }
        this._loaderService.stop();
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  handleCheck(userId, mainStatusId, count, roleId, event, userIndex) {
    if (event.target.checked) {
      this.checkedAssigned.push({
        userId,
        mainStatusId,
        count,
        roleId,
        value: event.target.value,
      });
      this.checkboxBtnArray.push(userId + "_" + roleId + "_" + userIndex);
    } else {
      this.removeItemOnce(
        this.checkboxBtnArray,
        userId + "_" + roleId + "_" + userIndex
      );
      this.chekboxBtn(userId + "_" + roleId + "_" + userIndex);
      this.checkedAssigned = this.checkedAssigned.filter((e) => {
        return e.value != event.target.value;
      });
    }
  }

  removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  chekboxBtn(item) {
    if (this.checkboxBtnArray.indexOf(item) !== -1) {
      return false;
    } else {
      return true;
    }
  }

  isStatusChecked(uniqueId) {
    if (this.checkedAssigned.length) {
      let found = 0;
      this.checkedAssigned.filter((e) => {
        if (e.value.includes(uniqueId)) found = 1;
      });
      return found ? true : false;
    }
    return false;
  }

  getRoleTabData(row) {
    const user = Object.keys(row).filter((item) => item != "null").length;
    const userUnassigned = Object.keys(row).filter(
      (item) => item == "null"
    ).length;

    return {
      user: user,
      assigned: user,
      unassigned: userUnassigned,
    };
  }

  learnMoreShow() {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: {
        header: "Learn More",
        hideNoButton: true,
        hideYesButton: true,
        text: ` <div class="re-learnformation">
        <p class="labels"><b>Assignment Type</b></p>
        <p class="labels"><b>Round Robin</b></p>
        <span>
          For example, if you have 3 Acquisition Managers, leads will be
          assigned on Round Robin basis. 1st lead will go to person A, 2nd lead
          to person B, 3rd lead to person C, 4th lead to person A, and 5th lead
          to person B, etc.
        </span>

        <p class="labels"><b>First to Claim</b></p>
        <span>
          If you have multiple people on the same role, anytime a new lead is
          created, an email will be sent to all people in that role to login and
          claim the lead. If there is only one person in a role, the lead will
          automatically be assigned to that person. First to Claim is only
          applicable when there are 2 or more people in the same role.
        </span>

        <p class="labels"><b>Manual Assignment</b></p>
        <span class="re-bottom">
          The leads will remain unassigned until someone who is already assigned
          to that lead manually goes in and assigns the lead for the unassigned
          role. If there is only one person in a role, the lead will
          automatically be assigned to that person. Manual Assignment is only
          applicable when there are 2 or more people in the same role.
        </span>
      </div>`,
        // learnText: 'learnmore',
      },
    });
  }

  changeAssignmentMethod(e) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "360px",
      data: {
        yesTitle: "Confirm",
        noTitle: "Cancel",
        header: "Confirmation",
        text: `<p>
        Are you sure you want to change the assignment type to
        <b>"${
          e?.target?.value == "1"
            ? "Round Robin"
            : e?.target?.value == "2"
            ? "First to Claim"
            : "Manual Assignment"
        }"</b>?<br />
        This assignment type will affect the new leads only
      </p>`,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const payload = {
          marketingTitle: this.campaign.marketingTitle,
          number: this.campaign.number,
          leadSourceId: this.campaign.leadSourceId,
          flowId: this.campaign.flow._id,
          assignmentMethod: e.target.value,
        };

        this._loaderService.start();
        this._sharedService.updateAssignment(payload).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode === 200) {
              this.campaign.assignmentMethod = e.target.value;
              this._sharedService.refreshInfo.next(true);
              this._loaderService.stop();
              this._toastrService.success(this.messageConstant.assigned);
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, "");
            } else {
              this._toastrService.error(this.messageConstant.unknownError, "");
            }
          }
        );
      } else {
        this.teamsForm.patchValue({
          assignmentMethod: this.campaign.assignmentMethod.toString(),
        });
      }
    });
  }

  checkCount(value) {
    try {
      let bool = true;
      value?.filter((x) => {
        if (!x.count) bool = false;
      });
      return bool;
    } catch (error) {
      return false;
    }
  }

  checkForUpgrade(e) {
    this.changeAssignmentMethod(e);
  }

  onSubmit() {}

  getMainStatus() {
    let obj: any = {
      page: 1,
      limit: 100,
      moduleId: this.moduleId,
    };

    this._sharedService.getMainStatus(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this.mainStatusList = response.data["items"];
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }
}
