//MODULE
import { Component, OnInit } from "@angular/core";

// UTILS
import { SideBarListModel, SubMenu } from "src/app/utils/models/sidebar";
import { StatusConstant } from "src/app/utils/status-constant";

// SERVICES
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";

declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  sideMenu: SideBarListModel[] = [];
  isDirectMailFreeUser: boolean = false;
  isCall: Boolean = false;
  isMail: Boolean = false;
  isAssistance: Boolean = false;
  showHelpDesk: Boolean = false;

  constructor(public _utilities: CommonFunctionsService) {}

  ngOnInit(): void {
    this.sideMenu.push(
      // KPIS
      {
        icon: "/assets/images/navbar/side-icon-1.svg",
        label: "KPIs",
        route: "/kpis",
      },

      // MARKETING
      {
        icon: "/assets/images/navbar/side-icon-2.svg",
        label: "Marketing",
      },

      // LEADS
      {
        icon: "/assets/images/navbar/side-icon-3.svg",
        label: "Lead",
        route: "/leads",
        queryParams: { contactType: "seller" },
        subMenu: [
          {
            icon: "/assets/images/navbar/warm-leads.svg",
            label: "Seller Leads",
            route: "/leads",
            queryParams: { contactType: "seller" },
          },
          {
            icon: "/assets/images/navbar/leads.svg",
            label: "Buyer Leads",
            route: "/leads",
            queryParams: { contactType: "buyer" },
          },
          {
            icon: "/assets/images/navbar/leads.svg",
            label: "Follow Up Leads",
            route: "/follow-up",
          },
          {
            icon: "/assets/images/navbar/dead-leads.svg",
            label: "Dead Leads",
            route: "/dead-leads",
          },
        ],
      },

      // TRANSACTIONS
      {
        icon: "/assets/images/navbar/side-icon-4.svg",
        label: "Transactions",
        route: "transactions",
        queryParams: { contactType: "seller" },
        subMenu: [
          {
            icon: "/assets/images/navbar/warm-leads.svg",
            label: "Sell Side Transactions",
            route: "/transactions",
            queryParams: { contactType: "seller" },
          },
          {
            icon: "/assets/images/navbar/leads.svg",
            label: "Buy Side Transactions",
            route: "/transactions",
            queryParams: { contactType: "buyer" },
          },
        ],
      },

      // CLOSE LEAD
      {
        icon: "/assets/images/navbar/side-icon-5.svg",
        label: "Close Lead",
        route: "close-lead",
      },

      // ACTIVITY
      {
        icon: "/assets/images/navbar/side-icon-6.svg",
        label: "Inbox Details",
        route: "/contacts",
      },

      // CALL LOG
      {
        icon: "/assets/images/navbar/side-icon-7.svg",
        label: "Activity",
        // route: "/logs/call",
        subMenu: [
          {
            icon: "/assets/images/inbox.svg",
            label: "Inbox",
            route: "/inbox-details",
          },
          {
            icon: "/assets/images/navbar/call-logs.svg",
            label: "Call Logs",
            route: "/logs/call",
          },
          {
            icon: "/assets/images/navbar/schedule-sms.svg",
            label: "Scheduled SMS",
            route: "/my-scheduled-sms",
          },
          // {
          //   icon: "/assets/images/navbar/tasks.svg",
          //   label: "Tasks",
          //   route: "/my-tasks",
          // },
        ],
      },

      // CONTACTS
      {
        icon: "/assets/images/navbar/side-icon-8.svg",
        label: "Contacts",
        route: "/settings",
      },

      // GENERAL
      {
        icon: "/assets/images/navbar/side-icon-9.svg",
        label: "General",
      }
    );

    $(function () {
      $(".re-agent-sidebar-nav .droplist").on(
        "mouseenter mouseleave",
        function (this: any) {
          if ($(".dropdown-menu", this).length) {
            var elm = $(".dropdown-menu", this);
            var off = elm.offset();
            var l = off.top;
            var w = elm.width();
            var docH = $(".re-agent-sidebar-wrapper").height();
            var docW = $(".re-agent-sidebar-wrapper").width();

            var isEntirelyVisible = l + w <= docH;

            if (!isEntirelyVisible) {
              $(this).addClass("is-about");
            } else {
              $(this).removeClass("is-about");
            }
          }
        }
      );
    });
  }

  closePopOver() {
    $(".re-popover_close").parents(".popover").popover("hide");
    $("body .infinite-scroll").removeClass("re-scroll-hide");
    $("body .re-card").removeClass("re-open-card");
  }

  checkActive(subMenu: any) {
    const { pathname } = window.location;

    if (pathname.includes("detail")) {
      if (pathname.includes(subMenu.route) && !subMenu.queryParams) return true;
    }

    return false;
  }

  onChatClick() {
    this.showHelpDesk = !this.showHelpDesk;
  }

  onAction(action: string) {
    if (action == "call") {
      this.isCall = true;
    } else if (action == "mail") {
      this.isMail = true;
    } else if (action == "back") {
      this.isMail = false;
      this.isCall = false;
    } else if (action == "chat") {
      this.showHelpDesk = false;
    } else if (action == "faq") {
      window.open(StatusConstant?.agentUrl, "_blank");
      this.showHelpDesk = false;
    } else if (action == "affiliate") {
      window.open(StatusConstant?.agentUrl, "_blank");
      this.showHelpDesk = false;
    } else if (action == "feature") {
      window.open(StatusConstant?.sideBarGoogleForm, "_blank");
      this.showHelpDesk = false;
    } else if (action == "assistance") {
      this.isAssistance = !this.isAssistance;
    } else if (action == "videoTrainingLibrary") {
      window.open("https://course.resimpli.com/", "_blank");
      this.showHelpDesk = false;
    }
  }

  onActionBook(action: string) {
    window.open(
      "https://calendly.com/" +
        action +
        "/60min?month=" +
        new Date().getFullYear().toString() +
        "-" +
        new Date().getMonth().toString(),
      "_blank"
    );
    this.showHelpDesk = false;
    this.isAssistance = false;
  }
}
