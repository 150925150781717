export class AppointmentTypeConstant {
  public static AppointmentType = [
    "Seller Appointment",
    "Buyer Showings",
    "Contractor Walkthrough",
    "Other",
  ];

  public static AppointmentSubType = [
    { title: "In-Person", path: "in-person-appt-icon.png" },
    { title: "Phone", path: "phone-appt-icon.png" },
    { title: "Drive By", path: "drive-by-appt-icon.png" },
  ];
}
