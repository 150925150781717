import {
  Component,
  Inject,
  Input,
  OnInit,
  ElementRef,
  ViewChildren,
  QueryList,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { MultiSelect } from "primeng/multiselect";
import jwt_decode from "jwt-decode";

// UTILS
import { FilterListModel } from "src/app/utils/models/filter";
import { ResponseModel } from "src/app/utils/models/response";
import { ErrorModel } from "src/app/utils/models/error";
import { MessageConstant } from "src/app/utils/message-constant";
import { environment } from "src/environments/environment";
import { MiscellaneousConstant } from "src/app/utils/miscellaneous-constant";
import { ListResponseModel } from "src/app/utils/models/response";
import { ListErrorModel } from "src/app/utils/models/error";
import { StatusConstant } from "src/app/utils/status-constant";
import { FilterListConstant } from "src/app/utils/filter-constant/filter-list";
import { QuickFilterConstant } from "src/app/utils/filter-constant/quick-filter";

// SERVICES
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TaggingService } from "src/app/providers/tagging/tagging.service";
import { ToastrService } from "ngx-toastr";
import { TeamsService } from "src/app/providers/teams/teams.service";
import { UserService } from "src/app/providers/user/user.service";
import { DripService } from "src/app/providers/drip/drip.service";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { SharedService } from "src/app/shared//shared.service";
import { LeadsService } from "src/app/providers/leads/leads.service";

// COMPONENTS
import { QuickFilterService } from "src/app/providers/quickFilter/quick-filter.service";

@Component({
  selector: "app-edit-manage-list-dialog",
  templateUrl: "./edit-manage-list-dialog.component.html",
  styleUrls: ["./edit-manage-list-dialog.component.scss"],
})
export class EditManageListDialogComponent implements OnInit {
  @ViewChildren("chipInput") chipInput: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChild("search") searchElement: ElementRef;
  @ViewChild("addFilter") addFilter: ElementRef;
  @ViewChild(MultiSelect) select: MultiSelect;

  // type: string = "Edit List";
  editManageListForm: FormGroup;
  submitted: boolean = false;
  public messageConstant = MessageConstant;
  reminderErrors: any[] = [];
  appliedFilteredList: FilterListModel[] = [];
  leadFilters: any = QuickFilterConstant?.QuickFilter;
  selectedRecord: any = {};

  dialogRef: any;
  cityControl = new FormControl();
  filteredCities: Observable<any[]>;
  zipControl = new FormControl();
  filteredZip: Observable<any[]>;
  tagControl = new FormControl();
  filteredTags: Observable<any[]>;
  title = new FormControl();
  description = new FormControl();

  filterHeader: any = FilterListConstant?.filterList;
  teams: any = [];
  roles: any = [];
  users: any = [];
  drip: any = [];
  templates: any[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  availableFilters: FilterListModel[] = [];
  suggestionList: any[] = [];
  allCitiesList: any[] = [];
  allZipList: any[] = [];
  allTagList: any = [];
  unavailableColorIndex: any[] = [];
  unavailableColorIndexResponse: any[] = [];
  lists: any[] = [];
  filterGroup: any[] = [];
  leadSources: any[] = [];
  campaignList: any[] = [];

  currentPage: number = 1;
  currentLimit: number = environment.pagination.pageLimit;
  selectedTemplateIndex: number = -1;

  searchTemplate: string = "";
  searchFilter: string = "";
  noDataAvailable: string = "Data Not Available";
  leadModuleId: string = StatusConstant?.ModuleId?.LEAD;

  selectable: boolean = true;
  removable: boolean = true;

  colorCodes = MiscellaneousConstant.colorCodes;
  loginUserId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<EditManageListDialogComponent>,
    private formBuilder: FormBuilder,
    private _dialog: MatDialog,
    private _loaderService: NgxUiLoaderService,
    private _taggingService: TaggingService,
    private _toastrService: ToastrService,
    private _teamsService: TeamsService,
    private _userService: UserService,
    private _dripService: DripService,
    private _commonFunctionsService: CommonFunctionsService,
    private _sharedServices: SharedService,
    private _leadsService: LeadsService,
    private _quickFilterService: QuickFilterService
  ) {
    const decoded: any = jwt_decode(localStorage.getItem("token"));
    this.loginUserId = decoded.userId;
  }

  ngOnInit(): void {
    this.selectTemplate(this.data.details);

    this.editManageListForm = this.formBuilder.group({
      title: ["", Validators.compose([Validators.required])],
      description: [""],
    });

    this.editManageListForm.patchValue({
      title: this.data.details.title,
      description: this.data.details.description,
    });

    let index;
    for (let i = 0; i < this.leadFilters.length; i++) {
      this.leadFilters[i]["show"] = 1;
      index = this.filterGroup.findIndex(
        (item) => item.name === this.leadFilters[i]?.filterGroup
      );
      if (this.leadFilters[i]?.filterGroup && index < 0)
        this.filterGroup.push({
          name: this.leadFilters[i]?.filterGroup,
          show: 1,
        });
    }
    this.availableFilters = Object.assign([], this.leadFilters);
  }

  save() {
    this.submitted = true;
    if (this.editManageListForm.invalid) {
      return;
    }
    if (this.appliedFilteredList.length == 0) {
      this._toastrService.error(this.messageConstant.filterOptErr, "");
      return;
    }
    this.calculateFilter(this.appliedFilteredList);
    let { isValid }: { filter: any; isValid: boolean } = this.calculateFilter(
      this.appliedFilteredList
    );
    if (isValid && this.data.type == "Edit") {
      let obj = {
        quickFilterId: this.data?.details?._id,
        position: this.data?.details?.position,
        title: this.editManageListForm.value?.title,
        description: this.editManageListForm.value?.description,
        filterData: JSON.stringify(this.appliedFilteredList),
      };
      this._loaderService.start();
      this._quickFilterService.update(obj).subscribe(
        (response: ResponseModel) => {
          if (response?.statusCode == 200) {
            this._loaderService.stop();
            this._toastrService.success(
              this.messageConstant.quickFilterUpdatedSuccessfully,
              ""
            );
            this.dialog.close(response);
          }
        },
        (err: ErrorModel) => {
          if (err.error) {
            this._loaderService.stop();
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
    } else if (isValid && this.data.type == "Add") {
      let obj = {
        title: this.editManageListForm.value?.title,
        position: this.data?.details.length,
        filterData: JSON.stringify(this.appliedFilteredList),
        moduleId: StatusConstant.ModuleId?.LEAD,
        description: this.editManageListForm.value?.description,
      };
      this._loaderService.start();
      this._quickFilterService.save(obj).subscribe(
        (response: ResponseModel) => {
          if (response?.statusCode == 200) {
            this._loaderService.stop();
            this._toastrService.success(
              this.messageConstant.quickFilterSaveSuccessfully,
              ""
            );
            this.dialog.close(response);
          }
        },
        (err: ErrorModel) => {
          if (err.error) {
            this._loaderService.stop();
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.editManageListForm.controls[controlName].hasError(errorName);
  };

  // addFilters(): void {}
  initialize() {
    if (!Object.keys(this.data?.appliedFilters).length) {
      setTimeout(() => {
        this.addFilter?.nativeElement?.click();
      }, 50);
    }

    this.selectedTemplateIndex = -1;
    this.resetFilterSearch();
    this.parseFilter();
  }

  async selectFilter(filter: any) {
    if (this.isDisabled(filter)) return;
    if (this.isActive(filter)) return;

    this.appliedFilteredList.push(await this.commonFunctionCalls(filter));
    let appliedFilteredListOrder = this.appliedFilteredList.map(
      (item, index) => {
        return { ...item, order: index };
      }
    );
    this.appliedFilteredList = appliedFilteredListOrder;
    this.updateOptions();
    this.resetFilterSearch();
  }

  async commonFunctionCalls(filter: any) {
    let filterCopy = Object.assign({}, filter);
    switch (filter.value) {
      case "tags":
        filterCopy.options = await this.getTags();
        this.getUsedColorIndex();
        break;
      case "teams":
        filterCopy.options = await this.getTeams();
        break;
      case "roles":
        filterCopy.options = await this.getRoles();
        break;
      case "users":
        filterCopy.options = await this.getUser();
        break;
      case "drips":
        filterCopy.options = await this.getDrips();
        break;
      // case "hotLead":
      //   filterCopy.options = [
      //     { label: "Select an option", value: "" },
      //     { label: "True", value: true },
      //     { label: "False", value: false },
      //   ];
      //   filterCopy.selectedOption = "";
      //   break;
      case "assignment":
        filterCopy.options = await this.getUser();
        break;
      // case "missingInfo":
      //   filterCopy.options = QuickFilterConstant.MissingInfo;
      //   break;
      case "leadSource":
        filterCopy.options = await this.getLeadSourceList();
        break;
      case "campaign":
        filterCopy.options = await this.getCampaignList();
        break;
      default:
        break;
    }

    return filterCopy;
  }

  resetFilterSearch() {
    this.searchFilter = "";
    this.leadFilters.map((e) => {
      e.show = 1;
    });
    this.leadFilters.map((e) => {
      e.show = 1;
    });
  }

  getTags() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();

      this._taggingService.getTags(this.data?.tagType).subscribe(
        (response) => {
          this._loaderService.stop();
          if (response && response.statusCode == 200) {
            let arr = response.data;
            const result = arr.reduce((acc, d) => {
              if (d.label) {
                const value = { _id: d._id, label: d.label, value: d._id };
                acc.push(value);
              }
              return acc;
            }, []);
            this.allTagList = result;
            resolve(result);
          }
        },
        (err: ErrorModel) => {
          reject([]);
          this._loaderService.stop();
        }
      );
    });
  }

  getTeams() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();

      const obj = {
        page: 1,
        limit: 1000,
      };
      this._teamsService.getTeams(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            let team = response.data?.items;
            const result = team.reduce((acc, d) => {
              if (d.title) {
                const value = { _id: d._id, label: d.title, value: d._id };
                acc.push(value);
              }
              return acc;
            }, []);
            this.teams = result;
            resolve(this.teams);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          reject([]);
          this._toastrService.error(MessageConstant.unknownError, "");
        }
      );
    });
  }

  getRoles() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();

      this._userService.getRoles("").subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            let role = response.data;
            const result = role.reduce((acc, d) => {
              if (d.name) {
                const value = { _id: d._id, label: d.name, value: d._id };
                acc.push(value);
              }
              return acc;
            }, []);
            this.roles = result;
            resolve(this.roles);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          reject([]);
          this._toastrService.error(MessageConstant.unknownError, "");
        }
      );
    });
  }

  getUser() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();
      let param = {};
      this._userService.getUsers(param).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            let user = response.data;
            const result = user.reduce((acc, d) => {
              if (d._id) {
                const value = {
                  _id: d._id,
                  label: d.firstName + " " + d.lastName,
                  value: d._id,
                };
                acc.push(value);
              }
              return acc;
            }, []);
            this.users = result;
            resolve(this.users);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          reject([]);
          this._toastrService.error(MessageConstant.unknownError, "");
        }
      );
    });
  }

  getDrips() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();
      let obj = {
        page: 1,
        limit: 10000000,
      };
      this._dripService.listDrip(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            let drip = response.data?.items;
            const result = drip.reduce((acc, d) => {
              if (d.name) {
                const value = { _id: d._id, label: d.name, value: d._id };
                acc.push(value);
              }
              return acc;
            }, []);
            this.drip = result;
            resolve(this.drip);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          reject([]);
          this._toastrService.error(MessageConstant.unknownError, "");
        }
      );
    });
  }

  removeFilter($event, index: number) {
    $event.stopPropagation();
    $event.preventDefault();
    let selectVal = this.appliedFilteredList[index].value;

    this.appliedFilteredList.splice(index, 1);
    if (selectVal == "repeatingMailingAddress") {
      this.updateOptions();
    }
  }

  setMaxDate(filter) {
    if (filter?.selectedOperator == "between") {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === filter.value
      );
      if (index > -1) {
        this.appliedFilteredList[index].minSelectDate =
          this.appliedFilteredList[index].minVal;
        if (
          this.appliedFilteredList[index].minVal >
          this.appliedFilteredList[index].maxVal
        ) {
          this.appliedFilteredList[index].maxVal = undefined;
        }
      }
    }
    this.modelChanged(filter);
  }

  calculateFilter(filter) {
    let obj = {};
    let isValid = true;
    filter.map((x) => {
      if (
        x.optionsType != "RANGE" &&
        x.optionsType != "DATE" &&
        (!x.selectedOption || !x.selectedOption?.length)
      ) {
        x.error = true;
        isValid = false;
      } else if (
        x.optionsType === "RANGE" &&
        this.validateMaxMinRange(x?.selectedOperator, x?.minVal, x?.maxVal)
      ) {
        x.rangeErrorMsg = this.validateMaxMinRange(
          x?.selectedOperator,
          x?.minVal,
          x?.maxVal
        );
        x.error = true;
        isValid = false;
      } else if (
        x.optionsType === "DATE" &&
        this.validateDateRange(x?.selectedOperator, x?.minVal, x?.maxVal)
      ) {
        x.rangeErrorMsg = this.validateDateRange(
          x?.selectedOperator,
          x?.minVal,
          x?.maxVal
        );
        x.error = true;
        isValid = false;
      } else {
        x.error = false;
      }
      let value = x.selectedOption;
      if (x.optionsType === "AUTO-COMPLETE") {
        value = x.selectedOption.map((option) => option[x.valueKey]);
      }
      if (x.optionsType === "RANGE") {
        value = [x?.minVal, x?.maxVal];
      }
      if (x.optionsType === "DATE") {
        // value = [
        //   this._commonFunctionsService.dueDateFormat(x?.minVal).dateFormat,
        //   x?.maxVal && x?.selectedOperator == "between"
        //     ? this._commonFunctionsService.dueDateFormat(x?.maxVal).dateFormat
        //     : "",
        // ];
        value = {
          field: x?.selectedOption,
          minVal: this._commonFunctionsService.dueDateFormat(x?.minVal)
            .dateFormat,
          maxVal:
            x?.maxVal && x?.selectedOperator == "between"
              ? this._commonFunctionsService.dueDateFormat(x?.maxVal).dateFormat
              : "",
        };
      }
      let dateValue = null;
      if (this.data?.moduleId == "" && x.optionsType === "DATE") {
        dateValue = [x?.minVal, x?.maxVal];
      }

      obj[x.value] = {
        value,
        operator: x.selectedOperator,
        optionsType: x.optionsType,
        order: x.order,
        dateValue,
      };
      if (x?.selectedCondition)
        obj[x.value]["selectedCondition"] = x?.selectedCondition;
      return x;
    });

    return { filter: obj, isValid };
  }

  validateMaxMinRange(selectedOperator, minVal, maxVal) {
    if (selectedOperator == "is-between") {
      if ((!minVal && minVal != 0) || (!maxVal && maxVal != 0)) {
        return "*Please fill min and max value.";
      }
      if (minVal > maxVal) return "*Min value can't be more than max value.";
    } else {
      if (typeof minVal == "undefined" || minVal == null)
        return "*Please fill value.";
      if (selectedOperator == "is-less-than" && minVal == 0)
        return "*Value can't be 0";
    }
    return "";
  }

  validateDateRange(selectedOperator, minVal, maxVal) {
    if (selectedOperator != "between") {
      if (!minVal) return "*Please select date.";
    } else {
      if (!minVal || !maxVal) return "*Please select both dates.";
    }
    return "";
  }

  async parseFilter() {
    let appliedFilteredListLength = this.appliedFilteredList.length;
    this.appliedFilteredList = [];
    Object.keys(this.data?.appliedFilters).forEach(async (key) => {
      let filter = this.availableFilters.find((filter) => filter.value === key);

      if (filter) {
        const { value, operator, selectedCondition, order } =
          this.data?.appliedFilters[key];
        filter.order = order;

        let filterMultiTypes = [
          "tags",
          "targetAreas",
          "targetCities",
          "targetZip",
          "listsInclude",
          "listsExclude",
          "propertyCity",
          "propertyState",
          "propertyZipCode",
          "propertyCountyName",
          "mailingCity",
          "mailingState",
          "mailingZipCode",
          "mailingCountyName",
          "phoneStatus",
          "companyName",
          "tagsInclude",
          "tagsExclude",
          "repeatingMailingAddress",
          "phoneStatusInclude",
          "phoneStatusExclude",
          "roleStatus",
          "marketStatus",
          "propertyHouseType",
          "skipTracedSource",
          "lead_property_status",
          "vendorType",
          "numberType",
          "teams",
          "roles",
          "users",
          "assignment",
          "drips",
          "missingInfo",
          "leadSource",
          "campaign",
        ];
        if (filterMultiTypes.includes(filter.value)) {
          filter = await this.commonFunctionCalls(filter);

          if (filter.optionsType === "CHIPS") {
            value?.filter((x) => {
              this.addChip(filter, x);
            });
          } else {
            filter.selectedOption = value;
            filter.selectedOperator = operator;
            if (selectedCondition) filter.selectedCondition = selectedCondition;
          }
        } else {
          if (filter.value === "emailCampaign") {
            filter.selectedOption = await this.getCampaigns(
              { campaignIds: value },
              "email"
            );
          } else if (filter.value === "smsCampaign") {
            filter.selectedOption = await this.getCampaigns(
              { campaignIds: value },
              "sms"
            );
          } else {
            if (filter.optionsType === "RANGE") {
              filter.minVal = value[0] || value[0] == 0 ? value[0] : "";
              filter.maxVal = value[1] || value[1] == 0 ? value[1] : "";
            } else if (filter.optionsType === "DATE") {
              let minDate = "",
                maxDate = "",
                tempDate;
              if (value?.minVal) {
                tempDate = value?.minVal.split("-");
                minDate = tempDate[1] + "-" + tempDate[2] + "-" + tempDate[0];
              }
              if (value?.maxVal != "") {
                tempDate = value?.maxVal.split("-");
                maxDate = tempDate[1] + "-" + tempDate[2] + "-" + tempDate[0];
              }
              filter.minVal = minDate ? new Date(minDate) : "";
              filter.maxVal = maxDate ? new Date(maxDate) : "";
              filter.selectedOption = value?.field
                ? value?.field
                : "leadCreated";
            } else {
              filter.selectedOption = value;
            }
          }

          filter.selectedOperator = operator;
        }

        this.appliedFilteredList.push(filter);
        this.updateOptions();
        if (appliedFilteredListLength == this.appliedFilteredList.length) {
          this.appliedFilteredList = this._commonFunctionsService.orderItems(
            this.appliedFilteredList,
            "order"
          );
        }
      }
    });
  }

  hideOptionsPanel() {
    this.select.hide();
  }

  async selectTemplate(template, index?) {
    try {
      let templateData;
      if (template?.filterData) {
        templateData = JSON.parse(template?.filterData);
        this.appliedFilteredList = [];
        templateData.map(async (e) => {
          e = await this.commonFunctionCalls(e);
          this.appliedFilteredList.push(e);
          let a = this.leadFilters.findIndex((item) => item.value == e?.value);
          return e;
        });

        this.selectedTemplateIndex = index;
      }
    } catch (error) {
      console.error("error: ", error);
    }
  }

  getUsedColorIndex() {
    this._loaderService.start();

    this._taggingService.getUsedTagsColor(this.data?.tagType).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          this.unavailableColorIndex = response?.data;
          this.unavailableColorIndexResponse = [];
          let i, j;

          for (i = 0; i < this.colorCodes.length; i++) {
            this.unavailableColorIndexResponse.push({ _id: i, count: 0 });

            for (j = 0; j < this.unavailableColorIndex.length; j++) {
              if (this.unavailableColorIndex[j]._id == i) {
                this.unavailableColorIndexResponse[i].count =
                  this.unavailableColorIndex[j].count;
                break;
              }
            }
          }

          this.unavailableColorIndexResponse.sort(
            (a, b) => parseFloat(a.count) - parseFloat(b.count)
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  updateOptions() {
    let repeatingMailingAddressIndex = this.appliedFilteredList.findIndex(
      (x) => x.value === "repeatingMailingAddress"
    );
    let appliedOwnerTypeIndex = this.appliedFilteredList.findIndex(
      (x) => x.value === "ownerType"
    );
    let appliedMailingAddressIndex = this.appliedFilteredList.findIndex(
      (x) => x.value === "mailingAddress"
    );

    if (appliedOwnerTypeIndex > -1) {
      let ownerTypeOptionLength =
        this.appliedFilteredList[appliedOwnerTypeIndex].options.length;
      if (repeatingMailingAddressIndex > -1) {
        if (ownerTypeOptionLength == 3)
          this.appliedFilteredList[appliedOwnerTypeIndex].options.splice(2, 1);
      } else {
        if (ownerTypeOptionLength == 2)
          this.appliedFilteredList[appliedOwnerTypeIndex].options.push({
            label: "Unknown",
            value: "Unknown",
          });
      }
    }
    if (appliedMailingAddressIndex > -1) {
      let mailingAddressoptionLength =
        this.appliedFilteredList[appliedMailingAddressIndex].options.length;
      if (repeatingMailingAddressIndex > -1) {
        if (mailingAddressoptionLength == 4) {
          this.appliedFilteredList[appliedMailingAddressIndex].selectedOption =
            this.appliedFilteredList[appliedMailingAddressIndex].selectedOption
              ? this.appliedFilteredList[
                  appliedMailingAddressIndex
                ].selectedOption.filter((e) => {
                  return e != "missing";
                })
              : [];
          this.appliedFilteredList[appliedMailingAddressIndex].options.splice(
            3,
            1
          );
        }
      } else {
        if (mailingAddressoptionLength == 3)
          this.appliedFilteredList[appliedMailingAddressIndex].options.push({
            label: "Missing",
            value: "missing",
          });
      }
    }
  }

  searchFilterFunction(e) {
    this.filterGroup.map((e) => {
      e.show = 0;
    });

    const searchData = this.searchFilter?.toLowerCase();
    let filterLabel, index;
    for (let i = 0; i < this.leadFilters.length; i++) {
      this.leadFilters[i].show = 0;
      filterLabel = this.leadFilters[i]?.label?.toLowerCase();
      if (filterLabel?.includes(searchData)) {
        this.leadFilters[i].show = 1;
        index = this.filterGroup.findIndex(
          (x) => x.name === this.leadFilters[i]?.filterGroup
        );
        if (index >= 0) this.filterGroup[index].show = 1;
      }
    }
  }

  async changeFilter($event, index: number) {
    let filter = this.leadFilters.find((x) => x.value === $event.target.value);
    filter = await this.commonFunctionCalls(filter);
    if (filter) {
      this.appliedFilteredList[index] = filter;
    }
  }

  isActive(filter: FilterListModel) {
    return !!(
      this.appliedFilteredList.findIndex((x) => x.value === filter.value) > -1
    );
  }

  removeChip(filter: FilterListModel, chipIndex) {
    if (chipIndex >= 0) {
      filter.selectedOption.splice(chipIndex, 1);
    }
  }

  addChip(filter: FilterListModel, event): void {
    const input = event?.input;
    const value = event.value || event;
    let key;
    let control;
    let field;

    if (filter.value === "tags") {
      key = this.allTagList;
      control = this.tagControl;
      field = "_id";
    } else if (filter.value === "targetZip") {
      key = this.allZipList;
      control = this.zipControl;

      if (isNaN(Number(value))) {
        this._toastrService.error(
          MessageConstant.numbersOnly.replace("[[FIELD]]", "Target Zip")
        );
        return;
      }
    } else if (filter.value === "targetCities") {
      key = this.allCitiesList;
      control = this.cityControl;
    }

    if (this.checkForUnique(value, filter.selectedOption, field)) {
      // let index = -1;
      let index = key.findIndex(
        (val) => val[field || "label"]?.toLowerCase() == value.toLowerCase()
      );

      if (index >= 0) {
        filter.selectedOption.push(key[index]);
      } else {
        if ((value || "").trim()) {
          filter.selectedOption.push({ _id: "custom", label: value.trim() });
        }
      }
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    control.setValue(null);
  }

  checkForUnique(val, data, field?) {
    let flag = true;
    for (let i = 0; i < data.length; i++) {
      if (data[i][field || "label"]?.toLowerCase() == val.toLowerCase()) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  async getSuggestionList(query, filter, isDefault?) {
    if (!isDefault && (!query || query == "")) {
      return;
    }

    let obj = {};

    if (isDefault) {
      obj["limit"] = environment.pagination.pageLimit;
    } else {
      obj["searchString"] = query;
    }
    this.modelChanged(filter);
    switch (filter.value) {
      case "emailCampaign":
        await this.getCampaigns(obj, "email");
        break;
      case "smsCampaign":
        await this.getCampaigns(obj, "sms");
        break;
    }
  }

  getCampaigns(obj, type) {
    return new Promise((resolve, reject) => {
      obj = {
        ...obj,
        moduleId: this.data?.moduleId,
      };
    });
  }

  selected(event: MatAutocompleteSelectedEvent, filter: FilterListModel): void {
    if (this.checkForUnique(event.option.value.label, filter.selectedOption)) {
      filter.selectedOption.push(event.option.value);
      const list = this.chipInput?.toArray();
      if (list) {
        list.filter((input) => {
          input.nativeElement.value = "";
          input.nativeElement.blur();
        });
      }

      this.modelChanged(filter);
      if (filter.value === "tags") {
        this.tagControl.setValue(null);
      } else if (filter.value === "targetCities") {
        this.cityControl.setValue(null);
      } else if (filter.value === "targetZip") {
        this.zipControl.setValue(null);
      }
    }
  }

  isVisible(filter: FilterListModel, item) {
    if (filter.value === "targetCities") {
      const cityIndex = filter.selectedOption.findIndex(
        (x) => x.label === item.label
      );
      return cityIndex > -1 ? false : true;
    } else if (filter.value === "targetZip") {
      const zipIndex = filter.selectedOption.findIndex(
        (x) => x.label === item.label
      );
      return zipIndex > -1 ? false : true;
    } else if (filter.value === "tags") {
      const tagIndex = filter.selectedOption.findIndex(
        (x) => x.label === item.label
      );
      return tagIndex > -1 ? false : true;
    }
  }

  getFormControl(filter: FilterListModel) {
    if (filter.value === "targetCities") {
      return this.cityControl;
    } else if (filter.value === "targetZip") {
      return this.zipControl;
    } else if (filter.value === "tags") {
      return this.tagControl;
    }
  }

  getFilteredList(filter: FilterListModel) {
    if (filter.value === "targetCities") {
      return this.filteredCities;
    } else if (filter.value === "targetZip") {
      return this.filteredZip;
    } else if (filter.value === "tags") {
      return this.filteredTags;
    }
  }

  isDisabled(filter: FilterListModel) {
    if (filter.value === "smsStats") {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === "smsCampaign"
      );
      if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption?.length === 1
      ) {
        return false;
      } else {
        filter.error = this.messageConstant?.noSmsCampErr;
        return true;
      }
    }

    if (filter.value === "emailStats") {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === "emailCampaign"
      );
      if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption?.length === 1
      ) {
        return false;
      } else {
        filter.error = this.messageConstant?.noEmailCampErr;
        return true;
      }
    }
    if (filter.value === "litigator" || filter.value === "dnc") {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === "skipTraced"
      );

      if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption === "no"
      ) {
        filter.error =
          'You cannot apply "' +
          filter.label +
          '" filter when "Skip Traced" Equals No';
        return true;
      } else if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption === "yes"
      ) {
        return false;
      }
    } else if (filter.value === "mailCount") {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === "repeatingMailingAddress"
      );

      if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption === "custom"
      ) {
        return false;
      } else if (index > -1) {
        filter.error =
          'You cannot apply "' +
          filter.label +
          '" filter when "Owners w/ Multiple Properties" Equals No';
        return true;
      }
    } else if (filter.value === "mailType") {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === "directMailSent"
      );

      if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption === "yes"
      ) {
        return false;
      } else if (index > -1) {
        filter.error =
          'You cannot apply "' +
          filter.label +
          '" filter when "Direct Mail Sent" Equals No';
        return true;
      }
    }
    return filter.disabled;
  }

  setSearchFocus() {
    setTimeout(() => {
      this.searchElement?.nativeElement?.focus();
    }, 50);
  }

  modelChanged(filter) {
    delete filter["error"];

    if (filter?.value !== "listsInclude" && filter?.value !== "listsExclude") {
      return;
    }

    let key;

    if (filter?.value === "listsInclude" || filter?.value === "listsExclude") {
      key = filter?.value === "listsInclude" ? "listsExclude" : "listsInclude";
    }

    const currentFilterIndex = this.appliedFilteredList.findIndex(
      (x) => x.value === key
    );

    if (currentFilterIndex > -1) {
      this.appliedFilteredList[currentFilterIndex].options = this.lists.filter(
        (x) => !filter?.selectedOption?.includes(x?.value)
      );

      if (
        this.appliedFilteredList[currentFilterIndex]?.selectedOption?.length
      ) {
        filter.options = this.lists.filter(
          (x) =>
            !this.appliedFilteredList[
              currentFilterIndex
            ].selectedOption.includes(x?.value)
        );
      }
    }
  }

  async executeTags(item) {
    let existingTags = item
      ?.filter((x) => x._id != "custom")
      ?.reduce((acc, d) => {
        acc.push(d._id);
        return acc;
      }, []);

    let newTags = item
      ?.filter((x) => x._id === "custom")
      ?.reduce((acc, d) => {
        acc.push(d.label);
        return acc;
      }, []);

    if (!newTags?.length) {
      return existingTags;
    }

    let tagsToSave = this.getTagsToSave(newTags);
    let obj = {
      tagsArr: tagsToSave,
      tagType: this.data?.tagType,
    };

    return await this.saveTags(obj, existingTags);
  }

  getTagsToSave(tagsArr) {
    if (tagsArr.length) {
      let finalArr = [],
        colorIndex = 0;

      for (let i = 0; i < tagsArr.length; i++) {
        if (this.unavailableColorIndexResponse.length == colorIndex)
          colorIndex = 0;
        finalArr.push({
          tag: tagsArr[i],
          colorIndex: this.unavailableColorIndexResponse[colorIndex]?._id,
        });
        colorIndex++;
      }
      return finalArr;
    } else {
      return [];
    }
  }

  saveTags(obj, existingTags) {
    return new Promise((resolve, reject) => {
      this._loaderService.start();
      this._taggingService.addTags(obj).subscribe(
        (response) => {
          if (response) {
            let finalIds = existingTags;

            for (let i = 0; i < response?.data?.length; i++) {
              if (response?.data[i] != null)
                finalIds.push(response?.data[i]?._id);
            }

            this._loaderService.stop();
            resolve(finalIds);
          }
        },
        (err: ErrorModel) => {
          reject([]);
          this._loaderService.stop();
          this._toastrService.error(
            err.message ? err.message : MessageConstant.unknownError
          );
        }
      );
    });
  }

  getLeadSourceList() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();

      const obj = {
        searchString: "hear-about-us",
      };

      this._leadsService.leadSourceList(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            let leadSources = response.data?.items;
            const result = leadSources.reduce((acc, d) => {
              if (d.answer) {
                const value = { _id: d._id, label: d.answer, value: d._id };
                acc.push(value);
              }
              return acc;
            }, []);
            this.leadSources = result;
            resolve(this.leadSources);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          reject([]);
          this._toastrService.error(MessageConstant.unknownError, "");
        }
      );
    });
  }

  getCampaignList() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();
      let obj = {
        numTypeId: 2,
      };

      this._leadsService.getCampaignList(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            let campaignList = response?.data?.items;
            const result = campaignList.reduce((acc, d) => {
              if (d.marketingTitle) {
                const value = {
                  _id: d._id,
                  label: d.marketingTitle,
                  value: d._id,
                };
                acc.push(value);
              }
              return acc;
            }, []);
            this.campaignList = result;
            resolve(this.campaignList);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          reject([]);
          this._toastrService.error(MessageConstant.unknownError, "");
        }
      );
    });
  }
}
