import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  EventEmitter,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment-timezone";
import { Subject } from "rxjs";

// UTILS
import { MessageConstant } from "src/app/utils/message-constant";
import { MiscellaneousConstant } from "src/app/utils/miscellaneous-constant";
import { ResponseModel } from "src/app/utils/models/response";
import { ErrorModel } from "src/app/utils/models/error";

// SERVICES
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SharedService } from "../../shared.service";
import { ToastrService } from "ngx-toastr";
import { TwilioServiceService } from "../../../utils/twilio-service/twilio-service.service";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { environment } from "src/environments/environment";
import { UserService } from "src/app/providers/user/user.service";
import { StatusConstant } from "src/app/utils/status-constant";

@Component({
  selector: "app-call-detail-dailogs",
  templateUrl: "./call-detail-dailogs.component.html",
  styleUrls: ["./call-detail-dailogs.component.scss"],
})
export class CallDetailDailogsComponent implements OnInit {
  messageConstant = MessageConstant;
  isMinimizedDis: boolean = false;
  isCalling: boolean = false;
  callDetailForm: FormGroup;
  leadSourceCountryCode: string = environment.countryCode;
  submitted: boolean = false;
  isAnsweredCall: boolean = false;
  isDialerPadOpen: boolean = false;
  dialedNumber: string = "";
  showMemberList = false;
  muteCall: boolean = false;
  holdCall: boolean = false;
  callReady: boolean = false;
  callDuration: string = "00:00:00";
  callStatus: string = "";
  defaultDiaplay: boolean = true;
  callEndDuration: string = "00:00:00";
  redialCall: boolean = false;
  makingCall: boolean = false;
  callEnd: boolean = false;
  callingData: any;
  callUUID: any;
  contactData: any;
  currentContact: number = 1;
  mergeCallDialer: any = {
    firstCall: false,
    secondCall: false,
    callMerged: false,
    callEnded: true,
    callNotJoined: false,
    checkStatus: false,
    endCause: "",
  };
  statusCheckFlag: boolean = false;
  leadData: any;
  contactType: string = "seller";
  buyerCallFrom: string = "";
  userId: string = "";
  addToMpcHeader: any;
  leadId: string = "";
  twilioCallSid: any;
  counter: any;
  callConnected: boolean = false;
  isCurrentCall: boolean = true;
  callEndTime: string = "00:00";
  isMinimized: boolean = false;
  isMarketReadonly: boolean = true;
  currentPage: number = 1;
  campaigns: any[] = [];
  callBalance: number = 0;
  isMinimizedScreen: boolean = true;
  evt: any;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _smsDialogRef: MatDialogRef<CallDetailDailogsComponent>,
    private _sharedService: SharedService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _twilioService: TwilioServiceService,
    public _utilities: CommonFunctionsService,
    private _userService: UserService
  ) {
    this.getUser();
    if (data?.callBalance) {
      this.callBalance = data?.callBalance;
    }
    this._sharedService.refreshCallerToggleDisplay = new Subject();
    this._sharedService.refreshCallerToggleDisplay.subscribe((response) => {
      if (response) {
        setTimeout(() => {
          this.minimizeDisplay();
        }, 100);
      }
    });
  }

  ngOnInit(): void {
    this._twilioService.twilioOutboundHangupEvent = new Subject();
    this._twilioService.twilioOutboundHangupEvent.subscribe((response) => {
      this.cancel();
    });

    //twilio call accept event
    this._twilioService.twilioOutboundEvent = new Subject();
    this._twilioService.twilioOutboundEvent.subscribe((response) => {
      if (response) {
        this.twilioOutboundCallEvent(response);
      }
    });

    // this.getUser();
    this.callDetailForm = this.formBuilder.group({
      leadSource: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(MiscellaneousConstant.phonePattern),
        ]),
      ],
    });

    // if (this.data.leadSource && typeof this.data.leadSource === "string") {
    //   this.leadSourceCountryCode = this._utilities.getCountryCode(
    //     this.data.leadSource
    //   );
    //   this.callDetailForm.patchValue({
    //     leadSource: this._utilities.maskNumber(this.data.leadSource),
    //   });
    // } else {
    //   this.callDetailForm
    //     .get("leadSource")
    //     .setValidators([Validators.compose([Validators.required])]);
    // }

    // Twilio Event
    this._twilioService.twilioOutboundHangupEvent = new Subject();
    this._twilioService.twilioOutboundHangupEvent.subscribe((response) => {
      this.cancel();
    });

    //twilio call accept event
    this._twilioService.twilioOutboundEvent = new Subject();
    this._twilioService.twilioOutboundEvent.subscribe((response) => {
      if (response) {
        this.twilioOutboundCallEvent(response);
      }
    });
    this.getBuyerNumbers();
  }

  get leadSource() {
    return this.callDetailForm.get("leadSource");
  }

  onNumberType(value) {
    const type = this._utilities.numberType(value?.toLowerCase());
    return type;
  }

  makeCall(data) {
    this.submitted = true;
    this.isAnsweredCall = true;
    if (this.callDetailForm.invalid) {
      return;
    }
    this.isMinimizedDis = false;
    this.isCalling = true;

    this.initiateCall(data);
  }
  initiateCall({ phoneNumber }) {
    this.mergeCallDialer = {
      firstCall: false,
      secondCall: false,
      callMerged: false,
      callEnded: true,
      callNotJoined: false,
      checkStatus: false,
      endCause: "",
    };
    this.statusCheckFlag = false;
    const user = JSON.parse(localStorage.getItem("currentUser"));

    const { leadSource } = this.callDetailForm.value;
    let number = leadSource;
    // if (typeof this.data.leadSource === "string") {
    //   number =
    //     this.leadSourceCountryCode + this._utilities.unMaskNumber(leadSource);
    // }

    number =
      this.leadSourceCountryCode + this._utilities.unMaskNumber(leadSource);

    let moduleType = this.data.moduleType || "Leads";

    let callObj = {
      from: number,
      to: phoneNumber,
      number: this.data.leadSource
        ? this._utilities.unMaskNumber(this.data.leadSource)
        : number,
      userId: this.userId,
      moduleType: moduleType,
      contactId: this.data?.contactId,
      // buyerId: buyerId,
      //leadId: this.data?.leadId,
      // vendorId: vendorId,
    };

    if (
      this.data.moduleType == "Leads" ||
      this.data.moduleId == StatusConstant?.ModuleId?.LEAD
    ) {
      callObj["leadId"] = this.data?.leadId;
    }
    if (
      this.data.moduleType == "Contacts" ||
      this.data.moduleId == StatusConstant?.ModuleId?.CONTACT
    ) {
      callObj["contactId"] = this.data?.contactId;
    }
    this._twilioService.initializeTwilio(callObj);

    this._utilities.callType = "outgoing";
    this._utilities.numberOfCallsRunning++;
  }

  getUser() {
    let param = {};
    this._loaderService.start();
    this._userService.getUserDetails(param).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this.userId = response?.data._id;
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
        }
      }
    );
  }

  mute() {
    if (this.twilioCallSid) {
      this.muteCall = true;
      this._twilioService.callMuteUnmute(true);
    }
  }

  unmute() {
    if (this.twilioCallSid) {
      this.muteCall = false;
      this._twilioService.callMuteUnmute(false);
    }
  }

  hold() {
    if (this.twilioCallSid) {
      this.holdCall = true;
      this._twilioService
        ?.holdCallFn({ callSid: this.twilioCallSid })
        .subscribe(
          (response) => {
            if (response.statusCode == 200) {
              this.holdCall = true;
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, "");
            } else {
              this._toastrService.error(this.messageConstant.unknownError, "");
            }
          }
        );
    }
  }

  unHold() {
    this.holdCall = false;
    if (this.twilioCallSid) {
      this._twilioService
        ?.unHoldCallFn({ callSid: this.twilioCallSid })
        .subscribe(
          (response: any) => {
            this.holdCall = false;
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, "");
            } else {
              this._toastrService.error(this.messageConstant.unknownError, "");
            }
          }
        );
      this.holdCall = false;
    }
  }

  dialPad() {
    this.isDialerPadOpen = true;
  }

  dialer(key) {
    if (!key) {
      return;
    }
    this.dialedNumber += key;
  }

  cancel() {
    if (this._utilities.callType == "outgoing") {
      this.callConnected = true;
      this.dialedNumber = "";
      this.callStatus = "Call Ended";
      if (this.counter) {
        clearInterval(this.counter);
      }
      this.statusCheckFlag = false;

      this._twilioService.callHangup();

      this.resetToDefault();

      if (this.isMinimized) {
        this.toggleDisplay();
      }

      this._smsDialogRef.close(true);
    } else {
      if (this._utilities.callTypeReject == "false") {
        this.callConnected = true;
        this.dialedNumber = "";
        this.callStatus = "Call Ended";
        this.resetToDefault();
      }
    }
  }

  removeDialer() {
    if (this.dialedNumber) {
      this.dialedNumber = this.dialedNumber.slice(0, -1);
    }
  }

  twilioOutboundCallEvent(connection) {
    this.twilioCallSid = connection?.parameters?.CallSid;
    if (this.twilioCallSid) {
      // this.callAnswered = true;
      this.callReady = true;
      this.callDuration = "00:00:00";
      this.callStatus = "Ongoing Call...";
      let a = moment();
      let b = moment();
      this.counter = setInterval(() => {
        this.showTime(a, b);
      }, 1000);
    }
  }

  showTime(dateA, dateB) {
    this.callStatus = "Ongoing Call...";
    dateB.add(1, "seconds");
    this.callDuration = moment
      .utc(
        moment(dateB, "DD/MM/YYYY HH:mm:ss").diff(
          moment(dateA, "DD/MM/YYYY HH:mm:ss")
        )
      )
      .format("HH:mm:ss");
    this.callEndDuration = moment
      .utc(
        moment(dateB, "DD/MM/YYYY HH:mm:ss").diff(
          moment(dateA, "DD/MM/YYYY HH:mm:ss")
        )
      )
      .format("HH:mm:ss");
  }

  resetToDefault() {
    this.defaultDiaplay = false;
    this.makingCall = false;
    this._utilities.makingCallDialer = false;
    this.isCalling = false;
    this.callReady = false;
    this.isDialerPadOpen = false;
    this.callEnd = true;
    if (
      this.callUUID == null ||
      this.callUUID == "" ||
      this.callEndDuration == "00:00:00"
    ) {
      this.callEnd = false;
    }
    this.redialCall = true;
    this.holdCall = false;
    this.muteCall = false;
    this.isCurrentCall = true;
    this.callEndTime = moment()
      .tz(localStorage["timezoneOffset"])
      .format("HH:mm A");
  }

  toggleDisplay() {
    const cdkOverlayPane = document.querySelector(
      ".cdk-overlay-pane"
    ) as HTMLElement | null;
    const cdkOverlayBackdrop = document.querySelector(
      ".cdk-overlay-backdrop"
    ) as HTMLElement | null;
    // if (this.isMinimized) {
    // this.getLeadDetails(this.leadId);
    // this.getQuestions(this.leadId);
    if (document.querySelector(".cdk-overlay-backdrop")) {
      cdkOverlayBackdrop.style.display = "block";
    }

    if (document.querySelector(".cdk-overlay-pane")) {
      cdkOverlayPane.style.position = "relative";
      cdkOverlayPane.style.height = "auto";
      cdkOverlayPane.style.transform = "";
      document
        .querySelector(".cdk-overlay-pane")
        .classList.remove("re-lead-dialer-minimize");
    }
    // } else {
    if (document.querySelector(".cdk-overlay-backdrop")) {
      cdkOverlayBackdrop.style.display = "none";
    }
    if (document.querySelector(".cdk-overlay-pane")) {
      cdkOverlayPane.style.position = "absolute";
      document
        .querySelector(".cdk-overlay-pane")
        .classList.add("re-lead-dialer-minimize");
    }
    // this.reset();
    // }

    // this.isMinimized = !this.isMinimized;
    // this.isMinimizedScreen = false;
  }

  getBuyerNumbers() {
    const data = {
      page: this.currentPage,
      limit: 1000,
      numTypeId: 2,
    };
    this._loaderService.start();
    this._sharedService.getManageNumbers(data).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this.campaigns = response.data.items;
          for (let i = 0; i > this.campaigns.length; i++) {
            this.callDetailForm.patchValue({
              leadSource: this.campaigns[i].number,
            });
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (error?.platform) {
            this._toastrService.error(error.platform, "");
          } else {
            this._toastrService.error(error.message, "");
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  minimizeDisplay() {
    if (this.isMinimizedDis) {
      if (document.querySelector(".cdk-overlay-backdrop"))
        document.querySelector(".cdk-overlay-backdrop")["style"].display =
          "block";

      if (document.querySelector(".cdk-overlay-pane")) {
        document.querySelector(".cdk-overlay-pane")["style"].position =
          "relative";
        document.querySelector(".cdk-overlay-pane")["style"].height = "auto";
        document.querySelector(".cdk-overlay-pane")["style"].transform = "";
        document
          .querySelector(".cdk-overlay-pane")
          .classList.remove("re-lead-dialer-minimize");
      }
    } else {
      if (document.querySelector(".cdk-overlay-backdrop"))
        document.querySelector(".cdk-overlay-backdrop")["style"].display =
          "none";
      if (document.querySelector(".cdk-overlay-pane")) {
        document.querySelector(".cdk-overlay-pane")["style"].position =
          "absolute";
        document
          .querySelector(".cdk-overlay-pane")
          .classList.add("re-lead-dialer-minimize");
      }
      this.reset();
    }

    this.isMinimizedDis = !this.isMinimizedDis;
    this.isMinimizedScreen = false;
  }

  reset() {
    if (this.evt) this.evt._dragRef.reset();
  }

  ngAfterViewInit(): void {
    document.onclick = (args: any): void => {
      if (
        args.target.className ==
        "cdk-overlay-backdrop popup-Backdrop-Class-dialer cdk-overlay-backdrop-showing"
      ) {
        if (this.isCalling) {
          this.minimizeDisplay();
        }
        return;
      }
    };
    this.isMarketReadonly = true;
  }
}
