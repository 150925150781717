import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public token = localStorage.getItem("token");
  constructor() {}

  // isAuthenticated() {
  //   if (this.token != undefined) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } //isAuthenticated ends here

  getToken() {
    let value: any = "";
    try {
      value = localStorage.getItem("token");
      return value;
    } catch (error) {
      console.error("GET TOKEN: ", error);
    }
  }
}
