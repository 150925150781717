import { ErrorHandler, Injectable } from "@angular/core";
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error: any): void {
    const message = error.message ? error.message : error.toString();
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const highChartFailedMessage = /Highcharts error #13/;
    const ngxLoaderFailedMessage = /loaderId "[\w]+" is duplicated/;
    const expressionChangedAfterFailedMessage =
      /Expression has changed after it was checked/;
    const mapsError = /WebGLRenderingContext/;
    const expectedError = /expected expression, got ‘<’/;
    const unexpectedError = /Unexpected token ‘<’/;
    const undefinedObjectError = /undefined is not an object/;
    const unsafeUrlError = /unsafe value used in a resource URL context/;
    const nullPropertyError = /Cannot read properties of null/;
    const undefinedPropertyError =
      /Cannot read properties of undefined \(reading/;
    const unsubscribedError = /object unsubscribed/;
    const resizeError = /ResizeObserver/;
    const err = error?.error;
    const obj = {
      error: err,
      message,
    };
    if (
      !chunkFailedMessage.test(message) &&
      !mapsError.test(message) &&
      !highChartFailedMessage.test(message) &&
      !ngxLoaderFailedMessage.test(message) &&
      !expressionChangedAfterFailedMessage.test(message) &&
      !expectedError.test(message) &&
      !unexpectedError.test(message) &&
      !undefinedObjectError.test(message) &&
      !unsafeUrlError.test(message) &&
      !nullPropertyError.test(message) &&
      !undefinedPropertyError.test(message) &&
      !unsubscribedError.test(message) &&
      !resizeError.test(message)
    ) {
      console.error("GlobalErrorHandler -------------->", obj);
    }

    if (chunkFailedMessage.test(message)) {
      window.location.reload();
    }
  }
}
