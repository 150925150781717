<div class="re-agent-permission-title">
  <div class="re-agent-permission-listgrid">
    <div class="re-agent-permission-unauthorized">
      <div class="re-agent-permission-unauthorizeddiv">
        <img src="./assets/images/lock.png" />
        <h2>Unauthorized Access</h2>
      </div>
      <h5>Oops! You are not authorized to access this feature.</h5>
      <h6>
        Having trouble accessing this module? Expecting access to this module?
        Let your administrator know about this.
      </h6>
    </div>
  </div>
</div>
