import {
  Component,
  OnInit,
  EventEmitter,
  Inject,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";
import "quill-mention";
import { QuillEditorComponent } from "ngx-quill";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";

// UTILS
import { MessageConstant } from "../../../../utils/message-constant";
import { MiscellaneousConstant } from "../../../../utils/miscellaneous-constant";
import { ResponseModel } from "../../../../utils/models/response";
import { ErrorModel } from "src/app/utils/models/error";
import { environment } from "src/environments/environment";
import { BaseUrl } from "src/app/utils/base-url-constants";
import { StatusConstant } from "src/app/utils/status-constant";

// SERVICES
import { SharedService } from "../../../shared.service";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { UserService } from "src/app/providers/user/user.service";

// COMPONENTS
import { VerifyEmailComponent } from "../choose-email-dailog/verify-email/verify-email.component";

@Component({
  selector: "app-add-edit-email-template-dailog",
  templateUrl: "./add-edit-email-template-dailog.component.html",
  styleUrls: ["./add-edit-email-template-dailog.component.scss"],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class AddEditEmailTemplateDailogComponent implements OnInit {
  @ViewChild("editor") editor: QuillEditorComponent;
  public showCC = false;
  public showBCC = false;
  public text: string;
  public messageConstant = MessageConstant;
  addEditEmailForm: FormGroup;
  dialogRef: any;
  submitted = false;
  checkValidation = {
    templateName: false,
    fromEmail: false,
    toEmail: false,
  };
  duplicateImageArr: any = [];
  editAttachmentArray: any = [];
  ccArray: any = [];
  bccArray: any = [];
  ccChecker: boolean = true;
  bccChecker: boolean = true;
  public items: string[] = [
    "First_Name",
    "Last_Name",
    "Property_Street_Address",
    "Property_City",
    "Property_State",
    "Property_Zip_Code",
  ];

  ccEmail: string;
  bccEmail: string;
  checkCC: any = [];
  checkBCC: any = [];
  fileSize: any = 0;
  selectedFileSize: any = 0;
  emailInvalidMessage: string = "";
  isEmailValid: boolean = true;
  editorStyle: {
    height: "500px";
  };
  editorConfig: any = {};
  editorOptions: any = {};
  moduleId: string = StatusConstant?.ModuleId?.LEAD;
  uploadFiles: any[] = [];
  fileCount: number = 0;
  allFileCount: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _utilities: CommonFunctionsService,
    private _userService: UserService,
    public _sharedService: SharedService,
    private dialog: MatDialog,
    private emailDialog: MatDialogRef<AddEditEmailTemplateDailogComponent>
  ) {
    this.getUserRoleList();
    this.editorOptions = {
      placeholder: this._utilities.commonEditor().placeholder,
      toolbar: this._utilities.commonEditor().toolbar,
      mention: this.mentionEditor(),
    };
  }

  mentionEditor() {
    return {
      mentionListClass: "ql-mention-list mat-elevation-z8",
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: true,
      spaceAfterInsert: true,
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        editor.insertText(editor.getLength() - 1, "", "user");
      },
      source: (searchTerm, renderList) => {
        const values = this.items.map((item, index) => {
          return { id: index, value: item };
        });

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    };
  }

  ngOnInit(): void {
    this.addEditEmailForm = this._formBuilder.group({
      moduleId: [""],
      templateName: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      fromEmail: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(MiscellaneousConstant.emailPatternWithCaps),
        ]),
      ],
      replyEmail: ["", Validators.pattern(MiscellaneousConstant.emailPattern)],
      fromName: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(MiscellaneousConstant.namePattern),
        ]),
      ],
      subject: ["", Validators.required],
      message: ["", Validators.required],
      ccEmail: [""],
      bccEmail: [""],
      attachmentArray: [""],
    });
  }

  get email() {
    return this.addEditEmailForm.get("fromEmail");
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addEditEmailForm.controls[controlName].hasError(errorName);
  };

  getUserRoleList() {
    this._loaderService.start();
    let role = [];
    this._userService.getUserRoleList({ page: 1, limit: 100 }).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          let hash = Object.create(null);

          let roles = _.flatten(
            response.data.items.map((item) => item.roleData)
          );
          let result: any = roles.reduce((r: any, o: any) => {
            if (!hash[o?._id]) {
              hash[o?._id] = {
                _id: o?._id,
                name: o?.name,
                roles: [],
              };
              r.push(hash[o?._id]);
            }
            hash[o?._id].roles.push({
              _id: o?._id,
              name: o?.name,
            });
            return r;
          }, []);

          result.map((item) => {
            role.push(item.name.toString());
          });

          this.items = [...this.items, ...role];

          if (this.data.action == "edit") {
            if (this.data.details?.ccEmail) {
              this.showCC = true;
              this.onAllowUniqueCcEmail(this.data.details?.ccEmail);
            }

            if (this.data.details?.bccEmail) {
              this.showBCC = true;
              this.onAllowUniqueBccEmail(this.data.details?.bccEmail);
            }

            if (this.data.details?.attachmentArray) {
              this.fileSize = this.data.details?.attachmentArray
                .map((obj) => {
                  return Number(obj.size);
                })
                .reduce((a, b) => a + b, 0);
            }

            this.editAttachmentArray = this.data.details?.attachmentArray
              ? this.data.details?.attachmentArray
              : [];

            if (this.data.details?.fromEmail) {
              this.isEmailValid = true;
            }

            let subject = this.data.details?.subject.replace(
              /<br\s*\/?>/gm,
              "\n"
            );
            let message = this.data.details?.message.replace(
              /<br\s*\/?>/gm,
              "\n"
            );

            this.addEditEmailForm.patchValue({
              templateName: this.data.details?.templateName,
              fromEmail: this.data.details?.fromEmail,
              fromName: this.data.details?.fromName,
              subject: subject,
              message: this._utilities.populateEditorDataWithBR(
                this.items,
                message
              ),
            });
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  onSubmit(template?) {
    switch (template) {
      case "add-edit":
        this.addEditEmail();
        break;
    }
  }

  addEditEmail() {
    let moduleIds = [];
    this.submitted = true;
    this.checkValidation = {
      templateName: true,
      fromEmail: true,
      toEmail: true,
    };
    if (
      this.addEditEmailForm.invalid ||
      !this.ccChecker ||
      !this.bccChecker ||
      !this.isEmailValid
    ) {
      return;
    }
    this._loaderService.start();
    let obj = {
      moduleId: moduleIds,
      templateName: this.addEditEmailForm.value.templateName,
      subject: this.addEditEmailForm.value.subject,
      message: this._utilities.getTextWithMentions(
        this.addEditEmailForm.value.message
      ),
      fromEmail: this.addEditEmailForm.value.fromEmail
        ? this.addEditEmailForm.value.fromEmail.toLowerCase()
        : "",
    };
    if (this.addEditEmailForm.value.fromName) {
      obj["fromName"] = this.addEditEmailForm.value.fromName;
    }
    if (this.showCC == true) {
      obj["ccEmail"] = this.addEditEmailForm.value.ccEmail;
    }
    if (this.showBCC == true) {
      obj["bccEmail"] = this.addEditEmailForm.value.bccEmail;
    }
    if (this.data.action == "edit") {
      obj["mailTemplateId"] = this.data.details?._id;
    }
    // this.editAttachmentArray = [
    //   ...this.editAttachmentArray,
    //   ...this.duplicateImageArr,
    // ];
    // this.editAttachmentArray = [
    //   ...new Set(this.editAttachmentArray.map(JSON.stringify)),
    // ];
    // this.editAttachmentArray = this.editAttachmentArray.map(JSON.parse);
    // this.editAttachmentArray.map((i) => {
    //   return delete i["src"];
    // });

    // if (this.editAttachmentArray.length) {
    //   obj["attachmentArray"] = this.editAttachmentArray;
    // }
    let duplicateImageArr: any = [
      ...this.editAttachmentArray,
      ...this.duplicateImageArr,
    ];
    duplicateImageArr = [...new Set(duplicateImageArr.map(JSON.stringify))];
    duplicateImageArr = duplicateImageArr.map(JSON.parse);
    duplicateImageArr.map((i) => {
      return delete i["src"];
    });

    if (duplicateImageArr.length) {
      obj["attachmentArray"] = duplicateImageArr;
    }
    if (this.data.action !== "edit") {
      this._sharedService.addEmail(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            let data = response;
            this.emailDialog.close(data);
            this._loaderService.stop();
            this._toastrService.success(this.messageConstant.emailAddedSuccess);
          }
        },
        (err) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
    } else {
      this._sharedService.editEmail(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            let result = response.data;
            this.emailDialog.close(result);
            this._toastrService.success(
              this.messageConstant.emailUpdatedSuccess
            );
          }
        },
        (err) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
    }
  }

  onBlurMethod(index) {
    this.checkValidation[index] = true;
  }

  onMentionSelect($event) {
    return `@${$event.name}` + ` `;
  }

  verifyEmail($event) {
    if (this.addEditEmailForm.controls["fromEmail"].status == "INVALID") {
      return;
    } else {
      this.text = $event.value
        ? $event.value
        : this.addEditEmailForm.value.fromEmail;
      this.checkEmail(this.text);
    }
  }

  checkEmail(email) {
    const obj = {
      email: email?.toLowerCase(),
    };
    this.emailInvalidMessage = "";
    this.isEmailValid = false;

    this._loaderService.start();
    this._sharedService.checkEmail(obj, true).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.emailInvalidMessage = response.data?.message;
          this.isEmailValid = response.type == "SUCCESS";
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (err.error.message) {
            this.emailInvalidMessage = err.error.message;
            this.isEmailValid = false;
          }
        }
      }
    );
  }

  onVerifyEmailDialog(email) {
    this.dialogRef = this.dialog.open(VerifyEmailComponent, {
      width: "550px",
      panelClass: "verifyEmail",
      disableClose: true,
      data: {
        type: "lead-info",
        subType: "sendEmail",
        category: "",
        email: email,
        details: {},
      },
    });
  }

  addRemoveCC(value) {
    this.showCC = value;
    if (this.showCC == false) {
      this.addEditEmailForm.patchValue({
        ccEmail: "",
      });
      this.checkCC = [];
      this.ccEmail = "";
    }
  }
  addRemoveBCC(value) {
    this.showBCC = value;
    if (this.showBCC == false) {
      this.addEditEmailForm.patchValue({
        bccEmail: "",
      });
      this.checkBCC = [];
      this.bccEmail = "";
    }
  }

  onAllowUniqueCcEmail(email) {
    let emails = email.split(",");
    emails = [...new Set([...emails])];
    this.checkCC = emails;
    emails = emails.filter((obj) => {
      return this.checkBCC.indexOf(obj) == -1;
    });
    this.ccArray = [];
    this.ccEmail = emails.toString();
    let emailValidate = this.ccEmail.split(",");
    let bool = emailValidate.map((obj) => {
      return MiscellaneousConstant.emailPattern.test(obj);
    });
    this.ccChecker = this.ccEmail ? bool.every(Boolean) : true;
    return this.ccEmail;
  }

  onAllowUniqueBccEmail(email) {
    let emails = email.split(",");
    emails = [...new Set([...emails])];
    this.checkBCC = emails;
    emails = emails.filter((obj) => {
      return this.checkCC.indexOf(obj) == -1;
    });
    this.bccArray = [];
    this.bccEmail = emails.toString();
    let emailValidate = this.bccEmail.split(",");
    let bool = emailValidate.map((obj) => {
      return MiscellaneousConstant.emailPattern.test(obj);
    });
    this.bccChecker = this.bccEmail ? bool.every(Boolean) : true;
    return this.bccEmail;
  }

  removeAttachmentArray(value) {
    this.editAttachmentArray = this.editAttachmentArray.filter(
      (item) => item.url !== value.url
    );
    this.fileSize = this.fileSize - Number(value?.size);
    this.selectedFileSize = 0;
  }

  removeImageArr(value) {
    this.duplicateImageArr = this.duplicateImageArr.filter(
      (item) => item.url !== value.url
    );
    this.fileSize = this.fileSize - Number(value?.size);
    this.selectedFileSize = 0;
  }

  onUploadLogo(output): void {
    this.uploadFiles = output.target.files;
    this.uploadAttachment();
  }

  async uploadAttachment() {
    if (this.uploadFiles.length > 0) {
      for (let i = 0; i < this.uploadFiles.length; i++) {
        const output = this.uploadFiles[i];
        this.fileCount = i + 1;
        this.uploadFile(output);
      }
    }
    this.uploadFiles = [];
  }

  uploadFile(fileData) {
    let endpoint = environment.baseUrl + BaseUrl.sharedUrl + "file-upload";
    if (this.data.moduleId) {
      endpoint += `?moduleId=${this.data.moduleId}&isPublic=1&type=0`;
    }

    var newformData: FormData = new FormData();
    const file: any = fileData;
    newformData.append("file", file, file.name);
    this._loaderService.start();
    this._sharedService.uploadFile(endpoint, newformData).subscribe(
      (response: ResponseModel) => {
        this.allFileCount += 1;
        if (this.allFileCount == this.fileCount) {
          this._loaderService.stop();
          this.allFileCount = 0;
        }
        if (response?.statusCode && response?.statusCode == 200) {
          if (response?.data.length > 0) {
            let imgData = response?.data[0];
            imgData["size"] = String(fileData?.size);
            this.duplicateImageArr.push(imgData);
          }
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }
}
