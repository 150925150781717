<body class="scrollhide">
  <!-- LOADER WITHOUT ID -->
  <ngx-ui-loader [hasProgressBar]="false"></ngx-ui-loader>

  <!-- LOADING WITH ID -->
  <ngx-ui-loader [loaderId]="'ALL'" [hasProgressBar]="false"></ngx-ui-loader>

  <!-- LOADING WITH ID FOR MY TASKS -->
  <ngx-ui-loader
    [loaderId]="'additionalLoader'"
    [hasProgressBar]="false"
  ></ngx-ui-loader>

  <!-- LOADER FOR NO INTERNET CONNECTION -->
  <ngx-ui-loader
    [fgsType]="'ball-scale-multiple'"
    [text]="
      'It seems your internet is not working. Please check your connection and Retry.'
    "
    [loaderId]="'NO-INTERNET'"
    [hasProgressBar]="false"
  ></ngx-ui-loader>

  <!-- HEADER -->
  <app-header id="navbar-wrapper" *ngIf="isNavVisible"></app-header>

  <!-- SIDEBAR -->
  <div id="wrapper" [class.pl-0]="!isNavVisible" [class.pr-0]="!isNavVisible">
    <app-sidebar *ngIf="isNavVisible"></app-sidebar>
    <router-outlet></router-outlet>
  </div>
</body>
