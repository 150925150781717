<!-- <div class="row">
  <div class="col">
    <span
      matDialogClose
      mat-dialog-close
      style="float: right; margin-right: 6px; margin-top: 4px"
      [class.disabled]="isCalling"
    >
      <img
        width="14px"
        height="14px"
        src="assets/images/close-black-1.svg"
        alt="close"
        style="margin-top: 8px; margin-bottom: 5px"
      />
    </span>
  </div>
</div> -->

<div class="dial-minisdiv" *ngIf="isMinimizedDis" (click)="minimizeDisplay()">
  <div class="re-agent-dial-left">
    <div class="re-agent-dialicon">
      <img src="assets/images/call-icon-white.png" alt="call" />
      {{
        data?.title != "" && data?.title != " " && data?.title != "  "
          ? data?.title
          : "N/A"
      }}
      •
      {{ _utilities.maskNumber(data?.phoneNumber) }}
    </div>
  </div>
  <div class="re-agent-dial-center" *ngIf="callReady">{{ callDuration }}</div>
  <div class="re-agent-dial-center" *ngIf="!callReady"></div>

  <div class="re-agent-dial-right">
    <span
      class="re-agent-expand"
      (click)="minimizeDisplay()"
      pTooltip="Maximize call window"
      tooltipPosition="bottom"
    >
      <img
        src="assets/images/icon-sqare.svg"
        alt="sqare"
        *ngIf="isMinimizedDis"
      />
    </span>
    <span
      class="re-agent-close"
      pTooltip="Close call window"
      [class.re-agent-cross-disable]="isCalling"
      tooltipPosition="bottom"
      matDialogClose
      mat-dialog-close
    >
      <img src="assets/images/close-white1.svg" alt="close" />
    </span>
  </div>
</div>

<div class="row" *ngIf="!isMinimizedDis">
  <div class="col">
    <!-- MINIMIZE -->
    <span
      style="float: right; margin-right: 6px; margin-top: 4px; cursor: pointer"
      (click)="minimizeDisplay()"
      *ngIf="isCalling"
    >
      <i class="fa fa-window-minimize" *ngIf="!isMinimized"></i>
      <i class="fa fa-window-maximize" *ngIf="isMinimized"></i>
    </span>

    <span
      matDialogClose
      mat-dialog-close
      style="float: right; margin-right: 6px; margin-top: 4px"
      *ngIf="!isCalling"
    >
      <img
        width="14px"
        height="14px"
        src="assets/images/close-black-1.svg"
        alt="close"
        style="margin-top: 8px; margin-bottom: 5px"
      />
    </span>
  </div>
</div>

<!-- Buy Credit -->
<mat-dialog-content class="mat-typography m-0 d-none">
  <div class="re-agent-caller-info text-center">
    <p class="re-agent-name">Buy Credit</p>
  </div>
  <div class="re-agent-card-info text-center">
    <p class="re-agent-caller-id">
      You have insufficient balance in your account. Please recharge to proceed.
    </p>
    <p class="re-agent-credit-info">Credit remains: <span>0</span> mins</p>
  </div>
  <div class="re-agent-form">
    <a class="btn btn-block re-agent-submit" type="button" mat-dialog-close>
      Buy Credit
    </a>
  </div>
</mat-dialog-content>

<mat-dialog-content
  class="mat-typography m-0"
  *ngIf="!isMinimizedDis && !isCalling"
>
  <div class="re-agent-caller-info text-center">
    <p class="re-agent-name">
      {{
        data?.title != "" && data?.title != " " && data?.title != "  "
          ? data?.title
          : "N/A"
      }}
    </p>
    <p class="re-agent-number">
      {{ _utilities.maskNumber(data?.phoneNumber) }}
      <span *ngIf="data?.numberType && data?.numberType !== 'invalid'">
        ({{ onNumberType(data?.numberType) }})
      </span>
      <span
        *ngIf="
          data?.numberType == '' ||
          data?.numberType == 'invalid' ||
          data?.numberType == null
        "
      >
        (N/A)
      </span>
    </p>
    <!-- <p class="re-agent-type" *ngIf="!data?.isContact">
      {{ data?.contactType }}
    </p> -->
    <!-- <p class="re-agent-type" *ngIf="data?.isContact"> -->
    <p class="re-agent-type">
      <span *ngFor="let item of data?.contactType">
        <span *ngIf="item == 1">Buyer &nbsp;</span>
        <span *ngIf="item == 2">Seller &nbsp; </span>
        <span *ngIf="item == 3">Vendor &nbsp;</span>
        <span *ngIf="item == 4">Agent &nbsp;</span>
        <span *ngIf="item == 5">Friend & Family &nbsp;</span>
      </span>
    </p>
  </div>
  <div class="re-agent-card-info text-center">
    <p class="re-agent-caller-id">
      You are calling from
      <strong>{{ _utilities.maskNumber(leadSource?.value) }}</strong> as your
      outbound caller ID.
    </p>
    <p class="re-agent-credit-info">
      Credit remains: <span>{{ data?.callBalance }}</span> mins
    </p>
  </div>
  <div class="re-agent-form">
    <form
      [formGroup]="callDetailForm"
      (ngSubmit)="makeCall(data)"
      autocomplete="off"
      novalidate
    >
      <div class="form-group">
        <label class="re-labels"
          >Change Caller ID <span class="re-required">*</span></label
        >
        <ng-container>
          <select name="" formControlName="leadSource" class="form-control">
            <option selected value="">Select number</option>
            <option
              *ngFor="let source of campaigns"
              [value]="_utilities.maskNumber(source.number)"
            >
              {{ _utilities.maskNumber(source.number) }}
            </option>
          </select>
        </ng-container>
        <!-- <input
          type="text"
          name="callerId"
          formControlName="leadSource"
          class="form-control"
          placeholder="Please enter number"
          mask="(000) 000-0000"
          [class.disabled]="isMarketReadonly ? 'disabled' : null"
        /> -->
      </div>
      <button class="btn btn-block re-agent-submit" type="submit">
        Make Call
      </button>
    </form>
  </div>
</mat-dialog-content>

<!-- CALLING -->
<mat-dialog-content
  class="mat-typography m-0"
  *ngIf="isCalling && !isMinimizedDis"
>
  <div class="re-agent-caller-info text-center">
    <p class="re-agent-name">
      {{
        data?.title != "" && data?.title != " " && data?.title != "  "
          ? data?.title
          : "N/A"
      }}
    </p>
    <p class="re-agent-number">
      {{ _utilities.maskNumber(data?.phoneNumber) }}
      <span *ngIf="data?.numberType && data?.numberType !== 'invalid'">
        ({{ onNumberType(data?.numberType) }})
      </span>
      <span
        *ngIf="
          data?.numberType == '' ||
          data?.numberType == 'invalid' ||
          data?.numberType == null
        "
      >
        (N/A)
      </span>
    </p>
  </div>
  <div class="re-agent-card-info text-center">
    <ng-container>
      <p class="re-agent-count" *ngIf="callReady">{{ callDuration }}</p>
      <p class="re-agent-count" *ngIf="!callReady">00:00:00</p>
      <p class="re-agent-number-info">
        Calling from
        <span class="re-black">
          {{ _utilities.maskNumber(leadSource.value) }}
        </span>
      </p>
      <p class="re-agent-number-info">
        Call credit remains:
        <span class="re-red">{{ data?.callBalance }}</span> mins
      </p>
    </ng-container>

    <div
      class="re-agent-dialpad-bottom"
      *ngIf="isDialerPadOpen && !showMemberList"
    >
      <div class="text-right">
        <a class="re-agent-dialclose" (click)="isDialerPadOpen = false"
          ><img src="assets/images/close-black-1.svg" alt="close-black"
        /></a>
      </div>
      <div class="re-agent-numbshow">
        {{ dialedNumber }}
        <img
          class="re-agent-errase"
          src="assets/images/icon-backspace.svg"
          alt="backspace"
          (click)="removeDialer()"
        />
      </div>
      <div class="re-agent-keypad">
        <div class="re-agent-row">
          <div class="re-agent-digit" (click)="dialer('1')">1</div>
          <div class="re-agent-digit" (click)="dialer('2')">2</div>
          <div class="re-agent-digit" (click)="dialer('3')">3</div>
        </div>
        <div class="re-agent-row">
          <div class="re-agent-digit" (click)="dialer('4')">4</div>
          <div class="re-agent-digit" (click)="dialer('5')">5</div>
          <div class="re-agent-digit" (click)="dialer('6')">6</div>
        </div>
        <div class="re-agent-row">
          <div class="re-agent-digit" (click)="dialer('7')">7</div>
          <div class="re-agent-digit" (click)="dialer('8')">8</div>
          <div class="re-agent-digit" (click)="dialer('9')">9</div>
        </div>
        <div class="re-agent-row">
          <div class="re-agent-digit" (click)="dialer('*')">*</div>
          <div class="re-agent-digit" (click)="dialer('0')">0</div>
          <div class="re-agent-digit" (click)="dialer('#')">#</div>
        </div>
      </div>
    </div>
    <div class="re-agent-callbtn">
      <div class="re-agent-callfeature">
        <a
          class="re-agent-callicon re-mute"
          pTooltip="Unmute"
          tooltipPosition="bottom"
          (click)="unmute()"
          *ngIf="muteCall"
          ><img src="assets/images/icon-micoff.svg" alt="micoff"
        /></a>
        <a
          class="re-agent-callicon"
          (click)="mute()"
          *ngIf="!muteCall"
          pTooltip="Mute"
          tooltipPosition="bottom"
          ><img src="assets/images/icon-micoff.svg" alt="micoff"
        /></a>
        <a
          class="re-agent-callicon"
          pTooltip="Keypad"
          tooltipPosition="bottom"
          (click)="dialPad()"
          ><img src="assets/images/icon-dialpad.svg" alt="dialpad"
        /></a>
        <a
          class="re-agent-callicon"
          pTooltip="Hold with music"
          tooltipPosition="bottom"
          (click)="hold()"
          *ngIf="!holdCall"
          ><img src="assets/images/Call-hold.png" alt="transfer"
        /></a>
        <a
          class="re-agent-callicon re-hold"
          pTooltip="Resume call"
          tooltipPosition="bottom"
          (click)="unHold()"
          *ngIf="holdCall"
          ><img src="assets/images/Call-hold.png" alt="transfer"
        /></a>
      </div>
      <a
        class="re-agent-callonoff"
        pTooltip="End Call"
        tooltipPosition="bottom"
        (click)="cancel()"
        ><img src="assets/images/call-off.png" alt="call-off"
      /></a>
    </div>
  </div>
</mat-dialog-content>
