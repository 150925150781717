//MODULE
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { saveAs } from "file-saver";
import { Router } from "@angular/router";

// MODEL AND CONSTANT
import { ErrorModel } from "src/app/utils/models/error";
import { MessageConstant } from "src/app/utils/message-constant";
import { ResponseModel } from "src/app/utils/models/response";
import { MiscellaneousConstant } from "src/app/utils/miscellaneous-constant";
import { TwilioErrorMessageConstant } from "../../utils/twilioErrorMessage-constant";
import { AppointmentTypeConstant } from "src/app/utils/appointment-constants";

// SERVICES
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { SharedService } from "../shared.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { CreditService } from "src/app/providers/credit/credit.service";
import { UserService } from "src/app/providers/user/user.service";
import { LeadsService } from "src/app/providers/leads/leads.service";
import { QuickFilterService } from "src/app/providers/quickFilter/quick-filter.service";
import { TaskService } from "src/app/providers/task/task.service";
import { StatusConstant } from "src/app/utils/status-constant";

// COMPONENTS
import { DeleteDialogComponent } from "../dialog/delete-dialog/delete-dialog.component";
import { PaginationComponent } from "../pagination/pagination.component";
import { AddEditTaskComponent } from "src/app/modules/leads/leads-details/task/add-edit-task/add-edit-task.component";
import { ConfirmationDialogComponent } from "../dialog/confirmation-dialog/confirmation-dialog.component";
import { MediaPreviewComponent } from "../dialog/media-preview/media-preview.component";
import { AddEditFolderFilesModalComponent } from "../files/dialog/add-edit-folder-files-modal/add-edit-folder-files-modal.component";
import { AddCardDialogComponent } from "../dialog/add-card-dialog/add-card-dialog.component";
import { CopyCallFlowComponent } from "../dialog/copy-call-flow/copy-call-flow.component";
import { AddEditSmsTemplateComponent } from "../dialog/add-edit-sms-template/add-edit-sms-template.component";
import { AddEditEmailTemplateDailogComponent } from "../dialog/emails-dailog/add-edit-email-template-dailog/add-edit-email-template-dailog.component";
import { ViewMembersListDialogComponent } from "../dialog/view-members-list-dialog/view-members-list-dialog.component";
import { AddEditTaskTemplateComponent } from "../dialog/add-edit-task-template/add-edit-task-template.component";
import { AddEditAppointmentsComponent } from "src/app/modules/leads/leads-details/task/add-edit-appointments/add-edit-appointments.component";
import { AddEditRvmTemplateComponent } from "../dialog/add-edit-rvm-template/add-edit-rvm-template.component";
import { LeadConnectDialogComponent } from "../dialog/lead-connect-dialog/lead-connect-dialog.component";
import { ConfirmationNumberDialogComponent } from "../dialog/confirmation-number-dialog/confirmation-number-dialog.component";
import { CopyDripComponent } from "../dialog/copy-drip/copy-drip.component";
import { EditCampaignComponent } from "../dialog/edit-campaign/edit-campaign.component";
import { ManageListDialogComponent } from "../dialog/manage-list-dialog/manage-list-dialog.component";
import { TeamDeleteComponent } from "../dialog/team-delete/team-delete.component";
import { SmsDialogComponent } from "../dialog/sms-dialog/sms-dialog.component";
import { CallDetailDailogsComponent } from "src/app/shared/dialog/call-detail-dailogs/call-detail-dailogs.component";
import { CampaignStatsComponent } from "../dialog/campaign-stats/campaign-stats.component";

@Component({
  selector: "app-list-view",
  templateUrl: "./list-view.component.html",
  styleUrls: ["./list-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ListViewComponent implements OnInit {
  @ViewChild("pagination") pagination: PaginationComponent;

  @Input() paginationType: string = environment.pagination.type;
  @Input() header: any[] = [];
  @Input() quickFilterList: any[] = [];
  @Input() data: any = {};
  @Input() moduleName: any = "";
  @Input() moduleId: any = "";
  @Input() hoverText: any = "";
  @Input() hoverTooltipText: any = "";
  @Input() id: any = "";
  @Input() listClass: string = "";
  @Input() customLoader: string = "";
  @Input() customLoaderMessage: string = "";
  @Input() isMultiSelectEnabled: boolean = false;
  @Input() style: any;
  @Input() inlineAddForm: FormGroup;
  @Input() customFunction: any;
  @Input() userListing: any[] = [];
  @Input() secondaryData: any;
  @Input() selectedItem: any[] = [];
  @Input() currentUser: any = {};
  @Input() selectActionType: string = "";
  @Input() quickFilterView: boolean = false;
  @Input() userList: any[] = [];
  @Input() teams: any[] = [];
  @Input() userPlan: any;
  @Input() address: any = {};
  @Input() filterObj: any = {};

  @Output() _changePage = new EventEmitter<any>();
  @Output() _changeLimit = new EventEmitter<any>();
  @Output() _emitter = new EventEmitter<any>();
  @Output() _emitFormSubmit = new EventEmitter<any>();
  @Output() _changeSort = new EventEmitter<any>();
  @Output() _refresh = new EventEmitter<any>();
  @Output() _selectedItem = new EventEmitter<any>();
  @Output() _showActiveDropdown = new EventEmitter<any>();
  @Output() _leadDetailsEmitter = new EventEmitter<any>();
  @Output() _contactDetailsEmitter = new EventEmitter<any>();
  @Output() _agentContactDetailsEmitter = new EventEmitter<any>();
  @Output() _applyFilter = new EventEmitter<any>();

  keys = Object.keys;
  dialogRef: any;
  messageConstant = MessageConstant;
  twilioErrorMessageConstant = TwilioErrorMessageConstant;
  miscellaneousConstant = MiscellaneousConstant;
  activeIconIndex: number = -1;
  activeIcon: string = "";
  currentPage: number = 1;
  // currentLimit: number = MiscellaneousConstant.paginationLimit.STANDARD;
  sort: string = environment.pagination.defaultSort;
  isInlineFormSubmitted: boolean = false;
  sortClass: string = "";
  sortVal: string = "";
  leadConnectData: any;
  leadConnectValue: any;
  leadConnectIndex: any;
  allRolesKey: any[] = MiscellaneousConstant.allRolesKey;
  toggle = false;
  butDisabled: boolean = true;
  SMSBalance: number = 0;
  callBalance: number = 0;
  activeFilterIndex: number = 0;
  mainQuickFilterList: any = [];
  subQuickFilterList: any = [];
  subFilter: any = "clear";

  constructor(
    public _sharedService: SharedService,
    private dialog: MatDialog,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    public utilities: CommonFunctionsService,
    private _taskService: TaskService,
    private _creditService: CreditService,
    private _userService: UserService,
    private _router: Router,
    private _leadsService: LeadsService,
    private _quickFilterService: QuickFilterService
  ) {
    this.inlineAddForm = new FormGroup({});
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.moduleName == "Leads") {
        this.getQuickFilter();
      }
    }, 2000);
  }

  onScroll() {
    if (this.paginationType !== MiscellaneousConstant.paginationType.INFINITE)
      return;
    this.currentPage += 1;
    this._changePage.emit(this.currentPage);
  }

  checkLoader() {
    if (this.paginationType === MiscellaneousConstant.paginationType.INFINITE)
      return true;
    return !this._sharedService.checkLoader(this.moduleName);
  }

  contactTypeClass(value) {
    let msg = "";
    if (Array.isArray(value)) {
      for (let index = 0; index < value.length; index++) {
        msg +=
          `<span><span class ="` +
          this.setClass(value[index]) +
          `">` +
          this.setValue(value[index]) +
          `</span></span>`;
      }
    } else {
      msg +=
        `<span><span class ="` +
        this.setClass(value) +
        `">` +
        this.setValue(value) +
        `</span></span>`;
    }
    return msg;
  }

  setValue(key) {
    return key == 1
      ? "B"
      : key == 2
      ? "S"
      : key == 3
      ? "V"
      : key == 4
      ? "A"
      : key == 5
      ? "F-F"
      : "None";
  }

  setClass(key) {
    return key == 1
      ? "re-buyer-tag"
      : key == 2
      ? "re-seller-tag"
      : key == 3
      ? "re-vendor-tag"
      : key == 4
      ? "re-agent-tag"
      : key == 5
      ? "re-ff-tag"
      : "";
  }

  getIcon(item?, type?) {
    let name = "";
    let imageType = "";
    if (type?.iconType === "FILE") {
      name = item?.fileType;
    } else if (type?.iconType === "CALL_LOGS") {
      name = item?.type;
    } else if (item?.iconType === "TASKS") {
      name = item?.type;
    } else if (item?.iconType === "CARDS") {
      name = item?.type;
      imageType = item?.type;
    } else if (type?.iconType === "SHARE_SOW") {
      if (item?.sharing) name = "share-tilt.svg";
      else name = "share-black.svg";
    }

    if (!name) {
      return;
    }

    if (name === "doc" || name === "docx") {
      imageType = "doc_icon.png";
    }

    if (name === "pdf") {
      imageType = "pdf_icon.png";
    }

    if (name === "mp4") {
      imageType = "mp4_icon.png";
    }

    if (name === "mp3") {
      imageType = "mp3_icon.png";
    }

    if (name === "gif") {
      imageType = "gif_icon.png";
    }

    if (name === "png") {
      imageType = "png_icon.png";
    }

    if (name === "xls" || name === "xlsx") {
      imageType = "xls_icon.png";
    }

    if (name === "jpeg" || name === "jpg") {
      imageType = "jpeg_icon.png";
    }

    // TASKS
    if (name === "reminder") {
      imageType = "bell.svg";
    }

    if (name === "description") {
      imageType = "description.svg";
    }
    if (name === "ico") {
      imageType = "ico_icon.png";
    }
    if (name === "svg") {
      imageType = "svg_icon.png";
    }
    if (name === "zip") {
      imageType = "zip_icon.png";
    }
    if (name === "txt") {
      imageType = "txt_icon.png";
    }
    if (name === "rar") {
      imageType = "rar_icon.png";
    }
    if (name === "json") {
      imageType = "json_icon.png";
    }
    if (name === "html") {
      imageType = "html_icon.png";
    }
    if (name === "dwg") {
      imageType = "dwg_icon.png";
    }
    if (name === "ai") {
      imageType = "ai_icon.png";
    }
    if (name === "csv") {
      imageType = "csv_icon.png";
    }
    if (name === "webp") {
      imageType = "webp_icon.png";
    }

    // OUTGOING
    if (name === "outgoingCall") {
      // imageType = "outgoing-calls.svg";
      imageType = "incoming-call.png";
    }

    if (name === "outgoingSms") {
      // imageType = "outgoing-smss.svg";
      imageType = "outgoing-call.png";
    }

    if (name === "outgoingMms") {
      // imageType = "outgoing-smss.svg";
      imageType = "outgoing-call.png";
    }

    // INCOMING
    if (name === "incomingCall") {
      imageType = "incoming-calls.svg";
    }

    if (name === "incomingCall" && item?.communicationType == 4) {
      imageType = "voicemail-rvm.svg";
    }

    if (name === "incomingCall" && item?.communicationType == 3) {
      imageType = "voice-mail.svg";
    }

    if (
      name === "incomingCall" &&
      (item?.status == "no-answer" ||
        item?.status == "cancel" ||
        item?.status == "no-confirm" ||
        item?.status == "busy")
    ) {
      imageType = "missed-calls.svg";
    }

    if (name === "incomingSms") {
      imageType = "incoming-smss.svg";
    }

    if (name === "incomingMms") {
      imageType = "incoming-smss.svg";
    }

    let imageIconUrl = "/assets/images/" + imageType;
    return imageIconUrl;
  }

  getImageIcon(item, type?) {
    try {
      if (!item) {
        return;
      }
      let imageUrl = "/assets/images/demo-profile.png";
      if (item?.profileImage) {
        imageUrl = item?.profileImage;
      } else {
        imageUrl = this.getIcon(item, type);
      }
      return imageUrl;
    } catch (error) {
      return "";
    }
  }

  getValue(data, header, isTooltipExists?) {
    try {
      if (!header || !data) {
        return;
      }

      let value = "";
      if (header.key && header.subKey) {
        if (header?.key === "numberData" && header?.subKey === "number") {
          const temp = [];
          data[header.key].forEach((dataNumber) => {
            temp.push(this.utilities.maskNumber(dataNumber.number));
          });
          value = temp?.length > 0 ? temp?.length + " Numbers" : "N/A";
        } else if (
          header?.key === "agent" &&
          header?.subKey === "profileImage"
        ) {
          value = data?.[header.key]?.[header.subKey]
            ? data[header.key][header.subKey]
            : "/assets/images/profile.svg";
        } else if (header.key == "leadInfo" && header.subKey == "address") {
          value = data["leadInfo"]
            ? this.utilities.formatAddress(
                data["leadInfo"]["address"],
                data["leadInfo"]["unitNo"]
              )
            : this.utilities.formatAddress(
                data["propertyData"]["address"],
                data["propertyData"]["unitNo"]
              );
          value = value
            ? value
            : header?.allowZero
            ? "0"
            : header?.emptyCheck
            ? ""
            : "N/A";
        } else {
          value = data?.[header.key]?.[header.subKey]
            ? data[header.key][header.subKey]
            : header?.allowZero
            ? "0"
            : header?.emptyCheck
            ? ""
            : "N/A";
          if (header?.isLeadsList) {
            value = data?.[header.key][0][header.subKey]
              ? data[header.key][0][header.subKey]
              : "N/A";
          }
        }
      } else if (header.key == "address" || header.key == "propertyAddress") {
        value =
          typeof data[header.key] != "undefined" && data[header.key] !== ""
            ? this.utilities.formatAddress(data[header.key], data["unitNo"])
            : "N/A";
      } else if (header.key && header.options) {
        const item = header.options.find((x) => x.value === data[header.key]);
        value = item?.label || "N/A";
      } else if (header.key) {
        value =
          typeof data[header.key] != "undefined" &&
          data[header.key] !== "" &&
          data[header.key] !== " "
            ? data[header.key]
            : header?.allowZero
            ? "0"
            : header?.emptyCheck
            ? ""
            : this.moduleName == "List Stacking" &&
              header?.key == "ownershipType"
            ? "Individual"
            : "N/A";
      } else if (header.value) {
        value = header.value;
      } else {
        if (header?.allowZero) {
          value = "0";
        } else if (header?.emptyCheck) {
          value = "";
        } else {
          value = "N/A";
        }
      }

      // TYPE
      if (header?.type === "RVM" || header?.type === "Email") {
        value = this.getValuesRVMFromId(value);
      }
      if (header?.type === "BOOLEAN") {
        value =
          data[header.key] == true ? data?.lead_property_status || "Yes" : "No";
      }
      if (header?.type === "BOTH") {
        value =
          data[header?.key][header?.subKey] +
          " " +
          data[header?.key][header?.subKey2];
      }

      if (header?.type === "TEAM") {
        value = this.getTeam(data[header?.key]);
      }

      if (header?.count && Array.isArray(data[header.key])) {
        value = data[header.key]?.length;
      }

      if (header?.countLs && Array.isArray(data[header.key])) {
        value = data[header.key];
      }

      if ((header?.count || header?.length) && data[header.key]) {
        let dataValue = [...new Set([...data[header.key]])];
        value = `${dataValue?.length}` || "0";
      }

      if (header?.length && Array.isArray(header.key)) {
        value = "0";
        header?.key?.filter((key) => {
          let count = parseInt(value);
          count += data[key]?.length || 0;
          value = `${count}`;
        });
        // if (header?.label != "Deals") value = `${value} ${header?.label}`;
      }

      if (header?.uniqueLength && typeof data[header?.key] === "object") {
        var assignUserData = data[header?.key];
        value = "0";
        let item = [];
        assignUserData.map((x) => {
          item.push(x.userId);
        });
        let union = [...new Set([...item])];
        item = union;
        // Object.keys(data[header?.key]).forEach((key) => {
        //   item = [...item, ...data[header?.key].key];
        // });
        // item = [...new Set(item)];
        value = `${item.length} ${header?.uniqueViewTag}`;
      }

      if (header?.hoverKey) {
        value = data[header?.hoverKey] || "";
      }

      if (header?.isThousandMultiplier) {
        value = `${parseInt(value) * 1000}`;
      }

      if (header.type === "APPOINTMENT_PROFILE") {
        value = this.getOwner(data).profile;
      }

      if (header.label === "Delivered At") {
        if (data?.newStats?.status != "delivered") {
          value = "N/A";
        }
      }

      if (header.label === "Not Delivered At") {
        if (data?.newStats?.status == "delivered") {
          value = "N/A";
        }
      }

      return value;
    } catch (error) {
      return "";
    }
  }

  getOwner(task) {
    let name = "";
    let profile = "";
    this.userListing.filter((x) => {
      if (x?._id === task?.assignUserId[0] || x?._id === task?.assignUserId) {
        name = x?.firstName + " " + x?.lastName;
        profile = x?.profileImage || "/assets/images/profile.svg";
      }
    });

    return { name, profile };
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.inlineAddForm.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    this.isInlineFormSubmitted = true;

    if (this.inlineAddForm.invalid) return;

    this._emitFormSubmit.emit(this.inlineAddForm);
  }

  onChangeSort(event) {
    let sortBy = "";
    if (event instanceof Object) {
      if (event.subKey) {
        sortBy = event.key + "." + event.subKey;
      } else {
        sortBy = event.key;
      }
    } else {
      sortBy = event;
    }

    this.sort = this.sort === "ASC" ? "DESC" : "ASC";
    let obj = {
      sortMode: this.sort === "ASC" ? 1 : -1,
      sortBy: sortBy,
    };

    this.sortClass = this.sort === "ASC" ? "re-sort re-asc" : "re-sort re-desc";
    this.sortVal = event;
    this._changeSort.emit(obj);
  }

  callNumberType(item, numberType) {
    const type = this.utilities.maskNumber(numberType);
    return type || "N/A";
  }

  checkKey(head, data) {
    if (!head?.checkKey) return false;
    return data[head?.checkKey];
  }

  getCurrency(data, header) {
    try {
      let value = 0;
      if (header.key && header.subKey) {
        value = data[header.key][header.subKey]
          ? data[header.key][header.subKey]
          : 0;
      } else if (header.key) {
        value = data[header.key] ? data[header.key] : 0;
      } else if (header.value) {
        value = header.value ? header.value : 0;
      } else {
        value = 0;
      }

      if (header?.length) {
        value = data[header.key] ? data[header.key].length : "0";
      }
      return value;
    } catch (error) {
      return 0;
    }
  }

  getColorCode(item, head) {
    const timeStamp = item[head?.key];
    if (!timeStamp) return;

    const day = moment(timeStamp).format("YYYY-MM-DD");
    const currentDay = moment().format("YYYY-MM-DD");

    if (moment(day).isBefore(currentDay)) {
      return "#800000";
    }

    if (moment(day).isAfter(currentDay)) {
      return "#4fa563";
    }

    if (moment(day).isSame(currentDay)) {
      return "#b76f1e";
    }
  }

  timeAgo(text) {
    if (!text || typeof text === "object" || text === "N/A") {
      return "N/A";
    }
    return moment(new Date(text)).fromNow();
  }

  formatedDate(expectedStartDate) {
    if (!expectedStartDate) return "N/A";
    const dateStr = expectedStartDate;
    const dateParts = dateStr.split("-");
    let formattedDate = "NA";
    if (dateParts.length == 3) {
      let monthData = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      formattedDate =
        monthData[parseInt(dateParts[0]) - 1] +
        " " +
        dateParts[1] +
        ", " +
        dateParts[2];
    }
    return formattedDate;
  }

  getActionIcon(action, actionIndex) {
    return this.activeIconIndex === actionIndex &&
      this.activeIcon === action?.type
      ? action?.activeIcon
      : action?.icon;
  }

  callAction(action, item, index, moduleId?, i?) {
    const type = action.type || action.event;
    this._toastrService.clear();
    if (
      item?.isActionDisabled &&
      (action?.label == "Delete" || action?.label == "Edit")
    ) {
      this._toastrService.error(
        "",
        item.disabledDescription.replace("@ACTION", action.label)
      );
      return;
    }

    if (
      (item?.isReadOnly &&
        (action?.label == "Edit" ||
          action?.label == "Toggle" ||
          action?.label == "Delete")) ||
      (action?.isEnabled && !item?.isEnabled)
    ) {
      this._toastrService.error(
        "",
        action?.disabledText.replace(
          "{{TYPE}}",
          `${item.isReadOnly ? "readonly" : "disabled"}`
        )
      );
      return;
    }
    switch (type) {
      case "EDIT_DATA_LIST":
        this.onEditList("users", item, index);
        break;
      case "DELETE_TEAMS":
        this.onDeleteDialog("teams", item, index);
        break;
      case "DELETE_SUB_USER":
        this.onDeleteDialog("users", item, index);
        break;
      case "DELETE_LEAD":
        this.onDeleteDialog("lead", item, index);
        break;
      case "EDIT_LEAD":
        this.onEditList("lead", item, index);
        break;
      case "EDIT_LOCAL_TASK":
        this.onEditLocalTaskDialog("Update", item, index);
        break;
      case "EDIT_CONTACT_TASK":
        this.onEditList("Update", item, index);
        break;
      case "DELETE_TASK":
        this.onDeleteTaskDialog(item);
        break;
      case "DELETE_FILE":
        this.onDeleteDialog("file", item, index);
        break;
      case "DOWNLOAD_FILE":
        this.downloadFile(item);
        break;
      case "SHARE_FILE":
        this.openAddEditFilesModal("share", item, index);
        break;
      case "PREVIEW":
        this.preview(item, index);
        break;
      case "EDIT_FILE":
        this.openAddEditFilesModal("edit", item, index);
        break;
      case "EDIT_DATA_TEAMS":
        this.onEditList("teams", item, index);
        break;
      case "EDIT_CARD":
        this.onEditCard(item, index);
        break;
      case "DELETE_CARD":
        if (item.isDefaultCard) {
          this._toastrService.error("You can not delete this card", "");
        } else {
          this.onDeleteCardDialog("cards", item, index);
        }
        break;
      case "DELETE_BLOCK_NUMBER":
        this.onBlockNumberDeleteDialog("blockNumber", item, index);
        break;

      case "EDIT_FLOW":
        window.open(`/call-flow/edit/${item._id}`, "_self");
        break;

      case "COPY_FLOW":
        this.onFlowCopyDialog("Flow", "copy", item, index);
        break;

      case "DELETE_FLOW":
        this.onDeleteFlow("Call Flow", item, index);
        break;

      case "EDIT_SMS":
        this.onEditSmsDialog("Edit", item, index, moduleId);
        break;

      case "DELETE_SMS":
        this.onDeleteDialog("sms", item, index);
        break;

      case "EDIT_EMAIL":
        this.onEditEmailDialog("Edit", item, index, moduleId);
        break;

      case "DELETE_EMAIL":
        this.onDeleteDialog("email", item, index);
        break;
      case "DELETE_RVM":
        this.onDeleteDialog(
          this.moduleName == "Dialer Voicemail"
            ? "Dialer Voicemail"
            : "RVM Template",
          item,
          index
        );
        break;
      case "EDIT_TASK_TEMPLATE":
        this.onEditTaskTemplateDialog("Update", item);
        break;
      case "DELETE_TASK_TEMPLATE":
        this.onDeleteDialog("taskTemplate", item, index);
        break;
      case "MEMBERS_LIST":
        this.onViewMembersList(item, false);
        break;
      case "EDIT_APPOINTMENT":
        this.onAppointmentDialog(item);
        break;
      case "COPY_MAIL_TEMPLATE":
        this.onMailTemplateCopyDialog(item);
        break;
      case "EDIT_RVM":
        this.onAddEditRvmDialog(item, index);
        break;
      case "DELETE_NUMBER":
        this.onDeleteNumberDialog("Number", item, index);
        break;

      case "COPY_DRIP":
        this.onDripCopyDialog("Drip", "copy", item, index);
        break;

      case "STOP_DRIP":
        this.onDripCopyDialog("Drip", "stop", item, index);
        break;

      case "DELETE_DRIP":
        this.onDripDeleteDialog("Drip", item, index);
        break;

      case "EDIT_CAMPAIGN_NUMBER":
        this.onEditNumberDialog(item, index);
        break;

      case "EDIT_DRIP":
        let redirectLink = `/drip-campaigns/edit-drip-campaign`;
        const navigationDetails: string[] = [redirectLink, item._id];
        this._router.navigate(navigationDetails);
        break;

      case "LEAD_DETAILS":
        if (action?.isTargetBlank) {
          item.bool = true;
        }
        this.getLeadDetails(item);
        break;

      case "INBOX_DETAILS":
        this._router.navigate(["/inbox-details"]);
        break;

      case "CONTACT_DETAILS":
        this.getContactDetails(item);
        break;

      case "DELETE_OFFER":
        this.onDeleteDialog("Offer", item, index);
        break;
      case "DELETE_OPEN_HOUSE":
        this.onDeleteDialog("Open House", item, index);
        break;
      case "EDIT_OFFER":
        this.onEditList("offer", item, index);
        break;
      case "EDIT_OPEN_HOUSE":
        this.onEditList("Edit", item, index);
        break;
      case "EDIT_SHOWING":
        this.onEditList("Edit", item, index, moduleId);
        break;
      case "DETAILS":
        this.getDetails(item, false);
        break;
      case "AGENT_CONTACT_DETAILS":
        this.getAgentContactDetails(item);
        break;
      case "EDIT_SCHEDULED_SMS":
        this.onEditScheduledSMS(item, index);
        break;
      case "DELETE_SCHEDULED_SMS":
        this.onDeleteSMSScheduledDialog("Scheduled SMS", item, index);
        break;
      case "DELETE_CONTACT":
        this.onDeleteDialog("contact", item, index);
        break;
      case "EDIT_CONTACT":
        this.onEditList("contact", item, index);
        break;
      case "DELETE_FOLLOW_UP_LEAD":
        this.onDeleteDialog("Follow Up Lead", item, index);
        break;
      case "EDIT_FOLLOW_UP_LEAD":
        this.onEditList("follow up lead", item, index);
        break;
      case "DELETE_DEAD_LEAD":
        this.onDeleteDialog("Dead Lead", item, index);
        break;
      case "EDIT_DEAD_LEAD":
        this.onEditList("dead lead", item, index);
        break;
      case "CALL_DETAILS":
        this.getDetailsCall(item);
        break;
      case "SMS_STATS":
        this.getSMSStats(action, item);
        break;
      case "EMAIL_STATS":
        this.getEmailStats(action, item);
        break;
    }
  }

  onDeleteDialog(type, data, index) {
    if (type == "teams") {
      this.dialogRef = this.dialog.open(TeamDeleteComponent, {
        width: "500px",
        data: {
          details: data,
        },
      });
    } else {
      this.dialogRef = this.dialog.open(DeleteDialogComponent, {
        width: "500px",
        data: {
          subModule:
            type === "email"
              ? "Email Template"
              : type === "sms"
              ? "SMS Template"
              : type === "taskTemplate"
              ? "Task Template"
              : type === "users"
              ? "User"
              : type === "contact"
              ? "Contact"
              : type === "Dead Lead"
              ? "Dead Lead"
              : type === "Follow Up Lead"
              ? "Follow Up Lead"
              : type,
          subModuleId: this.id,
          moduleId: this.moduleId,
          details: data,
          index,
        },
      });
    }
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: type,
          type: "delete",
          index,
          result,
          details: data,
        });
      }
    });
  }

  onEditList(type, data, index, bool?) {
    this._emitter.emit({
      module: type,
      type: "edit",
      index,
      bool: bool,
      details: data,
    });
  }

  onChangePage(page) {
    this._changePage.emit(page);
  }

  onChangeLimit(limit) {
    this._changeLimit.emit(limit);
  }

  onEditLocalTaskDialog(event, taskObj, index) {
    taskObj.isCompleted = taskObj.hasOwnProperty("isCompleted")
      ? taskObj.isCompleted
      : false;
    this._loaderService.start();
    this.dialogRef = this.dialog.open(AddEditTaskComponent, {
      width: "600px",
      data: {
        type: "task",
        subType: "task",
        category: event,
        task: taskObj,
        id: taskObj._id,
        userList: this.userList,
        currentUser: taskObj.user,
        leadInfo: taskObj.leadInfo
          ? taskObj.leadInfo
          : taskObj.propertyData
          ? null
          : {},
        isLocalTask: true,
        isGetTeams: false,
        isGeneralTask: true,
        isDisable: true,
        activeTask: "Lead",
        isTabs: true,
        isHideHeader: true,
      },
    });
    this._loaderService.stop();
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        delete result["mainStatusId"];
        this._taskService.updateTask(result, taskObj._id).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              this._sharedService.refreshActivityLog.next("task");
              this._loaderService.stop();
              this._emitter.emit({
                module: "Local Tasks",
                type: "edit",
                index,
                result,
              });
              this._toastrService.success(this.messageConstant.taskUpdate, "");
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              if (error?.platform) {
                this._toastrService.error(error.platform, "");
              } else {
                this._toastrService.error(error.message, "");
              }
            } else {
              this._toastrService.error(this.messageConstant.unknownError, "");
            }
          }
        );
      }
    });
  }

  onDeleteTaskDialog = (task): void => {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "450px",
      data: {
        header: "Confirmation",
        text: `Are you sure you want to delete task`,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        this._taskService.deleteTask(task._id).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              this._sharedService.refreshInfo.next(true);
              this._sharedService.refreshActivityLog.next(true);
              this._loaderService.stop();
              this._emitter.emit({
                module: "Local Tasks",
                type: "list",
                index: 0,
                result,
              });
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              if (error?.platform) {
                this._toastrService.error(error.platform, "");
              } else {
                this._toastrService.error(error.message, "");
              }
            } else {
              this._toastrService.error(this.messageConstant.unknownError, "");
            }
          }
        );
      }
    });
  };

  onEditTaskTemplateDialog(event, taskObj) {
    this.dialogRef = this.dialog.open(AddEditTaskTemplateComponent, {
      width: "600px",
      data: {
        type: "taskTemplate",
        subType: "taskTemplate",
        category: event,
        task: taskObj,
        id: taskObj._id,
        userList: this.userListing,
        currentUser: this.currentUser,
        isDisable: true,
        isLocalTask: this.moduleName == "Local Tasks" ? true : false,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: "taskTemplate",
          type: "update",
          result: result,
        });
      }
    });
  }

  changeValue(head, item, $event) {
    switch (head.key) {
      case "appointmentStatus":
        this.updateAppointmentStatus(item, $event);
        break;
      case "transactionLeadId":
        this.updateLeadMainStatus(item, $event);
        break;
    }
  }

  selectAll($event) {
    $event.stopPropagation();
    this.data["items"].map((x) => (x.isCompleted = $event.target.checked));
    this.selectedItem = this.data["items"].filter((x) => x.isCompleted);
    this._selectedItem.emit(this.selectedItem);
  }

  isChecked() {
    const selectedItems = this.data?.items?.filter((x) => x.isCompleted);

    if (
      this.data?.items?.length &&
      selectedItems?.length === this.data["items"]?.length
    ) {
      return true;
    }

    return false;
  }

  selectItem(item, $event) {
    $event.stopPropagation();
    if (
      this.moduleName === "Local Tasks" ||
      this.moduleName === "Local Completed Tasks"
    ) {
      this._selectedItem.emit([item]);
    } else {
      const index = this.selectedItem.findIndex((x) => x?._id === item?._id);
      const dataIndex = this.data["items"].findIndex(
        (x) => x?._id === item?._id
      );

      if (
        this.moduleName === "My Tasks" ||
        this.moduleName === "Local Tasks" ||
        this.moduleName === "Local Completed Tasks"
      ) {
        this.selectedItem = [];
        this.data["items"][dataIndex]["isCompleted"] = !$event.target.checked;
      } else {
        this.data["items"][dataIndex]["isCompleted"] = $event.target.checked;
      }

      if (index > -1) {
        this.selectedItem.splice(index, 1);
      } else {
        this.selectedItem.push(item);
      }
      if (this.moduleName === "Dialer") {
        var dialerActivityList;
        const activedata = this.data["items"].forEach((element) => {
          if (element.isCompleted) {
            dialerActivityList = element.isCompleted;
          }
        });

        if (dialerActivityList) {
          const dialerCampaignData = {
            details: this.data["items"],
            name: "Active Dropdown",
          };
          this._showActiveDropdown.emit(dialerCampaignData);
        } else {
          this._showActiveDropdown.emit("");
        }
      }
      this._selectedItem.emit(this.selectedItem);
    }
  }

  preview(file, index) {
    this.dialogRef = this.dialog.open(MediaPreviewComponent, {
      width: "50vw",
      backdropClass: "re-backdrop",
      panelClass: "re-preview-media",
      data: {
        file,
        items: this.data["items"],
        index,
      },
    });
  }

  checkFileType(fileType) {
    const type = fileType.toLowerCase();

    return !!(
      type === "pdf" ||
      type === "png" ||
      type === "jpeg" ||
      type === "jpg" ||
      type === "svg" ||
      type === "gif" ||
      type === "webp" ||
      type === "ico" ||
      type === "mp4" ||
      type === "mp3"
    );
  }

  downloadFile(file) {
    const obj = {
      moduleId: file?.moduleId,
      src: file?.photo,
    };
    this._loaderService.start();
    this._sharedService.getFile(obj).subscribe(
      (response) => {
        if (response) {
          var blob = new Blob([response], {
            type: `image/${file?.fileType}`,
          });
          this._toastrService.success(this.messageConstant.fileDownloadSuccess);
          saveAs(blob, file?.title);
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (error?.platform) {
            this._toastrService.error(error.platform, "");
          } else {
            this._toastrService.error(error.message, "");
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  openAddEditFilesModal(type, data, index) {
    type = type.toLowerCase();
    this.dialogRef = this.dialog.open(AddEditFolderFilesModalComponent, {
      width: type === "share" ? "450px" : "400px",
      disableClose: type === "edit" ? true : false,
      data: {
        action: type,
        moduleId: this.moduleId,
        subModuleId: this.id,
        type: "file",
        data,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: "file", type, index, result });
      }
    });
  }

  onEditCard(data, index) {
    if (data.defaultAdmin) {
      this._toastrService.error(
        this.messageConstant?.tagsEditPermissionErr,
        ""
      );
      return;
    }

    this.dialogRef = this.dialog.open(AddCardDialogComponent, {
      width: "650px",
      disableClose: true,
      data: {
        item: data,
        index: index,
        action: "edit",
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this._emitter.emit({
        module: "cards",
        type: "edit",
        index,
        result,
      });
    });
  }

  onDeleteCardDialog(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "500px",
      data: {
        subModule: type,
        subModuleId: type,
        moduleId: data._id,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: type,
          type: "delete",
          index,
          result,
          details: data,
        });
      }
    });
  }

  cardRadioChange(val, item) {
    if (val.target.value == "true") {
      this._emitter.emit({
        module: "cards",
        type: "setDefaultCard",
        item: item,
      });
    }
  }

  renderTooltip(action, item) {
    if (!action?.isTooltipExists) return;

    if (action?.checkActive && item[action?.activeCheckKey]) {
      return action.activeLabel;
    } else {
      return action?.label;
    }
  }

  onBlockNumberDeleteDialog(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "500px",
      data: {
        subModule: type === "blockNumber" ? "Unblock Number" : type,
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
        index,
        customMessage: "Are you sure you want to Unblock Number?",
        customButtonTitle: "Unblock Number",
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: type,
          type: "delete",
          index,
          result,
          details: data,
        });
        this._sharedService.refreshActivityLog.next(true);
      }
    });
  }

  redirectAction(action, redirectData) {
    this._toastrService.clear();
    if (redirectData?.isActionDisabled) {
      this._toastrService.error(
        "",
        redirectData.disabledDescription.replace("@ACTION", action.label)
      );
      return;
    }

    if (
      (redirectData?.isReadOnly &&
        (action?.label == "Edit" || action?.label == "Toggle")) ||
      (action?.isEnabled && !redirectData?.isEnabled)
    ) {
      this._toastrService.error(
        "",
        action?.disabledText.replace(
          "{{TYPE}}",
          `${redirectData.isReadOnly ? "readonly" : "disabled"}`
        )
      );
      return;
    }
    if (action?.type && action?.type == "EDIT_SCRIPT") {
      localStorage.setItem("redirectData", JSON.stringify(redirectData));
    }
    const navigationDetails: string[] = [action?.route, redirectData["_id"]];
    this._router.navigate(navigationDetails);
  }

  updateAppointmentStatus(item, event) {
    const obj = {
      appointmentId: item._id,
      appointmentStatus: parseInt(event),
    };

    this._sharedService.updateAppointmentStatus(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this._refresh.emit(true);
          return;
        }

        this._toastrService.error(
          response.message || this.messageConstant.unknownError
        );
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (error?.platform) {
            this._toastrService.error(error.platform, "");
          } else {
            this._toastrService.error(error.message, "");
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  updateLeadMainStatus(item, event) {
    let obj = {
      transactionLeadId: event,
      leadId: item?._id,
    };
    this._loaderService.start();
    this._leadsService
      .changeTransactionLeadStatus(obj)
      .subscribe((response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          response.data.transactionLeadId = event;
          this._changePage.emit(this.currentPage);
        }
      });
  }

  getApptType(item, head) {
    var isIconType;
    var isIconTitle;
    if (head) {
      if (item.appointmentType == 0) {
        isIconType =
          AppointmentTypeConstant?.AppointmentSubType[item?.appointmentSubType]
            .path;
        isIconTitle =
          AppointmentTypeConstant?.AppointmentSubType[item?.appointmentSubType]
            .title;
      } else {
        isIconType = "in-person-appt-icon.png";
        isIconTitle =
          AppointmentTypeConstant?.AppointmentType[item.appointmentType];
      }
      if (head == "img") {
        return isIconType;
      }
      if (head == "name") {
        return isIconTitle;
      }
    }
  }

  onFlowCopyDialog(type, subtype, data, index) {
    this.dialogRef = this.dialog.open(CopyCallFlowComponent, {
      width: "500px",
      data: {
        subModule: type,
        subModuleId: subtype,
        moduleId: data._id,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: type, type: subtype, index, result });
      }
    });
  }

  onDeleteFlow(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "500px",
      data: {
        subModule: type,
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result !== "false") {
          this._emitter.emit({
            module: type,
            type: "delete",
            index,
            result: data,
          });
        }
      }
    });
  }

  onEditSmsDialog(type, data, index, moduleId) {
    this.dialogRef = this.dialog.open(AddEditSmsTemplateComponent, {
      width: "650px",
      disableClose: true,
      panelClass: "editTemplate",
      data: {
        moduleId: moduleId,
        action: "edit",
        items: this.secondaryData,
        allTitle: {
          heading: "Edit Template",
          buttonTitle: "Edit",
        },
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: "sms" });
      }
    });
  }

  getTeam(teamId) {
    const index = this.teams.findIndex((x) => x?._id === (teamId || ""));
    if (index > -1) {
      return this.teams[index].title;
    }
    return "Primary Team";
  }

  checkSpam(item) {
    let cSpam = 0;
    item?.carrierAttFlagged ? cSpam++ : "";
    item?.carrierSprintFlagged ? cSpam++ : "";
    item?.carrierTmobileFlagged ? cSpam++ : "";
    item?.carrierVerizonFlagged ? cSpam++ : "";
    return cSpam;
  }

  checkSpamMsg(item) {
    let msg = "Marked Spam By:</br></br>";
    item?.carrierAttFlagged
      ? (msg += "<p style='line-height:16px'>AT&T</p>")
      : "";
    item?.carrierVerizonFlagged
      ? (msg += "<p style='line-height:16px'>Verizon</p>")
      : "";
    item?.carrierTmobileFlagged
      ? (msg += "<p style='line-height:16px'>T-Mobile</p>")
      : "";
    item?.carrierSprintFlagged
      ? (msg += "<p style='line-height:16px'>Sprint</p>")
      : "";
    return msg;
  }

  checkInstantLead(data, val, index) {
    this.leadConnectData = data;
    this.leadConnectValue = val;
    this.leadConnectIndex = index;
    if (this.userPlan === "enterprise") {
      this.dialogRef = this.dialog.open(LeadConnectDialogComponent, {
        width: "600px",
        data: {
          data,
          index,
        },
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        this._emitter.emit({
          module: this.moduleId,
          type: "edit_number",
          index,
          result,
        });
      });
    } else {
      this._toastrService.error(this.messageConstant?.planEnterpriseErr, "");
    }
  }

  checkCallRecord(id, val, index) {
    let obj = {
      isOutboundCallRecord: !val,
      numberId: id,
    };
    this._loaderService.start();
    this._sharedService.updateCallRecord(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode === 200) {
          this.data["items"][index].isOutboundCallRecord = !val;
          this._toastrService.success(
            this.messageConstant.numberUpdatedSuccess,
            ""
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (error?.platform) {
            this._toastrService.error(error.platform, "");
          } else {
            this._toastrService.error(error.message, "");
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  onEditEmailDialog(type, data, index, moduleId) {
    this.dialogRef = this.dialog.open(AddEditEmailTemplateDailogComponent, {
      width: "650px",
      disableClose: true,
      panelClass: "editTemplate",
      data: {
        moduleId: moduleId,
        type: "email-template",
        subType: "email",
        action: "edit",
        category: "",
        template: "add-edit",
        items: this.secondaryData,
        allTitle: {
          heading: "Edit Template",
          buttonTitle: "Edit",
        },
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: "email",
          type: "edit",
          index,
          result,
        });
      }
    });
  }

  onViewMembersList(item, bool?) {
    this.dialogRef = this.dialog.open(ViewMembersListDialogComponent, {
      width: "600px",
      data: {
        module: this.moduleName,
        item,
        members_list: bool ? "Number" : "Members",
      },
    });
  }

  onAppointmentDialog(data?) {
    if (!this.secondaryData.leadData && !this.secondaryData.propertyData) {
      return;
    }

    this.dialogRef = this.dialog.open(AddEditAppointmentsComponent, {
      width: "670px",
      data: {
        moduleId: this.moduleId,
        subModuleId: this.id,
        address: this.address,
        currentUser: this.currentUser,
        secondaryContacts: this.secondaryData.secondaryContacts,
        details: data,
        leadInfo:
          this.secondaryData.leadData || this.secondaryData.propertyData,
        teams: this.secondaryData.teams,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._sharedService.refreshAppointments.next(true);
      }
    });
  }

  onMailTemplateCopyDialog(item) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "450px",
      data: {
        header: "Confirmation",
        text: `Are you sure you want to copy this Email Template?`,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        this._sharedService.copyEmail({ mailTemplateId: item?._id }).subscribe(
          (response: ResponseModel) => {
            this._loaderService.stop();
            if (response.statusCode == 200) {
              this._emitter.emit({
                module: "email",
              });
              this._toastrService.success(
                this.messageConstant.mailTemplateCopySuccess,
                ""
              );
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              if (error?.platform) {
                this._toastrService.error(error.platform, "");
              } else {
                this._toastrService.error(error.message, "");
              }
            } else {
              this._toastrService.error(this.messageConstant.unknownError, "");
            }
          }
        );
      }
    });
  }

  getValuesRVMFromId(val) {
    let displayVal = [];
    for (let i = 0; i < val.length; i++) {
      for (let j = 0; j < this.secondaryData.length; j++) {
        if (val[i] == this.secondaryData[j]._id) {
          displayVal.push(this.secondaryData[j].title);
          break;
        }
      }
    }
    if (displayVal.length > 1) {
      return "Multiple";
    } else {
      return displayVal[0];
    }
  }

  onAddEditRvmDialog(item, index) {
    this.dialogRef = this.dialog.open(AddEditRvmTemplateComponent, {
      width: "500px",
      panelClass: "editRvm",
      data: {
        subModule:
          this.moduleName == "Dialer Voicemail"
            ? "Edit Dialer Voicemail Recording"
            : "Edit RVM Recording",
        items: this.secondaryData,
        dataVal: item,
        moduleId: this.moduleId,
        urlType:
          this.moduleName == "Dialer Voicemail"
            ? "dialerVoiceMail"
            : "ringlessvoicemail",
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: "rvm", type: "edit", index, result });
      }
    });
  }

  todayTimeStamp(e: any) {
    const timeStamp = e?.endDateTimeInTimeStamp;
    if (!timeStamp) return;

    const day = moment(timeStamp).format("YYYY-MM-DD");
    const currentDay = moment().format("YYYY-MM-DD");

    if (day === currentDay) {
      return true;
    } else {
      return false;
    }
  }

  onDeleteNumberDialog(type, data, index) {
    this.dialogRef = this.dialog.open(ConfirmationNumberDialogComponent, {
      width: "525px",
      disableClose: true,
      data: {
        subModule: type,
        subModuleId: this.id,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: "lead_number",
          type: "delete",
          index,
          result,
          details: data,
        });
        this._sharedService.refreshActivityLog.next(true);
      }
    });
  }

  onDripCopyDialog(type, subtype, data, index) {
    this.dialogRef = this.dialog.open(CopyDripComponent, {
      width: "500px",
      data: {
        subModule: type,
        subModuleId: subtype,
        moduleId: data._id,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: type, type: subtype, index, result });
      }
    });
  }

  onDripDeleteDialog(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "500px",
      data: {
        subModule: type,
        subModuleId: type,
        moduleId: data._id,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: type,
          type: "delete",
          index,
          result,
          details: data,
        });
      }
    });
  }

  onEditNumberDialog(data, index) {
    this.dialogRef = this.dialog.open(EditCampaignComponent, {
      width: "550px",
      data: {
        subModule: "edit number",
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.allRolesKey.filter((role) => {
          this.data["items"][index][role] = result[role];
        });

        this._emitter.emit({
          module: this.moduleId,
          type: "edit_number",
          index,
          result,
        });
      }
    });
  }

  getLeadDetails(item) {
    this._leadDetailsEmitter.emit(item);
  }

  getContactDetails(item) {
    this._contactDetailsEmitter.emit(item);
  }

  getAgentContactDetails(item) {
    this._agentContactDetailsEmitter.emit(item);
  }

  reset(moduleName) {
    this.currentPage = 1;
    this.moduleName = moduleName;
  }

  manageList() {
    // let manageList = manageListConstant.manageList;
    this.dialogRef = this.dialog.open(ManageListDialogComponent, {
      width: "550px",
      data: {
        details: this.quickFilterList,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._sharedService.refreshMainStatus.next(true);
      }
    });
  }

  getTooltipIcon(item, actionIndex) {
    return this.activeIconIndex === actionIndex &&
      this.activeIcon === item?.iconImage
      ? item?.activeIcon
      : item?.iconImage;
  }

  getValueTooltip(data, header) {
    try {
      if (!header || !data) {
        return;
      }

      let value = "";
      if (header?.key && header?.subKey) {
        value = data[header?.key][header?.subKey]
          ? data[header?.key][header?.subKey]
          : "N/A";
      } else if (header?.key) {
        value =
          typeof data[header?.key] != "undefined" && data[header?.key] != ""
            ? data[header?.key]
            : "N/A";
      } else if (header?.value) {
        value = header?.value ? header?.value : "N/A";
      } else {
        value = "N/A";
      }

      if (header?.isDynamicTooltip) {
        // const temp = [];

        let str = `
        <div
          data-toggle="tooltip"
          data-placement="top"
          data-html="true"
          class="re-contingencies re-tooltip">
          ${data?.contingencies?.length}


      `;

        if (data?.contingencies?.length) {
          str += `<span class="re-tooltiptext">
        <ul>`;
        }

        data?.contingencies?.map((x) => {
          str += `<li class="re-capitalize">${x}</li>`;
        });

        if (data?.contingencies?.length) {
          str += `</span>
        </ul>`;
        }

        `
        </div>
      `;

        return str;
        //   data?.contingencies.forEach((dataNumber) => {
        //     temp.push(this.utilities.capitalizeName(dataNumber));
        //   });
        //   value = temp?.toString() ? temp?.toString() : "N/A";
        // }
        // return value;
      }
    } catch (error) {
      return "";
    }
  }

  getDetails(item, bool?) {
    if (item?.url) {
      if (this.moduleName == "Calendar") {
        window.open(item?.url, "_blank");
      } else {
        this._router.navigateByUrl(item?.url);
      }
    } else {
      let type = this.moduleName.toLowerCase();
      let module = type;
      let id = item?._id;

      if (type === "call logs") {
        id = item?.leadId;
      }

      if (type === "call logs" || type === "calendar") {
        module = item?.contactType == 1 ? "buyer" : "seller";
      }

      if (!id) {
        return;
      }

      const key = module + "Id";
      let obj = { [key]: id };

      // if (module === "contact") {
      //   if (this.filterObj?.unreadTab) {
      //     obj["inbox"] = 1;
      //   } else {
      //     obj["contact"] = 1;
      //   }
      // }

      let params = {
        queryParams: obj,
      };

      params["state"] = this.filterObj;

      if (type == "search results") {
        module = "leads";
        let leadType = item?.address ? "sellerId" : "buyerId";
        this._router.navigateByUrl(
          `/${module}/details?${leadType}=${item._id}`
        );
        return;
      }

      if (bool) {
        // window.open(
        //   `/${module}/details?buyersId=${obj.buyersId}&contact=${obj.contact}`
        // );
        // return;
      } else {
        this._router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this._router.navigate([`/${module}/details`], params));
      }
    }
  }

  smsTooltipMsg(item) {
    return item?.status == "delivered"
      ? this.utilities.capitalizeName(item?.status)
      : (item?.status == "failed" || item?.status == "undelivered") &&
        item?.errorCode
      ? "Undelivered </br>" + this.twilioErrorMessageConstant[item?.errorCode]
      : item?.status == "failed" || item?.status == "undelivered"
      ? "Undelivered"
      : "Sent";
  }

  getStatusIcon(item) {
    if (item?.type == "outgoingSms" || item?.type == "outgoingMms") {
      if (item?.status == "delivered") {
        return "SMS-delivered.svg";
      }
      if (item?.status == "failed" || item?.status == "undelivered") {
        return "SMS-failed.svg";
      }
      if (item?.status == "queued" || item?.status == "sent") {
        return "SMS-queue.svg";
      }
    }
  }

  previewAttachment(items) {
    this._loaderService.start();
    let re = /(?:\.([^.]+))?$/;
    this.dialogRef = this.dialog.open(MediaPreviewComponent, {
      width: "50vw",
      data: {
        file: { fileType: "multiple", originalName: "Attachments" },
        items: items.map((x) => {
          let fileType = re.exec(x)[1];
          let obj = { src: x, fileType: fileType, title: x };
          return obj;
        }),
      },
    });
    this._loaderService.stop();
  }

  onEditScheduledSMS(data, index) {
    const object = {
      page: this.currentPage,
      limit: 1000,
      numTypeId: 2,
    };
    this._loaderService.start();
    this._sharedService.getManageNumbers(object).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          if (!response.data?.items?.length) {
            this._toastrService.info(this.messageConstant.noNumber, "", {
              enableHtml: true,
            });
            this._loaderService.stop();
            return;
          }
          let manageNumberItems = response.data?.items;
          let contact = [];
          if (data?.moduleId === StatusConstant?.ModuleId?.LEAD) {
            if (data.leadInfo && !data?.lsData) {
              if (!data.buyerId) {
                if (data.leadInfo?.secondaryContact.length <= 0) {
                  if (
                    data.leadInfo?.email ||
                    data.leadInfo?.title ||
                    data.leadInfo?.phoneNumber
                  ) {
                    contact.push({
                      email: data.leadInfo?.email,
                      title: data.leadInfo?.title,
                      phoneNumber: data.leadInfo?.phoneNumber,
                      contactType:
                        data.leadInfo?.contactType == 1 ? "buyer" : "seller",
                      isOwnerContact: true,
                      numberType: data.leadInfo?.numberType,
                    });
                  }
                } else {
                  let contactSelected = data.leadInfo?.secondaryContact.filter(
                    (item) => {
                      if (item.phoneNumber == data.sendTo) {
                        return item;
                      }
                    }
                  );
                  if (contactSelected.length > 0) {
                    contact = contactSelected;
                  } else {
                    contact.push({
                      email: data.leadInfo?.email,
                      title: data.leadInfo?.title,
                      phoneNumber: data.leadInfo?.phoneNumber,
                      contactType:
                        data.leadInfo?.contactType == 1 ? "buyer" : "seller",
                      isOwnerContact: true,
                      numberType: data.leadInfo?.numberType,
                    });
                  }
                }
              }
            }
            if (
              (data.buyerId && data.leadInfo) ||
              (data.buyerId && data.propertyData) ||
              !data.buyerId
            ) {
              this._creditService.getSMSCredit({}).subscribe(
                (response: ResponseModel) => {
                  let leadId;
                  if (data.leadInfo || data.propertyData) {
                    leadId = data.leadInfo
                      ? data.leadInfo?._id
                      : data.propertyData?.leadId?._id;
                  }
                  this._leadsService.getTeams({ leadId: leadId }).subscribe(
                    (responseTeams: ResponseModel) => {
                      if (
                        responseTeams.statusCode &&
                        responseTeams.statusCode == 200
                      ) {
                        this._loaderService.stop();
                        if (
                          response?.statusCode &&
                          response?.statusCode == 200
                        ) {
                          let SMSBalance = response?.data;
                          let obj = {
                            contact: contact,
                            phoneNumber: contact[0].phoneNumber,
                            leadSource: manageNumberItems,
                            defaultNumber: data.sendFrom,
                            address: data.leadInfo?.address,
                            contacts: contact,
                            leadId: data.leadInfo?._id,
                            moduleId: data?.moduleId,
                            moduleType: data?.moduleType,
                            type: "scheduledSMS",
                            smsTemplateId: data.smsTemplateId,
                            smsTemplateBody: data.smsTemplateBody,
                            dueInIso: data.dueInIso,
                            timezone: data.timezone,
                            scheduledSmsId: data._id,
                            attachments: data.attachments,
                            teams: responseTeams.data.items,
                            SMSBalance,
                          };
                          this.openSmsDialog(obj, index);
                        } else {
                          this._toastrService.error(response?.message, "");
                        }
                      }
                    },
                    (err: ErrorModel) => {
                      this._loaderService.stop();
                      if (err.error) {
                        const error: ResponseModel = err.error;
                        // this._toastrService.error(error.message, '');
                      } else {
                        this._toastrService.error(
                          this.messageConstant.unknownError,
                          ""
                        );
                      }
                    }
                  );
                },
                (err: ErrorModel) => {
                  this._loaderService.stop();
                  if (err.error) {
                    const error: ResponseModel = err.error;
                    this._toastrService.error(error.message, "");
                  } else {
                    this._toastrService.error(
                      this.messageConstant.unknownError,
                      ""
                    );
                  }
                }
              );
            }
          } else {
            if (data.contactData) {
              let contactSelected = data.contactData?.secondaryPhone.filter(
                (item) => {
                  if (item.phoneNumber == data.sendTo) {
                    return item;
                  }
                }
              );
              if (contactSelected.length > 0) {
                contact = contactSelected;
              } else {
                contact.push({
                  email: data.contactData?.email,
                  title: data.contactData?.title,
                  phoneNumber: data.contactData?.phoneNumber,
                  // contactType: "seller",
                  isOwnerContact: true,
                  numberType: data.contactData?.numberType,
                });
              }
              if ((data.buyerId && data.contactData) || !data.buyerId) {
                this._creditService.getSMSCredit({}).subscribe(
                  (response: ResponseModel) => {
                    this._loaderService.stop();
                    if (response?.statusCode && response?.statusCode == 200) {
                      let SMSBalance = response?.data;
                      let obj = {
                        contact: contact,
                        phoneNumber: contact[0].phoneNumber,
                        leadSource: manageNumberItems,
                        defaultNumber: data.sendFrom,
                        address: data.contactData?.address,
                        contacts: contact,
                        leadId: data.contactData?._id,
                        moduleId: data?.moduleId,
                        moduleType: data?.moduleType,
                        type: "scheduledSMS",
                        smsTemplateId: data.smsTemplateId,
                        smsTemplateBody: data.smsTemplateBody,
                        dueInIso: data.dueInIso,
                        timezone: data.timezone,
                        scheduledSmsId: data._id,
                        attachments: data.attachments,
                        SMSBalance,
                      };
                      this.openSmsDialog(obj, index);
                    } else {
                      this._toastrService.error(response?.message, "");
                    }
                  },
                  (err: ErrorModel) => {
                    this._loaderService.stop();
                    if (err.error) {
                      const error: ResponseModel = err.error;
                      this._toastrService.error(error.message, "");
                    } else {
                      this._toastrService.error(
                        this.messageConstant.unknownError,
                        ""
                      );
                    }
                  }
                );
              }
            }
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (error?.platform) {
            this._toastrService.error(error.platform, "");
          } else {
            this._toastrService.error(error.message, "");
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  openSmsDialog(obj, index) {
    this.dialogRef = this.dialog.open(SmsDialogComponent, {
      width: "600px",
      data: obj,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: "Scheduled SMS",
          type: "edit",
          index,
          result,
        });
      }
    });
  }

  onDeleteSMSScheduledDialog(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "500px",
      data: {
        subModule: type,
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: type,
          type: "delete",
          index,
          result,
          details: data,
        });
      }
    });
  }

  onToggle() {
    this.toggle = !this.toggle;
  }

  getBuyerNumbers(type, value) {
    let contact = value;
    if (contact?.phoneNumber == "" || !contact?.phoneNumber) {
      this._toastrService.error(this.messageConstant.callNumberRequired, "", {
        enableHtml: true,
      });
      return;
    }
    const data = {
      page: this.currentPage,
      limit: 1000,
      numTypeId: 2,
    };
    this._loaderService.start();
    this._sharedService.getManageNumbers(data).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          if (!response.data?.items?.length) {
            this._toastrService.info(this.messageConstant.noNumber, "", {
              enableHtml: true,
            });
            this._loaderService.stop();
            return;
          }
          if (type === "call") {
            this.getCallCredit(response.data?.items, contact);
          } else if (type === "sms") {
            this.getSMSCredit(response.data?.items, contact);
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (error?.platform) {
            this._toastrService.error(error.platform, "");
          } else {
            this._toastrService.error(error.message, "");
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  getCallCredit(items, contact) {
    this._loaderService.start();
    this._creditService.getCallCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.callBalance = response?.data;
          if (this.callBalance <= 0) {
            this._toastrService.error(this.messageConstant.callCreditLimit);
          } else {
            this.onCallDialog(items, contact);
          }
        } else {
          this._toastrService.error(response?.message, "");
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (error?.platform) {
            this._toastrService.error(error.platform, "");
          } else {
            this._toastrService.error(error.message, "");
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  getSMSCredit(items, contact) {
    this._loaderService.start();
    this._creditService.getSMSCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.SMSBalance = response?.data;
          if (this.SMSBalance <= 0) {
            this._toastrService.error(this.messageConstant.smsCreditLimit);
          } else {
            this.onSmsDialog(items, contact);
          }
        } else {
          this._toastrService.error(response?.message, "");
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (error?.platform) {
            this._toastrService.error(error.platform, "");
          } else {
            this._toastrService.error(error.message, "");
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  onCallDialog(item, contact) {
    let { title, contactType, phoneNumber, numberType } = contact;

    const obj = {
      title: title,
      contactType: contactType,
      phoneNumber: phoneNumber,
      numberType: numberType,
      isContact: true,
      leadSource: item[0].number,
      address: contact.address,
      callBalance: this.callBalance,
      moduleType: this.moduleName,
    };

    if (this.moduleId == StatusConstant?.ModuleId?.CONTACT) {
      obj["contactId"] = contact._id;
    } else if (this.moduleId == StatusConstant?.ModuleId?.LEAD) {
      obj["leadId"] = contact._id;
    }

    this.dialogRef = this.dialog.open(CallDetailDailogsComponent, {
      width: "400px",
      disableClose: true,
      data: obj,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  onSmsDialog(item, contact) {
    let contactData = [
      {
        title: contact?.title,
        phoneNumber: contact?.phoneNumber,
      },
    ];

    contact.secondaryPhone.map((c) => {
      contactData.push({
        title: contact?.title,
        phoneNumber: c.phoneNumber,
      });
    });

    let obj = {
      leadSource: item,
      address: contact.address,
      contacts: contactData,
      moduleId: this.moduleId,
      contactId: contact._id,
      phoneNumber: contact.phoneNumber,
      SMSBalance: this.SMSBalance,
      moduleType: this.moduleName,
    };

    if (
      this.moduleName == "Contacts" ||
      this.data.moduleId == StatusConstant?.ModuleId?.CONTACT
    ) {
      obj["contactId"] = contact?._id;
    } else if (
      this.moduleName == "Leads" ||
      this.data.moduleId == StatusConstant?.ModuleId?.LEAD
    ) {
      obj["leadId"] = contact?._id;
    }

    this.dialogRef = this.dialog.open(SmsDialogComponent, {
      width: "600px",
      disableClose: true,
      data: obj,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  getPropertyAddress(data) {
    let address = data?.address
      ? this.utilities.formatAddress(data?.address)
      : "No Property Address";
    return address;
  }

  getDetailsCall(item: any) {
    if (item?.moduleId == StatusConstant?.ModuleId?.LEAD) {
      let type;
      if (item.contactType == 1) {
        type = "buyerId";
      } else {
        type = "sellerId";
      }
      window.open(`/leads/details?${type}=${item.subModuleId}`, "_blank");
    } else if (item?.moduleId == StatusConstant?.ModuleId?.CONTACT) {
      window.open(`/contacts/details?contactsId=${item.subModuleId}`, "_blank");
    }
  }

  checkMainStatus(option, item, header) {
    let buyerModule = option.filter((x) => x?.leadType == 1);
    let sellerModule = option.filter((x) => x?.leadType == 2);
    let buyerTransactionModule = option.filter((x) => x?.leadType == 3);
    let sellerTransactionModule = option.filter((x) => x?.leadType == 4);
    if (item.contactType == 1 && header.key == "mainStatusId") {
      return buyerModule;
    } else if (item.contactType == 2 && header.key == "mainStatusId") {
      return sellerModule;
    } else if (item.contactType == 1 && header.key == "transactionLeadId") {
      return buyerTransactionModule;
    } else if (item.contactType == 2 && header.key == "transactionLeadId") {
      return sellerTransactionModule;
    } else {
      return [];
    }
  }

  getTooltipContent(item: any): string {
    const contingencies = item?.contingencies.join("\n\n");
    return item?.contingencies.length > 0 ? contingencies : "0";
  }

  getSMSStats(action, sms) {
    this.dialogRef = this.dialog.open(CampaignStatsComponent, {
      width: "350px",
      data: {
        item: sms,
        action,
        type: "SMS",
      },
    });
  }

  getEmailStats(action, item) {
    this.dialogRef = this.dialog.open(CampaignStatsComponent, {
      minWidth: "500px",
      data: {
        item,
        action,
        type: "EMAIL",
      },
    });
  }

  setActiveFilter(index: any, isMain?) {
    if (isMain) {
      this.subFilter = "clear";
    }

    if (index == "clear") {
      this.clearList();
    } else {
      this.activeFilterIndex = index;
    }

    let filter = JSON.parse(this.quickFilterList[index].filterData);
    let filterObject: any = {};
    filter.forEach((filter) => {
      filterObject[filter.value] = {
        dateValue: null,
        operator: filter.selectedOperator,
        optionsType: filter.optionsType,
        order: filter.order,
        value: filter.selectedOption,
      };
    });
    this._applyFilter.emit({ filterData: filterObject, from: "quickFilter" });
  }

  clearList() {
    this.subFilter = "clear";
    this.activeFilterIndex = 0;
    this._applyFilter.emit("quickFilterReset");
  }

  getQuickFilter(bool?) {
    let obj = {
      page: this.currentPage,
      limit: 100,
      moduleId: StatusConstant?.ModuleId?.LEAD,
    };

    this._quickFilterService.getQuickFilter(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.quickFilterList = response.data?.items;
          if (this.quickFilterList.length > 0) {
            this.mainQuickFilterList = this.quickFilterList.slice(0, 6);
            this.subQuickFilterList = this.quickFilterList.slice(6);
          }

          this.setActiveFilter(0, true);

          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (error?.platform) {
            this._toastrService.error(error.platform, "");
          } else {
            this._toastrService.error(error.message, "");
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }
}
