<div class="re-verifyEmail-modal">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        Verify Your Email
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form
    [formGroup]="verifyEmailForm"
    (ngSubmit)="onVerifyEmail()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="re-typography">
      <div class="container-fluid p-0">
        <div class="box">
          <div class="row">
            <!-- NICK NAME -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  Nickname
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  formControlName="nickName"
                  class="form-control"
                  placeholder="Nickname"
                />
                <div class="text-danger re-error">
                  <span *ngIf="submitted && hasError('nickName', 'required')">
                    {{
                      messageConstant.requiredField.replace("Field", "Nickname")
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="box">
          <div class="row">
            <div class="col-12 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  From Email
                  <span class="text-danger">*</span>
                </label>
                <input
                  [attr.disabled]="isMarketReadonly ? 'disabled' : null"
                  type="email"
                  class="form-control"
                  name="fromEmail"
                  formControlName="fromEmail"
                  placeholder="Email"
                />
                <div class="text-danger re-error">
                  <span *ngIf="submitted && hasError('fromEmail', 'required')"
                    >{{
                      messageConstant.requiredField.replace(
                        "Field",
                        "From Email"
                      )
                    }}
                  </span>
                  <span *ngIf="hasError('fromEmail', 'pattern')">
                    {{ messageConstant.validFieldValue }} From Email
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  From Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="fromName"
                  formControlName="fromName"
                  placeholder="Name"
                />
                <div class="text-danger re-error">
                  <span *ngIf="submitted && hasError('fromName', 'required')">
                    {{ messageConstant.requiredField.replace("Field", "Name") }}
                  </span>
                  <span *ngIf="hasError('fromName', 'pattern')">
                    {{ messageConstant.validFieldValue }} Name
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="box">
          <div class="row">
            <!-- ADDRESS -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  Address
                  <span class="text-danger">*</span>
                </label>
                <input
                  #search
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="off"
                  type="text"
                  class="form-control"
                  name="verifyAddress"
                  formControlName="verifyAddress"
                  placeholder="Address"
                  (keydown.enter)="preventDefault()"
                />
                <div class="text-danger re-error">
                  <span
                    *ngIf="submitted && hasError('verifyAddress', 'required')"
                  >
                    {{
                      messageConstant.requiredField.replace("Field", "Address")
                    }}
                  </span>
                </div>
              </div>
            </div>

            <!-- ADDRESS 2 -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">Address 2</label>
                <input
                  #search2
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="off"
                  type="text"
                  class="form-control"
                  name="verifyAddress2"
                  formControlName="verifyAddress2"
                  placeholder="Address 2"
                  (keydown.enter)="preventDefault2()"
                />
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="box">
          <div class="row">
            <!-- COUNTRY -->
            <div class="col-12 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  Country
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="verifyCountry"
                  formControlName="verifyCountry"
                  placeholder="Enter Country"
                />
                <div class="text-danger re-error">
                  <span
                    *ngIf="submitted && hasError('verifyCountry', 'required')"
                  >
                    {{
                      messageConstant.requiredField.replace("Field", "Country")
                    }}
                  </span>
                </div>
              </div>
            </div>

            <!-- STATE -->
            <div class="col-12 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  State
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter State"
                  name="verifyState"
                  formControlName="verifyState"
                />
                <div class="text-danger re-error">
                  <span
                    *ngIf="submitted && hasError('verifyState', 'required')"
                  >
                    {{
                      messageConstant.requiredField.replace("Field", "State")
                    }}
                  </span>
                </div>
              </div>
            </div>

            <!-- CITY -->
            <div class="col-12 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  City
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter City"
                  name="verifyCity"
                  formControlName="verifyCity"
                />
                <div class="text-danger re-error">
                  <span *ngIf="submitted && hasError('verifyCity', 'required')">
                    {{ messageConstant.requiredField.replace("Field", "City") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="box">
          <div class="row">
            <!-- ZIP CODE -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  Zip Code
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="zip"
                  formControlName="verifyZip"
                  class="form-control"
                  placeholder="Zip-Code"
                  mask="00000"
                />
                <div class="text-danger re-error">
                  <span *ngIf="submitted && hasError('verifyZip', 'required')"
                    >{{
                      messageConstant.requiredField.replace("Field", "Zip-Code")
                    }}
                  </span>
                  <span *ngIf="hasError('verifyZip', 'pattern')">
                    {{ messageConstant.validFieldValue }} Zip Code
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <hr style="margin-bottom: 10px !important" />
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button type="submit" mat-flat-button color="primary" cdkFocusInitial>
        Verify Email
      </button>
    </mat-dialog-actions>
  </form>
</div>
