import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SharedRoutingModule } from "./shared-routing.module";
import { NgxUiLoaderModule, POSITION, SPINNER } from "ngx-ui-loader";
import { NgxStripeModule } from "ngx-stripe";
import {
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { ToastrModule } from "ngx-toastr";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatRadioModule } from "@angular/material/radio";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatSliderModule } from "@angular/material/slider";
import * as Hammer from "hammerjs";
import { DndModule } from "ngx-drag-drop";
import { MultiSelectModule } from "primeng/multiselect";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ChipsModule } from "primeng/chips";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { FullCalendarModule } from "@fullcalendar/angular";
import { TooltipModule } from "primeng/tooltip";
import { DropdownModule } from "primeng/dropdown";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { QuillModule } from "ngx-quill";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { MatInputModule } from "@angular/material/input";
import { MentionModule } from "angular-mentions";

// UTILS
import { environment } from "src/environments/environment";

// SERVICES
import { SharedService } from "./shared.service";

// PIPES & DIRECTIVE
import { FormatPipe } from "../utils/pipes/format.pipe";
import { SearchTextPipe } from "../utils/pipes/search-text-pipe";
import { CurrencyPositivePipe } from "../utils/pipes/currency-positive.pipe";
import { DateByTimeZonePipe } from "../utils/pipes/date-by-time-zone.pipe";
import { SafeHtml } from "../utils/pipes/SafeHtml";
import { MentionHighlightPipe } from "../utils/pipes/mention-highlight.pipe";
import { TextToLinkPipe } from "../utils/pipes/text-to-link.pipe";

// COMPONENTS
import { ListViewComponent } from "./list-view/list-view.component";
import { GridViewComponent } from "./grid-view/grid-view.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { ConfirmationDialogComponent } from "./dialog/confirmation-dialog/confirmation-dialog.component";
import { SearchDialogComponent } from "./dialog/search-dialog/search-dialog.component";
import { ChangeTitleComponent } from "./dialog/change-title/change-title.component";
import { ChangeColorComponent } from "./dialog/change-color/change-color.component";
import { MoveColumnComponent } from "./dialog/move-column/move-column.component";
import { SubHeaderComponent } from "./sub-header/sub-header.component";
import { GlobalTagsComponent } from "./global-tags/global-tags.component";
import { PreferenceQuestionComponent } from "./preference-question/preference-question.component";
import { DeleteDialogComponent } from "./dialog/delete-dialog/delete-dialog.component";
import { ActivityLogsComponent } from "./activity-logs/activity-logs.component";
import { CheckingLeadComponent } from "./dialog/checking-lead/checking-lead.component";
import { OpenLeadDialogComponent } from "./dialog/open-lead-dialog/open-lead-dialog.component";
import { AddColumnComponent } from "./dialog/add-column/add-column.component";
import { RemoveTagsComponent } from "./dialog/remove-tags/remove-tags.component";
import { RenameTagComponent } from "./dialog/rename-tag/rename-tag.component";
import { AddTagsComponent } from "./dialog/add-tags/add-tags.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { ColorPickerComponent } from "./dialog/color-picker/color-picker.component";
import { ColorPaletteComponent } from "./dialog/color-picker/color-palette/color-palette.component";
import { ColorSliderComponent } from "./dialog/color-picker/color-slider/color-slider.component";
import { AddEditContactsComponent } from "./dialog/add-edit-contacts/add-edit-contacts.component";
import { FilterActivitylogDialogComponent } from "./activity-logs/filter-activitylog-dialog/filter-activitylog-dialog.component";
import { FilesComponent } from "./files/files.component";
import { UnderMaintenanceComponent } from "./under-maintenance/under-maintenance.component";
import { AddEditFolderFilesModalComponent } from "./files/dialog/add-edit-folder-files-modal/add-edit-folder-files-modal.component";
import { MoveFilesComponent } from "./files/dialog/move-files/move-files.component";
import { MediaPreviewComponent } from "./dialog/media-preview/media-preview.component";
import { GoogleMapComponent } from "./dialog/maps/google-map/google-map.component";
import { StreetMapComponent } from "./dialog/maps/street-map/street-map.component";
import { AddEditContingencyComponent } from "./dialog/add-edit-contingency/add-edit-contingency.component";
import { AddCardDialogComponent } from "./dialog/add-card-dialog/add-card-dialog.component";
import { FirstTimeLoginComponent } from "./dialog/first-time-login/first-time-login.component";
import { AddEditAgentComponent } from "./dialog/add-edit-agent/add-edit-agent.component";
import { TitleCasePipe } from "../utils/pipes/title-case.pipe";
import { TimeStampToTimePipe } from "../utils/pipes/time-stamp-to-time.pipe";
import { AddRecordingComponent } from "./dialog/add-recording/add-recording.component";
import { AudioPlayerComponent } from "./audio-player/audio-player.component";
import { CopyCallFlowComponent } from "./dialog/copy-call-flow/copy-call-flow.component";
import { AddEditEmailTemplateDailogComponent } from "./dialog/emails-dailog/add-edit-email-template-dailog/add-edit-email-template-dailog.component";
import { AddEditSmsTemplateComponent } from "./dialog/add-edit-sms-template/add-edit-sms-template.component";
import { AddEditRvmTemplateComponent } from "./dialog/add-edit-rvm-template/add-edit-rvm-template.component";
import { AddEditTaskTemplateComponent } from "./dialog/add-edit-task-template/add-edit-task-template.component";
import { VerifyEmailComponent } from "./dialog/emails-dailog/choose-email-dailog/verify-email/verify-email.component";
import { TakeNoteComponent } from "./dialog/take-note/take-note.component";
import { ContactsDialogComponent } from "./dialog/contacts-dialog/contacts-dialog.component";
import { ChooseEmailDailogComponent } from "./dialog/emails-dailog/choose-email-dailog/choose-email-dailog.component";
import { SendEmailDialogComponent } from "./dialog/emails-dailog/choose-email-dailog/send-email-dialog/send-email-dialog.component";
import { ViewMembersListDialogComponent } from "./dialog/view-members-list-dialog/view-members-list-dialog.component";
import { SetAvailabilityDialogComponent } from "./dialog/set-availability-dialog/set-availability-dialog.component";
import { BlockNumberDailogComponent } from "./dialog/block-number-dailog/block-number-dailog.component";
import { AddCallForwardComponent } from "./dialog/add-call-forward/add-call-forward.component";
import { CallDetailDailogsComponent } from "./dialog/call-detail-dailogs/call-detail-dailogs.component";
import { SmsDialogComponent } from "./dialog/sms-dialog/sms-dialog.component";
import { CopyDripComponent } from "./dialog/copy-drip/copy-drip.component";
import { AutoFollowUpComponent } from "./dialog/auto-follow-up/auto-follow-up.component";
import { LeadConnectDialogComponent } from "./dialog/lead-connect-dialog/lead-connect-dialog.component";
import { ConfirmationNumberDialogComponent } from "./dialog/confirmation-number-dialog/confirmation-number-dialog.component";
import { CardSelectionDialogComponent } from "./dialog/card-selection-dialog/card-selection-dialog.component";
import { EditCampaignComponent } from "./dialog/edit-campaign/edit-campaign.component";
import { DripAutomationComponent } from "./dialog/edit-campaign/drip-automation/drip-automation.component";
import { GeneralComponent } from "./dialog/edit-campaign/general/general.component";
import { TagsAutomationComponent } from "./dialog/edit-campaign/tags-automation/tags-automation.component";
import { TeamsComponent } from "./dialog/edit-campaign/teams/teams.component";
import { ReassignLeadsComponent } from "./dialog/edit-campaign/teams/reassign-leads/reassign-leads.component";
import { FiltersComponent } from "./filters/filters.component";
import { SaveFilterTemplateDialogComponent } from "./filters/save-filter-template-dialog/save-filter-template-dialog.component";
import { SendRvmComponent } from "./dialog/send-rvm/send-rvm.component";
import { PropertyInformationDialogComponent } from "./dialog/property-information-dialog/property-information-dialog.component";
import { SmsStatsComponent } from "./sms-stats/sms-stats.component";
import { EmailStatsComponent } from "./email-stats/email-stats.component";
import { SowComponent } from "./sow/sow.component";
import { ManageListDialogComponent } from "./dialog/manage-list-dialog/manage-list-dialog.component";
import { EditManageListDialogComponent } from "./dialog/edit-manage-list-dialog/edit-manage-list-dialog.component";
import { BuyerLeadOfferAcceptedDialogComponent } from "./dialog/buyer-lead-offer-accepted-dialog/buyer-lead-offer-accepted-dialog.component";
import { SellerLeadsStatusDialogComponent } from "./dialog/seller-leads-status-dialog/seller-leads-status-dialog.component";
import { SellerTransactionsStatusDialogComponent } from "./dialog/seller-transactions-status-dialog/seller-transactions-status-dialog.component";
import { ChangeRoleComponent } from "./dialog/change-role/change-role.component";
import { AddRoleComponent } from "./dialog/add-role/add-role.component";
import { PermissionDeniedComponent } from "./permission-denied/permission-denied.component";
import { AddEditTeamDialogComponent } from "./dialog/add-edit-team-dialog/add-edit-team-dialog.component";
import { TeamDeleteComponent } from "./dialog/team-delete/team-delete.component";
import { IncomingCallDetailDailogsComponent } from "./dialog/incoming-call-detail-dailogs/incoming-call-detail-dailogs.component";
import { DirectMailComponent } from "./direct-mail/direct-mail.component";
import { DirectMailPreviewComponent } from "./direct-mail/direct-mail-preview/direct-mail-preview.component";
import { TemplateOneComponent } from "./direct-mail/direct-mail-preview/template-one/template-one.component";
import { TemplateTwoComponent } from "./direct-mail/direct-mail-preview/template-two/template-two.component";
import { TemplateThreeComponent } from "./direct-mail/direct-mail-preview/template-three/template-three.component";
import { ContactCommunicationActionsComponent } from "./dialog/contact-communication-actions/contact-communication-actions.component";
import { AddContactMatchingComponent } from "./dialog/add-contact-matching/add-contact-matching.component";
import { AddRelatedContactComponent } from "./dialog/add-related-contact/add-related-contact.component";
import { LinkExistingContactComponent } from "./dialog/link-existing-contact/link-existing-contact.component";
import { InboxAssociatedLeadsComponent } from "./dialog/inbox-associated-leads/inbox-associated-leads.component";
import { InboxTaskComponent } from "./dialog/inbox-task/inbox-task.component";
import { InboxApptsComponent } from "./dialog/inbox-appts/inbox-appts.component";
import { AddEditContactDialogComponent } from "./dialog/add-edit-contact-dialog/add-edit-contact-dialog.component";
import { LeadContactListComponent } from "./dialog/lead-contact-list/lead-contact-list.component";
import { TaskDripStatusChangeComponent } from "./dialog/task-drip-status-change/task-drip-status-change.component";
import { SendBulkSmsDialogComponent } from './dialog/send-bulk-sms-dialog/send-bulk-sms-dialog.component';
import { CampaignStatsComponent } from './dialog/campaign-stats/campaign-stats.component';
import { ViewHtmlDialogComponent } from './dialog/view-html-dialog/view-html-dialog.component';
import { SendBulkEmailDialogComponent } from './dialog/send-bulk-email-dialog/send-bulk-email-dialog.component';
import { SaveTemplateDialogComponent } from './dialog/save-template-dialog/save-template-dialog.component';
import { TestEmailDialogComponent } from './dialog/test-email-dialog/test-email-dialog.component';

const ngxUiLoaderConfig: NgxUiLoaderModule = {
  bgsColor: "#0a4e81",
  bgsOpacity: 1,
  bgsPosition: POSITION.bottomRight,
  bgsSize: 40,
  bgsType: SPINNER.threeStrings,
  fgsColor: "#0a4e81",
  fgsPosition: POSITION.centerCenter,
};

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    ListViewComponent,
    GridViewComponent,
    TopbarComponent,
    ConfirmationDialogComponent,
    FormatPipe,
    SearchTextPipe,
    CurrencyPositivePipe,
    DateByTimeZonePipe,
    SafeHtml,
    SearchDialogComponent,
    MentionHighlightPipe,
    TextToLinkPipe,
    ChangeTitleComponent,
    ChangeColorComponent,
    MoveColumnComponent,
    SubHeaderComponent,
    GlobalTagsComponent,
    PreferenceQuestionComponent,
    DeleteDialogComponent,
    ActivityLogsComponent,
    CheckingLeadComponent,
    OpenLeadDialogComponent,
    AddColumnComponent,
    RemoveTagsComponent,
    RenameTagComponent,
    AddTagsComponent,
    PaginationComponent,
    ColorPickerComponent,
    ColorPaletteComponent,
    ColorSliderComponent,
    AddEditContactsComponent,
    FilterActivitylogDialogComponent,
    FilesComponent,
    UnderMaintenanceComponent,
    FilterActivitylogDialogComponent,
    AddEditFolderFilesModalComponent,
    MoveFilesComponent,
    MediaPreviewComponent,
    GoogleMapComponent,
    StreetMapComponent,
    AddEditContingencyComponent,
    AddCardDialogComponent,
    FirstTimeLoginComponent,
    AddEditAgentComponent,
    TitleCasePipe,
    TimeStampToTimePipe,
    AddRecordingComponent,
    AudioPlayerComponent,
    CopyCallFlowComponent,
    AddEditEmailTemplateDailogComponent,
    AddEditSmsTemplateComponent,
    AddEditRvmTemplateComponent,
    AddEditTaskTemplateComponent,
    VerifyEmailComponent,
    TakeNoteComponent,
    ContactsDialogComponent,
    ChooseEmailDailogComponent,
    SendEmailDialogComponent,
    ViewMembersListDialogComponent,
    SetAvailabilityDialogComponent,
    BlockNumberDailogComponent,
    AddCallForwardComponent,
    CallDetailDailogsComponent,
    SmsDialogComponent,
    AutoFollowUpComponent,
    LeadConnectDialogComponent,
    ConfirmationNumberDialogComponent,
    CardSelectionDialogComponent,
    CopyDripComponent,
    EditCampaignComponent,
    DripAutomationComponent,
    GeneralComponent,
    TagsAutomationComponent,
    TeamsComponent,
    ReassignLeadsComponent,
    FiltersComponent,
    SaveFilterTemplateDialogComponent,
    SendRvmComponent,
    PropertyInformationDialogComponent,
    SmsStatsComponent,
    EmailStatsComponent,
    SowComponent,
    ManageListDialogComponent,
    EditManageListDialogComponent,
    BuyerLeadOfferAcceptedDialogComponent,
    SellerLeadsStatusDialogComponent,
    SellerTransactionsStatusDialogComponent,
    ChangeRoleComponent,
    AddRoleComponent,
    PermissionDeniedComponent,
    AddEditTeamDialogComponent,
    TeamDeleteComponent,
    IncomingCallDetailDailogsComponent,
    DirectMailComponent,
    DirectMailPreviewComponent,
    TemplateOneComponent,
    TemplateTwoComponent,
    TemplateThreeComponent,
    ContactCommunicationActionsComponent,
    AddContactMatchingComponent,
    AddRelatedContactComponent,
    LinkExistingContactComponent,
    InboxAssociatedLeadsComponent,
    InboxTaskComponent,
    InboxApptsComponent,
    AddEditContactDialogComponent,
    LeadContactListComponent,
    TaskDripStatusChangeComponent,
    SendBulkSmsDialogComponent,
    CampaignStatsComponent,
    ViewHtmlDialogComponent,
    SendBulkEmailDialogComponent,
    SaveTemplateDialogComponent,
    TestEmailDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
    }),
    QuillModule.forRoot(),
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    NgxStripeModule.forRoot(environment.stripePublickKey),
    MatMenuModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    HammerModule,
    MatSliderModule,
    DndModule,
    InfiniteScrollModule,
    MultiSelectModule,
    AutoCompleteModule,
    ChipsModule,
    CalendarModule,
    CheckboxModule,
    ClipboardModule,
    FullCalendarModule,
    TooltipModule,
    DropdownModule,
    DragDropModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatInputModule,
    MentionModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxUiLoaderModule,
    MatDialogModule,
    ListViewComponent,
    GridViewComponent,
    TopbarComponent,
    MatRadioModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    FormatPipe,
    SearchTextPipe,
    MatChipsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    CurrencyPositivePipe,
    DateByTimeZonePipe,
    SafeHtml,
    SearchDialogComponent,
    MentionHighlightPipe,
    TextToLinkPipe,
    MatMenuModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    HammerModule,
    MatSliderModule,
    ChangeTitleComponent,
    ChangeColorComponent,
    DndModule,
    InfiniteScrollModule,
    MultiSelectModule,
    AutoCompleteModule,
    ChipsModule,
    CalendarModule,
    CheckboxModule,
    ClipboardModule,
    FullCalendarModule,
    TooltipModule,
    DropdownModule,
    DragDropModule,
    SubHeaderComponent,
    GlobalTagsComponent,
    PreferenceQuestionComponent,
    ActivityLogsComponent,
    QuillModule,
    MatInputModule,
    PaginationComponent,
    AddEditContactsComponent,
    FilesComponent,
    MentionModule,
    TitleCasePipe,
    TimeStampToTimePipe,
    AudioPlayerComponent,
    ColorSliderComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    SmsStatsComponent,
    EmailStatsComponent,
    SowComponent,
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    { provide: MatDialogRef, useValue: {} },
    SharedService,
    CurrencyPipe,
    provideNgxMask(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
