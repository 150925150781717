import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SettingsComponent } from "./navigation/settings/settings.component";

const routes: Routes = [
  // AUTH
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
    data: { title: "Auth Module" },
  },

  // PUBLIC
  {
    path: "public",
    loadChildren: () =>
      import("./public/public.module").then((m) => m.PublicModule),
    data: { title: "Public Module" },
  },

  // MODULES
  {
    path: "",
    loadChildren: () =>
      import("./modules/modules.module").then((m) => m.ModulesModule),
    data: { title: "Modules" },
  },

  //SETTINGS
  {
    path: "settings",
    component: SettingsComponent,
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
