import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { BaseUrl } from "../../utils/base-url-constants";
import { environment } from "src/environments/environment";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
@Injectable({
  providedIn: "root",
})
export class CreditService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem("token");

  constructor(private _commonFunction: CommonFunctionsService) {}

  /**
   * Function: GET ALL USER PLAN
   * @param {Object} data
   * @returns ARRAY OF PLAN
   */
  getUserPlan = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + "getUserPlan";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSMSCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + "getSMSCredit";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCallCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + "getCallCredit";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + "updateCredit";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getRVMCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + "getRVMCredit";
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
