<div class="re-agent-html-modal">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between re-agent-header"
      >
        {{ data?.title }}
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-right: -5px; margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <div class="re-agent-html-container" [innerHtml]="data?.content"></div>
</div>
