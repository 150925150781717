<div class="re-agent-sidebar-wrapper">
  <a
    class="navbar-brand1"
    routerLink="/dashboard"
    *ngIf="isDirectMailFreeUser == false"
  >
    <img src="assets/images/navbar/agentpro-white.png" alt="logo" />
    <span class="re-count" *ngIf="_utilities.newCount">
      {{ _utilities.newCount > 9 ? "9+" : _utilities.newCount }}
    </span>
  </a>
  <a class="navbar-brand1" style="cursor: auto" *ngIf="isDirectMailFreeUser">
    <img src="assets/images/navbar/logo.svg" alt="logo" />
  </a>
  <ul id="sidemenu" class="re-agent-sidebar-nav">
    <ng-container *ngFor="let menu of sideMenu; let i = index">
      <li
        class="droplist"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="$event.stopPropagation()"
      >
        <a
          class="dropdown-toggle"
          [attr.data_id]="i"
          [routerLink]="menu?.route"
          [target]="menu?.target"
          [queryParams]="menu.queryParams"
          (click)="closePopOver()"
        >
          <img [src]="menu?.icon" alt="menu" />
        </a>
        <div class="dropdown-menu dropdown_menu_{{ i }}" *ngIf="menu?.subMenu">
          <div class="subdiv">
            <span class="title">{{ menu?.label }}</span>
            <div class="dropdown-divider"></div>
            <ng-container *ngFor="let subMenu of menu?.subMenu">
              <a
                [routerLink]="subMenu?.route"
                [target]="subMenu?.target"
                [queryParams]="subMenu.queryParams"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{
                  exact: true
                }"
                [class.active]="checkActive(subMenu)"
                class="dropdown-item"
                (click)="closePopOver()"
              >
                <img [src]="subMenu?.icon" alt="submenu" />
                {{ subMenu?.label }}
              </a>
            </ng-container>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>

  <div class="re-agent-question-foot">
    <a class="re-agent-link">
      <img src="assets/images/navbar/side-bottom-icon-1.svg" alt="side" />
    </a>
    <a
      class="re-agent-link"
      pTooltip="Help Desk"
      tooltipPosition="right"
      (click)="onChatClick()"
    >
      <img src="assets/images/navbar/side-bottom-icon-2.svg" alt="chat" />
    </a>
  </div>
</div>

<div class="re-agent-helpdesk-modal" [class.d-none]="!showHelpDesk">
  <div class="re-agent-top-head">
    <div class="col-12 re-agent-sub-head">
      <h2 mat-dialog-title class="w-100 d-flex" *ngIf="!isCall && !isMail">
        AgentPro CRM Help Desk
      </h2>
      <div class="d-flex w-100 align-items-center" *ngIf="isCall || isMail">
        <a class="re-agent-back" (click)="onAction('back')">
          <i aria-hidden="true" class="fa fa-arrow-left mr-2"></i> Back
        </a>
      </div>
      <span mat-dialog-close style="float: right" (click)="onChatClick()">
        <img
          src="assets/images/close-full-white.svg"
          alt="close"
          style="margin-top: 8px"
        />
      </span>
    </div>
  </div>
  <mat-dialog-content>
    <div class="container">
      <div class="re-agent-boxes" *ngIf="!isCall && !isMail && !isAssistance">
        <div class="re-agent-title-div">
          <h4>Hi {{ _utilities.userData?.firstName }}!</h4>
          <h5>How may we help you today?</h5>
        </div>
        <h5 class="re-agent-support-title">Support</h5>
        <ul class="re-agent-support-ul">
          <li>
            <a
              class="re-img"
              (click)="onAction('chat')"
              id="custom_link_intercom"
              ><img src="assets/images/support_sms.svg" alt="support_sms"
            /></a>
            <span class="re-txt">Live Chat</span>
          </li>
          <li>
            <a class="re-img" (click)="onAction('mail')"
              ><img src="assets/images/support_mail.svg" alt="support_mail"
            /></a>
            <span class="re-txt">Email</span>
          </li>
          <li>
            <a class="re-img" (click)="onAction('call')"
              ><img src="assets/images/support_sms.svg" alt="support_call"
            /></a>
            <span class="re-txt">SMS</span>
          </li>
          <li>
            <a class="re-img" (click)="onAction('faq')"
              ><img src="assets/images/support_faq.svg" alt="support_faq"
            /></a>
            <span class="re-txt">Self Help</span>
          </li>
        </ul>
        <div class="re-agent-support-lists">
          <div class="re-agent-lists">
            <img
              class="re-agent-support-img"
              src="assets/images/training-figure.svg"
              alt="training figure"
            />
            <div>
              <h5
                style="cursor: pointer"
                (click)="onAction('videoTrainingLibrary')"
              >
                AgentPro CRM Training Course
              </h5>
              <p>
                Get more value from your AgentPro CRM account by checking out
                the video training library
              </p>
            </div>
            <a class="re-agent-open-link" (click)="onAction('affiliate')"
              ><img src="assets/images/open_link_teal.svg" alt="open_link_teal"
            /></a>
          </div>
          <div class="re-agent-lists">
            <img
              class="re-agent-support-img"
              src="assets/images/dollar-symbol-teal.svg"
              alt="dollar"
            />
            <div>
              <h5 style="cursor: pointer" (click)="onAction('affiliate')">
                AgentPro CRM Affiliate Program
              </h5>
              <p>
                Help us spread the word about AgentPro CRM and earn recurring
                commission in return.
              </p>
            </div>
            <a class="re-agent-open-link" (click)="onAction('affiliate')"
              ><img src="assets/images/open_link_teal.svg" alt="open_link_teal"
            /></a>
          </div>
          <div class="re-agent-lists">
            <img
              class="re-agent-support-img"
              src="assets/images/support_pen.svg"
              alt="support_pen"
            />
            <div>
              <h5 style="cursor: pointer" (click)="onAction('feature')">
                Request a Feature
              </h5>
              <p>
                Help us improve AgentPro CRM, suggest features that will add
                more value to your business
              </p>
            </div>
            <a class="re-agent-open-link" (click)="onAction('feature')"
              ><img src="assets/images/open_link_teal.svg" alt="open_link_teal"
            /></a>
          </div>
        </div>
        <a class="re-agent-need-more" (click)="onAction('assistance')"
          >Need More Assistance?<img
            class="img"
            src="assets/images/right_angle_teal.svg"
            alt="right_angle_teal"
        /></a>
      </div>
      <div class="re-agent-boxes" *ngIf="isAssistance">
        <div class="re-agent-title-div">
          <h4>Hi {{ _utilities.userData?.firstName }} !</h4>
          <h5>How may we help you today?</h5>
        </div>
        <h5 class="re-agent-support-title">
          Book an Onboarding call with our Representatives
        </h5>
        <div class="re-agent-bookcall-div">
          <div class="re-agent-bookcall">
            <img src="assets/images/anthony.png" alt="anthony" />
            <a (click)="onActionBook('anthony-resimpli')">Book with Anthony</a>
          </div>
          <div class="re-agent-bookcall">
            <img src="assets/images/red-pigoloy.png" alt="red-pigoloy" />
            <a (click)="onActionBook('red-resimpli')">Book with Red</a>
          </div>
        </div>
        <a class="re-agent-need-more-back" (click)="onAction('assistance')"
          ><img
            class="img"
            src="assets/images/left_angle_teal.svg"
            alt="left_angle_teal"
          />
          Go back to Help Desk</a
        >
      </div>
      <div class="re-agent-boxes re-agent-call-email" *ngIf="isMail">
        <h3>Contact us by email</h3>
        <p>
          <a
            href="mailto:support@agentprocrm.com"
            target="_blank"
            rel="noopener noreferrer"
            >support@agentprocrm.com</a
          >
        </p>
        <p>
          We usually reply to emails within 1-2 hours during business hours.
        </p>
      </div>
      <div class="re-agent-boxes re-agent-call-email" *ngIf="isCall">
        <h3>Contact us by phone</h3>
        <p>+1 (619) 786-3482</p>
        <p>Our phones are open on weekdays between 10 am - 6 pm EST.</p>
      </div>
    </div>
  </mat-dialog-content>
</div>
