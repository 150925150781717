export class MiscellaneousConstant {
  // EMAIL
  public static emailPattern =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  // EMAIL WITH CAPS
  public static emailPatternWithCaps =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // NAME
  public static namePattern = /^[a-zA-Z ]*$/;
  public static namePatternWithoutSpace = /^[a-zA-Z]+$/;
  public static namePatternSignup = /^[-a-zA-Z0-9@.+_]+$/;

  //Number
  public static numberPattern = /^[0-9]+\.?[0-9]*$/;

  //year-Build
  public static yearBuild = /^([0-9]{4})$/;

  // phone
  public static phonePattern =
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  //password
  public static passwordPattern =
    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

  //pincode
  public static zipPattern = /^([0-9]{5})$/;

  //StateCode
  public static stateCodePattern = /^([A-Za-z]{2})$/;
  //StateCode
  public static einPattern = /^\(?([0-9]{2})\)?[-. ]?([0-9]{7})$/;

  public static colorCodes = [
    {
      backgroundColor: "#F3E0EC",
      borderColor: "#A41A71",
      color: "#A41A71",
    },
    {
      backgroundColor: "#ECF3E0",
      borderColor: "#8EA862",
      color: "#8EA862",
    },
    {
      backgroundColor: "#BEDBFF",
      borderColor: "#3466A5",
      color: "#3466A5",
    },
    {
      backgroundColor: "#DAFFC5",
      borderColor: "#4FA520",
      color: "#4FA520",
    },
    {
      backgroundColor: "#FFC5DA",
      borderColor: "#B75679",
      color: "#B75679",
    },
    {
      backgroundColor: "#E0F3EE",
      borderColor: "#269175",
      color: "#269175",
    },
    {
      backgroundColor: "#F3E3E0",
      borderColor: "#BC7366",
      color: "#BC7366",
    },
    {
      backgroundColor: "#F2E1BC",
      borderColor: "#A27A20",
      color: "#A27A20",
    },
    {
      backgroundColor: "#E2D3FC",
      borderColor: "#8C72B8",
      color: "#8C72B8",
    },
    {
      backgroundColor: "#FECBB6",
      borderColor: "#BF5F34",
      color: "#BF5F34",
    },
    {
      backgroundColor: "#DAF8D9",
      borderColor: "#73B970",
      color: "#73B970",
    },
    {
      backgroundColor: "#EED2CC",
      borderColor: "#E37259",
      color: "#E37259",
    },
    {
      backgroundColor: "#D2E0DF",
      borderColor: "#429E97",
      color: "#429E97",
    },
    {
      backgroundColor: "#FECCFB",
      borderColor: "#FA46F0",
      color: "#FA46F0",
    },
    {
      backgroundColor: "#EEFFE8",
      borderColor: "#80B234",
      color: "#80B234",
    },
    {
      backgroundColor: "#F2F3CE",
      borderColor: "#979D05",
      color: "#979D05",
    },
    {
      backgroundColor: "#F6E9FF",
      borderColor: "#9564BB",
      color: "#9564BB",
    },
    {
      backgroundColor: "#FFDBB2",
      borderColor: "#A76010",
      color: "#A76010",
    },
    {
      backgroundColor: "#B5FFFF",
      borderColor: "#1C8181",
      color: "#1C8181",
    },
    {
      backgroundColor: "#F1F6FC",
      borderColor: "#5587C7",
      color: "#5587C7",
    },
  ];

  public static paginationType = {
    INFINITE: "INFINITE_SCROLL",
    STANDARD: "STANDARD",
    ADVANCED: "ADVANCED",
  };

  public static paginationLimit = {
    INFINITE: 30,
    STANDARD: 10,
  };

  public static appointmentStatus = [
    { label: "Kept", value: 1 },
    { label: "Not Kept", value: 2 },
    { label: "No Answer", value: 3 },
  ];

  public static appointmentTypes = [
    { label: "Seller Appointment", value: 0 },
    { label: "Buyer Showings", value: 1 },
    { label: "Contractor Walkthrough", value: 2 },
    { label: "Other", value: 3 },
  ];

  public static appointmentSubTypes = [
    { label: "In-Person Appointment", value: 0 },
    { label: "Phone Appointment", value: 1 },
    { label: "Drive By Appointment", value: 2 },
  ];

  public static roleIds = {
    "64e2d956e4e726ec35b3618a": "",
    "64e2d946e4e726ec35b36189": "",
    "64e2d90ce4e726ec35b36187": "",
    "64e2d8fbe4e726ec35b36186": "",
    "64e2d8e2e4e726ec35b36185": "",
    "64e2d8cbe4e726ec35b36184": "",
    "64e2d8afe4e726ec35b36183": "",
    "64e2d89ae4e726ec35b36182": "",
    "64e2d86fe4e726ec35b36181": "",
    "64e2d859e4e726ec35b36180": "",
    "64e2d845e4e726ec35b3617f": "",
    "64e2d82ae4e726ec35b3617e": "",
    "64a002b70edad2d4b59eb4de": "",
    "64a002330edad2d4b59eb4dc": "",
    "64a002220edad2d4b59eb4db": "",
  };

  public static allRolesKey = [
    "teamLeader",
    "showingAssistant",
    "operationsManager",
    "officeManager",
    "marketingManager",
    "marketingAssistant",
    "managingBroker",
    "listingManager",
    "listingCoordinator",
    "isa",
    "executiveAssistant",
    "coldCaller",
    "buyersAssistant",
    "agent",
    "accountOwner",
  ];

  //http validation
  public static urlValidation =
    /^(http[s]?:\/\/){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

  //http & https validation
  public static urlHttpCheck =
    /^(http[s]?:\/\/)(www\.){0,1}[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  public static urlHttpsCheck =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,20}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
}
