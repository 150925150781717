import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  ErrorHandler,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { AutoCompleteModule } from "primeng/autocomplete";
import { SlickCarouselModule } from "ngx-slick-carousel";
//import { NgxUploaderModule } from 'ngx-uploader';

// COMPONENTS
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { HeaderComponent } from "./navigation/header/header.component";
import { SidebarComponent } from "./navigation/sidebar/sidebar.component";

// SERVICES
import { AuthGuard } from "./providers/guard/auth.guard";
import { LazyGuard } from "./providers/guard/lazy.guard";
import { AuthService } from "./providers/auth/auth.service";
import { TokenInterceptor } from "./token.interceptor";
import { HttpCancelInterceptor } from "src/app/http-cancel.interceptor";
import { HttpCancelService } from "src/app/providers/http-cancel/http-cancel.service";
import { GlobalErrorHandler } from "./global-error-handler";

// PIPES
import { ThousandSeparatorPipe } from "./utils/pipes/thousand-separator.pipe";
import { MatDialogModule } from "@angular/material/dialog";
import { SettingsComponent } from "./navigation/settings/settings.component";
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    ThousandSeparatorPipe,
    SettingsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatSnackBarModule,
    AutoCompleteModule,
    SlickCarouselModule,
    MatDialogModule,
    //NgxUploaderModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
    },
    AuthGuard,
    AuthService,
    LazyGuard,
    HttpCancelService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
