import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, throwError, from } from "rxjs";

// UTILS
import { environment } from "src/environments/environment";
import { BaseUrl } from "../utils/base-url-constants";

// SERVICES
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem("token");
  refreshAppointments: any = new Subject();
  refreshTask: any = new Subject();
  refreshLeadGrid: any = new Subject();
  refreshMainStatus: any = new Subject();
  refreshActivityLog: any = new Subject();
  refreshInfo: any = new Subject();
  refreshUnresolvedAppts: any = new Subject();
  refreshRelated: any = new Subject();
  refreshTeamsInfo: any = new Subject();
  refreshSubHeaderPinnedCount: any = new Subject();
  // refreshAgent: any = new Subject();
  refreshOpenHouse: any = new Subject();
  refreshOffer: any = new Subject();
  refreshContact: any = new Subject();
  refreshInboxActivity: any = new Subject();
  refreshCallerToggleDisplay: any = new Subject();

  constructor(
    private http: HttpClient,
    private _loaderService: NgxUiLoaderService
  ) {}

  updateMainStatus = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mainStatus + "update-label";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateMainStatusPosition = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mainStatus + "update-position";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteColumnList = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mainStatus + "delete";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getMainStatus = (fieldData: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mainStatus + "list";
    return this.http.post(endpoint, fieldData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  saveMainStatus = (fieldData: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mainStatus + "save";
    return this.http.post(endpoint, fieldData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  /**
   * FUNCTION: GET ALL MODULES
   * @param {Object} fieldData
   * @returns ARRAY OF MODULES
   */
  getAllModules = (fieldData: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.moduleUrl + "list";

    return this.http.post(endpoint, fieldData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  checkLoader(id?: any) {
    return this._loaderService.hasRunningTask(true, id);
  }

  saveQuestion = (data, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.masterQuestionUrl;

    if (val) {
      endpoint += "edit";
      data = { ...data, masterQuestionId: val?._id };
    } else {
      endpoint += "save";
    }

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  saveSection = (data, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.masterGroupUrl;

    if (val) {
      endpoint += "edit";
      data = { ...data, masterGroupId: val?._id };
    } else {
      endpoint += "save";
    }

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteCustomSection = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.masterGroupUrl + "delete";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteCustomQuestion = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.masterQuestionUrl + "delete";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getFile = (data, isPublic?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.sharedUrl;

    if (isPublic) {
      endpoint += `get-public-file?${isPublic?.type}ShareLink=${isPublic?.itemId}`;
    } else {
      endpoint += "get-file";
    }

    return this.http.post(endpoint, data, { responseType: "blob" }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addTags = (fieldData): Observable<any> => {
    let baseUrl = "";
    if (fieldData?.for == "lead") {
      baseUrl = BaseUrl.leadUrl;
    }
    if (fieldData?.for == "contact") {
      baseUrl = BaseUrl.contactUrl;
    }

    const endpoint =
      this.baseUrl + baseUrl + "add-or-remove-tag/" + fieldData.leadId;

    return this.http
      .post(endpoint, {
        moduleId: fieldData.moduleId,
        tagIds: fieldData.tagIds,
        isTagAdd: fieldData.isTagAdd,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  };

  addBulkTags = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.contactUrl + "add-or-remove-bulk-tag";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getAppointments = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentUrl + "list";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getActivityLog = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.activityUrl + "list";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addActivityLog = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.activityUrl + "save";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  editActivityLogs = (data, id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.activityUrl + "update/" + id;
    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteActivityLogs = (id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.activityUrl + "delete/" + id;
    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  /*************** Appointment ***************/
  saveAppointment = (data, isEdit?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentUrl;
    if (isEdit) {
      endpoint += `edit/${isEdit?._id}`;
    } else {
      endpoint += "save";
    }
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getStats = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentUrl + "stats";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateAppointmentStatus = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentUrl + "update-status";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteAppointment = (id): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentUrl + "delete";

    if (id) {
      endpoint += `/${id}`;
    }

    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  //****** Files And Folder  */
  getFiles = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.fileUrl + "list";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addFile = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.fileUrl + "save";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateFile = (data, fileId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.fileUrl + "update";

    if (fileId) {
      endpoint += `/${fileId}`;
    }

    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteFile = (fileId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.fileUrl + "delete";

    if (fileId) {
      endpoint += `/${fileId}`;
    }

    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  moveFiles = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.fileUrl + "move-to-other-folder";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getUploadUrl = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sharedUrl + "s3-signed-url-put";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getFolders = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.folderUrl + "list";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addFolder = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.folderUrl + "save";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateFolder = (data, folderId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.folderUrl + "update";

    if (folderId) {
      endpoint += `/${folderId}`;
    }

    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteFolder = (folderId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.folderUrl + "delete";

    if (folderId) {
      endpoint += `/${folderId}`;
    }

    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getMapLatLng = (address): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.googleMapUrl + "streetView";
    return this.http.post(endpoint, address).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  // addFile1 = (data): Observable<any> => {
  //   const endpoint = this.baseUrl + BaseUrl.fileUrl + "file-upload1";
  //   return this.http.post(endpoint, data).pipe(
  //     catchError((err) => {
  //       return throwError(err);
  //     })
  //   );
  // };

  getBlockNumber = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.blockNumberUrl + "getblocknumber";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getManageFlow = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + "list";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  blockNumber = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.blockNumberUrl + "blocknumber";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getAllNumType = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumber + "fetch_num_types";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getAvailability = (data, webLink?): Observable<any> => {
    let endpoint =
      this.baseUrl + BaseUrl.appointmentUrl + "calendar-availability";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getUserToken = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "decode_token";
    return this.http.post(endpoint, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getEmailList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + "list";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getBuyNumbers = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + "fetch_numbers";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  submitCampaign = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumber + "save";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  buyFreeNumber = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + "buy_free_numbers";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addEmail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + "save";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  editEmail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + "edit";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getBuyNumberCount = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumber + "getCount";
    return this.http.post(endpoint, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  checkEmail = (email, isBulkEmail?): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sendGridUrl + "checkEmail";
    return this.http.post(endpoint, email).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  verifyEmail = (data, isBulkEmail?): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sendGridUrl + "verify";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteEmail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + "delete";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getManageNumbers = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumber + "list";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateCallRecord = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumber + "updateCallRecord";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  uploadFile = (endpoint, data): Observable<any> => {
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getAccessToken = (): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.googleCalendarUrl + "get-access-token";
    return this.http.post(endpoint, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  googleCalendarLink = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.googleCalendarUrl + "link";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  sendEmail = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + "sendEmail";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  setAvailability = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + "setAvailability";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  unlinkCalendar = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.googleCalendarUrl + "unlink";
    return this.http.post(endpoint, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  markAsFavorite = (moduleId, data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "favourite"; // TODO: SPELL CHECK

    if (moduleId) {
      endpoint += `/${moduleId}`;
    }

    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  checkBlockNumber = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.blockNumberUrl + "checkblocknumber";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  copyEmail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + "copy";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getDLCForm = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumber + "getDLCForm";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  submitDlc = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumber + "saveDlcData";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getTemplateCountList = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + "getCount";
    return this.http.post(endpoint, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  changeLeadInvestmentStatus = (moduleId, data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "investmentStatusUpdate";
    if (moduleId) {
      endpoint += `/${moduleId}`;
    }
    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateLeadConnect = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumber + "updateLeadConnect";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteNumber = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumber + "delete";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  sendSms = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.communicationUrl + "sendSms";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateAssignment = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumber + "update";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteFilterTemplate = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.filterTemplateUrl + "delete";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  appointmentForTaskPage = (fieldData): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentUrl + "task/list";
    return this.http.post(endpoint, fieldData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getFilterTemplates = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.filterTemplateUrl + "list";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  saveFilterTemplates = (data, type: string = "save"): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.filterTemplateUrl + type;
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getExportFile = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sharedUrl + "exportFileList";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  exportFileDelete = (fileId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.sharedUrl + "exportFileDelete";
    if (fileId) {
      endpoint += `/${fileId}`;
    }
    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getBulkSMSCredits = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsUrl + "checkCreditAndBuyerCount";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getSMSCampaigns = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsUrl + "campaignStats";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  sendBulkSms = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsUrl + "bulk";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  checkCampaign = (data, type?): Observable<any> => {
    let endpoint = this.baseUrl;
    if (type) {
      endpoint += BaseUrl.smsUrl;
    } else {
      endpoint += BaseUrl.sendGridUrl;
    }
    endpoint += "checkCampaign";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getSMSstatsById = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsUrl + "campaign-stats-by-id";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getSMSstatsBySubmoduleId = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.smsUrl + "campaign-stats-by-submoduleId";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getEmailTemplates = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.templateUrl + "get";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  editEmailTemplates = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.templateUrl + "edit";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  sendBulkEmail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sendGridUrl + "send";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  saveEmailTemplates = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.templateUrl + "save";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getCampaigns = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sendGridUrl + "campaign-stats";

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getEmailStatsById = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.sendGridUrl + "campaign-stats-by-id";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };
}
