import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  EventEmitter,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment-timezone";
import { QuillEditorComponent } from "ngx-quill";

// UTILS
import { ResponseModel } from "src/app/utils/models/response";
import { ErrorModel } from "src/app/utils/models/error";
import { MessageConstant } from "src/app/utils/message-constant";
import { environment } from "src/environments/environment";
import { BaseUrl } from "src/app/utils/base-url-constants";
import * as _ from "lodash";
import { StatusConstant } from "src/app/utils/status-constant";
import { timezoneConstant } from "src/app/utils/timezone-list-constant";

// SERVICES
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SharedService } from "../../shared.service";
import { ToastrService } from "ngx-toastr";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { SmsService } from "src/app/providers/sms/sms.service";
import { ScheduledSmsService } from "src/app/providers/scheduled-sms/scheduled-sms.service";

// COMPONENTS
import { AddEditSmsTemplateComponent } from "../add-edit-sms-template/add-edit-sms-template.component";

@Component({
  selector: "app-sms-dialog",
  templateUrl: "./sms-dialog.component.html",
  styleUrls: ["./sms-dialog.component.scss"],
})
export class SmsDialogComponent implements OnInit {
  @ViewChild("editor") editor: QuillEditorComponent;
  smsDetailForm: FormGroup;
  toggle = false;
  submitted: boolean = false;
  scheduledDateTime: boolean = false;
  minimumTime = new Date();
  messageConstant = MessageConstant;
  smsFilterBody: String = "";
  smsCount: number = 0;
  smsCharCount: number = 0;
  items: string[] = [
    "First_Name",
    "Last_Name",
    "Property_Street_Address",
    "Property_City",
    "Property_State",
    "Property_Zip_Code",
  ];
  smsTemplates: any[] = [];
  timezoneList: any = [];
  showAttachments: any[] = [];
  editorOptions: any = {};
  editorStyle: {
    height: "500px";
  };
  attachments: any[] = [];
  timeOut: any;
  fileCount: number = 0;
  allFileCount: number = 0;
  duplicateImageArr: any = [];
  fileSize: any = 0;
  selectedFileSize: any = 0;
  dialogRef;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _smsDialogRef: MatDialogRef<SmsDialogComponent>,
    private _sharedService: SharedService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    public _utilities: CommonFunctionsService,
    public _smsService: SmsService,
    private _scheduledSMSService: ScheduledSmsService
  ) {
    this.editorOptions = {
      placeholder: this._utilities.commonEditor().placeholder,
      toolbar: this._utilities.commonEditor().toolbar,
      mention: this.mentionEditor(),
    };
  }

  mentionEditor() {
    return {
      mentionListClass: "ql-mention-list mat-elevation-z8",
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: true,
      spaceAfterInsert: true,
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        // this.tagUser(item.value)
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, "", "user");
      },
      source: (searchTerm, renderList) => {
        const values = this.items.map((item, index) => {
          return { id: index, value: item };
        });

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    };
  }

  ngOnInit(): void {
    this.getSmsTemplates();
    this.minimumTime = new Date(
      moment
        .tz(localStorage.getItem("timezoneOffset"))
        .startOf("day")
        .format("YYYY/MM/DD h:mm:ss A")
    );
    this.smsDetailForm = this.fb.group({
      toNumber: ["", Validators.required],
      fromNumber: ["", Validators.required],
      smsBody: ["", Validators.required],
      mediaUrls: [""],
      smsTemplateId: ["", []],
      activeTimeChange: [false],
      scheduleDate: [""],
      scheduleTime: [""],
      timezone: [localStorage.getItem("timezoneOffset")],
    });

    this.timezoneList = timezoneConstant.timezoneArray;
    if (this.data) {
      const { phoneNumber, defaultNumber } = this.data;
      this.smsDetailForm.patchValue({
        fromNumber: defaultNumber ? defaultNumber : "",
        toNumber: phoneNumber,
      });
    }

    if (this.data.type == "scheduledSMS") {
      if (this.data.attachments && this.data.attachments) {
        let attchments = this.data.attachments.map((item) => {
          let attach = item.split("/");
          return { name: attach[attach.length - 1], url: item };
        });
        this.showAttachments = attchments;
      }

      let convertTime = moment(this.data.dueInIso)
        .tz(this.data.timezone)
        .format("YYYY-MM-DD HH:mm:ss");
      let dueIso = this._utilities.dueDateFormat(
        new Date(convertTime)
      ).parseDate;

      this.toggle = true;
      this.smsDetailForm.patchValue({
        activeTimeChange: [true],
        smsTemplateId: this.data.smsTemplateId ? this.data.smsTemplateId : "",
        smsBody: this.data.smsTemplateBody,
        scheduleDate: dueIso,
        scheduleTime: dueIso,
        timezone: this.data.timezone,
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.smsDetailForm.invalid) {
      return;
    }

    let obj = {
      ...this.smsDetailForm.value,
      // leadId: this.data.leadId,
      // contactId: this.data._id,
      moduleType: this.data?.moduleType,
      moduleId: this.data.moduleId,
      contactId: this.data?.contactId,
    };

    if (
      this.data.moduleType == "Leads" ||
      this.data.moduleId == StatusConstant?.ModuleId?.LEAD
    ) {
      obj.leadId = this.data.leadId;
      obj.contactId = this.data._id;
    } else if (
      this.data.moduleType == "Contacts" ||
      this.data.moduleId == StatusConstant?.ModuleId?.CONTACT
    ) {
      obj.contactId = this.data.contactId;
    }

    // obj["fromNumber"] =
    //   environment.countryCode +
    //   this._utilities.unMaskNumber(this.smsDetailForm.value.fromNumber);

    obj["fromNumber"] = this._utilities.unMaskNumber(
      this.smsDetailForm.value.fromNumber
    );

    if (obj.activeTimeChange && this.scheduledDateTime) {
      this._toastrService.error("Invalid or expired time");
      return;
    }
    this._loaderService.start();
    if (obj.activeTimeChange) {
      obj.dueInIso =
        moment(obj.scheduleDate).utcOffset("0").format("YYYY-MM-DD") +
        "T" +
        moment(obj.scheduleTime).utcOffset("0").seconds(0).format("HH:mm:ss") +
        moment().tz(obj.timezone).format("Z");
    }

    let attachments: any = [
      ...this.showAttachments,
      ...this.attachments
    ]
    // if (this.showAttachments.length > 0) {
    //   this.showAttachments.forEach((item) => {
    //     this.attachments.push(item);
    //   });
    // }
    if (attachments.length) {
      obj = {
        ...obj,
        mediaUrls: attachments.map((x) => x.url),
      };
    } else {
      delete obj["mediaUrls"];
    }
    this.messageBody(
      this.data,
      this._utilities.getTextWithMentions(obj.smsBody),
      this.data.teams
    );
    obj.smsBody = this._utilities.populateEditorDataBR(this.smsFilterBody);

    if (obj.smsBody) {
      obj.smsBody = obj.smsBody
        ?.replaceAll("&amp;", "&")
        ?.replaceAll("&gt;", ">")
        ?.replaceAll("&lt;", "<")
        ?.replaceAll("&#xFEFF;", "");
      obj.smsBody = obj.smsBody.replace(/[^\x00-\x7F]/g, "");
    }

    if (this.data.type == "scheduledSMS") {
      obj.scheduledSmsId = this.data.scheduledSmsId;
      obj.smsTemplateId = obj.smsTemplateId == "" ? null : obj.smsTemplateId;
      delete obj["activeTimeChange"];
      delete obj["propertyId"];
      delete obj["vendorId"];
      delete obj["dialerCampaignId"];
      delete obj["scheduleDate"];
      delete obj["scheduleTime"];

      this._scheduledSMSService.editScheduledSMS(obj).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response.statusCode == 200) {
            this._toastrService.success(
              this.messageConstant.smsScheduledUpdateSuccess,
              ""
            );
            // if (this.newLeadSourceId) {
            //   obj.newLeadSourceId = this.newLeadSourceId;
            // }
            this._smsDialogRef.close(obj);
            this._sharedService.refreshActivityLog.next(true);
            // this._sharedService.refreshDashboard.next(true);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
    } else {
      this._sharedService.sendSms(obj).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response.statusCode == 200) {
            this._toastrService.success(
              this.messageConstant.smsSendSuccess,
              ""
            );
            this._smsDialogRef.close(obj);
            this._sharedService.refreshActivityLog.next(true);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            if (error?.platform) {
              this._toastrService.error(error.platform, "");
            } else {
              this._toastrService.error(error.message, "");
            }
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
    }
  }

  onChangeTime() {
    let nowDate = moment()
      .tz(this.smsDetailForm.get("timezone").value)
      .format();
    let selected = moment(this.smsDetailForm.get("scheduleDate").value).format(
      "YYYY-MM-DD"
    );
    let selectedTime = moment(
      this.smsDetailForm.get("scheduleTime").value
    ).format("HH:mm:ss");
    let selectedDate =
      selected +
      "T" +
      selectedTime +
      moment().tz(this.smsDetailForm.get("timezone").value).format("Z");

    if (nowDate.valueOf() > selectedDate.valueOf()) {
      this.scheduledDateTime = true;
      this._toastrService.error("Invalid or expired date");
    } else {
      this.scheduledDateTime = false;
    }
  }

  async uploadAttachment() {
    if (this.attachments.length > 0) {
      for (let i = 0; i < this.attachments.length; i++) {
        const output = this.attachments[i];
        this.fileCount = i + 1;
        this.uploadFile(output);
      }
    }
    this.attachments = [];
  }

  uploadFile(fileData) {
    let endpoint = environment.baseUrl + BaseUrl.sharedUrl + "file-upload";
    if (this.data.moduleId) {
      endpoint += `?moduleId=${this.data.moduleId}&isPublic=1&type=0`;
    }

    var newformData: FormData = new FormData();
    const file: any = fileData;
    newformData.append("file", file, file.name);
    this._loaderService.start();
    this._sharedService.uploadFile(endpoint, newformData).subscribe(
      (response: ResponseModel) => {
        this.allFileCount += 1;
        if (this.allFileCount == this.fileCount) {
          this._loaderService.stop();
          this.allFileCount = 0;
        }
        if (response?.statusCode && response?.statusCode == 200) {
          if (response?.data.length > 0) {
            let imgData = response?.data[0];
            imgData["size"] = String(fileData?.size);
            this.attachments.push(imgData);
          }
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  onToggle() {
    this.toggle = !this.toggle;
    if (this.toggle) {
      this.customFormValidation(
        this.smsDetailForm,
        ["scheduleDate", "scheduleTime"],
        [[Validators.required], [Validators.required]]
      );

      let convertTime = moment()
        .tz(localStorage.getItem("timezoneOffset"))
        .format("YYYY-MM-DD HH:mm:ss");
      let dueIso = this._utilities.dueDateFormat(
        new Date(convertTime)
      ).parseDate;
      this.smsDetailForm.patchValue({
        scheduleDate: dueIso,
        scheduleTime: dueIso,
      });
    } else {
      this.smsDetailForm.patchValue({
        scheduleDate: "",
        scheduleTime: "",
      });
      this.customFormValidation(
        this.smsDetailForm,
        ["scheduleDate", "scheduleTime"],
        [[], []]
      );
    }
  }

  customFormValidation(form: FormGroup, field, validation) {
    for (let i = 0; i < field.length; i++) {
      form.get(field[i]).setValidators(validation[i]);
      form.get(field[i]).updateValueAndValidity();
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.smsDetailForm.controls[controlName].hasError(errorName);
  };

  messageBody(data, smsMessage, teams) {
    let messageBody;
    let address;
    let streetAddress;
    let city;
    let state;
    let zip;
    let name;
    let firstName;
    let lastName;

    if (data?.name) {
      const leadTitle = data?.name
        ? data.name.replace(/ {2,}/g, " ").trim()
        : "";
      name = leadTitle ? leadTitle.split(" ") : "";
      firstName = name[0] ? name[0] : "First_Name(N/A)";
      lastName = name[1] ? name[1] : "last_Name(N/A)";
    } else if (data?.contacts[0]?.title) {
      name = data?.contacts[0]?.title.split(" ");
      firstName = name[0] ? name[0] : "First_Name(N/A)";
      lastName = name[1] ? name[1] : "last_Name(N/A)";
    } else {
      firstName = "First_Name(N/A)";
      lastName = "last_Name(N/A)";
    }

    // if (this.addEditForm.value.title) {
    //   apptTime =
    //     this.addEditForm.value.title +
    //     ' ' +
    //     moment(this.addEditForm.value.dateTime).format('ddd MMM DD, YYYY') +
    //     ', at ' +
    //     moment(moment(this.startTime).set({ second: 0 })).format('LT');
    // }

    if (data?.address) {
      address = data?.address.split(",");
      if (address.length > 1) {
        streetAddress = address[0]
          ? address[0]
          : "Property_Street_Address(N/A)";
        city = address[1] ? address[1] : "Property_City(N/A)";
        if (address.length > 2) {
          let subDivide = address[2].trim();
          if (subDivide) {
            let sub = subDivide.split(" ");
            state = sub[0] ? sub[0] : "Property_State(N/A)";
            zip = sub[1] ? sub[1] : "Property_Zip_Code(N/A)";
          }
        } else {
          state = "Property_State(N/A)";
          zip = "Property_Zip_Code(N/A)";
        }
      } else {
        streetAddress = address[0];
        city = "Property_City(N/A)";
        state = "Property_State(N/A)";
        zip = "Property_Zip_Code(N/A)";
      }
    } else if (
      data.dialerSmsTemplete &&
      data.dialerSmsTemplete == "dialer Sms"
    ) {
      address = data?.propertyFormatedAddress;
      streetAddress = data?.propertyAddress
        ? data?.propertyAddress
        : "Property_Street_Address(N/A)";
      city = data?.propertyCity ? data?.propertyCity : "Property_City(N/A)";
      state = data?.propertyState ? data?.propertyState : "Property_State(N/A)";
      zip = data?.propertyZipCode
        ? data?.propertyZipCode
        : "Property_Zip_Code(N/A)";
    } else {
      streetAddress = "Property_Street_Address(N/A)";
      city = "Property_City(N/A)";
      state = "Property_State(N/A)";
      zip = "Property_Zip_Code(N/A)";
    }

    let myObj = {
      "@First_Name": this._utilities.titleCaseToWord(firstName),
      "@Last_Name": this._utilities.titleCaseToWord(lastName),
      "@Property_Street_Address": streetAddress ? streetAddress : "",
      "@Property_City": city ? city : "",
      "@Property_State": state ? state : "",
      "@Property_Zip_Code": zip ? zip : "",
      "@Appt_Date_and_Time": "",
      "@Appt_Day": "",
      "@Appt_Time": "",
    };
    teams?.roles?.map((i) => {
      let key = i.name.toString();
      if (key != undefined && smsMessage.includes(key)) {
        let originalRole = teams.roles.find((item) => item.name == key);
        let userId = teams.userAssignment[originalRole._id];
        if (!userId) {
          if (teams?.items?.roles) {
            originalRole = teams?.items?.roles.find(
              (item) => item.name == "Account Owner"
            );
          }
          if (teams?.roles) {
            originalRole = teams?.roles.find(
              (item) => item.name == "Account Owner"
            );
          }
        }
        userId =
          userId != null
            ? userId
            : teams?.items?.assignUser["5fd093a2f44c5ac2ea1919b4"]
            ? teams?.items?.assignUser["5fd093a2f44c5ac2ea1919b4"]
            : teams?.assignUser["5fd093a2f44c5ac2ea1919b4"];
        let username;
        if (userId) {
          username = originalRole.members.find(
            (item) => item._id.toString() == userId.toString()
          );
        }
        let obj = {};
        obj["@" + key] = username?.firstName
          ? this._utilities.titleCaseToWord(`${username?.firstName}`)
          : `${key}(N/A)`;
        myObj = { ...myObj, ...obj };
      }
    });

    let rowRole = [
      "@First_Name",
      "@Last_Name",
      "@Property_Street_Address",
      "@Property_City",
      "@Property_State",
      "@Property_Zip_Code",
      "@Appt_Date_and_Time",
      "@Appt_Day",
      "@Appt_Time",
    ];

    let roles = teams?.roles
      ? teams?.roles?.map((item) => "@" + item.name)
      : [];
    let rolesWithKey = new RegExp([...rowRole, ...roles].join("|"), "g");
    messageBody = smsMessage.replace(rolesWithKey, (matched) => {
      return myObj[matched];
    });
    //messageBody = messageBody.replace(/@/g, '');
    this.smsFilterBody = messageBody;
  }

  getSMSCount(e) {
    const MAX_LENGTH = 160;
    const message = this._utilities.getText(
      this["editor"].quillEditor.getContents()
    );

    const length = message.length ? message?.length - 1 : 0;

    this.smsCount = Math.ceil(length / MAX_LENGTH);
    this.smsCharCount = MAX_LENGTH * this.smsCount - length;
  }

  setSmsTemplate(id: any, bool?: any) {
    this.showAttachments = [];
    if (!id) {
      this.smsDetailForm.patchValue({
        smsBody: "",
      });
    }
    const smsTemplateId = id;
    if (bool) {
      if (this.data.type != "scheduledSMS") {
        let obj = { smsTemplateId: smsTemplateId };

        this._smsService.changeTemplate(obj).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              if (error?.platform) {
                this._toastrService.error(error.platform, "");
              } else {
                this._toastrService.error(error.message, "");
              }
            } else {
              this._toastrService.error(this.messageConstant.unknownError, "");
            }
          }
        );
      }
    }
    const index = this.smsTemplates.findIndex((x) => x._id === smsTemplateId);
    if (index === -1) {
      return;
    }
    if (this.smsTemplates[index]?.attachmentArray) {
      let attchments = this.smsTemplates[index]?.attachmentArray.map((item) => {
        return { name: item?.name, url: item?.url };
      });
      this.showAttachments = attchments;
    }
    this.messageBody(
      this.data,
      this._utilities.getTextWithMentions(this.smsTemplates[index].message),
      this.data.teams
    );
    this.smsDetailForm.patchValue({
      smsBody: this._utilities.populateEditorDataWithN(this.smsFilterBody),
    });
    this.getSMSCount("");
  }

  getSmsTemplates() {
    let obj = {};

    this._smsService.getSmsList(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.smsTemplates = response.data?.items;
          if (this.data.type != "scheduledSMS") {
            let smsTemplateId = this.smsTemplates
              .map((template) => {
                if (template.smsTemplateId) {
                  return template.smsTemplateId;
                }
              })
              .filter((x) => x)[0];
            if (smsTemplateId) {
              this.smsDetailForm.patchValue({
                smsTemplateId: smsTemplateId,
              });
              this.setSmsTemplate(smsTemplateId, false);
            }
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (error?.platform) {
            this._toastrService.error(error.platform, "");
          } else {
            this._toastrService.error(error.message, "");
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  removeAttachment(index) {
    this.attachments.splice(index, 1);
  }

  removeExistingAttachment(index) {
    this.showAttachments.splice(index, 1);
  }

  onUploadLogo(output): void {
    this.attachments = output.target.files;

    if (this.attachments.length > 0) {
      for (let i = 0; i < this.attachments.length; i++) {
        const element = this.attachments[i];
        if (
          element?.type == "application/pdf" ||
          element?.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          element?.type == "test.csv"
        ) {
          this._toastrService.error(this.messageConstant?.fileFormateErr);
          this.attachments = [];
          return;
        }
      }
    }

    this.uploadAttachment();
  }
  removeImageArr(value) {
    this.attachments = this.attachments.filter(
      (item) => item.url !== value.url
    );
    this.fileSize = this.fileSize - Number(value?.size);
    this.selectedFileSize = 0;
  }

  onAddTemplate() {
    this.dialogRef = this.dialog.open(AddEditSmsTemplateComponent, {
      width: "650px",
      disableClose: true,
      panelClass: "editTemplate",
      data: {
        moduleId: StatusConstant?.ModuleId?.LEAD,
        action: "create",
        items: [],
        allTitle: {
          heading: "Create Template",
          buttonTitle: "Save",
        },
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        let smsTemplateId = data._id;
        this.getSmsTemplates();
        setTimeout(() => {
          this.smsDetailForm.patchValue({
            smsTemplateId: smsTemplateId,
          });
          this.setSmsTemplate(smsTemplateId, true);
        }, 1000);
      }
    });
  }
}
