<div class="re-agent-tasksdetail">
  <div>
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between"
      >
        {{ type }} Task
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form autocomplete="off" [formGroup]="addEditTaskForm" novalidate>
    <mat-dialog-content>
      <ng-container *ngIf="data.isTabs">
        <div class="re-agent-tabbtn" *ngIf="data.isLocalTask">
          <div class="nav">
            <a
              href="javascript:;"
              [class.active]="activeTab == 'Lead'"
              [class.disabled_class]="type == 'Update' && activeTab != 'Lead'"
              (click)="tabChange('Lead')"
            >
              Lead Task
            </a>
            <a
              href="javascript:;"
              [class.active]="activeTab == 'General'"
              [class.disabled_class]="
                type == 'Update' && activeTab != 'General'
              "
              (click)="tabChange('General')"
            >
              General Business
            </a>
          </div>
        </div>

        <div class="re-agent-tabbtn" *ngIf="!data.isLocalTask">
          <div class="nav">
            <a
              href="javascript:;"
              [class.active]="activeTab == 'General'"
              [class.disabled_class]="
                type == 'Update' && activeTab != 'General'
              "
              (click)="tabChange('General')"
            >
              General Business
            </a>
            <a
              href="javascript:;"
              [class.active]="activeTab == 'Lead'"
              [class.disabled_class]="type == 'Update' && activeTab != 'Lead'"
              (click)="tabChange('Lead')"
            >
              Lead Task
            </a>
          </div>
        </div>
      </ng-container>

      <div class="container-fluid p-0">
        <div class="">
          <div>
            <div>
              <div class="re-agent-box">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <h3 class="re-agent-subtitle">Task detail</h3>
                  </div>
                  <div class="col-12 col-md-12">
                    <div class="form-group">
                      <label class="re-agent-label">Task Template</label>
                      <select
                        name="taskTemplateOption"
                        formControlName="taskTemplateOption"
                        class="form-control"
                        (change)="
                          onChangeTemplate(
                            addEditTaskForm.value.taskTemplateOption
                          )
                        "
                      >
                        <option value="">Select Task Template</option>
                        <option
                          *ngFor="let task of taskTemplate"
                          value="{{ task._id }}"
                        >
                          {{ task.templateName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-md-12" *ngIf="activeTab == 'Lead'">
                    <div class="form-group re-agent-custom px-0">
                      <label class="re-agent-label"
                        >Property Address
                        <span class="text-danger">*</span></label
                      >
                      <p-autoComplete
                        *ngIf="!data.isLocalTask && data.category == 'Add'"
                        placeholder="Type Property"
                        formControlName="subModuleId"
                        appendTo="body"
                        field="address"
                        name="subModuleId"
                        [suggestions]="propertyList"
                        (completeMethod)="getPropertyAddress($event.query)"
                        emptyMessage="{{ noDataAvailable }}"
                        dataKey="_id"
                        [multiple]="false"
                        [dropdown]="true"
                        (onSelect)="getTeamBySubModuleId($event._id)"
                      >
                      </p-autoComplete>
                      <select
                        *ngIf="
                          data.isLocalTask ||
                          (!data.isLocalTask && data.category == 'Update')
                        "
                        class="form-control"
                        name="subModuleId"
                        formControlName="subModuleId"
                        [class.re-agent-disabled-property]="
                          isMarketReadonly ? 'disabled' : null
                        "
                      >
                        <option [value]="data.leadInfo._id">
                          {{
                            !data.leadInfo.address
                              ? "N/A"
                              : _utilities.formatAddress(
                                  data.leadInfo.address,
                                  data.leadInfo?.unitNo
                                )
                          }}
                        </option>
                      </select>

                      <div class="text-danger re-agent-error-txt">
                        <span
                          *ngIf="
                            submitted && hasError('subModuleId', 'required')
                          "
                        >
                          {{
                            messageConstant.requiredField.replace(
                              "Field",
                              "Property"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-12">
                    <div class="form-group">
                      <label class="re-agent-label">
                        Task Name <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="title"
                        placeholder="Enter Task Name"
                        formControlName="title"
                      />
                      <div class="text-danger re-agent-error-txt">
                        <span
                          *ngIf="submitted && hasError('title', 'required')"
                        >
                          {{
                            messageConstant.requiredField.replace(
                              "Field",
                              "Task Name"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-12">
                    <div class="form-group">
                      <label class="re-agent-label"
                        >Task Type <span class="text-danger">*</span></label
                      >
                      <select
                        name="taskTypeOption"
                        formControlName="taskTypeOption"
                        class="form-control"
                      >
                        <option value="">Select the task type</option>
                        <option
                          *ngFor="let task of taskType"
                          value="{{ task.id }}"
                        >
                          {{ task.name }}
                        </option>
                      </select>
                      <div class="text-danger re-agent-error-txt">
                        <span
                          *ngIf="
                            submitted && hasError('taskTypeOption', 'required')
                          "
                        >
                          {{
                            messageConstant.requiredField.replace(
                              "Field",
                              "Task Type"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-12">
                    <div class="form-group">
                      <label class="re-agent-label">Task Description</label>
                      <textarea
                        class="form-control"
                        name="taskDetail"
                        formControlName="taskDetail"
                        rows="2"
                        placeholder="Enter description (Optional)"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12 col-md-12">
                    <div class="form-group">
                      <label class="re-agent-label">Priority</label>
                      <div class="re-radiolist">
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="taskPriority"
                            formControlName="taskPriority"
                            value="1"
                            [checked]="addEditTaskForm.value.taskPriority == 1"
                          />
                          <span class="re-checkmark"></span>Urgent
                        </label>
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="taskPriority"
                            formControlName="taskPriority"
                            value="2"
                            [checked]="addEditTaskForm.value.taskPriority == 2"
                          />
                          <span class="re-checkmark"></span>High
                        </label>
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="taskPriority"
                            formControlName="taskPriority"
                            value="3"
                            [checked]="addEditTaskForm.value.taskPriority == 3"
                          />
                          <span class="re-checkmark"></span>Medium
                        </label>
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="taskPriority"
                            formControlName="taskPriority"
                            value="4"
                            [checked]="addEditTaskForm.value.taskPriority == 4"
                          />
                          <span class="re-checkmark"></span>Low
                        </label>
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="taskPriority"
                            formControlName="taskPriority"
                            value="5"
                            [checked]="addEditTaskForm.value.taskPriority == 5"
                          />
                          <span class="re-checkmark"></span>None
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="re-agent-box">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <h3 class="re-agent-subtitle">Assignment & due</h3>
                  </div>
                  <div class="col-12 col-md-12" *ngIf="activeTab == 'Lead'">
                    <div class="form-group">
                      <label class="re-agent-label"
                        >Role to Assign
                        <span class="text-danger">*</span></label
                      >

                      <!-- <select
                        name="roleId"
                        formControlName="roleId"
                        class="form-control"
                        (change)="
                          onChangeRole(addEditTaskForm.value.roleId, true)
                        "
                      >
                        <option value="">Select role to assign</option>
                        <option *ngFor="let role of roles" [value]="role._id">
                          {{ role?.name | titleCase }}
                        </option>
                      </select> -->
                      <select
                        name="roleId"
                        formControlName="roleId"
                        class="form-control"
                        (change)="
                          onChangeRole(addEditTaskForm.value.roleId, true)
                        "
                      >
                        <option value="">Select role to assign</option>
                        <option
                          *ngFor="let role of leadAssignmentRoleList"
                          [value]="role._id"
                        >
                          {{ role?.roleName | titleCase }} |
                          {{ role?.firstName + " " + role?.lastName }}
                        </option>
                      </select>
                      <div class="text-danger re-agent-error-txt">
                        <span
                          *ngIf="submitted && hasError('roleId', 'required')"
                        >
                          {{
                            messageConstant.requiredField.replace(
                              "Field",
                              "Role"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-12" *ngIf="activeTab != 'Lead'">
                    <div class="form-group">
                      <label class="re-agent-label"
                        >Assign to user
                        <span class="text-danger">*</span></label
                      >
                      <select
                        name="assignUserId"
                        formControlName="assignUserId"
                        class="form-control"
                      >
                        <option value="">Select user to assign</option>
                        <option
                          *ngFor="let user of userListing"
                          [value]="user.value"
                        >
                          {{ user?.label || user?.name }}
                        </option>
                      </select>
                      <div class="text-danger re-agent-error-txt">
                        <span
                          *ngIf="
                            submitted && hasError('assignUserId', 'required')
                          "
                        >
                          {{
                            messageConstant.requiredField.replace(
                              "Field",
                              "Assign User"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-12 mb-3">
                    <label class="re-agent-label">
                      Task Due <span class="text-danger">*</span></label
                    >
                    <div class="re-radiolist">
                      <label class="re-radiobtn">
                        <input
                          type="radio"
                          name="leadtasktime"
                          [checked]="taskDueNumber == 0"
                          (click)="taskDue('One Hour')"
                          checked
                        />
                        <span class="re-checkmark"></span>One Hour
                      </label>
                      <label class="re-radiobtn">
                        <input
                          type="radio"
                          name="leadtasktime"
                          [checked]="taskDueNumber == 1"
                          (click)="taskDue('One Day')"
                        />
                        <span class="re-checkmark"></span>One Day
                      </label>
                      <label class="re-radiobtn">
                        <input
                          type="radio"
                          name="leadtasktime"
                          [checked]="taskDueNumber == 2"
                          (click)="taskDue('One Week')"
                        />
                        <span class="re-checkmark"></span>One Week
                      </label>
                      <label class="re-radiobtn">
                        <input
                          type="radio"
                          name="leadtasktime"
                          [checked]="taskDueNumber == 3"
                          (click)="taskDue('Custom')"
                        />
                        <span class="re-checkmark"></span>Custom
                      </label>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <p-calendar
                        styleClass="ngCalendarClass"
                        formControlName="dueInIso"
                        [showIcon]="true"
                        [icon]="'fa fa-calendar'"
                        [minDate]="minimumDate"
                        appendTo="body"
                        placeholder="Due date"
                        (onSelect)="taskDueValidationChange()"
                      ></p-calendar>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <!-- <div class="form-group" *ngIf="customTime">
                      <div class="re-custom-select">
                        <select
                          class="form-control re-agent-subinput"
                          formControlName="time"
                        >
                          <option value="">Choose due time</option>
                          <option
                            *ngFor="let number of timeOption"
                            [value]="number"
                          >
                            {{ number }}
                          </option>
                        </select>
                        <i class="fa fa-clock-o"></i>
                      </div>
                    </div> -->
                    <div class="form-group">
                      <p-calendar
                        formControlName="time"
                        styleClass="ngCalendarClass"
                        [timeOnly]="true"
                        inputId="timeonly"
                        [showIcon]="true"
                        [icon]="'fa fa-clock-o'"
                        hourFormat="12"
                        [minDate]="minimumTime"
                        appendTo="body"
                        placeholder="Due time"
                        (onSelect)="taskDueValidationChange()"
                      ></p-calendar>
                      <div class="text-danger re-agent-error-txt">
                        <span *ngIf="submitted && hasError('time', 'required')">
                          {{
                            messageConstant.requiredField.replace(
                              "Field",
                              "Due Time"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-12">
                    <div class="form-group">
                      <label class="re-agent-label">
                        Timezone <span class="text-danger">*</span></label
                      >
                      <select
                        name="taskTimezone"
                        formControlName="taskTimezone"
                        class="form-control"
                      >
                        <option value="">Select the Time Zone</option>
                        <option
                          *ngFor="let timezone of timezoneList"
                          value="{{ timezone.timezonename }}"
                        >
                          {{ timezone.name }}
                        </option>
                      </select>
                      <div class="text-danger re-agent-error-txt">
                        <span
                          *ngIf="
                            submitted && hasError('taskTimezone', 'required')
                          "
                        >
                          {{
                            messageConstant.requiredField.replace(
                              "Field",
                              "Task Timezone"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="re-agent-box">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <h3 class="re-agent-subtitle">Notify user</h3>
                  </div>
                  <div class="col-12">
                    <div class="re-agent-switchdiv">
                      <p class="switch-text">
                        Would you like to notify the assignee on task creation?
                      </p>
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="checkbox1"
                          (click)="onInstantNotification()"
                          [checked]="instantNotification"
                        />
                        <span class="slider"></span>
                      </label>
                    </div>
                    <div
                      class="re-radiolist mb-3"
                      *ngIf="instantNotification === true"
                    >
                      <label class="re-radiobtn">
                        <input
                          type="radio"
                          name="smsAndEmail"
                          formControlName="smsAndEmail"
                          value="both"
                          [checked]="
                            addEditTaskForm.value.smsAndEmail == 'both'
                          "
                        />
                        <span class="re-checkmark"></span>Via both SMS & Email
                      </label>
                      <label class="re-radiobtn">
                        <input
                          type="radio"
                          name="smsAndEmail"
                          formControlName="smsAndEmail"
                          value="sms"
                          [checked]="addEditTaskForm.value.smsAndEmail == 'sms'"
                        />
                        <span class="re-checkmark"></span>Via SMS
                      </label>
                      <label class="re-radiobtn">
                        <input
                          type="radio"
                          name="smsAndEmail"
                          formControlName="smsAndEmail"
                          value="email"
                          [checked]="
                            addEditTaskForm.value.smsAndEmail == 'email'
                          "
                        />
                        <span class="re-checkmark"></span>Via Email
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="re-agent-box">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <h3 class="re-agent-subtitle">
                      Task Reminder
                      <a
                        class="re-agent-addreminder pull-right"
                        href="javascript:;"
                        (click)="addReminder()"
                      >
                        + Add Reminder
                      </a>
                    </h3>
                  </div>
                </div>

                <div
                  class="row"
                  *ngIf="
                    addEditTaskForm.get('reminders')['controls'].length > 0
                  "
                >
                  <div class="col-12 col-md-4">
                    <label class="re-agent-label">
                      Remind Via <span class="text-danger">*</span></label
                    >
                  </div>
                  <div class="col-12 col-md-4 col-sm-5">
                    <label class="re-agent-label">
                      Remind Before <span class="text-danger">*</span></label
                    >
                  </div>
                  <div class="col-12 col-md-3 col-sm-5">
                    <label class="re-agent-label">
                      Remind Time <span class="text-danger">*</span></label
                    >
                  </div>
                </div>

                <div formArrayName="reminders">
                  <div
                    *ngFor="
                      let control of addEditTaskForm.get('reminders')[
                        'controls'
                      ];
                      let i = index
                    "
                  >
                    <div [formGroupName]="i" class="row">
                      <div class="col-12 col-md-4">
                        <div class="form-group">
                          <select formControlName="via" class="form-control">
                            <option value="">Select</option>
                            <option value="sms">SMS</option>
                            <option value="email">Email</option>
                          </select>
                          <div class="text-danger re-agent-error-txt">
                            <span
                              *ngIf="
                                submitted &&
                                (control.get('via').invalid ||
                                  (control.get('via').invalid &&
                                    control.get('via').touched))
                              "
                            >
                              {{
                                messageConstant.requiredField.replace(
                                  "Field",
                                  "Remind Via"
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-4 col-sm-5">
                        <div class="form-group">
                          <select formControlName="before" class="form-control">
                            <option value="">Select</option>
                            <option
                              *ngFor="let number of minutesList"
                              value="{{ number }}"
                            >
                              {{ number }}
                            </option>
                          </select>
                          <div class="text-danger re-agent-error-txt">
                            <span
                              *ngIf="
                                submitted &&
                                (control.get('before').invalid ||
                                  (control.get('before').invalid &&
                                    control.get('before').touched))
                              "
                            >
                              {{
                                messageConstant.requiredField.replace(
                                  "Field",
                                  "Remind Before"
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-3 col-sm-5">
                        <div class="form-group">
                          <select formControlName="time" class="form-control">
                            <option value="">Select</option>
                            <option
                              *ngFor="let timeType of taskTimeTypeList"
                              value="{{ timeType }}"
                            >
                              {{ timeType }}
                            </option>
                          </select>
                          <div class="text-danger re-agent-error-txt">
                            <span
                              *ngIf="
                                submitted &&
                                (control.get('time').invalid ||
                                  (control.get('time').invalid &&
                                    control.get('time').touched))
                              "
                            >
                              {{
                                messageConstant.requiredField.replace(
                                  "Field",
                                  "Remind Time"
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-1 col-sm-2">
                        <div class="form-group text-center re-trash-icon">
                          <a
                            class="d-inline-block pointer mt-2"
                            (click)="removeReminder(i)"
                            ><img
                              src="assets/images/trash-bin-red.svg"
                              alt="delete"
                          /></a>
                        </div>
                      </div>
                      <div class="col-12 re-invalid-reminder" #errorReminder>
                        <span
                          class="text-danger re-agent-error-txt"
                          *ngIf="
                            submitted &&
                            !control.get('via').invalid &&
                            !control.get('before').invalid &&
                            !control.get('time').invalid &&
                            reminderErrors.length &&
                            reminderErrors[i]
                          "
                        >
                          {{ reminderErrors[i] }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="re-agent-box">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <div class="re-agent-note mb-3">
                      By opting in to SMS Notifications, you consent to receive
                      notifications regarding tasks and appointments created by
                      you. You understand and agree that text messages sent to
                      your mobile number may be generated using automated
                      technology. Message and data rates may apply. Reply STOP
                      to opt-out. Consent is not a condition of any purchase.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        (click)="save()"
        cdkFocusInitial
      >
        {{ button }} Task
      </button>
    </mat-dialog-actions>
  </form>
</div>
