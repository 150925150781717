<form [formGroup]="tagAutomationForm" autocomplete="off">
  <ng-container *ngIf="isAllowManageNumberFeature">
    <div class="form-group">
      <div class="re-agent-radiosec">
        <p class="re-agent-radotext">
          Auto assign a Tags to leads created under this campaign?
        </p>
        <label class="re-agent-switch">
          <input
            type="checkbox"
            id="marketingcheck"
            formControlName="isTagAutomation"
            (change)="resetAllChip()"
          />
          <span class="re-agent-slider"></span>
          <span value="false" class="re-agent-text1">No</span>
          <span value="true" class="re-agent-text2">Yes</span>
        </label>
      </div>
      <hr />
    </div>

    <!--leads manually-->
    <div
      class="re-agent-tasks-cont"
      *ngIf="tagAutomationForm?.value?.isTagAutomation"
    >
      <div class="re-agent-tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadManually')">
          <span class="re-agent-name-txt">Leads created manually</span>
          <span
            class="re-agent-arrows"
            *ngIf="!isCollapseVisible('leadManually')"
          >
            <i
              class="fa fa-chevron-circle-up re-agent-teals"
              aria-hidden="true"
            ></i>
          </span>
          <span
            class="re-agent-arrows"
            *ngIf="isCollapseVisible('leadManually')"
          >
            <i
              class="fa fa-chevron-circle-down re-agent-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>

      <div
        class="row pb-3"
        [class.collapse]="isCollapseVisible('leadManually')"
      >
        <div class="col-12 col-md-12">
          <div class="re-agent-radiosec">
            <p class="re-agent-radotext">Would you like to enable this</p>
            <label class="re-agent-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isTagLeadCreatedManual"
                (change)="resetChipIds()"
              />
              <span class="re-agent-slider"></span>
              <span value="false" class="re-agent-text1">Off</span>
              <span value="true" class="re-agent-text2">On</span>
            </label>
          </div>
          <div *ngIf="tagAutomationForm?.value?.isTagLeadCreatedManual">
            <label class="labels">
              Auto assign Tags to leads created manually
            </label>
            <!-- Tag Editor -->
            <mat-form-field class="re-chip-list">
              <mat-chip-grid #chipGrid1 aria-label="Tag selection">
                <mat-chip-row
                  *ngFor="let tag of tagsCreatedManually; let tagIndex = index"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tagIndex, 1)"
                >
                  {{ tag.label }}
                  <mat-icon matChipRemove *ngIf="removable">
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </mat-icon>
                </mat-chip-row>
                <input
                  placeholder="Type tag name and press enter or comma to create a new tag"
                  #tagInputCreatedManually
                  [formControl]="tagCtrlCreatedManually"
                  [matChipInputFor]="chipGrid1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 1)"
                  (input)="onSearchChange($any($event.target).value)"
                />
              </mat-chip-grid>
            </mat-form-field>
            <label class="mt-1 re-agent-suggest-text">
              Press Enter or comma to create a new tag
            </label>
            <!-- End Tag Editor -->
          </div>
        </div>
      </div>
    </div>

    <!--leads Answered-->
    <div
      class="re-agent-tasks-cont"
      *ngIf="tagAutomationForm?.value?.isTagAutomation"
    >
      <div class="re-agent-tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadAnswered')">
          <span class="re-agent-name-txt">
            Leads created from incoming ANSWERED calls
          </span>
          <span
            class="re-agent-arrows"
            *ngIf="!isCollapseVisible('leadAnswered')"
          >
            <i
              class="fa fa-chevron-circle-up re-agent-teals"
              aria-hidden="true"
            ></i>
          </span>
          <span
            class="re-agent-arrows"
            *ngIf="isCollapseVisible('leadAnswered')"
          >
            <i
              class="fa fa-chevron-circle-down re-agent-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div
        class="row pb-3"
        [class.collapse]="isCollapseVisible('leadAnswered')"
      >
        <div class="col-12 col-md-12">
          <div class="re-agent-radiosec">
            <p class="re-agent-radotext">Would you like to enable this</p>
            <label class="re-agent-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isTagAnsCall"
                (change)="resetChipIds()"
              />
              <span class="re-agent-slider"></span>
              <span value="false" class="re-agent-text1">Off</span>
              <span value="true" class="re-agent-text2">On</span>
            </label>
          </div>
          <div *ngIf="tagAutomationForm?.value?.isTagAnsCall">
            <label class="labels">
              Auto assign Tags to leads created from incoming answered calls
            </label>
            <!-- Tag Editor -->
            <mat-form-field class="re-chip-list">
              <mat-chip-grid #chipGrid2 aria-label="Tag selection">
                <mat-chip-row
                  *ngFor="
                    let tag of tagsIncomingAnswerCall;
                    let tagIndex = index
                  "
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tagIndex, 2)"
                >
                  {{ tag.label }}
                  <mat-icon matChipRemove *ngIf="removable"
                    ><i class="fa fa-times" aria-hidden="true"></i
                  ></mat-icon>
                </mat-chip-row>

                <!-- CAMEL CASE ISSUE WITH VARIABLE -->
                <input
                  placeholder="Type tag name and press enter or comma to create a new tag"
                  #tagInputIncomingAnswerCall
                  [formControl]="tagCtrlIncomingAnswerCall"
                  [matChipInputFor]="chipGrid2"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 2)"
                  (input)="onSearchChange($any($event.target).value)"
                />
              </mat-chip-grid>
            </mat-form-field>
            <label class="mt-1 re-agent-suggest-text">
              Press Enter or comma to create a new tag
            </label>
            <!-- End Tag Editor -->
          </div>
        </div>
      </div>
    </div>

    <!--leads Un-Answered-->
    <div
      class="re-agent-tasks-cont"
      *ngIf="tagAutomationForm?.value?.isTagAutomation"
    >
      <div class="re-agent-tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadUnAnswered')">
          <span class="re-agent-name-txt">
            Leads created from incoming UN-ANSWERED calls
          </span>
          <span
            class="re-agent-arrows"
            *ngIf="!isCollapseVisible('leadUnAnswered')"
          >
            <i
              class="fa fa-chevron-circle-up re-agent-teals"
              aria-hidden="true"
            ></i>
          </span>
          <span
            class="re-agent-arrows"
            *ngIf="isCollapseVisible('leadUnAnswered')"
          >
            <i
              class="fa fa-chevron-circle-down re-agent-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div
        class="row pb-3"
        [class.collapse]="isCollapseVisible('leadUnAnswered')"
      >
        <div class="col-12 col-md-12">
          <div class="re-agent-radiosec">
            <p class="re-agent-radotext">Would you like to enable this</p>
            <label class="re-agent-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isTagUnansCall"
                (change)="resetChipIds()"
              />
              <span class="re-agent-slider"></span>
              <span value="false" class="re-agent-text1">Off</span>
              <span value="true" class="re-agent-text2">On</span>
            </label>
          </div>
          <div *ngIf="tagAutomationForm?.value?.isTagUnansCall">
            <label class="labels">
              Auto assign Tags to leads created from incoming un-answered calls
            </label>
            <!-- Tag Editor -->
            <mat-form-field class="re-chip-list">
              <mat-chip-grid #chipGrid3 aria-label="Tag selection">
                <mat-chip-row
                  *ngFor="
                    let tag of tagsIncomingUnAnswerCall;
                    let tagIndex = index
                  "
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tagIndex, 3)"
                >
                  {{ tag.label }}
                  <mat-icon matChipRemove *ngIf="removable"
                    ><i class="fa fa-times" aria-hidden="true"></i
                  ></mat-icon>
                </mat-chip-row>
                <input
                  placeholder="Type tag name and press enter or comma to create a new tag"
                  #tagInputIncomingUnAnswerCall
                  [formControl]="tagCtrlIncomingUnAnswerCall"
                  [matChipInputFor]="chipGrid3"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 3)"
                  (input)="onSearchChange($any($event.target).value)"
                />
              </mat-chip-grid>
            </mat-form-field>
            <label class="mt-1 re-agent-suggest-text">
              Press Enter or comma to create a new tag
            </label>
            <!-- End Tag Editor -->
          </div>
        </div>
      </div>
    </div>

    <!--leads integration-->
    <div
      class="re-agent-tasks-cont"
      *ngIf="tagAutomationForm?.value?.isTagAutomation"
    >
      <div class="re-agent-tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadIntegration')">
          <span class="re-agent-name-txt">
            Leads created from integration (Zapier or Carrot)
          </span>
          <span
            class="re-agent-arrows"
            *ngIf="!isCollapseVisible('leadIntegration')"
          >
            <i
              class="fa fa-chevron-circle-up re-agent-teals"
              aria-hidden="true"
            ></i>
          </span>
          <span
            class="re-agent-arrows"
            *ngIf="isCollapseVisible('leadIntegration')"
          >
            <i
              class="fa fa-chevron-circle-down re-agent-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div
        class="row pb-3"
        [class.collapse]="isCollapseVisible('leadIntegration')"
      >
        <div class="col-12 col-md-12">
          <div class="re-agent-radiosec">
            <p class="re-agent-radotext">Would you like to enable this</p>
            <label class="re-agent-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isTagZap"
                (change)="resetChipIds()"
              />
              <span class="re-agent-slider"></span>
              <span value="false" class="re-agent-text1">Off</span>
              <span value="true" class="re-agent-text2">On</span>
            </label>
          </div>
          <div *ngIf="tagAutomationForm?.value?.isTagZap">
            <label class="labels">
              Auto assign Tags to leads created from integration
            </label>
            <!-- Tag Editor -->
            <mat-form-field class="re-chip-list">
              <mat-chip-grid #chipGrid4 aria-label="Tag selection">
                <mat-chip-row
                  *ngFor="let tag of tagsZapierOrCarrot; let tagIndex = index"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tagIndex, 4)"
                >
                  {{ tag.label }}
                  <mat-icon matChipRemove *ngIf="removable"
                    ><i class="fa fa-times" aria-hidden="true"></i
                  ></mat-icon>
                </mat-chip-row>

                <input
                  placeholder="Type tag name and press enter or comma to create a new tag"
                  #tagInputZapierOrCarrot
                  [formControl]="tagCtrlZapierOrCarrot"
                  [matChipInputFor]="chipGrid4"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 4)"
                  (input)="onSearchChange($any($event.target).value)"
                />
              </mat-chip-grid>
            </mat-form-field>
            <label class="mt-1 re-agent-suggest-text">
              Press Enter or comma to create a new tag
            </label>
            <!-- End Tag Editor -->
          </div>
        </div>
      </div>
    </div>

    <!--leads web form-->
    <div
      class="re-agent-tasks-cont"
      *ngIf="tagAutomationForm?.value?.isTagAutomation"
    >
      <div class="re-agent-tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadWebForm')">
          <span class="re-agent-name-txt">Leads created from Web Forms</span>
          <span
            class="re-agent-arrows"
            *ngIf="!isCollapseVisible('leadWebForm')"
          >
            <i
              class="fa fa-chevron-circle-up re-agent-teals"
              aria-hidden="true"
            ></i>
          </span>
          <span
            class="re-agent-arrows"
            *ngIf="isCollapseVisible('leadWebForm')"
          >
            <i
              class="fa fa-chevron-circle-down re-agent-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div class="row pb-3" [class.collapse]="isCollapseVisible('leadWebForm')">
        <div class="col-12 col-md-12">
          <div class="re-agent-radiosec">
            <p class="re-agent-radotext">Would you like to enable this</p>
            <label class="re-agent-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isTagWebForm"
                (change)="resetChipIds()"
              />
              <span class="re-agent-slider"></span>
              <span value="false" class="re-agent-text1">Off</span>
              <span value="true" class="re-agent-text2">On</span>
            </label>
          </div>
          <div *ngIf="tagAutomationForm?.value?.isTagWebForm">
            <label class="labels">
              Auto assign Tags to leads created from Web Forms
            </label>
            <!-- Tag Editor -->
            <mat-form-field class="re-chip-list">
              <mat-chip-grid #chipGrid5 aria-label="Tag selection">
                <mat-chip-row
                  *ngFor="let tag of tagsWebForms; let tagIndex = index"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tagIndex, 5)"
                >
                  {{ tag.label }}
                  <mat-icon matChipRemove *ngIf="removable"
                    ><i class="fa fa-times" aria-hidden="true"></i
                  ></mat-icon>
                </mat-chip-row>

                <input
                  placeholder="Type tag name and press enter or comma to create a new tag"
                  #tagInputWebForms
                  [formControl]="tagCtrlWebForms"
                  [matChipInputFor]="chipGrid5"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 5)"
                  (input)="onSearchChange($any($event.target).value)"
                />
              </mat-chip-grid>
            </mat-form-field>
            <label class="mt-1 re-agent-suggest-text">
              Press Enter or comma to create a new tag
            </label>
            <!-- End Tag Editor -->
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div
    class="re-agent-unauthorized"
    *ngIf="isAllowManageNumberFeature == false"
  >
    <img
      class="img-fluid"
      src="assets/images/unauthrozied.png"
      alt="unauthrozied"
    />
    <h4>Upgrade Your Account</h4>
    <div class="re-agent-text">
      Automations are only available on <u>Pro</u> and <u>Enterprise</u> plans.
      <u>Automate your leads follow ups</u> by upgrading your plan.
    </div>
  </div>
</form>
