import { Injectable } from "@angular/core";
import { Observable, Subject, catchError, throwError } from "rxjs";
import { BaseUrl } from "../../utils/base-url-constants";
import { environment } from "src/environments/environment";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class LeadsService {
  public baseUrl = environment.baseUrl;
  public token = localStorage.getItem("token");

  constructor(
    private _commonFunction: CommonFunctionsService,
    private http: HttpClient
  ) {}

  getLeads = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // getAllLeads = (data: any): Observable<any> => {
  //   const endpoint = this.baseUrl + BaseUrl.leadUrl + "allLeadsList";
  //   return this._commonFunction.globalPostService(endpoint, data);
  // };

  // getAllTransactionLeads = (data: any): Observable<any> => {
  //   const endpoint = this.baseUrl + BaseUrl.leadUrl + "allTransactionLeadsList";
  //   return this._commonFunction.globalPostService(endpoint, data);
  // };

  checkLead = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "checkLead";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  checkLeadType = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "checkLeadType";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  checkPropertyAddress = (address: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "checkPropertyAddress";
    return this._commonFunction.globalPostService(endpoint, address);
  };

  getPropertyFromMelissa = (address): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.melissaUrl + "property";
    if (address) {
      endpoint += `?address=${address}`;
    }
    return this._commonFunction.globalGetService(endpoint);
  };

  addLeads = (fieldData: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "save";
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  leadSourceList = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadSourceUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  relationshipList = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.relationshipUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  leadInfo = (lead: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "detail";
    return this._commonFunction.globalPostService(endpoint, lead);
  };

  getAdjacentLead = (lead: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "previous-next";
    return this._commonFunction.globalPostService(endpoint, lead);
  };

  editLead = (data, contactId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "edit";
    if (contactId) {
      endpoint += `/${contactId}`;
    }
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteLead = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "deleteLead";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getQuestions = (lead: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "getQuestions";
    return this._commonFunction.globalPostService(endpoint, lead);
  };

  checkPropertyAddressWithUnitNo = (address): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.leadUrl + "checkPropertyAddressWithUnitNo";
    return this._commonFunction.globalPostService(endpoint, address);
  };

  changeLeadStatus = (moduleId, data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "statusUpdate";
    if (moduleId) {
      endpoint += `/${moduleId}`;
    }
    return this._commonFunction.globalPutService(endpoint, data);
  };

  updateOpenLead = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "updateOpenLead";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  searchleads = (item): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "searchLeads";
    return this._commonFunction.globalPostService(endpoint, item);
  };

  // updateContact = (data, contactId): Observable<any> => {
  //   let endpoint = this.baseUrl + BaseUrl.leadUrl + "edit";
  //   if (contactId) {
  //     endpoint += `/${contactId}`;
  //   }
  //   return this._commonFunction.globalPostService(endpoint, data);
  // };

  editLeadContact = (data, contactId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "editLeadContact";
    if (contactId) {
      endpoint += `/${contactId}`;
    }
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteLeadContact = (data, contactId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "deleteLeadContact";
    if (contactId) {
      endpoint += `/${contactId}`;
    }
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTeams = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "teamDetailsData";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getRelatedLeads = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "related";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCount = (lead: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "getCount";
    return this._commonFunction.globalPostService(endpoint, lead);
  };

  changeTransactionLeadStatus = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.leadUrl + "transaction-lead/status-update";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCampaignList = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.numberUrl + "campaignlist";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  claimLead = (fieldData: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "lead-claim";
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  markHotLead = (data, leadId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "hotLead";

    if (leadId) {
      endpoint += `/${leadId}`;
    }

    return this._commonFunction.globalPutService(endpoint, data);
  };

  callForward = (data, leadId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "callForward";

    if (leadId) {
      endpoint += `/${leadId}`;
    }
    return this._commonFunction.globalPutService(endpoint, data);
  };

  getCallForwardNumber = (data, leadId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "callForwardNumber";

    if (leadId) {
      endpoint += `/${leadId}`;
    }
    return this._commonFunction.globalPutService(endpoint, data);
  };

  generateTokenForZap = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "generateTokenForZap";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTokenForZap = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "getTokenForZap";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  campaignTeam = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "leadStageCampain";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // addEditAgent = (data): Observable<any> => {
  //   let endpoint = this.baseUrl + BaseUrl.agentUrl + "save";
  //   return this._commonFunction.globalPostService(endpoint, data);
  // };

  addOffer = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.offerUrl + "save";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addShowingData = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.showingUrl + "save";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // getAgent = (data): Observable<any> => {
  //   let endpoint = this.baseUrl + BaseUrl.agentUrl + "list";
  //   return this._commonFunction.globalPostService(endpoint, data);
  // };

  getAllLeadAddress = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "getAllLeadAddress";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addOpenHouse = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.openHouseUrl + "save";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editOpenHouse = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.openHouseUrl + "update";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getOpenHouse = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.openHouseUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getOffers = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.offerUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteOffer = (fileId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.offerUrl + "delete";

    if (fileId) {
      endpoint += `/${fileId}`;
    }

    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteOpenHouse = (fileId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.openHouseUrl + "delete";

    if (fileId) {
      endpoint += `/${fileId}`;
    }

    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  editOffer = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.offerUrl + "update";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getShowingData = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.showingUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editShowingData = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.showingUrl + "edit";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteShowing = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.showingUrl + "delete";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateTeam = (data, teamId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "teamUpdate";

    if (teamId) {
      endpoint += `/${teamId}`;
    }
    return this._commonFunction.globalPutService(endpoint, data);
  };

  addRole = (data, leadId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "addRole";

    if (leadId) {
      endpoint += `/${leadId}`;
    }
    return this._commonFunction.globalPutService(endpoint, data);
  };

  getActiveTeam = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.buyNumber + "active-team";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getLeadAssignmentRoleList = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "getLeadAssignmentRoleList";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getMainStatusData = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "leadStatusUpdateData";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAllCampaign = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.numberUrl + "getCampaignListAll";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSearchLeads = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "listSearchLeads";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  primaryAssigneeInfo = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + "primaryAssignee";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  makePrimary = (data, leadId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "makePrimary";

    if (leadId) {
      endpoint += `/${leadId}`;
    }
    return this._commonFunction.globalPutService(endpoint, data);
  };

  changeQualifyStatus = (data, leadId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + "changeQualifyStatus";

    if (leadId) {
      endpoint += `/${leadId}`;
    }
    return this._commonFunction.globalPutService(endpoint, data);
  };
}
