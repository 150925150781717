<div class="re-agent-message-box">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      Speed to Lead
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        (click)="onClose()"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="re-agent-typography">
  <div class="re-agent-box re-agent-sms-box">
    <div class="row">
      <div class="col-12 col-sm-12">
        <p class="re-agent-radotext">NEW LEAD TRIGGERS</p>
        <p class="re-agent-shedule-p">
          Select the type of leads that should trigger the Speed to Lead
        </p>
      </div>
    </div>
    <div class="re-agent-switch">
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="form-group">
            <div class="re-agent-radiosec">
              <p class="re-agent-radoSecondtext">
                Leads created from integration (Zapier, Carrot, etc.)
              </p>
              <label class="re-agent-switch">
                <input
                  type="checkbox"
                  id="integration"
                  name="integration"
                  (click)="onToggle('integration')"
                  [checked]="instantLeadAssignment?.trigger['integration']"
                  [(ngModel)]="
                    instantLeadAssignment?.trigger['integration']
                  "
                />
                <span class="re-agent-slider"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="instantLeadAssignment?.trigger['integration']">
      <div class="row mt-4 mb-3">
        <div class="col-12 col-sm-12">
          <p class="re-agent-radotext">ROLES TO CALL</p>
          <p class="re-agent-shedule-p">
            Please select roles you would like to call as new lead added
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <label class="re-agent-cus-label">Roles in Campaign</label>
        </div>
        <div class="col-12 col-md-8">
          <label class="re-agent-cus-label">Call Preference</label>
        </div>
      </div>
      <div
        class="row mb-2"
        *ngFor="let role of userAssignmentArray; let i = index"
      >
        <div class="col-12 col-md-4">
          <select class="form-control">
            <option selected value="{{ role }}" disabled>
              {{ getRoleName(role) }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-8">
          <div class="re-radiolist">
            <label
              class="re-radiobtn form-check-inline"
              [for]="'lead-role-contact' + i"
            >
              <input
                class="form-check-input re-checkbox-size"
                type="radio"
                value="1"
                [id]="'lead-role-contact' + i"
                [name]="'lead-role-contact' + i"
                [(ngModel)]="instantLeadAssignment?.roles[i][role]"
                [checked]="instantLeadAssignment?.roles[i][role] === '1'"
                (change)="changeRoles(instantLeadAssignment, role, '1', i)"
              />
              <span class="re-checkmark"></span>Profile Number
            </label>
            <label
              class="re-radiobtn form-check-inline"
              [for]="'lead-role-donot' + i"
            >
              <input
                class="form-check-input re-checkbox-size"
                type="radio"
                value="2"
                [id]="'lead-role-donot' + i"
                [name]="'lead-role-donot' + i"
                [(ngModel)]="instantLeadAssignment?.roles[i][role]"
                [checked]="
                  instantLeadAssignment?.roles[i][role] === '2' ||
                  instantLeadAssignment?.roles[i][role] === undefined
                "
                (change)="changeRoles(instantLeadAssignment, role, '2', i)"
              />
              <span class="re-checkmark"></span>Do Not Call
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-stroked-button mat-dialog-close (click)="onClose()">
    Cancel
  </button>
  <button mat-flat-button color="primary" type="button" (click)="onDone()">
    Update Preference
  </button>
</mat-dialog-actions>
