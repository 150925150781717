import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment-timezone";
import * as _ from "lodash";

// SERVICES
//import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';

import { UserService } from "src/app/providers/user/user.service";
import { TaskService } from "src/app/providers/task/task.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";

// UTILS
import { MessageConstant } from "src/app/utils/message-constant";
import { ErrorModel } from "src/app/utils/models/error";
import { ResponseModel } from "src/app/utils/models/response";
import { timezoneConstant } from "src/app/utils/timezone-list-constant";

@Component({
  selector: "app-add-edit-task-template",
  templateUrl: "./add-edit-task-template.component.html",
  styleUrls: ["./add-edit-task-template.component.scss"],
})
export class AddEditTaskTemplateComponent {
  @ViewChild("errorReminder") errorReminder: ElementRef;
  addEditTaskTemplateForm: FormGroup;
  button;
  reminders;
  minutesList;
  type = "Add";
  submitted: boolean = false;
  selectUser: boolean = false;
  isGeneralTask: boolean = false;
  instantNotification: boolean = false;
  validationShow: boolean = false;
  public messageConstant = MessageConstant;
  noDataAvailable: string = "No Data Available";
  customTask: boolean = false;

  taskType: any[] = [];
  assignUserList: any[] = [];
  assignRoleList: any[] = [];
  timezoneList: any = [];
  taskTimeTypeList: any[] = [];
  reminderErrors: any[] = [];
  validationMess: string = "";
  priorityClass: number = 1;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddEditTaskTemplateComponent>,
    //private _masterTaskService: MasterTaskService,
    private _userService: UserService,
    private _taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _utilities: CommonFunctionsService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.timezoneList = timezoneConstant.timezoneArray;
    this.taskTimeTypeList = this._utilities.taskTimeTypeList();
    this.taskType = this._utilities.taskType();
    this.minutesList = this._utilities.minutesList(59, 1);

    this.type = this.data.category;
    if (this.type == "Update") {
      this.button = "Update";
    } else {
      this.button = "Add";
    }

    this.addEditTaskTemplateForm = this.formBuilder.group({
      templateName: ["", Validators.required],
      title: ["", Validators.required],
      taskTypeOption: [8, Validators.required],
      taskDetail: [""],
      assignRoleId: [""],
      assignUserId: [""],
      taskPriority: [1],
      taskDueType: [0],
      dueInIso: [new Date()],
      time: [new Date()],
      taskDueTime: [""],
      taskDueTimeType: [""],
      isInstantNotification: ["0", Validators.required],
      smsAndEmail: ["email"],
      reminders: this.formBuilder.array([]),
    });

    if (this.type == "Update") {
      let smsEmail = "";
      let {
        isInstantNotification,
        isInstantNotificationSms,
        isInstantNotificationEmail,
      } = this.data.task;

      let dateInso = new Date(
        moment(this.data.task.dueInIso)
          .utcOffset("0")
          .format("YYYY-MM-DD HH:mm")
      );
      let dueIso = this._utilities.dueDateFormat(dateInso).parseDate;

      if (this.data.task.assignUserId && !this.data.task.assignRoleId) {
        this.selectUser = true;
      } else {
        this.selectUser = false;
      }

      if (this.data.task.taskPriority) {
        this.priorityClass = this.data.task.taskPriority
          ? this.data.task.taskPriority
          : 5;
      }

      if (isInstantNotificationEmail && isInstantNotificationSms) {
        smsEmail = "both";
      } else if (isInstantNotificationSms) {
        smsEmail = "sms";
      } else if (isInstantNotificationEmail) {
        smsEmail = "email";
      }
      if (this.data.task.taskDueTime && this.data.task.taskDueTimeType) {
        this.customTask = true;
      }

      this.instantNotification = isInstantNotification;
      isInstantNotification = isInstantNotification ? "1" : "0";
      this.addEditTaskTemplateForm.patchValue({
        templateName: this.data.task.templateName,
        title: this.data.task.title,
        taskTypeOption: this.data.task.taskTypeOption
          ? this.data.task.taskTypeOption
          : 8,
        taskDetail: this.data.task.taskDetail,
        assignRoleId: this.data.task.assignRoleId
          ? this.data.task.assignRoleId
          : "",
        assignUserId: this.data.task.assignUserId
          ? this.data.task.assignUserId
          : "",
        taskPriority: this.data.task.taskPriority
          ? this.data.task.taskPriority
          : 5,
        dueInIso: dueIso,
        time: moment(dueIso, "h:mm:ss A").set({ second: 0 }).format("LT"),
        isInstantNotification,
        smsAndEmail: smsEmail,
        taskDueType: this.data.task.taskDueType
          ? this.data.task.taskDueType
          : 0,
        taskDueTime: this.data.task.taskDueTime,
        taskDueTimeType: this.data.task.taskDueTimeType,
      });

      this.addEditTaskTemplateForm.setControl(
        "reminders",
        this.setExistingReminder(this.data.task.reminders)
      );
    }

    this.getUsers();
    this.getUserRoleList();
  }

  getUserRoleList() {
    this._userService.getUserRoleList({ page: 1, limit: 100 }).subscribe(
      (response: ResponseModel) => {
        let hash = Object.create(null);
        let roleData = _.flatten(
          _.map(response.data.items, (item) => item.roleData)
        );
        let result = roleData.reduce((r, o) => {
          if (!hash[o?._id]) {
            hash[o?._id] = {
              _id: o?._id,
              name: o?.name,
              user: o?.name,
              roles: [],
            };
            r.push(hash[o?._id]);
          }
          hash[o?._id].roles.push({
            _id: o?._id,
            name: o?.name,
          });
          return r;
        }, []);
        this.assignRoleList = result;
      },
      (err) => {
        this._toastrService.error(this.messageConstant?.unknownError, "");
      }
    );
  }

  getUsers() {
    this._userService.getUsers({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.assignUserList = [];
          for (let i = 0; i < response?.data.length; i++) {
            const user = response?.data[i];
            const fullName = user.firstName + " " + user.lastName;
            const matches = fullName.match(/\b(\w)/g);
            const acronym = matches?.join("");
            this.assignUserList.push({
              label: user.firstName + " " + user.lastName,
              value: user._id,
              profileImage: user?.profileImage ? user?.profileImage : "",
              name: acronym || "N/A",
            });
          }
        } else {
          this._toastrService.error(response?.message, "");
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }
  onInstantNotification() {
    this.instantNotification = !this.instantNotification;
    this.addEditTaskTemplateForm.patchValue({
      isInstantNotification: this.instantNotification,
    });
  }

  assignedTask(type) {
    if (type == "Assigned User") {
      this.selectUser = true;
    } else {
      this.selectUser = false;
    }
  }

  taskDueDetails(type) {
    this.customTask = false;
    let currentTime = new Date();
    let convertTimeNew;
    let convertTime;
    let dueIso;
    switch (type) {
      case "One Hour":
        convertTimeNew = new Date(
          new Date(currentTime).setHours(currentTime.getHours() + 1)
        );
        convertTime = moment(convertTimeNew)
          .tz(localStorage.getItem("timezoneOffset"))
          .format("YYYY-MM-DD HH:mm:ss");
        dueIso = this._utilities.dueDateFormat(new Date(convertTime)).parseDate;
        this.addEditTaskTemplateForm.patchValue({
          dueInIso: dueIso,
          time: dueIso,
          taskDueType: 0,
        });
        break;
      case "One Day":
        convertTimeNew = new Date(
          new Date(currentTime).setDate(currentTime.getDate() + 1)
        );
        convertTime = moment(convertTimeNew)
          .tz(localStorage.getItem("timezoneOffset"))
          .format("YYYY-MM-DD HH:mm:ss");
        dueIso = this._utilities.dueDateFormat(new Date(convertTime)).parseDate;
        this.addEditTaskTemplateForm.patchValue({
          dueInIso: dueIso,
          time: dueIso,
          taskDueType: 1,
        });
        break;
      case "One Week":
        convertTimeNew = new Date(
          new Date(currentTime).setDate(currentTime.getDate() + 7)
        );
        convertTime = moment(convertTimeNew)
          .tz(localStorage.getItem("timezoneOffset"))
          .format("YYYY-MM-DD HH:mm:ss");
        dueIso = this._utilities.dueDateFormat(new Date(convertTime)).parseDate;
        this.addEditTaskTemplateForm.patchValue({
          dueInIso: dueIso,
          time: dueIso,
          taskDueType: 2,
        });
        break;
      case "Custom":
        this.customTask = true;
        convertTime = moment(currentTime)
          .tz(localStorage.getItem("timezoneOffset"))
          .format("YYYY-MM-DD HH:mm:ss");
        dueIso = this._utilities.dueDateFormat(new Date(convertTime)).parseDate;
        this.addEditTaskTemplateForm.patchValue({
          dueInIso: dueIso,
          time: "12:00 PM",
          taskDueType: 3,
        });
        break;
      default:
        break;
    }
  }

  addReminder(): void {
    this.reminders = this.addEditTaskTemplateForm.get("reminders") as FormArray;
    if (this.reminders.length >= 5) {
      this._toastrService.error(this.messageConstant?.reminder5LmtErr, "");
    } else {
      let add = true;
      if (this.reminders.length > 0) {
        for (let i = 0; i < this.reminders.length; i++) {
          let element = this.reminders.at(i).value;
          if (!element.via || !element.before || !element.time) {
            add = false;
          }
        }
      }
      if (add) {
        this.reminders.push(this.createReminder());
      } else {
        this._toastrService.error(this.messageConstant?.reminderFillLmtErr, "");
      }
    }
  }

  removeReminder(i: number) {
    (<FormArray>this.addEditTaskTemplateForm.get("reminders")).removeAt(i);
  }

  createReminder(): FormGroup {
    return this.formBuilder.group({
      via: ["", Validators.required],
      before: ["", Validators.required],
      time: ["", Validators.required],
    });
  }

  setExistingReminder(reminders): FormArray {
    const formArray = new FormArray([]);
    reminders.forEach((element) => {
      formArray.push(
        this.formBuilder.group({
          via: element.via,
          before: element.before.toString(),
          time: element.time,
        })
      );
    });
    return formArray;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addEditTaskTemplateForm.controls[controlName].hasError(
      errorName
    );
  };

  onChangeRole(roleId, update?) {
    let assignedUserId = this.assignRoleList.find((item) => item._id == roleId);

    if (assignedUserId && !this.selectUser) {
      this.addEditTaskTemplateForm.patchValue({
        assignRoleId: assignedUserId.assignUserId,
      });

      return;
    }
  }

  onChangePriority(priorityVal) {
    this.priorityClass = priorityVal ? priorityVal : 5;
  }

  onTaskTemplateSubmit() {}

  save() {
    this.submitted = true;
    if (this.addEditTaskTemplateForm.invalid) {
      return;
    }
    let formObject = this.addEditTaskTemplateForm.value;
    let { isInstantNotification, reminders, dueInIso, time } = formObject;

    let obj = {
      ...this.addEditTaskTemplateForm.value,
    };

    obj["isInstantNotification"] = isInstantNotification == 0 ? false : true;

    obj["dueInIso"] =
      moment(dueInIso).format("YYYY-MM-DD") +
      "T" +
      moment(time, "h:mm:ss A").set({ second: 0 }).format("HH:mm:ss");

    if (isInstantNotification) {
      if (this.addEditTaskTemplateForm.value.smsAndEmail == "email") {
        obj["isInstantNotificationEmail"] = true;
      } else if (this.addEditTaskTemplateForm.value.smsAndEmail == "sms") {
        obj["isInstantNotificationSms"] = true;
      } else if (this.addEditTaskTemplateForm.value.smsAndEmail == "both") {
        obj["isInstantNotificationEmail"] = true;
        obj["isInstantNotificationSms"] = true;
      }
    }

    if (
      !this.selectUser &&
      !this.addEditTaskTemplateForm?.value?.assignRoleId
    ) {
      this._toastrService.error(this.messageConstant?.roleReAssignSelectErr);
      return;
    }
    if (this.selectUser && !this.addEditTaskTemplateForm?.value?.assignUserId) {
      this._toastrService.error(this.messageConstant?.roleReAssignSelectErr);
      return;
    }

    if (
      this.customTask &&
      this.addEditTaskTemplateForm.value.taskDueType == 3
    ) {
      if (
        !this.addEditTaskTemplateForm.value.taskDueTime ||
        !this.addEditTaskTemplateForm.value.taskDueTimeType
      ) {
        this._toastrService.error(this.messageConstant?.dueSelectErr, "");
        return;
      }
      obj["taskDueTime"] = this.addEditTaskTemplateForm.value.taskDueTime;
      obj["taskDueTimeType"] =
        this.addEditTaskTemplateForm.value.taskDueTimeType;
    }

    delete obj["smsAndEmail"];
    delete obj["time"];
    obj["templateName"] = this.addEditTaskTemplateForm.value.templateName
      ? this.addEditTaskTemplateForm.value.templateName.trim()
      : "";
    obj["title"] = this.addEditTaskTemplateForm.value.title
      ? this.addEditTaskTemplateForm.value.title.trim()
      : "";
    obj["taskDetail"] = this.addEditTaskTemplateForm.value.taskDetail
      ? this.addEditTaskTemplateForm.value.taskDetail.trim()
      : "";

    let timeStamp =
      this.addEditTaskTemplateForm.value.dueInIso +
      moment(this.addEditTaskTemplateForm.value.dueInIso).format("Z");
    delete obj["dueInIso"];
    if (this.addEditTaskTemplateForm.value.reminders.length) {
      let remindersDates = this.addEditTaskTemplateForm.value.reminders.map(
        (item) => {
          item.remInIsoTimeStamp = moment(timeStamp)
            .subtract(item.before, item.time)
            .valueOf();
          return item;
        }
      );
      obj["reminders"] = remindersDates;
    }

    if (this.type == "Update") {
      obj["taskTemplateId"] = this.data.id;
    }

    this._loaderService.start();
    if (this.type == "Add") {
      this._taskService.createTaskTemplate(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            this._toastrService.success(
              this.messageConstant.taskTemplateAddedSuccess,
              ""
            );
            this.dialogRef.close(true);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
    } else {
      this._taskService.updateTaskTemplate(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            this._toastrService.success(
              this.messageConstant.taskTemplateUpdatedSuccess,
              ""
            );
            this.dialogRef.close(true);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
    }
  }

  close() {
    this.dialogRef.close();
  }
}
