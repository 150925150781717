import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { DndDropEvent, DropEffect } from "ngx-drag-drop";
import { MessageConstant } from "src/app/utils/message-constant";
import { EditManageListDialogComponent } from "../edit-manage-list-dialog/edit-manage-list-dialog.component";
import { QuickFilterService } from "src/app/providers/quickFilter/quick-filter.service";
import { ResponseModel } from "src/app/utils/models/response";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "../../shared.service";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { StatusConstant } from "src/app/utils/status-constant";
import { ErrorModel } from "src/app/utils/models/error";

@Component({
  selector: "app-manage-list-dialog",
  templateUrl: "./manage-list-dialog.component.html",
  styleUrls: ["./manage-list-dialog.component.scss"],
})
export class ManageListDialogComponent implements OnInit {
  messageConstant = MessageConstant;
  dialogRef: any;
  promises: any[] = [];
  type: string = "Manage List";
  closeLeadIcon: string = "/assets/images/close-lead.png";
  isDragging: boolean = false;
  effectAllowedVal: any = "all";
  draggable = {
    data: "myDragData",
    effectAllowed: this.effectAllowedVal,
    disable: false,
    handle: true,
  };
  draggedItem: object = {
    data: {},
    to: "",
  };
  _currentDraggableEvent: DragEvent;
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private manageListDialog: MatDialogRef<ManageListDialogComponent>,
    private _loaderService: NgxUiLoaderService,
    public _utilities: CommonFunctionsService,
    private _toastrService: ToastrService,
    private _quickFilterService: QuickFilterService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  /**
   * FUNCTION: DRAG AND DROP START
   * @param {DragEvent} event
   */
  onDragStart(event: DragEvent) {
    this._currentDraggableEvent = event;
  }

  /**
   * FUNCTION: DRAG LIST
   * @param {Array} item
   * @param {Array} list
   * @param {String} effect
   */
  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
    }
  }

  /**
   * FUNCTION: DROP SECTION AND QUESTION
   * @param {DndDropEvent} event
   * @param {Array} data
   * @param {String} type
   * @returns DROP SECTION AND QUESTION
   */
  onDrop = (event: DndDropEvent, data, type): void => {
    this._loaderService.start();
    const value = event.data._id;
    if (type == "section") {
      const fromIndex = this.data.details.findIndex((x) => x._id === value);
      const toIndex = this.data.details.findIndex((x) => x._id === data?._id);
      this.data.details = this._utilities.reOrder(
        this.data.details,
        fromIndex,
        toIndex
      );

      this.data.details.filter((x, index) => {
        let obj = {
          quickFilterId: x?._id,
          title: x?.title,
          description: x?.description,
          filterData: x?.filterData,
          position: index,
        };

        delete obj["createdAt"];
        delete obj["createdBy"];
        delete obj["updatedBy"];
        delete obj["updatedAt"];
        delete obj["isDeleted"];
        delete obj["isEnabled"];
        delete obj["isUpdated"];
        delete obj["mainUserId"];
        delete obj["moduleId"];
        delete obj["__v"];

        this.saveOrder(obj);
      });
    }
  };

  saveOrder(obj) {
    return new Promise((resolve, reject) => {
      this._quickFilterService.update(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            this._sharedService.refreshMainStatus.next(true);
            this._toastrService.success(
              this.messageConstant.quickFilterListSuccessfully
            );
            resolve(true);
          }
        },
        (err) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }

          reject(false);
        }
      );
    });
  }

  edit(userVal) {
    this.dialogRef = this.dialog.open(EditManageListDialogComponent, {
      width: "520px",
      data: {
        details: userVal,
        type: "Edit",
      },
    });
    this.manageListDialog.close("");
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this._sharedService.refreshMainStatus.next(true);
      }
    });
  }

  addFilter() {
    this.dialogRef = this.dialog.open(EditManageListDialogComponent, {
      width: "520px",
      data: {
        details: this.data?.details,
        type: "Add",
      },
    });
    this.manageListDialog.close("");
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this._sharedService.refreshMainStatus.next(true);
      }
    });
  }

  deleteFilter(id) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "500px",
      data: {
        subModule: "delete",
        subModuleId: id,
        moduleId: StatusConstant?.ModuleId?.LEAD,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteTag(id);
      }
    });
  }

  deleteTag(id) {
    let obj = {
      quickFilterId: id,
    };
    this._quickFilterService.deleteOne(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._toastrService.error(
            MessageConstant.quickFilterDeletedSuccessfully,
            ""
          );
          this.manageListDialog.close("");
          this._sharedService.refreshMainStatus.next(true);
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(MessageConstant.unknownError, "");
        }
      }
    );
  }
}
