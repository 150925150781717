import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseUrl } from "../../utils/base-url-constants";
import { environment } from "src/environments/environment";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { Subject } from "rxjs";
import { Device } from "@twilio/voice-sdk";

@Injectable({
  providedIn: "root",
})
export class TwilioServiceService {
  baseUrl = environment.baseUrl;
  twilioIncomingEvent: any = new Subject();
  twilioIncomingCancelEvent: any = new Subject();
  twilioOutboundEvent: any = new Subject();
  twilioOutboundHangupEvent: any = new Subject();
  twilioCallConnection: any;
  twilioCallIncomingConnection: any;
  twilioDevice: any;

  constructor(private _utilities: CommonFunctionsService) {}

  async connectTwilio() {
    let twilioToken = await this.getTwilioAccessToken();

    try {
      let deviceOptions: any = {
        debug: true,
        enableRingingState: true,
        fakeLocalDTMF: true,
        codecPreferences: ["opus", "pcmu"],
        answerCallOnBridge: true,
        logLevel: 1,
        edge: ["dublin", "singapore"],
      };
      this.twilioDevice = new Device(twilioToken?.toString(), deviceOptions);

      const handleSuccessfulRegistration = () => {
        console.log("The device is ready to receive incoming calls. ==>");
        this._utilities.isTwilioDeviceConnect = true;
      };

      this.twilioDevice.on("registered", handleSuccessfulRegistration);

      this.twilioDevice.register();

      this.twilioDevice.on("ready", (res, err) => {
        console.log("twilio device connected ready ==>");
        this._utilities.isTwilioDeviceConnect = true;
      });

      this.twilioDevice.on("error", (twilioError, call) => {
        console.log("An twilio error has occurred: ==>", twilioError);
      });

      this.twilioDevice.on("incoming", (connection) => {
        console.log("twilio device connected ready incoming ==>");
        this.twilioCallIncomingConnection = connection;
        this.twilioIncomingEvent.next(connection);

        connection.on("accept", (connection) => {
          console.log("Twilio call accept. ===>", connection);
        });

        connection.on("disconnect", (connection) => {
          console.log("Twilio call disconnect. ===>", connection);
          this.twilioIncomingCancelEvent.next("disconnect");
        });

        connection.on("cancel", (connection) => {
          console.log("Twilio call cancel ===>", connection);
          this.twilioIncomingCancelEvent.next("cancel");
        });

        connection.on("reject", (connection) => {
          console.log("Twilio call reject. ===>", connection);
          this.twilioIncomingCancelEvent.next("reject");
        });
      });

      this.twilioDevice.on("offline", (device) => {
        console.log("Twilio call offline. ==>", this.twilioDevice.status());
        this._utilities.isTwilioDeviceConnect = false;
      });

      this.twilioDevice.on("accept", (connection) => {
        console.log("call ==>");
      });

      this.twilioDevice.on("cancel", (connection) => {
        console.log("Twilio call cancel. ==>", this.twilioDevice.status());
        this.twilioIncomingCancelEvent.next(connection);
      });

      this.twilioDevice.on("reject", (connection) => {
        console.log("Twilio call reject. ==>", this.twilioDevice.status());
        this.twilioIncomingCancelEvent.next(connection);
      });

      this.twilioDevice.on("disconnect", (connection) => {
        this.deviceDestroy();
        console.log("Twilio call disconnect. ==>", this.twilioDevice.status());
        this.twilioIncomingCancelEvent.next(connection);
      });

      this.twilioDevice.on("tokenWillExpire", async () => {
        console.log("Twilio Token Will Expire. ==>");
        twilioToken = await this.getTwilioAccessToken();
        this.twilioDevice.updateToken(twilioToken);
      });
    } catch (error) {
      console.log("errorrrr ==>", error);
    }
  }

  async initializeTwilio(payload: any) {
    let {
      from,
      to,
      number,
      userId,
      moduleType,
      eUname,
      buyerId,
      leadId,
      vendorId,
      campaignId,
      propertyId,
      contactId,
    } = payload;

    const extraHeaders = {
      To: to,
      From: from,
      "X-PH-to": to,
      "X-PH-from": from,
      "X-PH-userId": userId,
      // "X-PH-default": this._utilities.unMaskNumber(number),
      "X-PH-default": from,
      // "X-PH-Id": eUname,
      "X-PH-moduleType": moduleType,
    };

    // if (buyerId) {
    //   extraHeaders["X-PH-buyerId"] = buyerId;
    // }

    if (leadId) {
      extraHeaders["X-PH-leadId"] = leadId;
    }

    // if (vendorId) {
    //   extraHeaders["X-PH-vendorId"] = vendorId;
    // }

    // if (campaignId) {
    //   extraHeaders["X-PH-campaignId"] = campaignId;
    // }

    // if (propertyId) {
    //   extraHeaders["X-PH-propertyId"] = propertyId;
    // }
    if (contactId) {
      extraHeaders["X-PH-contactId"] = contactId;
    }

    this.twilioCallConnection = await this.twilioDevice.connect({
      params: extraHeaders,
    });

    this.twilioCallConnection.on("ringing", (connection) => {
      console.log("Twilio call ringing.. ==>", connection);
    });

    this.twilioCallConnection.on("accept", (connection) => {
      console.log("Twilio call accept.. ==>");
      this.twilioOutboundEvent.next(connection);
    });

    this.twilioCallConnection.on("disconnect", (connection) => {
      console.log("Twilio call disconnect.. ==>", connection);
      this.callHangup();
      this.twilioOutboundHangupEvent.next(connection);
    });
  }

  callMuteUnmute(type: boolean) {
    this.twilioCallConnection.mute(type);
  }

  callHangup() {
    this.twilioDevice.disconnectAll();
  }

  getTwilioAccessToken() {
    return new Promise(async (resolve, reject) => {
      this.createToken().subscribe(
        (response: any) => {
          let twilioToken = response?.data;
          resolve(twilioToken);
        },
        (error: any) => {
          resolve("");
          console.log("getTwilioAccessToken errorrrr ==>", error);
          // this._loaderService.stop();
        }
      );
    });
  }
  createToken = (): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.linkTwilio + "twilioVoiceAccessToken";
    return this._utilities.globalPostService(endpoint, {});
  };

  holdCallFn = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + "holdCall";
    try {
      return this._utilities.globalPostService(endpoint, data);
    } catch (error) {
      console.log(" holdCallFn ===>", error);
    }
  };

  unHoldCallFn = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + "unHoldCall";
    return this._utilities.globalPostService(endpoint, data);
  };

  deviceDestroy() {
    this.twilioDevice.destroy();
  }
  sendDigits(digits, connection) {
    this.twilioDevice.sendDigits(digits);
  }
}
