import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "SearchText",
  pure: false,
})
export class SearchTextPipe implements PipeTransform {
  constructor() {}

  transform(value: any, query: string, field: string): any {
    return query
      ? value.reduce((prev: any[], next: any) => {
          const data = next[field]?.toLowerCase();
          const search = query?.toLowerCase();
          if (data?.includes(search)) {
            prev.push(next);
          }
          return prev;
        }, [])
      : value;
  }
}
