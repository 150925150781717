import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Observable, Observer, fromEvent, merge, Subject } from "rxjs";
import { Title } from "@angular/platform-browser";
// SERVICES
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonFunctionsService } from "./utils/common-functions/common-functions.service";
import { TwilioServiceService } from "./utils/twilio-service/twilio-service.service";
import { MatDialogRef } from "@angular/material/dialog";
import { IncomingCallDetailDailogsComponent } from "./shared/dialog/incoming-call-detail-dailogs/incoming-call-detail-dailogs.component";
import { MatDialog } from "@angular/material/dialog";
import { LeadsService } from "./providers/leads/leads.service";

import { ResponseModel } from "src/app/utils/models/response";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "AgentPro CRM Dashboard";
  isNavVisible: boolean = false;
  openModel: boolean = false;
  openModels: any = [];
  constructor(
    private _router: Router,
    private ngxService: NgxUiLoaderService,
    public _utilities: CommonFunctionsService,
    private _titleService: Title,
    private _twilioService: TwilioServiceService,
    private incomingCallDialog: MatDialogRef<IncomingCallDetailDailogsComponent>,
    private _dialog: MatDialog,
    private _leadService: LeadsService
  ) {
    // CHECK INTERNET CONNECTION
    this.checkInternetStatus();

    //Twilio
    const token = localStorage.getItem("token");
    if (token && !this._utilities.isTwilioDeviceConnect) {
      this._twilioService.connectTwilio();
    }
    this._twilioService.twilioIncomingEvent = new Subject();
    this._twilioService.twilioIncomingEvent.subscribe((response) => {
      if (response) {
        this.twilioIncomingCallEvent(response);
      }
    });

    // CHECK ROUTE EVENTS
    this._router.events.subscribe((val) => {
      // this.ngxService.start();
      if (val instanceof NavigationEnd) {
        let currentUrl = window.location.pathname;
        if (val.url != currentUrl) {
          this.ngxService.start();
        }
        this.setTitle(val);
        this.ngxService.stop();
        this.isNavVisible =
          val["urlAfterRedirects"].indexOf("/auth/login") >= 0 ||
          val["urlAfterRedirects"] == "/auth/forgot-password" ||
          val["urlAfterRedirects"].indexOf("/auth/reset-password/") >= 0 ||
          val["urlAfterRedirects"].indexOf("/auth/confirm-email/") >= 0 ||
          val["urlAfterRedirects"].indexOf("/public") >= 0 ||
          val["urlAfterRedirects"].indexOf("/auth/signup") >= 0 ||
          val["urlAfterRedirects"].indexOf(
            "/auth/thank-you-for-registration"
          ) >= 0
            ? false
            : true;
      }
    });
  }

  checkInternetStatus() {
    this.createOnline$().subscribe((isOnline) => {
      if (!isOnline) {
        this.ngxService.startLoader("NO-INTERNET");
      } else {
        this.ngxService.stopLoader("NO-INTERNET");
      }
      this._router.initialNavigation();
    });
  }

  createOnline$() {
    return merge<any>(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  reload() {
    document.location.reload();
  }

  setTitle(val: any) {
    const location = val["urlAfterRedirects"].split("/");
    let url = "";

    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const leadType = urlParams.get("leadType");
      if (leadType) url = `${leadType} Leads`;
      else {
        let pathName = window.location.pathname.split("/");
        if (pathName[2]) {
          url = this._utilities.capitalizeName(pathName[2]);
        } else {
          url = "AgentPro CRM Dashboard";
          this._titleService.setTitle(url);
          return;
        }
      }
    } else {
      const locationFirstIndex = location?.length ? location[1] : "";
      const locationSecondIndex =
        location?.length && location?.length > 2 ? location[2] : "";

      url = locationFirstIndex?.replaceAll("-", " ")?.split("?")[0];

      if (url === "auth") {
        url = `Auth - ${locationSecondIndex?.replaceAll("-", " ")}`;
        this._titleService.setTitle(this._utilities.capitalizeName(url));
        return;
      }

      if (url === "dashboard") {
        if (locationSecondIndex) {
          this._titleService.setTitle(
            this._utilities.capitalizeName(locationSecondIndex)
          );
        } else {
          this._titleService.setTitle("AgentPro CRM Dashboard");
        }
        return;
      }
    }

    let title = url
      ?.replace(/([A-Z]+)/g, " $1")
      ?.replace(/([A-Z][a-z])/g, " $1")
      ?.replace(/[^a-zA-Z0-9]/g, " ");
    if (url == "Createtemplate") {
      title = "eSign Template";
    }
    this._titleService.setTitle(this._utilities.capitalizeName(title));
  }

  async twilioIncomingCallEvent(connection) {
    const myObject = connection.customParameters.get("customData");
    const header = JSON.parse(myObject);
    const parameters = connection.parameters;
    const fromNumber = header.fromNumber.trim();
    const connectionParams = connection;
    if (this.openModel === false) {
      this.openModel = true;
      this._utilities.callType = "incoming";
      this._leadService
        .searchleads({
          searchString: fromNumber.replace("+1", ""),
        })
        .subscribe((response: ResponseModel) => {
          let leadDetails = {};
          if (response.statusCode === 200) {
            leadDetails = response.data.items;
          }
          this.incomingCallDialog = this._dialog.open(
            IncomingCallDetailDailogsComponent,
            {
              width: "400px",
              panelClass: "re-incomingcall-modal",
              backdropClass: "popup-Backdrop-Class-dialer-incoming",
              id: parameters.CallSid,
              data: {
                isCalling: false,
                parameters,
                leadDetails,
                buyers: false,
                name: fromNumber,
                callerName: fromNumber,
                numberType: header.numTypeId,
                listStackingDetails: [],
                isTwilio: true,
                connectionParams,
              },
              disableClose: true,
            }
          );

          this.incomingCallDialog.afterClosed().subscribe(() => {
            if (this._utilities.numberOfCallsRunning === 0) {
              this.openModel = false;
            }
          });          
        });      
    }
  }
}
