import {
  Component,
  OnInit,
  Inject,
  NgZone,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

// UTILS
import { MessageConstant } from "../../../../../utils/message-constant";
import { MiscellaneousConstant } from "../../../../../utils/miscellaneous-constant";
import { ResponseModel } from "../../../../../utils/models/response";

// SERVICES
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "../../../../shared.service";

// COMPONENTS
import { ConfirmationDialogComponent } from "../../../confirmation-dialog/confirmation-dialog.component";

declare var google: any;
declare namespace google.maps.places {
  export interface PlaceResult {
    geometry;
  }
}
@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class VerifyEmailComponent implements OnInit {
  @ViewChild("search") searchElementRef: ElementRef;
  @ViewChild("search2") searchElementRef2: ElementRef;

  verifyEmailForm: FormGroup;
  countryInfo: any[] = [];
  messageConstant = MessageConstant;
  submitted = false;
  isMarketReadonly: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sharedService: SharedService,
    private verifyDialog: MatDialogRef<VerifyEmailComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private ngZone: NgZone,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.verifyEmailForm = this.formBuilder.group({
      fromEmail: [
        this.data?.email,
        Validators.compose([
          Validators.required,
          Validators.pattern(MiscellaneousConstant.emailPattern),
        ]),
      ],
      fromName: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(MiscellaneousConstant.namePattern),
        ]),
      ],
      nickName: ["", Validators.required],
      verifyZip: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(MiscellaneousConstant.zipPattern),
        ]),
      ],
      verifyState: ["", Validators.required],
      verifyCountry: ["", Validators.required],
      verifyCity: ["", Validators.required],
      verifyAddress2: [""],
      verifyAddress: ["", Validators.required],
    });
    this.countrySelected();
  }

  ngAfterViewInit() {
    this.findAddress();
    this.findAddress2();
    this.isMarketReadonly = true;
  }

  findAddress() {
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        this.patchingAddress(place);
      });
    });
  }

  findAddress2() {
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef2.nativeElement
    );
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        this.patchingAddress2(place);
      });
    });
  }

  patchingAddress(place) {
    let address = place?.formatted_address;
    let matches = address.match(/\b\d{5}\b/g);
    let breakAddress;
    let streetAddress;
    let city;
    let state;
    let zip;

    if (place.address || place?.formatted_address) {
      breakAddress = address ? address.split(",") : "";
      if (breakAddress.length > 1) {
        streetAddress = breakAddress[0] ? breakAddress[0] : "";
        city = breakAddress[1] ? breakAddress[1] : "";
        if (breakAddress.length > 2) {
          let subDivide = breakAddress[2];
          if (subDivide) {
            let sub = subDivide.split(" ");
            state = sub[1] ? sub[1] : "";
            zip = sub[2] ? sub[2] : "";
          }
        } else {
          state = "";
          zip = "";
        }
      } else {
        streetAddress = breakAddress[0];
        city = "";
        state = "";
        zip = "";
      }
    } else {
      streetAddress = "";
      city = "";
      state = "";
      zip = "";
    }

    this.verifyEmailForm.patchValue({
      verifyAddress: address,
      verifyAddress2: "",
      verifyState: state,
      verifyCity: city,
      verifyZip: matches ? matches[0] : zip,
    });
  }

  patchingAddress2(place) {
    let address = place?.formatted_address;
    this.verifyEmailForm.patchValue({
      verifyAddress2: address,
    });
  }

  preventDefault() {
    this.verifyEmailForm.patchValue({
      verifyAddress: "",
      verifyState: "",
      verifyCity: "",
      verifyZip: "",
    });
  }

  preventDefault2() {
    this.verifyEmailForm.patchValue({
      verifyAddress2: "",
    });
  }

  countrySelected() {
    this.verifyEmailForm.patchValue({
      verifyCountry: "USA",
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.verifyEmailForm.controls[controlName].hasError(errorName);
  };

  onVerifyEmail() {
    this.submitted = true;
    if (this.verifyEmailForm.invalid) {
      return;
    }
    const { verifyAddress2 } = this.verifyEmailForm.value;

    this._loaderService.start();
    let obj = {
      ...this.verifyEmailForm.value,
      verifyAddress2: verifyAddress2 || undefined,
    };

    this._sharedService.verifyEmail(obj, this.data?.isBulkEmail).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.verifyDialog.close(response.data);
          this._dialog.open(ConfirmationDialogComponent, {
            width: "450px",
            data: {
              header: "Email Confirmation",
              type: "verifyEmail",
              text: this.messageConstant.checkYourEmail,
              buttons: [{ title: "Ok" }],
            },
          });
          this._loaderService.stop();
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }
}
