<div class="re-sms-modal">
  <div class="re-top-head">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        {{ data?.allTitle?.heading }}
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <form
    [formGroup]="templateForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <!-- FORM CONTAINER -->
    <mat-dialog-content class="">
      <div class="container-fluid no-padd">
        <div class="box">
          <div class="row">
            <!-- TEMPLATE NAME -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  Template Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="templateName"
                  formControlName="templateName"
                  class="form-control"
                  placeholder="Name"
                />
                <div class="text-danger re-error-text">
                  <span
                    *ngIf="submitted && hasError('templateName', 'required')"
                    ><span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Template Name"
                        )
                      }}
                    </span>
                  </span>
                  <span *ngIf="hasError('templateName', 'pattern')">
                    {{ messageConstant.validFieldValue }} Template Name
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <!-- MESSAGE -->
        <div class="box">
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  Message
                  <span class="text-danger">*</span>
                  <span class="pull-right re-grey-text" *ngIf="smsCount != 0">
                    ({{ smsCharCount + "/" + smsCount }})
                  </span>
                </label>

                <quill-editor
                  #editor
                  name="message"
                  formControlName="message"
                  [styles]="editorStyle"
                  [modules]="{
                    toolbar: false,
                    mention: editorOptions.mention
                  }"
                  [placeholder]="editorOptions.placeholder"
                  appendTo="body"
                  (ngModelChange)="getSMSCount($event)"
                ></quill-editor>

                <div class="text-danger re-error-text">
                  <span *ngIf="submitted && hasError('message', 'required')">
                    {{
                      messageConstant.requiredField.replace("Field", "Message")
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <!-- ATTACHMENT -->
        <div class="box">
          <div class="row">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
              <div class="attachfile">
                <label class="labels">Attachment</label>
                <div>
                  <ul class="attchlist re-list1" *ngIf="editAttachmentArray.length > 0">
                    <li *ngFor="let file of editAttachmentArray; let i = index">
                      {{ file.name }}
                      <a
                        class="fa fa-times-circle"
                        (click)="removeAttachmentArray(file)"
                      ></a>
                    </li>
                  </ul>
                  <ul class="attchlist re-list2">
                    <li *ngFor="let file of duplicateImageArr; let i = index">
                      {{ file.name }}
                      <a
                        class="fa fa-times-circle"
                        (click)="removeImageArr(file)"
                      ></a>
                    </li>
                  </ul>
                  <div class="attachbtn" style="cursor: pointer">
                    <input
                      type="file"
                      multiple
                      name="attachmentArray"
                      formControlName="attachmentArray"
                      (change)="onUploadLogo($event)"
                      style="cursor: pointer"
                    />
                    <img
                      src="assets/images/attachment-symbol.png"
                      alt="attachment"
                      style="cursor: pointer"
                    />
                    Add attachments
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <!-- ACTION CONTAINER -->
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button type="submit" mat-flat-button color="primary" cdkFocusInitial>
        {{ data?.allTitle?.buttonTitle }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
