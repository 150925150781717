import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as _ from "lodash";
import "quill-mention";

// UTILS
import { MessageConstant } from "src/app/utils/message-constant";
import { ResponseModel } from "src/app/utils/models/response";
import { ErrorModel } from "src/app/utils/models/error";
import { StatusConstant } from "src/app/utils/status-constant";
import { environment } from "src/environments/environment";
import { BaseUrl } from "src/app/utils/base-url-constants";

// SERVICES
import { SharedService } from "../../shared.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
import { SmsService } from "src/app/providers/sms/sms.service";
import { QuillEditorComponent } from "ngx-quill";
import { UserService } from "src/app/providers/user/user.service";

@Component({
  selector: "app-add-edit-sms-template",
  templateUrl: "./add-edit-sms-template.component.html",
  styleUrls: ["./add-edit-sms-template.component.scss"],
})
export class AddEditSmsTemplateComponent implements OnInit {
  @ViewChild("editor") editor: QuillEditorComponent;
  templateForm: FormGroup;
  messageConstant = MessageConstant;

  public items: string[] = [
    "First_Name",
    "Last_Name",
    "Property_Street_Address",
    "Property_City",
    "Property_State",
    "Property_Zip_Code",
    "Appt_Date_and_Time",
    "Appt_Day",
    "Appt_Time",
  ];

  submitted: boolean = false;
  selectedModuleId: any[] = [];
  moduleData: any[] = [];
  editAttachmentArray: any = [];
  fileSize: any = 0;
  selectedFileSize: any = 0;
  imageArr: any[] = [];
  uploadFiles: any[] = [];
  fileCount: number = 0;
  allFileCount: number = 0;
  duplicateImageArr: any = [];
  smsCount: number = 0;
  smsCharCount: number = 0;
  editorStyle: {
    height: "500px";
  };
  editorConfig: any = {};
  editorOptions: any = {};
  moduleId: string = StatusConstant?.ModuleId?.LEAD;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _smsDailog: MatDialogRef<AddEditSmsTemplateComponent>,
    private _utilities: CommonFunctionsService,
    private _smsService: SmsService,
    private _userService: UserService
  ) {
    this.getUserRoleList();
    this.editorOptions = {
      placeholder: this._utilities.commonEditor().placeholder,
      toolbar: this._utilities.commonEditor().toolbar,
      mention: this.mentionEditor(),
    };
  }

  mentionEditor() {
    return {
      mentionListClass: "ql-mention-list mat-elevation-z8",
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: true,
      spaceAfterInsert: true,
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        editor.insertText(editor.getLength() - 1, "", "user");
      },
      source: (searchTerm, renderList) => {
        const values = this.items.map((item, index) => {
          return { id: index, value: item };
        });

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    };
  }

  ngOnInit(): void {
    this.templateForm = this._formBuilder.group({
      templateName: ["", Validators.compose([Validators.required])],
      message: ["", Validators.required],
      attachmentArray: [""],
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.templateForm.controls[controlName].hasError(errorName);
  };

  getUserRoleList() {
    this._loaderService.start();
    let role = [];
    this._userService.getUserRoleList({ page: 1, limit: 100 }).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          let hash = Object.create(null);

          let roles = _.flatten(
            response.data.items.map((item) => item.roleData)
          );
          let result: any = roles.reduce((r: any, o: any) => {
            if (!hash[o?._id]) {
              hash[o?._id] = {
                _id: o?._id,
                name: o?.name,
                roles: [],
              };
              r.push(hash[o?._id]);
            }
            hash[o?._id].roles.push({
              _id: o?._id,
              name: o?.name,
            });
            return r;
          }, []);
          result.map((item) => {
            role.push(item.name.toString());
          });
          this.items = [...this.items, ...role];

          if (this.data.action == "edit") {
            if (this.data.details?.attachmentArray) {
              this.fileSize = this.data.details?.attachmentArray
                .map((obj) => {
                  return Number(obj.size);
                })
                .reduce((a, b) => a + b, 0);
            }

            this.editAttachmentArray = this.data.details?.attachmentArray
              ? this.data.details?.attachmentArray
              : [];

            this.templateForm.patchValue({
              templateName: this.data.details?.templateName,
              message: this._utilities.populateEditorDataWithBR(
                this.items,
                this.data.details?.message
              ),
            });

            this.getSMSCount("");

            for (let i = 0; i < this.data.details.moduleId.length; i++) {
              for (let j = 0; j < this.data.items.length; j++) {
                if (this.data.items[j]._id == this.data.details.moduleId[i]) {
                  this.selectedModuleId.push(this.data.items[j]);
                  break;
                }
              }
            }
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.templateForm.invalid) {
      return;
    }
    this.templateForm.value.message = this._utilities.getTextWithMentions(
      this.templateForm.value.message
    );
    this.templateForm.value.message = this.templateForm.value.message
      .replace(/[^\x00-\x7F]/g, "")
      .replace(/&#xFEFF;/g, "");
    let obj = {
      ...this.templateForm.value,
    };

    this._loaderService.start();
    // this.editAttachmentArray = [
    //   ...this.editAttachmentArray,
    //   ...this.duplicateImageArr,
    // ];
    // this.editAttachmentArray = [
    //   ...new Set(this.editAttachmentArray.map(JSON.stringify)),
    // ];
    // this.editAttachmentArray = this.editAttachmentArray.map(JSON.parse);
    // if (this.editAttachmentArray?.length > 0) {
    //   obj["attachmentArray"] = this.editAttachmentArray;
    // } else {
    //   delete obj["attachmentArray"];
    // }
    let duplicateImageArr: any = [
      ...this.editAttachmentArray,
      ...this.duplicateImageArr,
    ];
    duplicateImageArr = [
      ...new Set(duplicateImageArr.map(JSON.stringify)),
    ];
    duplicateImageArr = duplicateImageArr.map(JSON.parse);
    if (duplicateImageArr?.length > 0) {
      obj["attachmentArray"] = duplicateImageArr
    } else {
      delete obj["attachmentArray"];
    }
    if (this.data.action === "edit") {
      obj = {
        ...obj,
        smsTemplateId: this.data.details?._id,
      };

      this._smsService.editSmsTemplate(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._smsDailog.close(true);
            this._toastrService.success(
              this.messageConstant.smsTemplateEditSuccess
            );
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
      return;
    }

    this._smsService.addSmsTemplate(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._smsDailog.close(response?.data);
          this._toastrService.success(
            this.messageConstant.smsTemplateAddSuccess
          );
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  getSMSCount(e) {
    const MAX_LENGTH = 160;
    const message = this._utilities.getText(
      this["editor"].quillEditor.getContents()
    );

    const length = message.length ? message?.length - 1 : 0;

    this.smsCount = Math.ceil(length / MAX_LENGTH);
    this.smsCharCount = MAX_LENGTH * this.smsCount - length;
  }
  removeAttachmentArray(value) {
    this.editAttachmentArray = this.editAttachmentArray.filter(
      (item) => item.url !== value.url
    );
    this.fileSize = this.fileSize - Number(value?.size);
    this.selectedFileSize = 0;
  }

  removeImageArr(value) {
    this.duplicateImageArr = this.duplicateImageArr.filter(
      (item) => item.url !== value.url
    );
    this.fileSize = this.fileSize - Number(value?.size);
    this.selectedFileSize = 0;
  }

  onFileSelected(event: Event, isProfile): void {
    const inputElement = event.target as HTMLInputElement;
    const files: FileList = inputElement.files;
    this._loaderService.start();
    const formData = new FormData();
    formData.append("file", files[0]);

    this._userService
      .fileUploadForprofileandOther(formData, isProfile, this.moduleId)
      .subscribe(
        (response: ResponseModel) => {
          if (response?.statusCode == 200) {
            let imgData = response?.data[0];
            this.duplicateImageArr.push(imgData);
            this._loaderService.stop();
          }
        },
        (err) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, "");
          } else {
            this._toastrService.error(this.messageConstant.unknownError, "");
          }
        }
      );
  }

  onUploadLogo(output): void {
    this.uploadFiles = output.target.files;

    if (this.uploadFiles.length > 0) {
      for (let i = 0; i < this.uploadFiles.length; i++) {
        const element = this.uploadFiles[i];
        if (
          element?.type == "application/pdf" ||
          element?.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          element?.type == "test.csv"
        ) {
          this._toastrService.error(this.messageConstant?.fileFormateErr);
          this.uploadFiles = [];
          return;
        }
      }
    }

    this.uploadAttachment();
  }

  async uploadAttachment() {
    if (this.uploadFiles.length > 0) {
      for (let i = 0; i < this.uploadFiles.length; i++) {
        const output = this.uploadFiles[i];
        this.fileCount = i + 1;
        this.uploadFile(output);
      }
    }
    this.uploadFiles = [];
  }

  uploadFile(fileData) {
    let endpoint = environment.baseUrl + BaseUrl.sharedUrl + "file-upload";
    if (this.data.moduleId) {
      endpoint += `?moduleId=${this.data.moduleId}&isPublic=1&type=0`;
    }

    var newformData: FormData = new FormData();
    const file: any = fileData;
    newformData.append("file", file, file.name);
    this._loaderService.start();
    this._sharedService.uploadFile(endpoint, newformData).subscribe(
      (response: ResponseModel) => {
        this.allFileCount += 1;
        if (this.allFileCount == this.fileCount) {
          this._loaderService.stop();
          this.allFileCount = 0;
        }
        if (response?.statusCode && response?.statusCode == 200) {
          if (response?.data.length > 0) {
            let imgData = response?.data[0];
            imgData["size"] = String(fileData?.size);
            this.duplicateImageArr.push(imgData);
          }
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }
}
