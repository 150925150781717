import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from "lodash";

// SERVICE
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/providers/user/user.service";

// UTILS
import { ResponseModel } from "src/app/utils/models/response";
import { ErrorModel } from "src/app/utils/models/error";
import { MessageConstant } from "src/app/utils/message-constant";

@Component({
  selector: "app-view-members-list-dialog",
  templateUrl: "./view-members-list-dialog.component.html",
  styleUrls: ["./view-members-list-dialog.component.scss"],
})
export class ViewMembersListDialogComponent implements OnInit {
  currentPage: number = 1;

  users: any[] = [];
  roles: any[] = [];
  userRoleId: any;
  roledatas: any[] = [];
  totalRolesAssign: any[] = [];
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _userService: UserService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.data.members_list === "Members") {
      this.getRoles();
    } else {
      return;
    }

    this.data["item"].userAssignment.map((x) => {
      this.totalRolesAssign.push(x.roleId);
    });
    let union = [...new Set([...this.totalRolesAssign])];
    this.totalRolesAssign = union;
  }

  getRoles() {
    this._loaderService.start();
    this._userService.getRoles({}).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.roles = response?.data.map((x) => {
            let obj = {
              label: x.name,
              value: x.key,
              _id: x._id,
            };
            return obj;
          });
          this.roles = _.sortBy(this.roles, ["label"]);
          this.getUserList();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  getUserList() {
    const obj = {
      page: this.currentPage,
      limit: 100,
    };
    this._userService.getSubUserList(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.users = response.data.items.map((x) => {
            let obj = {
              label: `${x?.name}`,
              value: x?._id,
              roleData: x?.roleData,
            };
            return obj;
          });
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, "");
        } else {
          this._toastrService.error(this.messageConstant.unknownError, "");
        }
      }
    );
  }

  getUserName(user, userRoleData, roleId) {
    let flag = false;
    userRoleData.map((x) => {
      if (x._id == roleId) {
        this.data["item"].userAssignment.map((j) => {
          if (j?.userId == user?.value) {
            flag = true;
          }
        });
      }
    });
    if (flag) {
      return user.label;
    }
    return "";
  }
}
