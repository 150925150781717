import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

// UTILS
import { MessageConstant } from "src/app/utils/message-constant";

// SERVICES
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";

@Component({
  selector: "app-delete-dialog",
  templateUrl: "./delete-dialog.component.html",
  styleUrls: ["./delete-dialog.component.scss"],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class DeleteDialogComponent implements OnInit {
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<DeleteDialogComponent>,
    private _loaderService: NgxUiLoaderService,
    public _utilities: CommonFunctionsService
  ) {}

  ngOnInit(): void {}

  submit() {
    this._loaderService.start();
    let { _id } = this.data?.details || {};
    this.dialog.close(_id || true);
  }
}
