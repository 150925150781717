import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import * as moment from "moment-timezone";
import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { StatusConstant } from "../status-constant";
import { environment } from "src/environments/environment";
import {
  StatusChangeModel,
  StatusChangeChecksModel,
} from "../models/leads-status-change";
import { timezoneConstant } from "../timezone-list-constant";

@Injectable({
  providedIn: "root",
})
export class CommonFunctionsService {
  constructor(private http: HttpClient) {}

  public leadSort: any;
  public userData: any;
  public currentPlanData: any;
  public leadFilter: any;
  public currentModule: string = "";
  public leadIdArray: any[] = [];
  public currentLeadCount: number = 0;
  public newCount: number = 0;
  public reduceGrid: boolean = false;
  public updateAvailable: boolean = false;
  public isFilterLeads: boolean = false;
  public showAddLimitPopup: Boolean = true;
  public userAccessData: any;
  public currentMainStatusId: string = "";
  public isLeadPreviousEnable: boolean = true;
  public isLeadNextEnable: boolean = true;
  public activityLogFilter: any = {};
  public currentNumberTab: number = 0;
  public dashboardSelectedUserId: string = "";
  public isFilterContact: boolean = false;
  public contactFilter: any = {};
  public currentPageContact: number = 1;
  // public isSearchLead: boolean = false;
  public isTaskFromDashboard: boolean = false;

  /****************** For Call********************/
  public callType: string = "outgoing";
  public callTypeReject: string = "false";
  // public isCallStatusLead: boolean = false;
  public makingCallDialer: boolean = false;
  public numberOfCallsRunning: number = 0;
  public isDialogOpen: boolean = false;
  public dialogcurrentLeadId: string = "";

  public changeLeadStatusObject: StatusChangeModel[] = [
    {
      key: "NEW_LEAD",
      showQuestion: false,
      isVisibleKey: "",
    },
    {
      key: "NO_CONTACT_MADE",
      showQuestion: false,
      isVisibleKey: "",
    },
    {
      key: "CONTACT_MADE",
      showQuestion: false,
      isVisibleKey: "",
    },
    {
      key: "APPOINTMENT_SET",
      showQuestion: false,
      isVisibleKey: "",
    },
    {
      key: "DUE_DILIGENCE",
      showQuestion: false,
      isVisibleKey: "",
    },
    {
      key: "OFFERS_MADE",
      showQuestion: false,
      isVisibleKey: "",
    },
    {
      key: "ACCEPTED_OFFER",
      showQuestion: false,
      isVisibleKey: "",
    },
    {
      key: "LISTING_SECURED",
      showQuestion: false,
      isVisibleKey: "",
    },
  ];

  public dlcForm: any;

  // public isTwilioUser: any;
  public isTwilioDeviceConnect: boolean = false;
  public leadType: any;

  orderItems(data: any[], key: string) {
    if (!data.length || !key) {
      return data;
    }
    const sortedData = data.sort((a, b) => a[key] - b[key]);
    return sortedData;
  }

  // validatePhone(phone) {
  //   var value = phone.toString().trim().replace(/^\+/, "");
  //   if (value.match(/[^0-9]/)) {
  //     return phone;
  //   }
  //   var number;
  //   switch (value.length) {
  //     case 10:
  //       number = value;
  //       break;
  //     case 11:
  //       number = value.slice(1);
  //       break;
  //     case 12:
  //       number = value.slice(2);
  //       break;
  //     default:
  //       return phone;
  //   }
  //   return number;
  // }

  reOrder(data: any[], fromIndex: number, toIndex: number) {
    if (fromIndex === toIndex) return data;

    const newArray = [...data];
    const target = newArray[fromIndex];
    const inc = toIndex < fromIndex ? -1 : 1;

    for (let i = fromIndex; i !== toIndex; i += inc) {
      newArray[i] = newArray[i + inc];
    }
    newArray[toIndex] = target;
    return newArray;
  }

  reset() {
    this.leadSort = null;
    this.reduceGrid = false;
    this.updateAvailable = false;
    this.currentLeadCount = 0;
    this.currentModule = "";
    this.newCount = 0;
    this.userData = null;
    this.currentPlanData = null;
    this.leadFilter = null;
    this.isFilterLeads = false;
    this.leadIdArray = [];
    this.userAccessData = null;
    this.currentNumberTab = 0;
    this.dashboardSelectedUserId = "";
    this.makingCallDialer = false;
    // this.isCallStatusLead = false;
    this.isTaskFromDashboard = false;
  }

  maskNumber(tel: any) {
    try {
      if (!tel) {
        return "";
      }

      var phone = this.unMaskNumber(tel);
      var value = phone.toString().trim().replace(/^\+/, "");
      if (value.match(/[^0-9]/)) {
        return phone;
      }
      var country, city, number;
      switch (value.length) {
        case 10:
          country = 1;
          city = value.slice(0, 3);
          number = value.slice(3);
          break;
        case 11:
          country = value.slice(0, 1);
          city = value.slice(1, 4);
          number = value.slice(4);
          break;
        case 12:
          country = value.slice(0, 2);
          city = value.slice(2, 5);
          number = value.slice(5);
          break;
        case 13:
          country = value.slice(0, 3);
          city = value.slice(3, 6);
          number = value.slice(6);
          break;
        case 14:
          country = value.slice(0, 4);
          city = value.slice(4, 7);
          number = value.slice(7);
          break;
        default:
          return tel;
      }

      number = number.slice(0, 3) + "-" + number.slice(3);

      return ("(" + city + ") " + number).trim();
    } catch (error) {
      return tel;
    }
  }

  unMaskNumber(tel: string) {
    try {
      if (!tel) {
        return "";
      }

      return tel
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", "");
    } catch (error) {
      return tel;
    }
  }

  getCountryCode(tel) {
    try {
      switch (tel?.length) {
        case 12:
          return tel.slice(0, 2);

        case 13:
          return tel.slice(0, 3);
      }
    } catch (error) {
      return environment.countryCode;
    }
  }

  capitalizeName(name: string) {
    if (!name) {
      return name;
    }

    try {
      name = name?.trim();
      let words = name?.split(" ");

      if (!words?.length) {
        return name;
      }

      for (let i = 0; i < words?.length; i++) {
        if (words[i]) {
          words[i] =
            words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }
      }

      return words?.join(" ");
    } catch (error) {
      return "";
    }
  }

  /**
   * FUNCTION: GET DATE FORMAT
   * @param {Date} dueDate
   * @param {String} timezone
   * @param {String} time
   * @returns OBJECT OF DATE FORMAT
   */
  dueDateFormat = (dueDate?: any, timezone?: any, time?: any) => {
    try {
      dueDate = Number(dueDate);
      let timezoneOffset: any;
      let timeAbbr;
      let validDate;
      let timeStampValue;
      let formatDateByDay;
      let currentTimezone;
      let day = "" + new Date(dueDate).getDate();
      let month = "" + (new Date(dueDate).getMonth() + 1);
      let year = new Date(dueDate).getFullYear();
      let offset = new Date().getTimezoneOffset();
      let offset1 = Math.abs(Math.round(offset / 60));
      let offset11 = offset1.toString();
      let offset2 = Math.abs(offset) % 60;
      let offset22 = offset2.toString();
      timezoneOffset = localStorage.getItem("timezoneOffset");
      let timeZoneFormat = moment(new Date(dueDate))
        .tz(timezoneOffset)
        .format("Z");
      if (offset11.length == 1) {
        offset11 = "0" + offset11;
      }

      if (offset22.length == 1) {
        offset22 = "0" + offset22;
      }

      if (!timezone) {
        timezone = timezoneOffset;
      }
      if (timezone) {
        let type: any;
        type = timezoneConstant.timezoneArray.find(
          (x) => x?.timezonename === timezone
        );
        timeAbbr = type.abbreviation;
      }

      if (!dueDate || dueDate == 0) {
        validDate = "";
      } else if (!isNaN(Date.parse(dueDate))) {
        validDate = new Date(dueDate);
      } else if (!isNaN(Number(dueDate))) {
        validDate = new Date(Number(dueDate));
      } else if (moment(dueDate).isValid()) {
        validDate = new Date(moment(dueDate).toString());
      } else if (moment(dueDate, "DD/MM/YYYY").isValid()) {
        validDate = new Date(moment(dueDate, "DD/MM/YYYY").toString());
      } else if (moment(dueDate, "YYYY/MM/DD").isValid()) {
        validDate = new Date(moment(dueDate, "YYYY/MM/DD").toString());
      } else if (moment(dueDate, "YYYY/DD/MM").isValid()) {
        validDate = new Date(moment(dueDate, "YYYY/DD/MM").toString());
      } else {
        validDate = "";
      }

      if (!dueDate) {
        timeStampValue = "";
      } else {
        let convertedDate =
          moment(new Date(dueDate)).format("YYYY-MM-DD") +
          "T" +
          moment()
            .tz(timezoneOffset)
            .set(time || { second: 0 })
            .format("HH:mm:ss") +
          timeZoneFormat;
        timeStampValue = moment(convertedDate)
          .set(time || {})
          .valueOf();
      }

      if (day.length < 2) {
        day = "0" + day;
      }
      if (month.length < 2) {
        month = "0" + month;
      }
      formatDateByDay = [year, month, day].join("-");

      if (offset < 0) {
        currentTimezone = "+" + offset11 + ":" + offset22;
      } else {
        currentTimezone = "-" + offset11 + ":" + offset22;
      }

      return {
        date: moment(new Date(dueDate)).format("MMM D, YYYY"),
        time: moment(new Date(dueDate)).format("LT"),
        timezone: moment(new Date(dueDate))
          .tz(timezone)
          .format("MMM DD, YYYY hh:mm A"),
        timezoneDate: moment(new Date(dueDate))
          .tz(timezone)
          .format("MMM DD, YYYY"),
        timezoneTime: moment(new Date(dueDate)).tz(timezone).format("hh:mm A"),
        timezoneAbbr: timeAbbr,
        parseDate: validDate,
        timeStamp: timeStampValue,
        dateFormat: formatDateByDay,
        browserTimezone: currentTimezone,
      };
    } catch (error) {
      return {};
    }
  };

  getSum = (data, key) => {
    return data.reduce((a, b) => a + (b[key] || 0), 0);
  };

  formatAddress(address: string, unitNo = "") {
    try {
      if (!address) {
        return "";
      }
      const re = /, USA/gi;
      if (unitNo) {
        let address1 = address.replace(re, "");
        var fields = address1.split(",");
        fields.splice(1, 0, "Unit # " + unitNo);
        return fields.join(", ");
      } else {
        return address.replace(re, "");
      }
    } catch (error) {
      return "";
    }
  }

  checkForStatusNameChange(allStatus: any[], type: string, leadType: any) {
    let statusKey: any,
      index: number,
      statusConstantData: any = {},
      statusObject: any[] = [];
    if (type == "new leads") {
      statusConstantData = this.getMainStatusId(leadType)?.allMainStatusData;
      statusObject = this.changeLeadStatusObject;
    }
    allStatus.filter((e) => {
      statusKey = Object.keys(statusConstantData).find(
        (key) => statusConstantData[key] === e?._id
      );
      if (statusKey && e?.labels?.title) {
        index = statusObject.findIndex((e) => e.key === statusKey);
        if (index > -1) statusObject[index]["showQuestion"] = false;
      }
    });
  }

  //Global Get, Post, Put and Delete
  /**
   * FUNCTION: COMMON GET HTTP REQUEST
   * @param {STRING} endpoint
   * @param {Object} data
   * @param {Object} headers
   * @returns HTTP GET CALL RESPONSE
   */
  globalGetService(endpoint: any, headers?: any) {
    let httpPostReq = this.http.get(endpoint);
    if (headers) httpPostReq = this.http.get(endpoint, headers);
    return httpPostReq.pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  /**
   * FUNCTION: COMMON PUT HTTP REQUEST
   * @param {STRING} endpoint
   * @param {Object} data
   * @returns HTTP PUT CALL RESPONSE
   */
  globalPutService(endpoint: any, data: any) {
    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  /**
   * FUNCTION: COMMON POST HTTP REQUEST
   * @param {STRING} endpoint
   * @param {Object} data
   * @param {Object} headers
   * @returns HTTP POST CALL RESPONSE
   */
  globalPostService(endpoint: string, data: any, headers?: any) {
    let httpPostReq = this.http.post(endpoint, data);
    if (headers) httpPostReq = this.http.post(endpoint, data, headers);
    return httpPostReq.pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  statusChangeChecks(allParams: StatusChangeChecksModel) {
    if (allParams?.fromStatus === allParams?.toStatus) {
      return { success: 0, type: 1 };
    }
    let fromStatusKey: string,
      toStatusKey: string,
      statusConstantData = {},
      statusObject = [],
      showPopup = false;
    if (allParams?.module === "Leads") {
      statusConstantData = this.getMainStatusId(
        allParams?.leadType
      )?.allMainStatusData;
      statusObject = this.changeLeadStatusObject;
    }
    fromStatusKey = Object.keys(statusConstantData).find(
      (key) => statusConstantData[key] === allParams?.fromStatus
    );
    toStatusKey = Object.keys(statusConstantData).find(
      (key) => statusConstantData[key] === allParams?.toStatus
    );
    let data = {},
      fromKey = "";
    if (fromStatusKey && toStatusKey) {
      let fromIndex = statusObject.findIndex((e) => e.key === fromStatusKey);
      let toIndex = statusObject.findIndex((e) => e.key === toStatusKey);
      fromKey = statusObject[fromIndex]?.isVisibleKey;
      statusObject?.filter((e, i) => {
        if (fromIndex < toIndex) {
          if (i >= fromIndex && i <= toIndex && e?.isVisibleKey) {
            data[e?.isVisibleKey] = true;
            showPopup = true;
          } else if (e?.isVisibleKey) {
            data[e?.isVisibleKey] = false;
          }
        } else if (e?.isVisibleKey) {
          data[e?.isVisibleKey] = i > toIndex ? false : true;
        }
      });
    }
    return {
      success: 1,
      showPopup: showPopup,
      data: data,
      fromKey: fromKey,
    };
  }

  camelCaseToWord(text, style?) {
    if (!text) {
      return;
    }

    let result = text.replace(/([A-Z])/g, " $1");
    if (style && style?.index) {
      if (style?.transform === "uppercase") {
        var split = result.split(" ");
        if (split[style?.index] && split[style?.index] != "Call") {
          split[style?.index] = split[style?.index].toUpperCase();
        } else {
          split[style?.index] =
            split[style?.index].charAt(0).toUpperCase() +
            split[style?.index].slice(1);
        }
        result = split.join(" ");
      }
    }
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  sortItems(data: any[], key: string) {
    const sortedData = data.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });

    return sortedData;
  }

  titleCaseToWord(text) {
    try {
      if (!text || typeof text === "object") {
        return;
      }
      return text?.toLowerCase()?.replace(/\b(\w)/g, (s) => s?.toUpperCase());
    } catch (error) {
      return text;
    }
  }

  numberType = (type: string) => {
    switch (type) {
      case "voip":
        return "V";

      case "landline":
        return "L";

      case "mobile":
        return "M";

      case "toll-free":
        return "TF";

      default:
        return "N/A";
    }
  };

  taskTimeTypeList = () => {
    return ["Minutes", "Hours", "Days", "Weeks", "Months"];
  };

  taskType = () => {
    return [
      {
        id: 1,
        name: "Appointment",
        class: "re-agent-appointment",
      },
      {
        id: 2,
        name: "Call",
        class: "re-agent-call",
      },
      {
        id: 3,
        name: "Closing",
        class: "re-agent-closing",
      },
      {
        id: 4,
        name: "Disposition",
        class: "re-agent-disposition",
      },
      {
        id: 5,
        name: "Due Diligence",
        class: "re-agent-due-diligence",
      },
      {
        id: 6,
        name: "Marketing",
        class: "re-agent-marketing",
      },
      {
        id: 7,
        name: "Offer",
        class: "re-agent-offer",
      },
      {
        id: 8,
        name: "Other",
        class: "re-agent-other",
      },
      {
        id: 9,
        name: "SMS",
        class: "re-agent-sms",
      },
    ];
  };

  minutesList = (num, fill) => {
    return new Array(num).fill(fill).map((x, i) => i + 1);
  };
  commonEditor() {
    return {
      placeholder: "Type message here use @user to tag someone",
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  }

  getText(editor) {
    const { ops } = editor;
    const result = ops.reduce((acc, { insert }) => {
      const insertValue: any = insert;
      if (typeof insertValue === "string") {
        acc += insertValue;
      } else {
        acc += `@${insertValue.mention.value} `;
      }
      return acc;
    }, "");

    return result;
  }

  /**
   * FUNCTION: COMMON DELETE HTTP REQUEST
   * @param {STRING} endpoint
   * @returns HTTP DELETE CALL RESPONSE
   */
  globalDeleteService(endpoint) {
    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getTaskTypeOption(id) {
    return this.taskType().find((item) => {
      if (item.id == id) {
        return item;
      }
    });
  }

  getEncryptedString(value) {
    return new Promise(async (resolve, reject) => {
      const cipher = (salt) => {
        const textToChars = (text) =>
          text.split("").map((c) => c.charCodeAt(0));
        const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = (code) =>
          textToChars(salt).reduce((a, b) => a ^ b, code);

        return (text) =>
          text
            .split("")
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join("");
      };

      const myCipher = cipher("resimpli_Agent_Live");

      let encryptedString = myCipher(value);
      resolve(encryptedString);
    });
  }

  getMainStatusId(type) {
    let mainStatusId: any;
    let allMainStatusData: any;
    let secondMainStatusData: any;
    let secondLastMainStatusData: any;
    let lastMainStatusId: any;
    if (type == 1) {
      allMainStatusData = StatusConstant?.BuyerLeadStatus;
      mainStatusId = StatusConstant?.BuyerLeadStatus?.NEW_LEAD;
      lastMainStatusId = StatusConstant?.BuyerLeadStatus?.ACCEPTED_OFFER;
    } else if (type == 2) {
      allMainStatusData = StatusConstant?.SellerLeadStatus;
      mainStatusId = StatusConstant?.SellerLeadStatus?.NEW_LEAD;
      lastMainStatusId = StatusConstant?.SellerLeadStatus?.LISTING_SECURED;
    } else if (type == 3) {
      allMainStatusData = StatusConstant?.BuyerTransactionStatus;
      mainStatusId =
        StatusConstant?.BuyerTransactionStatus?.BUYER_ACCEPTED_OFFER;
      lastMainStatusId = StatusConstant?.BuyerTransactionStatus?.CLOSED;
      secondLastMainStatusData =
        StatusConstant?.BuyerTransactionStatus?.CLEAR_TO_CLOSE;
    } else if (type == 4) {
      allMainStatusData = StatusConstant?.SellerTransactionStatus;
      mainStatusId = StatusConstant?.SellerTransactionStatus?.NEW_LISTING;
      lastMainStatusId = StatusConstant?.SellerTransactionStatus?.CLOSED;
      secondLastMainStatusData =
        StatusConstant?.SellerTransactionStatus?.CLEAR_TO_CLOSE;
      secondMainStatusData =
        StatusConstant?.SellerTransactionStatus?.LIVE_ON_MLS;
    }

    return {
      allMainStatusData,
      mainStatusId,
      lastMainStatusId,
      secondMainStatusData,
      secondLastMainStatusData,
    };
  }

  getTextWithMentions(text) {
    let replacePattern1 =
      /<span\b(?:(?=(\s+(?:id="([^"]*)"|class="([^"]*)")|[^\s>]+|\s+))\1)*>/gim;
    let replacedText = text.replace(replacePattern1, "");
    replacedText = replacedText.replace(/<\/span>/gi, function (matched) {
      return "";
    });
    replacedText = replacedText
      .replace(/<br><\/p>/g, "\n")
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "\n")
      .replace(/<br>/g, "\n")
      .replace(/<[^>]*>?/gm, "")
      .replace(/&nbsp;/g, " ");

    return replacedText;
  }

  populateEditorDataWithBR(data, message) {
    try {
      let result = [];
      let arr = message.split("\n");
      for (let i = 0; i < arr.length; i++) {
        result.push(this.populateEditorData(data, arr[i]));
      }
      return result.join("<br>");
    } catch (error) {
      return message;
    }
  }

  populateEditorData(data, message) {
    try {
      let str = message;

      data.map((x, index) => {
        var index = str.search(x);

        if (index > -1) {
          var textToReplace = "@" + x;
          str = str.replaceAll(
            textToReplace,
            ` <span class="mention" data-index="${index}" data-denotation-char="@" data-id="${index}" data-value="${x}">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${x}</span>&#xFEFF;</span>`
          );
        }

        return x;
      });

      return str;
    } catch (error) {
      return message;
    }
  }

  populateEditorDataWithN(message) {
    let result = [];
    let arr = message.split("\n");
    for (let i = 0; i < arr.length; i++) {
      result.push(arr[i]);
    }
    return result.join("<br>");
  }

  calculateMinutes(num, isShowSeconds?, isFormatCustom?) {
    let d, h, m, s, time;
    d = Math.floor(num / 1440); // 60*24
    h = Math.floor((num - d * 1440) / 60);
    m = Math.round(num % 60);

    if (d > 0) {
      time = d + " days, " + h + " hours, " + m + " minutes ago";
    } else if (h > 0) {
      time = h + " hours, " + m + " minutes ago";
    } else if (m > 0) {
      time = m + " minutes ago";
    } else {
      s = Math.floor((num * 60000) / 1000);
      time = isShowSeconds ? s + ` seconds` : "a few seconds ago";
    }

    return isFormatCustom ? { d, h, m, s } : time;
  }

  calculateTimimg(d) {
    let months = 0,
      years = 0,
      days = 0,
      weeks = 0;
    while (d) {
      if (d >= 365) {
        years++;
        d -= 365;
      } else if (d >= 30) {
        months++;
        d -= 30;
      } else if (d >= 7) {
        weeks++;
        d -= 7;
      } else {
        days++;
        d--;
      }
    }

    if (years > 0) {
      return (
        years +
        " year " +
        months +
        " months " +
        weeks +
        " weeks " +
        days +
        " days"
      );
    } else if (months > 0) {
      return months + " months " + weeks + " weeks " + days + " days";
    } else if (weeks > 0) {
      return weeks + " weeks " + days + " days";
    } else {
      return days + " days";
    }
  }

  populateEditorDataBR(message) {
    let result = [];
    let arr = message.split("<br>");
    for (let i = 0; i < arr.length; i++) {
      result.push(arr[i]);
    }
    return result.join("\n");
  }

  commonInboxEditor() {
    return {
      placeholder: "Type your message here...",
      toolbar: [],
    };
  }

  // formatNumber = (number: Number) => {
  //   if (!number) return number;
  //   return String(number).replace(/(.)(?=(\d{3})+$)/g, "$1,");
  // };

  // transformAmount(element) {
  //     var value = element.target.value.split(".");
  //     if (element.target.value && value[1]) {
  //       if (value[1].length == 2) {
  //         element.target.value = element.target.value;
  //       } else if (value[1].length == 1) {
  //         element.target.value = element.target.value + "0";
  //       } else {
  //         element.target.value = element.target.value + "00";
  //       }
  //     } else if (element.target.value != "$ ") {
  //       element.target.value = value[0] + ".00";
  //     }
  //   }

  // return First charactor of first and last name
  getInitials(user) {
    var initials = user.match(/\b\w/g) || [];
    return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  }

  getContactValue(item) {
    let title =
      item?.title && item?.title.trim()
        ? this.titleCaseToWord(item?.title.trim())
        : item?.phoneNumber
        ? this.maskNumber(item.phoneNumber)
        : item?.email
        ? item.email
        : "N/A";
    return title;
  }

  sortByObject = (moduleList) =>
    moduleList.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

  capitalizeFirstWord(inputString) {
    if (!inputString) {
      return;
    }
    const words = inputString.split(" ");
    if (words.length > 0) {
      words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    }
    const resultString = words.join(" ");
    return resultString;
  }

  updateLeadItem(item) {
    let displayArrayData = [];
    let pipeline = this.pipeline(item?.leadCreated);
    if (
      item?.mainStatusId == StatusConstant?.SellerLeadStatus?.LISTING_SECURED
    ) {
      let dic = {
        title: "Listing Signed",
        value: item?.listingSecured?.signedDate,
        pipe: "DATE",
        isDisplayNone: false,
      };
      displayArrayData.push(dic);

      let dic1 = {
        title: "Listing Price",
        value: item?.listingSecured?.listingPrice,
        pipe: "CURRENCY",
        isDisplayNone: false,
      };
      displayArrayData.push(dic1);

      let dic2 = {
        title: "Listing Expiry",
        value: item?.listingSecured?.expiryDate,
        pipe: "DATE",
        isDisplayNone: false,
      };
      displayArrayData.push(dic2);
    }

    let dic3 = {
      title: "Created",
      value: item?.leadCreated,
      pipe: "DATE",
      isDisplayNone: false,
    };
    displayArrayData.push(dic3);

    let dic4 = {
      title: "In pipeline",
      value: pipeline + " days",
      isDisplayNone: false,
    };
    displayArrayData.push(dic4);
    return (item.displayArray = displayArrayData);
  }

  pipeline(first) {
    const nowDate = new Date().getTime();
    const createdDate = new Date(first).getTime();
    const differenceInTime = nowDate - createdDate;
    let days = Math.round(differenceInTime / (1000 * 3600 * 24));
    return days > 0 ? days : 0;
  }
}
