<div>
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      {{ data?.type }} Quick Filter
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<div class="re-agent-editManageList">
  <div class="re-top-container">
    <mat-dialog-content>
      <div class="container-fluid p-0">
        <div class="re-agent-box">
          <form autocomplete="off" [formGroup]="editManageListForm" novalidate>
            <div class="row">
              <div class="col-12 col-md-12">
                <h3 class="re-filter-title">BASIC DETAILS</h3>
              </div>
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label class="re-agent-label">
                    Filter Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="title"
                    placeholder="Enter Filter Name"
                    formControlName="title"
                  />
                  <div class="text-danger re-agent-error-txt">
                    <span
                      *ngIf="
                        (title.dirty || title.touched || submitted) &&
                        hasError('title', 'required')
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Filter Name"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <label class="re-agent-label">Description</label>
                  <textarea
                    class="form-control"
                    name="description"
                    formControlName="description"
                    rows="2"
                    placeholder="Enter description (Optional)"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
        <hr />
        <div class="re-agent-box">
          <div class="row">
            <div class="col-12 col-md-12">
              <h3 class="re-filter-title">FILTER PARAMETERS</h3>
            </div>
          </div>

          <!-- ACTIVE FILTERS -->
          <ng-container
            *ngFor="let filter of appliedFilteredList; let filterIndex = index"
          >
            <div class="re-list">
              <ng-container>
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-8">
                    <select
                      [name]="filter.value + '-' + filterIndex"
                      [id]="filter.value + '-' + filterIndex"
                      class="form-control"
                      [(ngModel)]="filter.value"
                      (change)="changeFilter($event, filterIndex)"
                    >
                      <ng-container *ngFor="let filter of leadFilters">
                        <option
                          [class.d-none]="isActive(filter)"
                          [value]="filter?.value"
                          [disabled]="isDisabled(filter)"
                          [title]="filter?.tooltip || ''"
                        >
                          {{ filter?.label }}
                        </option>
                      </ng-container>
                    </select>

                    <ng-container *ngIf="isDisabled(filter)">
                      <div class="re-filter-warning text-danger mt-1">
                        <span>
                          <img
                            class="img re-warning"
                            src="assets/images/warning-24x.png"
                            alt="warning"
                          />

                          {{ filter?.error }}
                        </span>
                      </div>
                    </ng-container>
                  </div>

                  <!-- OPERATOR -->
                  <div class="col-12 col-md-3 col-lg-3">
                    <select
                      [name]="filter.value + '-' + filterIndex"
                      [id]="filter.value + '-' + filterIndex"
                      class="form-control"
                      [(ngModel)]="filter.selectedOperator"
                    >
                      <ng-container *ngFor="let operator of filter?.operator">
                        <option [value]="operator?.value">
                          {{ operator?.label }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                  <div class="col-12 col-md-1 col-lg-1 text-center">
                    <a
                      class="re-delete"
                      (click)="removeFilter($event, filterIndex)"
                    >
                      <img src="assets/images/trash-bin.svg" alt="delete" />
                    </a>
                  </div>
                </div>
                <div class="row">
                  <!-- OPTIONS -->
                  <div
                    [ngClass]="
                      filter?.conditionType && filter?.conditionType.length
                        ? 'form-group col-12 col-md-12 col-lg-12'
                        : 'form-group col-12 col-md-12 col-lg-12'
                    "
                  >
                    <ng-container [ngSwitch]="filter?.optionsType">
                      <!-- SINGLE SELECT -->
                      <ng-container *ngSwitchCase="'SELECT'">
                        <select
                          [name]="filter.value + '-' + filterIndex"
                          [id]="filter.value + '-' + filterIndex"
                          class="form-control"
                          [(ngModel)]="filter.selectedOption"
                          (change)="modelChanged(filter)"
                        >
                          <ng-container *ngFor="let option of filter?.options">
                            <option [value]="option.value">
                              {{ option?.label }}
                            </option>
                          </ng-container>
                        </select>
                        <div
                          class="text-danger mb-2"
                          *ngIf="filter?.error == true"
                        >
                          <span> *Please select any option. </span>
                        </div>
                      </ng-container>

                      <!-- MULTIPLE SELECT -->
                      <ng-container *ngSwitchCase="'MULTI-SELECT'">
                        <p-multiSelect
                          #select
                          [name]="filter.value + '-' + filterIndex"
                          [id]="filter.value + '-' + filterIndex"
                          [placeholder]="'Select ' + filter?.label + '(s)'"
                          [options]="filter.options"
                          [(ngModel)]="filter.selectedOption"
                          appendTo="body"
                          (onChange)="modelChanged(filter)"
                        ></p-multiSelect>
                        <div
                          class="text-danger mb-2"
                          *ngIf="filter?.error == true"
                        >
                          <span> *Please select at least one option. </span>
                        </div>
                      </ng-container>

                      <!-- CHIPS -->
                      <ng-container *ngSwitchCase="'CHIPS'">
                        <mat-form-field class="re-chip-list">
                          <!-- PLACEHOLDER -->
                          <mat-placeholder
                            [ngClass]="
                              filter?.selectedOption?.length > 0
                                ? 'placeholder removePlaceHolder'
                                : 'placeholder'
                            "
                          >
                            {{ filter?.label }}
                          </mat-placeholder>

                          <!--  -->
                          <mat-autocomplete
                            #cityAutoComplete="matAutocomplete"
                            (optionSelected)="selected($event, filter)"
                          >
                            <ng-container
                              *ngFor="
                                let tag of getFilteredList(filter) | async
                              "
                            >
                              <mat-option
                                *ngIf="isVisible(filter, tag)"
                                [value]="tag"
                              >
                                {{ tag?.label }}
                              </mat-option>
                            </ng-container>
                          </mat-autocomplete>
                        </mat-form-field>

                        <div class="text-danger mb-2" *ngIf="filter?.error">
                          <span> *Please enter at least one value. </span>
                        </div>
                      </ng-container>

                      <!-- AUTO COMPLETE -->
                      <ng-container *ngSwitchCase="'AUTO-COMPLETE'">
                        <p-autoComplete
                          name="title"
                          appendTo="body"
                          [placeholder]="filter?.label"
                          [suggestions]="suggestionList"
                          [emptyMessage]="noDataAvailable"
                          [multiple]="filter.isMultiple"
                          [dropdown]="true"
                          [ngModelOptions]="{ standalone: true }"
                          [(ngModel)]="filter.selectedOption"
                          [forceSelection]="true"
                          (onDropdownClick)="
                            getSuggestionList($event.query, filter, true)
                          "
                          (completeMethod)="
                            getSuggestionList($event.query, filter)
                          "
                          (onSelect)="modelChanged(filter)"
                        >
                          <ng-template let-item pTemplate="selectedItem">
                            <span style="vertical-align: middle">
                              {{ item[filter.optionKey] }}
                            </span>
                          </ng-template>

                          <ng-template let-item pTemplate="item">
                            <div class="ui-helper-clearfix">
                              <div class="re-search-name">
                                {{ item[filter.optionKey] }}
                              </div>
                            </div>
                          </ng-template>
                        </p-autoComplete>

                        <div
                          class="text-danger mb-2"
                          *ngIf="filter?.error == true"
                        >
                          <span> *Please select at least one option. </span>
                        </div>
                      </ng-container>

                      <!-- RANGE -->
                      <ng-container *ngSwitchCase="'RANGE'">
                        <div class="row">
                          <div class="col-md-6">
                            <input
                              class="form-control"
                              type="number"
                              placeholder="{{
                                filter?.selectedOperator == 'is-between'
                                  ? 'Min'
                                  : 'Value'
                              }}"
                              min="0"
                              [(ngModel)]="filter.minVal"
                              onkeypress="return (event.charCode == 8) ? null : event.charCode >= 48 && event.charCode <= 57"
                              (change)="modelChanged(filter)"
                            />
                          </div>
                          <div
                            class="col-md-6"
                            *ngIf="filter?.selectedOperator == 'is-between'"
                          >
                            <input
                              class="form-control"
                              type="number"
                              placeholder="Max"
                              min="0"
                              [(ngModel)]="filter.maxVal"
                              onkeypress="return (event.charCode == 8) ? null : event.charCode >= 48 && event.charCode <= 57"
                              (change)="modelChanged(filter)"
                            />
                          </div>
                        </div>
                        <div class="text-danger mb-2" *ngIf="filter?.error">
                          {{ filter.rangeErrorMsg }}
                        </div>
                      </ng-container>

                      <!-- DATE-RANGE -->
                      <ng-container *ngSwitchCase="'DATE'">
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <select
                              [name]="filter.value + '-' + filterIndex"
                              [id]="filter.value + '-' + filterIndex"
                              class="form-control"
                              [(ngModel)]="filter.selectedOption"
                              (change)="modelChanged(filter)"
                            >
                              <ng-container
                                *ngFor="let option of filter?.options"
                              >
                                <option [value]="option.value">
                                  {{ option?.label }}
                                </option>
                              </ng-container>
                            </select>
                            <div
                              class="text-danger mb-2"
                              *ngIf="filter?.error == true"
                            >
                              <span> *Please select any option. </span>
                            </div>
                          </div>
                          <div class="col-12 col-md-4">
                            <p-calendar
                              styleClass="ngCalendarClass"
                              appendTo="body"
                              [icon]="'fa fa-calendar'"
                              [(ngModel)]="filter.minVal"
                              placeholder="{{
                                filter?.selectedOperator == 'between'
                                  ? 'From'
                                  : 'Select Date'
                              }}"
                              dateFormat="mm-dd-yy"
                              (onSelect)="setMaxDate(filter)"
                              [showButtonBar]="true"
                              [showIcon]="false"
                            ></p-calendar>
                          </div>
                          <div
                            class="col-12 col-md-4"
                            *ngIf="filter?.selectedOperator == 'between'"
                          >
                            <p-calendar
                              styleClass="ngCalendarClass"
                              appendTo="body"
                              [icon]="'fa fa-calendar'"
                              [(ngModel)]="filter.maxVal"
                              placeholder="To"
                              dateFormat="mm-dd-yy"
                              [minDate]="filter.minSelectDate"
                              [showButtonBar]="true"
                              [showIcon]="false"
                              (onSelect)="modelChanged(filter)"
                            ></p-calendar>
                          </div>
                        </div>
                        <div class="text-danger mb-2" *ngIf="filter?.error">
                          <span> {{ filter.rangeErrorMsg }} </span>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <div class="buttons dropdown-submenu">
            <a
              #addFilter
              class="re-agent-addFilter dropdown-toggle"
              data-toggle="dropdown"
              tabindex="-1"
              (click)="setSearchFocus()"
            >
              Add Filter Condition
            </a>

            <div class="dropdown-menu">
              <div class="input-group">
                <button type="button" class="input-group-addon" title="Search">
                  <span class="fa fa-search"></span>
                </button>
                <input
                  #search
                  type="text"
                  name="search-filter"
                  id="search-filter"
                  [(ngModel)]="searchFilter"
                  (keyup)="searchFilterFunction($event)"
                  placeholder="Search"
                  class="form-control"
                />
              </div>
              <div class="re-name-list">
                <!--for filters with grouping-->
                <div
                  *ngFor="let group of filterGroup"
                  [ngClass]="group?.show ? 're-filter-group' : ''"
                >
                  <ng-container *ngIf="group?.show">
                    <p class="re-filter-group-heading">{{ group?.name }}</p>
                  </ng-container>
                  <ng-container *ngFor="let filter of leadFilters">
                    <ng-container
                      *ngIf="
                        filter?.filterGroup &&
                        group?.name == filter?.filterGroup &&
                        filter?.show
                      "
                    >
                      <div
                        class="re-filter-name"
                        [class.re-drop-disabled]="isDisabled(filter)"
                        [class.d-none]="isActive(filter)"
                        (click)="selectFilter(filter)"
                      >
                        {{ filter?.label || "N/A" }}
                        <span
                          [pTooltip]="filter?.tooltip"
                          tooltipPosition="bottom"
                          *ngIf="filter?.isTooltipExists && isDisabled(filter)"
                        >
                          <i class="fa fa-question-circle"></i>
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>

                <!--for filters without grouping-->
                <div
                  *ngFor="
                    let filter of leadFilters
                      | SearchText : searchFilter : 'label'
                  "
                >
                  <div
                    class="re-filter-name"
                    [class.re-drop-disabled]="isDisabled(filter)"
                    [class.d-none]="isActive(filter)"
                    (click)="selectFilter(filter)"
                    *ngIf="!filter?.filterGroup"
                  >
                    {{ filter?.label || "N/A" }}
                    <span
                      [pTooltip]="filter?.tooltip"
                      tooltipPosition="bottom"
                      *ngIf="filter?.isTooltipExists && isDisabled(filter)"
                    >
                      <i class="fa fa-question-circle"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        (click)="save()"
        cdkFocusInitial
      >
        Confirm
      </button>
    </mat-dialog-actions>
  </div>
</div>
