import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseUrl } from "../../utils/base-url-constants";
import { environment } from "src/environments/environment";
import { CommonFunctionsService } from "src/app/utils/common-functions/common-functions.service";
@Injectable({
  providedIn: "root",
})
export class DripService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem("token");

  constructor(private _commonFunction: CommonFunctionsService) {}

  createDrip = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dripUrl + "create";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  listDrip = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dripUrl + "list";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  viewDrip = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dripUrl + "detail";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateDrip = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dripUrl + "edit";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteDrip = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dripUrl + "delete";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  assignDrip = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dripUrl + "assign";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  unassignDrip = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dripUrl + "unAssign";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  copyDrip = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dripUrl + "copy";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  stopDrip = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dripUrl + "stop";
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // getAllDripCampaign = (data): Observable<any> => {
  //   const endpoint = this.baseUrl + BaseUrl.dripUrl + "getAllDripCampaign";
  //   return this._commonFunction.globalPostService(endpoint, data);
  // };

  listDropDown = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dripUrl + "listDropDown";
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
