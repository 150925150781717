import { VersionConstant } from "./version-constant";
export class BaseUrl {
  public static user = VersionConstant.v1 + "user/";
  public static mainStatus = VersionConstant.v1 + "mainStatus/";
  public static leadUrl = VersionConstant.v1 + "lead/";
  public static moduleUrl = VersionConstant.v1 + "module/";
  public static resetPasswordRedirectLink = "auth/reset-password";
  public static confirmEmailRedirectLink = "auth/confirm-email/";
  public static plans = VersionConstant.v1 + "plans/";
  public static creditUrl = VersionConstant.v1 + "credit/";
  public static masterGroupUrl = VersionConstant.v1 + "masterGroup/";
  public static masterQuestionUrl = VersionConstant.v1 + "masterQuestion/";
  public static sharedUrl = VersionConstant.v1 + "shared/";
  public static leadSourceUrl = VersionConstant.v1 + "leadSource/";
  public static relationshipUrl = VersionConstant.v1 + "relationship/";
  public static melissaUrl = VersionConstant.v1 + "melissa/";
  public static teamUrl = VersionConstant.v1 + "team/";
  public static roleUrl = VersionConstant.v1 + "role/";
  public static globalTagsUrl = VersionConstant.v1 + "global-tags/";
  public static taskTemplateUrl = VersionConstant.v1 + "taskTemplate/";
  public static activityUrl = VersionConstant.v1 + "activity/";
  public static dashboardUrl = VersionConstant.v1 + "dashboard/";
  public static appointmentUrl = VersionConstant.v1 + "appointment/";
  public static taskUrl = VersionConstant.v1 + "task/";
  public static fileUrl = VersionConstant.v1 + "file/";
  public static folderUrl = VersionConstant.v1 + "folder/";
  public static googleMapUrl = VersionConstant.v1 + "googleMap/";
  public static contingencyUrl = VersionConstant.v1 + "contingency/";
  public static projectTypeUrl = VersionConstant.v1 + "projectType/";
  public static billingUrl = VersionConstant.v1 + "billing/";
  public static blockNumberUrl = VersionConstant.v1 + "blocknumber/";
  public static callFlowUrl = VersionConstant.v1 + "flow/";
  public static buyNumber = VersionConstant.v1 + "buyNumber/";
  public static mailTemplateUrl = VersionConstant.v1 + "mailTemplate/";
  public static smsTemplateUrl = VersionConstant.v1 + "smsTemplate/";
  public static kpisUrl = VersionConstant.v1 + "kpis/";
  public static linkTwilio = VersionConstant.v1 + "twilio/";
  public static numberUrl = VersionConstant.v1 + "number/";
  public static rvmUrl = VersionConstant.v1 + "audioTrack/";
  public static sendGridUrl = VersionConstant.v1 + "send-grid/";
  public static googleCalendarUrl = VersionConstant.v1 + "googleCalendar/";
  public static communicationUrl = VersionConstant.v1 + "communications/";
  public static dripUrl = VersionConstant.v1 + "dripCampaign/";
  public static filterTemplateUrl = VersionConstant.v1 + "filterTemplate/";
  public static agentUrl = VersionConstant.v1 + "agent/";
  public static offerUrl = VersionConstant.v1 + "offer/";
  public static showingUrl = VersionConstant.v1 + "showing/";
  public static openHouseUrl = VersionConstant.v1 + "openHouse/";
  public static vendorCategoryUrl = VersionConstant.v1 + "vendorCategory/";
  public static vendorSubCategoryUrl =
    VersionConstant.v1 + "vendorSubCategory/";
  public static callLogUrl = VersionConstant.v1 + "callLog/";
  public static directMailTemplate = VersionConstant.v1 + "directMail/";
  public static scheduledSmsUrl = VersionConstant.v1 + "scheduledsms/";
  public static contactUrl = VersionConstant.v1 + "contact/";
  public static inboxUrl = VersionConstant.v1 + "inbox/";
  public static smsUrl = VersionConstant.v1 + "sms-bulk/";
  public static templateUrl = VersionConstant.v1 + "bulkEmailTemplate/";
  public static quickFilterUrl = VersionConstant.v1 + "quickFilter/";
}
