import { Injectable } from "@angular/core";
import { Route, Router, CanLoad } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LazyGuard implements CanLoad {
  public token: any;
  constructor(private router: Router) {}

  canLoad(): boolean {
    return this.checkSession();
  }

  checkSession() {
    this.token = localStorage.getItem("token");
    if (this.token != undefined && this.token != "") {
      return true;
    } else {
      this.router.navigate(["/auth"]);
      return false;
    }
  } //Check Session ends here

  redirectRoute() {
    this.router.navigate([environment.defaultPath]);
  } //Redirect
}
