// MODULES
import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as RecordRTC from "recordrtc";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";

// MODEL AND CONSTANT
import { environment } from "../../../../environments/environment";
import { BaseUrl } from "../../../utils/base-url-constants";
import { MessageConstant } from "src/app/utils/message-constant";

// SERVICES
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { StatusConstant } from "src/app/utils/status-constant";

@Component({
  selector: "app-add-recording",
  templateUrl: "./add-recording.component.html",
  styleUrls: ["./add-recording.component.scss"],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
      }
    `,
  ],
})
export class AddRecordingComponent implements OnInit {
  dataKey: any;
  title = "micRecorder";
  record;
  recording = false;
  url;
  error;
  countUpConfig: any;
  uploadInput: any;
  moduleId: string;
  originName = "";
  public moduleData: any;
  uploadFiles: any[] = [];
  imageArr: any[] = [];
  fileUrl: any;
  fileName: any;
  fileResponse: any;
  fileUpload = false;
  audioTrack: any;
  titleGreeting: any = "Record Greeting";
  time: number = 0;
  display;
  interval;

  constructor(
    private dialog: MatDialogRef<AddRecordingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizer: DomSanitizer,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private http: HttpClient
  ) {
    this.titleGreeting = this.data.title;
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  // Start recording.

  initiateRecording() {
    this.recording = true;
    const mediaConstraints = {
      video: false,
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }
  // Will be called automatically.

  successCallback(stream) {
    this.audioTrack = stream;
    const options = {
      mimeType: "audio/mp3",
      numberOfAudioChannels: 1,
    };
    // Start Actuall Recording

    const StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
    this.startTimer();
  }
  // Stop recording.

  stopRecording() {
    this.recording = false;
    this.audioTrack.getTracks()[0].stop();
    this.record.stop(this.processRecording.bind(this));
  }

  async processRecording(blob) {
    this.url = URL.createObjectURL(blob);
    const d = new Date();
    const file = new File([blob], String(d.valueOf()), { type: blob.type });
    const token = localStorage.getItem("token");
    this.moduleId = StatusConstant?.ModuleId?.LEAD;
    // for rvm type should be 1, 2 for mms and 0 for rest
    let endpoint = environment.baseUrl + BaseUrl.sharedUrl + "file-upload";
    this._loaderService.start();
    if (this.moduleId) {
      endpoint += `?moduleId=${this.moduleId}&isPublic=1&type=1`;
    }
    const fd = new FormData();
    const filename = "Recording-" + d.getTime() + "." + blob.type.split("/")[1];

    fd.append("files", file, filename);

    this.fileResponse = await this.http.post(endpoint, fd).toPromise();
    if (this.fileResponse && this.fileResponse.statusCode === 200) {
      this._loaderService.stop();
      this.fileUpload = true;
      this.fileUrl = this.fileResponse?.data[0].url;
      this.fileName = filename;
    } else {
      this._loaderService.stop();
      this._toastrService.error(MessageConstant?.unknownError);
    }
  }
  // Process Error.

  errorCallback(error) {
    this.error = "Can not play audio in your browser";
  }

  ngOnInit(): void {}

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
      this.display = this.transform(this.time);
    }, 1000);
  }

  transform(value: number): string {
    const sec_num = value;
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 1) {
      hours = 0;
    }
    if (minutes < 1) {
      minutes = 0;
    }
    if (seconds < 1) {
      seconds = 0;
    }
    return (
      (hours > 9 ? hours : "0" + hours) +
      ":" +
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds)
    );
  }

  addKey() {
    const data = {
      url: this.fileUrl !== "" ? this.fileUrl : "",
      moduleId: this.moduleId,
      name: this.fileName,
      originName: this.fileName,
    };
    this.dialog.close(data);
  }

  closeKey() {
    this.recording = false;
    if (
      this.audioTrack?.getTracks() &&
      this.audioTrack?.getTracks()[0] !== undefined
    ) {
      this.audioTrack.getTracks()[0].stop();
      this.record.stop(this.processRecording.bind(this));
    }
    this.dialog.close();
  }
}
