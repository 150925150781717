<div>
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        Delete Team
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <mat-dialog-content class="">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="re-notification-box padd-15">
            <h3 class="re-title">
              You are deleting <b>{{ data?.details?.title }}</b
              >. Do you want to move existing leads assigned from
              <b>{{ data?.details?.title }}</b> to <b>{{ newTeam?.title }}</b>.
            </h3>
            <div class="col-12 col-md-12">
              <div class="form-group">
                <label class="labels">Team List</label>
                <select
                  name=""
                  class="form-control"
                  [(ngModel)]="selectedTeam"
                  (change)="selectNewTeam()"
                >
                  <option value="">Select Team</option>
                  <option *ngFor="let item of teamList" value="{{ item?._id }}">
                    {{ item?.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="false" (click)="onCancel()">
      Cancel
    </button>
    <button
      (click)="submit()"
      mat-raised-button
      color="primary"
      cdkFocusInitial
    >
      Submit
    </button>
  </mat-dialog-actions>
</div>
