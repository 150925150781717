import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { BaseUrl } from "../../utils/base-url-constants";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SmsService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem("token");

  constructor(private http: HttpClient) {}

  getSmsList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsTemplateUrl + "list";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addSmsTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsTemplateUrl + "save";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  editSmsTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsTemplateUrl + "edit";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteSmsTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsTemplateUrl + "delete";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  changeTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsTemplateUrl + "changeTemplate";
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };
}
